import React, { useState } from 'react';

import classes from '../../../assets/Accordion.module.css';
import classesGlobal from '../../../assets/Global.module.css';
import View from '../../../helpers/slab/View';
import ViewVendor from '../vendor/Vendor';
import OptionList from '../../../helpers/options/OptionList';
import PutForm from '../../../api/internal/PutForm';
import Delete from '../../../api/internal/Delete';
import DeleteButton from '../../../helpers/buttons/DeleteButton';
import Button from '../../../assets/essentials/Button';
import Select from '../../../assets/essentials/Select';
import Input from '../../../assets/essentials/Input';
import TextArea from '../../../assets/essentials/TextArea';
import Form from '../../../assets/essentials/Form';

function Vendor({
  loadId,
  vendor,
  RefreshPage,
}: {
  loadId: number;
  vendor: LoadVendor;
  RefreshPage: Function;
}) {
  const [isOpen, setIsOpen] = useState(false);
  function UpdateVendorAssignment(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    PutForm(`/Load/UpdateVendorAssignment`, formData, {
      loadId: loadId,
      vendorId: vendor.vendorId,
      vendorAssignmentId: vendor.vendorAssignmentId,
    }).then(() => RefreshPage('Summary'));
    setIsOpen(false);
  }

  function DeleteVendorAssignment() {
    Delete(`/Load/DeleteVendorAssignment/${vendor.vendorAssignmentId}`).then(
      () => RefreshPage('Summary', ['Audit']),
    );
    setIsOpen(false);
  }
  return (
    <>
      <div className={classes.accordionSummary}>
        <div className={classesGlobal.viewGrid}>
          <div className={`${classesGlobal.attribute} ${classesGlobal.span3}`}>
            <span onClick={() => setIsOpen(!isOpen)}>
              <b>Name: </b>
              {vendor.vendorName}
            </span>
            <span onClick={() => setIsOpen(!isOpen)}>
              <b>Phone: </b>
              {vendor.phoneNumber}
            </span>
            <div
              className={`${classesGlobal.cTA} ${classesGlobal.attribute}`}
              onClick={() =>
                View(
                  <ViewVendor
                    vendorId={vendor.vendorId}
                    vendorName={vendor.vendorName}
                  />,
                )
              }
            >
              Manage Vendor Info
            </div>
          </div>
        </div>
      </div>
      <div className={`${classes.expandable} ${classes.vendor}`}>
        {isOpen && (
          <Form onSubmit={e => UpdateVendorAssignment(e)}>
            <div className={classesGlobal.attribute}>
              <label>Contact</label>
              <Select
                defaultValue={vendor.vendorContactId}
                name="vendorContactId"
              >
                <option key="disabled" disabled>
                  Select One
                </option>
                <OptionList
                  optionData={vendor.vendorContactOptions}
                  attributeID="vendorContactId"
                  attributeName="contactNamePosition"
                />
              </Select>
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>Phone</label>
              <Input
                disabled
                placeholder="Phone"
                defaultValue={vendor.phoneNumber}
              />
            </div>
            <div className={classesGlobal.attribute}>
              <label>Address</label>
              <Input
                disabled
                defaultValue={
                  vendor.addressLineOne + ' ' + vendor.addressLineTwo
                }
              />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>City, State</label>
              <Input
                disabled
                placeholder="City, State"
                defaultValue={vendor.cityState}
              />
            </div>
            <div className={classesGlobal.attribute}>
              <label>Zip</label>
              <Input disabled defaultValue={vendor.zip} />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>Country</label>
              <Input disabled defaultValue={vendor.country} />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span3}`}
            >
              <label>Notes</label>
              <TextArea defaultValue={vendor.note} name="note" />
            </div>
            <div>
              <Button type="submit" variant="good" disabled={true}>
                Save Changes
              </Button>
            </div>
            <DeleteButton
              OnDelete={DeleteVendorAssignment}
              buttonLabel={'Delete'}
            />
          </Form>
        )}
      </div>
    </>
  );
}

export default Vendor;
