import React from 'react';
import classes from '../../../assets/Global.module.css';
import classesEmail from './BulkEmailPDFs.module.css';
import Input from '../../../assets/essentials/Input';
import TextArea from '../../../assets/essentials/TextArea';

const EmailMessage = ({ email, setEmail }) => {

  return (
    <>
      <h1>Email Message</h1>
      <form>
        <div className={classes.attribute}>
          <label htmlFor="subject">
            Subject <span className={classes.required}>*</span>
          </label>
          <Input
            required
            type="text"
            defaultValue={email.subject}
            id="subject"
            name="subject"
            onBlur={e =>
              setEmail(prevEmail => ({
                ...prevEmail,
                subject: e.target.value,
              }))
            }
          />
        </div>
        <div className={classes.attribute}>
          <label htmlFor="body">Body: </label>
          <TextArea
            required
            defaultValue={email.body}
            id="body"
            name="body"
            rows={12}
            onBlur={e =>
              setEmail(prevEmail => ({
                ...prevEmail,
                body: e.target.value,
              }))
            }
          />
          <p className={classesEmail.reminder}>Reminder: This message will be sent to all selected drivers.</p>
        </div>
      </form>
    </>
  );
};

export default EmailMessage;
