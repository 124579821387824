import React, { useState } from 'react';
import { MdOutlineCleaningServices } from 'react-icons/md';

import classes from '../../assign/Assign.module.css';
import classesGlobal from '../../../assets/Global.module.css';
import Button from '../../../assets/essentials/Button';
import PostForm from '../../../api/internal/PostForm';
import Form from '../../../assets/essentials/Form';
import Input from '../../../assets/essentials/Input';

function Customer({
  customerId,
  RefreshPage,
}: {
  customerId: Number;
  RefreshPage: Function;
}) {
  const [isOpen, setIsOpen] = useState(false);
  function AddLane(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    PostForm(`/Customer/CreateCustomerLane`, formData, {
      customerId: customerId,
    })
      .then(() => RefreshPage('Lanes', ['Audit']))
      .catch(error => {
        const errorMessage = error.response.data;
        if (errorMessage.includes('already exists')) {
          alert(error.response.data);
        }
      });
    setIsOpen(false);
  }

  return (
    <>
      <div className={classes.btnHolder}>
        <button type="button" onClick={() => setIsOpen(!isOpen)}>
          <MdOutlineCleaningServices />
          <span>Add Lane</span>
        </button>
      </div>
      <div className={`${classes.expandable} ${classes.lane}`}>
        {isOpen && (
          <Form onSubmit={e => AddLane(e)}>
            <div className={classesGlobal.attribute}>
              <label>
                Start Zip <span className={classesGlobal.required}>*</span>
              </label>
              <Input type="string" name="startZip" required />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>
                End Zip <span className={classesGlobal.required}>*</span>
              </label>
              <Input type="string" name="endZip" required />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span3}`}
            >
              <label>
                Rate <span className={classesGlobal.required}>*</span>
              </label>
              <Input type="number" name="rate" step="0.01" required />
            </div>
            <div>
              <Button type="submit" variant="good" disabled={true}>
                Submit
              </Button>
            </div>
            <div>
              <Button onClick={() => setIsOpen(!isOpen)}>Cancel</Button>
            </div>
          </Form>
        )}
      </div>
    </>
  );
}

export default Customer;
