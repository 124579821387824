import React from 'react';
import BBITable from '../../../helpers/bBITable/BBITable';
import View from '../../../helpers/slab/View';
import ViewMaintenanceLog from '../../../features/views/assets/maintenanceLog/MaintenanceLog';

function MaintenanceLogs({ data }: { data: Array<AssetMaintenanceLog> }) {
  
  const formattedMaintenanceLogData = data?.map(log => ({
    ...log,
    totalCost: log.totalCost.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }),
  }));

  let columnConfig = [
    {
      key: '1',
      attribute: 'invoiceNumber',
      attributeprimaryhidden: 'maintenanceLogId',
      header: 'Invoice #',
      onClick: e =>
        View(
          <ViewMaintenanceLog
            maintenanceLogId={e.target.dataset.attributeprimaryhidden}
            invoiceNumber={e.target.innerText}
          />,
        ),
    },
    {
      key: '2',
      attribute: 'vendorName',
      header: 'Vendor',
    },
    {
      key: '3',
      attribute: 'formattedBillDate',
      header: 'Bill Date',
    },
    {
      key: '4',
      attribute: 'description',
      header: 'Description',
    },
    {
      key: '5',
      attribute: 'totalCost',
      header: 'Cost',
      dataType: 'currency',
    },
  ];

  return (
    <BBITable
      data={formattedMaintenanceLogData}
      columns={columnConfig}
      stickyTableHead={false}
    />
  );
}
export default MaintenanceLogs;
