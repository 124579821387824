import React from 'react';

import classes from '../../../assets/Global.module.css';
import Appointment from '../../templates/Appointment';
import OptionList from '../../../helpers/options/OptionList';
import Select from '../../../assets/essentials/Select';
import CreateLocationFromBody from '../location/LocationBody';
import StopInfo from '../../templates/StopInfo';

function StopList({
  stop,
  createLoad,
  UpdateParam,
  stopIndex,
  requirementDisplay,
}: {
  stop: CreateStopParam;
  createLoad: CreateLoadOptions;
  UpdateParam: Function;
  stopIndex: number;
  requirementDisplay: LoadRequirementDisplay;
}) {
  return (
    <React.Fragment key={stopIndex}>
      <h1>Stop #{stopIndex + 1}</h1>
      <div className={classes.viewGrid}>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>
            Stop Type <span className={classes.required}>*</span>
          </label>
          <Select
            defaultValue={stop.stopTypeId ? stop.stopTypeId : ''}
            onChange={e => {
              UpdateParam(e.target.value, 'stopTypeId', stopIndex);
              requirementDisplay.stopType =
                e.target.options[e.target.selectedIndex].text;
            }}
          >
            <option disabled value="">
              -- Required --
            </option>
            <OptionList
              optionData={createLoad?.stopTypeOptions}
              attributeID="stopTypeId"
              attributeName="type"
            />
          </Select>
        </div>
        <Appointment
          stop={stop}
          timeZoneOptions={createLoad?.timeZoneOptions}
          UpdateParam={UpdateParam}
          stopIndex={stopIndex}
        />
        <CreateLocationFromBody
          stop={stop}
          UpdateParam={UpdateParam}
          stopIndex={stopIndex}
        />
        <StopInfo stop={stop} UpdateParam={UpdateParam} stopIndex={stopIndex} />
      </div>
    </React.Fragment>
  );
}

export default StopList;
