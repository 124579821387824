import { pca } from '..';

/**
 * Custom React hook to check if a user is authorized based on their roles.
 *
 * @param {string[]} permittedRoles - An array of roles that the user is required to have for authorization, defined in AuthRoles.tsx
 * @returns {boolean} - A Boolean indicating whether the user is authorized (true) or not (false).
 * @example
 * // Import the hook
 * import { useRoleAuthorization } from './useRoleAuthorization';
 *
 * // Define the roles required for authorization defined in the enV files
 * const requiredRoles = [process.env.REACT_APP_DEFAULT_ROLE, process.env.REACT_APP_ACCOUNTING_ROLE];
 * // Or
 * const requiredRoles = ADMIN_AUTHORIZED_ROLES;
 *
 * // Use the hook to check if the current user is authorized
 * const isAuthorized = useRoleAuthorization(requiredRoles);
 *
 * if (isAuthorized) {
 *   // Render authorized content
 *   return <div>Authorized Content</div>;
 * } else {
 *   // Render unauthorized content or a redirection to a login page
 *   return <div>Unauthorized Content</div>;
 * }
 * //A more condensed example
 *  {useRoleAuthorization(ACCOUNTING_AUTHORIZED_ROLES) && <AccountingSection />}
 */

export const useRoleAuthorization = (permittedRoles: string[]): boolean => {
  const currentUserRoles = pca.getAllAccounts()[0]?.idTokenClaims?.roles ?? [];

  const IsAuthorized = (
    permittedRoles: string[],
    currentUserRoles: string[],
  ) => {
    return permittedRoles.some(role => currentUserRoles.includes(role));
  };

  return IsAuthorized(permittedRoles, currentUserRoles);
};
