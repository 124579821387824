import React, { useEffect, useState } from 'react';
import Slab from '../../../helpers/slab/Slab';
import Summary from './Summary';
import Audit from '../generic/Audit';
import Files from './../../creates/file/Files';
import Admin from './Admin';
import Get from '../../../api/internal/Get';
import { useRoleAuthorization } from '../../../hooks/useRoleAuthorization';
import { ADMIN_AUTHORIZED_ROLES } from '../../../auth/AuthRoles';
import RentalFinancials from './Financials';

function Rental({
  rentalId,
  startPage,
}: {
  rentalId: number;
  startPage?: string;
}) {
  const [currentPage, setCurrentPage] = useState();
  const [rentalStatus, setRentalStatus] = useState();
  const [rentalDataObject] = useState<RentalDataObject>({
    Summary: null,
    Financials: null,
    Files: null,
    Audit: null,
  });

  useEffect(() => {
    GetRental();
    startPage ? HandlePageChange(startPage) : HandlePageChange('Summary');
  }, []);

  function GetRental() {
    Get(`/Rental/GetRental/${rentalId}`).then(response => {
      if (response) {
        setRentalStatus(response.data.rentalStatus);
      }
    });
  }

  function HandlePageChange(params) {
    if (!rentalDataObject[params] && pages[params].callerPath) {
      Get(pages[params].callerPath).then(response => {
        if (response) {
          rentalDataObject[params] = response.data;
        }
        AssignPages();
        setCurrentPage(params);
      });
    } else {
      setCurrentPage(params);
    }
  }

  function RefreshPage(currentPage: string, additionalPages: string[]) {
    if (rentalDataObject) {
      setCurrentPage(null);
      rentalDataObject[currentPage] = null;
      additionalPages?.forEach(pageName => {
        rentalDataObject[pageName] = null;
      });
      HandlePageChange(currentPage);
    }
  }

  let pages = {};
  AssignPages();
  function AssignPages() {
    pages = {
      Summary: {
        content: (
          <Summary
            data={rentalDataObject.Summary}
            rentalId={rentalId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Rental/GetRentalSummary/${rentalId}`,
      },
      Financials: {
        content: (
          <RentalFinancials
            data={rentalDataObject.Financials}
            rentalId={rentalId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Rental/GetRentalFinancials/${rentalId}`,
      },
      Files: {
        content: (
          <Files
            data={rentalDataObject.Files}
            categoryId={rentalId}
            fileCategory={'rental'}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/File/GetAllFiles/rental-${rentalId}`,
      },
      Audit: {
        content: <Audit data={rentalDataObject.Audit} />,
        callerPath: `/Rental/GetRentalAudit/${rentalId}`,
      },
    };
    if (useRoleAuthorization(ADMIN_AUTHORIZED_ROLES))
      pages['Admin'] = {
        content: (
          <Admin
            rentalStatus={rentalStatus}
            rentalId={rentalId}
            RefreshPage={RefreshPage}
            GetRental={GetRental}
          />
        ),
      };
  }

  return (
    <Slab
      title={
        <>
          Rental #{rentalId}
          <p>{rentalStatus}</p>
        </>
      }
      pages={pages}
      currentPage={currentPage}
      HandlePageChange={HandlePageChange}
      dataObject={rentalDataObject}
    />
  );
}
export default Rental;
