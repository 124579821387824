import React, { useState } from 'react';
import { BsTrash } from 'react-icons/bs';
import { IoCreateOutline } from 'react-icons/io5';

import Form from '../../../../assets/essentials/Form';
import Input from '../../../../assets/essentials/Input';
import Button from '../../../../assets/essentials/Button';
import classes from '../../../assign/Assign.module.css';
import classesGlobal from '../../../../assets/Global.module.css';
import BBITable from '../../../../helpers/bBITable/BBITable';
import Delete from '../../../../api/internal/Delete';
import PostForm from '../../../../api/internal/PostForm';
import TextArea from '../../../../assets/essentials/TextArea';
import PutForm from '../../../../api/internal/PutForm';
import CurrencyInput from '../../../../helpers/inputs/CurrencyInput';
import PercentageInput from '../../../../helpers/inputs/PercentageInput';

function Rate({
  data,
  RefreshPage,
  employeeId,
}: {
  data: DriverRate;
  RefreshPage: Function;
  employeeId: Number;
}) {
  const [isOpen, setIsOpen] = useState(false);

  let lineItemsColumnConfig = [
    {
      key: '1',
      attribute: 'accountType',
      header: 'Type',
    },
    {
      key: '2',
      attribute: 'lessThanMiles',
      header: 'Less Than Miles',
    },
    {
      key: '3',
      attribute: 'rate',
      header: 'Rate',
      width: '12ch',
    },
    {
      key: '4',
      attribute: 'note',
      header: 'Note',
    },
    {
      key: '5',
      attribute: 'delete',
      header: '',
      width: '3ch',
    },
  ];

  const tableData = data.driverAdditionalRates.map(rate => ({
    ...rate,
    delete: (
      <BsTrash
        className={classesGlobal.clickable}
        onClick={() => {
          DeleteAdditionalRate(rate.driverAdditionalRateId);
        }}
      />
    ),
  }));

  function CreateAdditionalRate(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    PostForm(`/Employee/CreateDriverAdditionalRate`, formData, {
      employeeId: employeeId,
    }).then(() => RefreshPage('Rate', ['Audit']));
    setIsOpen(false);
  }

  function UpdateDriverRate(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    //remove $ and , from string
    formData.set(
      'ratePerMile',
      e.target.ratePerMile.value.replace(/[\$,]/g, ''),
    );
    // change whole number into percentage before sending to back end
    const percentageValue = e.target.loadRevenuePercentage.value
      ? e.target.loadRevenuePercentage.value.replace(/[%]/g, '')
      : 0;
    const decimalValue = parseFloat(percentageValue) / 100;
    formData.set('loadRevenuePercentage', decimalValue.toString());
    PutForm(`/Employee/UpdateDriverRate`, formData, {
      employeeId: employeeId,
    }).then(() => RefreshPage('Rate', ['Audit']));
  }

  function DeleteAdditionalRate(DriverAdditionalRateId) {
    Delete(
      `/Employee/DeleteDriverAdditionalRate/${DriverAdditionalRateId}`,
    ).then(() => {
      RefreshPage('Rate');
    });
  }
  return (
    <>
      <div>
        <h3>Driver Pay Rates</h3>
        <div>
          <Form
            onSubmit={e => {
              UpdateDriverRate(e);
            }}
          >
            <div className={classesGlobal.attribute}>
              <label>Rate Per Mile</label>
              <CurrencyInput
                name="ratePerMile"
                defaultValue={data.ratePerMile}
              />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>Load Revenue Percentage</label>
              <PercentageInput
                name="loadRevenuePercentage"
                defaultValue={data.loadRevenuePercentage * 100} //mult by 100 so it shows as full number ("60% vs 0.60%")
              />
            </div>
            <div>
              <Button type="submit" variant="good" disabled={true}>
                Save Changes
              </Button>
            </div>
          </Form>
        </div>
      </div>

      <div>
        <div>
          <h3>Additional Rates</h3>
        </div>
        <div className={classes.btnHolder}>
          <button type="button" onClick={() => setIsOpen(!isOpen)}>
            <IoCreateOutline />
            <span>Create Additional Rate</span>
          </button>
        </div>
        <div className={classes.createRateHolder}>
          {isOpen && (
            <Form
              onSubmit={e => {
                CreateAdditionalRate(e);
              }}
            >
              <div className={classesGlobal.attribute}>
                <label>Rate</label>
                <Input required name="rate" />
              </div>
              <div
                className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
              >
                <label>Less Than Miles</label>
                <Input required defaultValue={0} name="lessThanMiles" />
              </div>
              <div
                className={`${classesGlobal.attribute} ${classesGlobal.span3}`}
              >
                <label>Note</label>
                <TextArea defaultValue={''} name="note" />
              </div>

              <div>
                <Button type="submit" variant="good">
                  Submit
                </Button>
              </div>
              <div>
                <Button onClick={() => setIsOpen(!isOpen)}>Cancel</Button>
              </div>
            </Form>
          )}
        </div>
      </div>
      <div className={`${classesGlobal.attribute} ${classesGlobal.span3}`}>
        <BBITable data={tableData ?? []} columns={lineItemsColumnConfig} />
      </div>
    </>
  );
}
export default Rate;
