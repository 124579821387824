import React from 'react';
import classes from '../../../../assets/Global.module.css';

function Summary({ data }: { data: DriverSummary }) {
  return (
    <>
      <div className={classes.viewGrid}>
        <div className={classes.attribute}>
          <h3>Driver Mobile</h3>
          <span>{data?.driverMobile}</span>
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <h3>Driver Home</h3>
          <span>{data?.driverPhone}</span>
        </div>
        <div className={classes.attribute}>
          <h3>Preferred Truck</h3>
          <span>{data?.preferredTruckName}</span>
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <h3>Preferred Trailer</h3>
          <span>{data?.preferredTrailerName}</span>
        </div>
        <div className={classes.attribute}>
          <h3>Company</h3>
          <span>{data?.company}</span>
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <h3>Preferred Dispatch</h3>
          {data?.preferredDispatchName !== 'None' &&
          data?.preferredDispatchPhone !== 'None' ? (
            <>
              <span>{data?.preferredDispatchName}</span>
              <span>{data?.preferredDispatchPhone}</span>
            </>
          ) : (
            <span>None</span>
          )}
        </div>
        <div className={classes.attribute}>
          <h3>Note</h3>
          <span>{data?.note}</span>
        </div>
      </div>
    </>
  );
}
export default Summary;
