import React, { useState, useRef, useEffect } from 'react';
import classes from './BBITable.module.css';

function Resizable({ column, columnWidth, HandleResizeStop, children }) {
  const [width, setWidth] = useState(column.width || 'auto');
  const [isResizing, setIsResizing] = useState(false);

  const HandleMouseMove = event => {
    if (!isResizing) {
      return;
    }
    const { left: containerLeft } =
      containerRef.current.getBoundingClientRect();
    const newWidth = event.clientX - containerLeft;
    setWidth(newWidth);
  };

  const HandleMouseDown = event => {
    setIsResizing(true);
  };

  const HandleMouseUp = event => {
    setIsResizing(false);
    HandleResizeStop(event, { size: { width } }, column.key);
  };

  const containerRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousemove', HandleMouseMove);
    document.addEventListener('mouseup', HandleMouseUp);
    return () => {
      document.removeEventListener('mousemove', HandleMouseMove);
      document.removeEventListener('mouseup', HandleMouseUp);
    };
  }, [isResizing]);

  return (
    <th
      style={{ width: columnWidth || width }}
      onMouseDown={HandleMouseDown}
      className={isResizing ? classes.resizing : classes.resizable}
    >
      <div ref={containerRef}>{children}</div>
    </th>
  );
}

export default Resizable;
