import Header from '../../layouts/Header';
import Filter from '../../helpers/filter/Filter';
import View from '../../helpers/slab/View';
import ViewDriver from '../../features/views/users/driver/Driver';
import { BiGasPump } from 'react-icons/bi';
import Create from '../../helpers/panel/Create';
import { useState, useEffect } from 'react';
import FilterData from '../../helpers/filter/FilterData';
import BBITable from '../../helpers/bBITable/BBITable';
import Get from '../../api/internal/Get';
import { CSVLink } from 'react-csv';
import { MdDownload } from 'react-icons/md';
import CreateGas from '../../features/creates/gas/Gas';
import classesGlobal from '../../assets/Global.module.css';

function Gas({ toggleBoardRefresh }) {
  const [filterValue, setFilterValue] = useState('');
  const [employeeSavingsAccountBoard, setEmployeeSavingsAccountBoard] =
    useState([]);

  const csvFileName =
    new Date().toISOString().split('T')[0].replace(/[-]/g, '') +
    '_ESA Report.csv';

  //Call needs created for new ESA feature
  useEffect(() => {
    GetGasBoard();
  }, [toggleBoardRefresh]);

  function GetGasBoard() {
    Get(`/Accounting/GetEmployeeSavingsAccountBoard`).then(response => {
      setEmployeeSavingsAccountBoard(response.data);
    });
  }
  const formattedEmployeeSavingsAccountBoard = employeeSavingsAccountBoard.map(
    employeeSavingsAccount => ({
      ...employeeSavingsAccount,
      balance: employeeSavingsAccount.balance.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    }),
  );

  let columnConfig = [
    {
      key: '1',
      attribute: 'employeeName',
      attributeprimaryhidden: 'employeeId',
      header: 'Employee',
      onClick: e =>
        View(
          <ViewDriver
            driverName={e.target.innerText}
            employeeId={e.target.dataset.attributeprimaryhidden}
            startPage={'ESA'}
          />,
        ),
    },
    {
      key: '2',
      attribute: 'balance',
      header: 'Balance',
      dataType: 'currency',
    },
  ];

  let tableData = FilterData(formattedEmployeeSavingsAccountBoard, filterValue);

  return (
    <>
      <h1>Gas</h1>
      <Header>
        <div>
          <button
            onClick={() => Create(<CreateGas GetGasBoard={GetGasBoard} />)}
          >
            <BiGasPump />
            <span>Create Gas Report</span>
          </button>
          <button>
            <CSVLink
              data={formattedEmployeeSavingsAccountBoard}
              headers={[
                { label: 'Employee Number', key: 'employeeId' },
                { label: 'Employee', key: 'employeeName' },
                { label: 'Balance', key: 'balance' },
              ]}
              filename={csvFileName}
            >
              <MdDownload />
              <span>Download</span>
            </CSVLink>
          </button>
        </div>
        <div>
          <Filter
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            placeholder="Filter employees"
          />
        </div>
      </Header>
      <BBITable data={tableData} columns={columnConfig} />
    </>
  );
}

export default Gas;
