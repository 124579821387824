import React from 'react';

import Input from '../../../assets/essentials/Input';
import Form from '../../../assets/essentials/Form';
import StatesAndProvinces from '../../../helpers/options/StatesAndProvinces';
import classes from '../../../assets/Global.module.css';
import Select from '../../../assets/essentials/Select';
import PutForm from '../../../api/internal/PutForm';
import Button from '../../../assets/essentials/Button';

export default function Admin({
  data,
  locationId,
  RefreshPage,
}: {
  data: LocationAdmin;
  locationId: number;
  RefreshPage: Function;
}) {
  function UpdateLocationAddress(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    // Removing the default form data where "disableValidation" returns 'on' if it is checked
    formData.delete('disableValidation');
    const appendix = {
      locationId: locationId,
      // Reading disableValidation as a boolean
      disableValidation: e.target.disableValidation.checked,
    };
    PutForm(`/Location/UpdateLocationAddress`, formData, appendix).then(
      response => {
        if (response) {
          RefreshPage('Admin', ['Audit', 'Summary', 'Details']);
        }
      },
    );
  }

  const UpdateAddressInputs = ({ data }) => {
    return (
      <>
        <div className={classes.attribute}>
          <label>Address Line One</label>
          <Input
            defaultValue={data?.addressLineOne}
            type="text"
            name="addressLineOne"
            required
          />
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>Address Line Two</label>
          <Input
            defaultValue={data?.addressLineTwo}
            type="text"
            name="addressLineTwo"
          />
        </div>
        <div className={classes.attribute}>
          <label>City</label>
          <Input defaultValue={data?.city} type="text" name="city" required />
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>State/Province</label>
          <Select defaultValue={data?.state} name="state">
            <StatesAndProvinces />
          </Select>
        </div>
        <div className={classes.attribute}>
          <label>Zip</label>
          <Input defaultValue={data?.zip} type="text" name="zip" />
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>Country</label>
          <Select required defaultValue={data?.country} name="country">
            <option value="US">US</option>
            <option value="CA">Canada</option>
          </Select>
        </div>
        <div className={classes.attribute}>
          <label>Latitude</label>
          <Input defaultValue={data?.latitude} type="text" name="latitude" />
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>Longitude</label>
          <Input defaultValue={data?.longitude} type="text" name="longitude" />
        </div>
      </>
    );
  };

  return (
    <>
      <h2>Update Address</h2>
      <Form
        onSubmit={e => UpdateLocationAddress(e)}
        className={classes.viewGrid}
      >
        <UpdateAddressInputs data={data} />
        <div
          className={`${classes.attribute} ${classes.span3}`}
          style={{ alignItems: 'left' }}
        >
          <label>
            <input
              defaultChecked={false}
              type="checkbox"
              name="disableValidation"
            ></input>
            Disable validation?
          </label>
        </div>
        <div>
          <Button variant="good" type="submit" disabled={true}>
            Save Changes
          </Button>
        </div>
      </Form>
    </>
  );
}
