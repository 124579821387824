import React from 'react';

function StatesAndProvinces() {
  return (
    <>
      <optgroup key={0} label="USA">
        <option key={1} value="AL" data-country="USA">
          AL
        </option>
        <option key={2} value="AK" data-country="USA">
          AK
        </option>
        <option key={3} value="AZ" data-country="USA">
          AZ
        </option>
        <option key={4} value="AR" data-country="USA">
          AR
        </option>
        <option key={5} value="CA" data-country="USA">
          CA
        </option>
        <option key={6} value="CO" data-country="USA">
          CO
        </option>
        <option key={7} value="CT" data-country="USA">
          CT
        </option>
        <option key={8} value="DC" data-country="USA">
          DC
        </option>
        <option key={9} value="DE" data-country="USA">
          DE
        </option>
        <option key={10} value="FL" data-country="USA">
          FL
        </option>
        <option key={11} value="GA" data-country="USA">
          GA
        </option>
        <option key={12} value="HI" data-country="USA">
          HI
        </option>
        <option key={13} value="ID" data-country="USA">
          ID
        </option>
        <option key={14} value="IL" data-country="USA">
          IL
        </option>
        <option key={15} value="IN" data-country="USA">
          IN
        </option>
        <option key={16} value="IA" data-country="USA">
          IA
        </option>
        <option key={17} value="KS" data-country="USA">
          KS
        </option>
        <option key={18} value="KY" data-country="USA">
          KY
        </option>
        <option key={19} value="LA" data-country="USA">
          LA
        </option>
        <option key={20} value="ME" data-country="USA">
          ME
        </option>
        <option key={21} value="MD" data-country="USA">
          MD
        </option>
        <option key={22} value="MA" data-country="USA">
          MA
        </option>
        <option key={23} value="MI" data-country="USA">
          MI
        </option>
        <option key={24} value="MN" data-country="USA">
          MN
        </option>
        <option key={25} value="MS" data-country="USA">
          MS
        </option>
        <option key={26} value="MO" data-country="USA">
          MO
        </option>
        <option key={27} value="MT" data-country="USA">
          MT
        </option>
        <option key={28} value="NE" data-country="USA">
          NE
        </option>
        <option key={29} value="NV" data-country="USA">
          NV
        </option>
        <option key={30} value="NH" data-country="USA">
          NH
        </option>
        <option key={31} value="NJ" data-country="USA">
          NJ
        </option>
        <option key={32} value="NM" data-country="USA">
          NM
        </option>
        <option key={33} value="NY" data-country="USA">
          NY
        </option>
        <option key={34} value="NC" data-country="USA">
          NC
        </option>
        <option key={35} value="ND" data-country="USA">
          ND
        </option>
        <option key={36} value="OH" data-country="USA">
          OH
        </option>
        <option key={37} value="OK" data-country="USA">
          OK
        </option>
        <option key={38} value="OR" data-country="USA">
          OR
        </option>
        <option key={39} value="PA" data-country="USA">
          PA
        </option>
        <option key={40} value="RI" data-country="USA">
          RI
        </option>
        <option key={41} value="SC" data-country="USA">
          SC
        </option>
        <option key={42} value="SD" data-country="USA">
          SD
        </option>
        <option key={43} value="TN" data-country="USA">
          TN
        </option>
        <option key={44} value="TX" data-country="USA">
          TX
        </option>
        <option key={45} value="UT" data-country="USA">
          UT
        </option>
        <option key={46} value="VT" data-country="USA">
          VT
        </option>
        <option key={47} value="VA" data-country="USA">
          VA
        </option>
        <option key={48} value="WA" data-country="USA">
          WA
        </option>
        <option key={49} value="WV" data-country="USA">
          WV
        </option>
        <option key={50} value="WI" data-country="USA">
          WI
        </option>
        <option key={51} value="WY" data-country="USA">
          WY
        </option>
      </optgroup>
      <optgroup key={52} label="Canada">
        <option key={54} value="AB" data-country="Canada">
          Alberta
        </option>
        <option key={55} value="BC" data-country="Canada">
          British Columbia
        </option>
        <option key={56} value="MB" data-country="Canada">
          Manitoba
        </option>
        <option key={57} value="NB" data-country="Canada">
          New Brunswick
        </option>
        <option key={58} value="NL" data-country="Canada">
          Newfoundland and Labrador
        </option>
        <option key={59} value="NS" data-country="Canada">
          Nova Scotia
        </option>
        <option key={60} value="ON" data-country="Canada">
          Ontario
        </option>
        <option key={61} value="PE" data-country="Canada">
          Prince Edward Island
        </option>
        <option key={62} value="QC" data-country="Canada">
          Quebec
        </option>
        <option key={63} value="SK" data-country="Canada">
          Saskatchewan
        </option>
        <option key={64} value="NT" data-country="Canada">
          Northwest Territories
        </option>
        <option key={65} value="NU" data-country="Canada">
          Nunavut
        </option>
        <option key={66} value="YT" data-country="Canada">
          Yukon
        </option>
      </optgroup>
    </>
  );
}
export default StatesAndProvinces;
