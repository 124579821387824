import React, { useEffect, useState } from 'react';

import Get from '../../../api/internal/Get';
import Slab from '../../../helpers/slab/Slab';
import Summary from './Summary';
import Details from './Details';
import Payments from '../accounting/Payments';
import Audit from '../generic/Audit';
import Admin from './Admin';
import { useRoleAuthorization } from '../../../hooks/useRoleAuthorization';
import { ADMIN_AUTHORIZED_ROLES } from '../../../auth/AuthRoles';

function Payroll({
  assetAssignmentId,
  startPage,
}: {
  assetAssignmentId: number;
  startPage?: string;
}) {
  const [currentPage, setCurrentPage] = useState();
  const [payrollDataObject] = useState<PayrollDataObject>({
    Summary: null,
    Details: null,
    Payments: null,
    Audit: null,
    Admin: null,
  });

  useEffect(() => {
    startPage ? HandlePageChange(startPage) : HandlePageChange('Summary');
  }, []);

  const HandlePageChange = params => {
    if (!payrollDataObject[params] && pages[params].callerPath) {
      Get(pages[params].callerPath).then(response => {
        if (response) {
          payrollDataObject[params] = response.data;
          AssignPages();
          setCurrentPage(params);
        }
      });
    } else {
      setCurrentPage(params);
    }
  };

  function RefreshPage(currentPage, additionalPages) {
    setCurrentPage(null);
    delete payrollDataObject[currentPage];
    additionalPages?.forEach(pageName => {
      delete payrollDataObject[pageName];
    });
    HandlePageChange(currentPage);
  }

  let pages = {};
  AssignPages();

  function AssignPages() {
    pages = {
      Summary: {
        content: <Summary data={payrollDataObject.Summary} />,
        callerPath: `/Accounting/GetPayrollSummary/${assetAssignmentId}`,
      },
      Details: {
        content: (
          <Details
            data={payrollDataObject.Details}
            assetAssignmentId={assetAssignmentId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Accounting/GetPayrollDetails/${assetAssignmentId}`,
      },
      Payments: {
        content: (
          <Payments
            relationId={assetAssignmentId}
            relationType={'Asset'}
            data={payrollDataObject.Payments}
            HandlePageChange={HandlePageChange}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Accounting/GetPaymentMethodList`,
      },
      Audit: {
        content: <Audit data={payrollDataObject.Audit} />,
        callerPath: `/Accounting/GetAssetAssignmentAudit/${assetAssignmentId}`,
      },
    };
    if (useRoleAuthorization(ADMIN_AUTHORIZED_ROLES))
      pages['Admin'] = {
        content: (
          <Admin
            data={payrollDataObject.Admin}
            assetAssignmentId={assetAssignmentId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Accounting/GetPayrollAdmin/${assetAssignmentId}`,
      };
  }

  return (
    <Slab
      title={'Payroll Assignment: PR' + assetAssignmentId}
      pages={pages}
      currentPage={currentPage}
      HandlePageChange={HandlePageChange}
      dataObject={payrollDataObject}
    />
  );
}
export default Payroll;
