import React from 'react';

import classes from '../../../../assets/Global.module.css';
import Button from '../../../../assets/essentials/Button';
import Input from '../../../../assets/essentials/Input';
import Select from '../../../../assets/essentials/Select';
import TextArea from '../../../../assets/essentials/TextArea';
import PutForm from '../../../../api/internal/PutForm';
import OptionList from '../../../../helpers/options/OptionList';
import Form from '../../../../assets/essentials/Form';
import Phone from '../../../../helpers/inputs/Phone';

function Details({
  data,
  employeeId,
  RefreshPage,
}: {
  data: EmployeeDetails;
  employeeId: number;
  RefreshPage: Function;
}) {
  function UpdateDispatch(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.set(
      'isIndependentContractor',
      (formData.get('isIndependentContractor') === 'on').toString(),
    );
    const appendix = {
      employeeId: employeeId,
    };
    PutForm(`/Employee/UpdateDispatch`, formData, appendix).then(() =>
      RefreshPage('Details', ['Audit', 'Summary']),
    );
  }

  const hireDateOnly = data.hireDate?.split('T')[0];

  return (
    <Form onSubmit={e => UpdateDispatch(e)}>
      <div className={classes.attribute}>
        <label>First Name <span className={classes.required}>*</span></label>
        <Input defaultValue={data.firstName} name="firstName" />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Last Name</label>
        <Input defaultValue={data.lastName} name="lastName" />
      </div>
      <div className={classes.attribute}>
        <label>
          Phone <span className={classes.required}>*</span>
        </label>
        <Phone
          required={true}
          defaultValue={data.phone}
          name="phone"
          onChange={null}
          expectsEventObject={false}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Ext</label>
        <Input defaultValue={data.phoneExt} name="phoneExt" />
      </div>
      <div className={classes.attribute}>
        <label>Mobile</label>
        <Phone
          defaultValue={data.mobile}
          name="mobile"
          onChange={null}
          expectsEventObject={false}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Email</label>
        <Input defaultValue={data.email} name="email" />
      </div>
      <div className={classes.attribute}>
        <label>Hire Date</label>
        <Input defaultValue={hireDateOnly} name="hireDate" type="date" />
      </div>
      <div className={classes.attribute}>
        <label>Branch</label>
        <Select name="branchId" defaultValue={data.branchId}>
          <OptionList
            optionData={data.branchOptions}
            attributeID="branchId"
            attributeName="branchName"
          ></OptionList>
        </Select>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>
          <Input
            defaultChecked={data.isIndependentContractor}
            name="isIndependentContractor"
            type="checkbox"
          />
          Independent Contractor
        </label>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>Note</label>
        <TextArea rows={4} defaultValue={data.note} name="note" />
      </div>
      <div>
        <Button type="submit" variant="good" disabled={true}>
          Save Changes
        </Button>
      </div>
    </Form>
  );
}
export default Details;
