import React from 'react';

import classes from '../../assets/Global.module.css';
import OptionList from '../../helpers/options/OptionList';
import Select from '../../assets/essentials/Select';
import Input from '../../assets/essentials/Input';
import TextArea from '../../assets/essentials/TextArea';
import StatesAndProvinces from '../../helpers/options/StatesAndProvinces';
import MultipleEmailInput from '../../helpers/inputs/MultipleEmailInput';
import classesMultipleEmailInput from '../../helpers/inputs/MultipleEmailInput.module.css';

function Billing({
  data,
  emails,
  HandlePageChange,
  setEmails,
  submitButtonId,
}: {
  data: VendorBilling;
  emails: string[];
  HandlePageChange: Function;
  setEmails: Function;
  submitButtonId: string;
}) {
  return (
    <>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>
          Billing Entity Name <span className={classes.required}>*</span>
        </label>
        <Input
          required
          defaultValue={data?.billingEntity}
          type="text"
          name="billingEntity"
        />
      </div>
      <div className={classes.attribute}>
        <label>
          Address Line One <span className={classes.required}>*</span>
        </label>
        <Input
          required
          defaultValue={data?.billingAddressLineOne}
          type="text"
          name="billingAddressLineOne"
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Address Line Two</label>
        <Input
          defaultValue={data?.billingAddressLineTwo}
          type="text"
          name="billingAddressLineTwo"
        />
      </div>
      <div className={classes.attribute}>
        <label>
          City <span className={classes.required}>*</span>
        </label>
        <Input
          required
          defaultValue={data?.billingCity}
          type="text"
          name="billingCity"
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>State/Province</label>
        <Select defaultValue={data?.billingState} name="billingState">
          <StatesAndProvinces />
        </Select>
      </div>
      <div className={classes.attribute}>
        <label>
          Zip <span className={classes.required}>*</span>
        </label>
        <Input
          required
          defaultValue={data?.billingZip}
          type="text"
          name="billingZip"
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Country</label>
        <Select
          required
          defaultValue={data?.billingCountry}
          name="billingCountry"
        >
          <option value="US">US</option>
          <option value="CA">Canada</option>
        </Select>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <br />
      </div>
      <div className={classes.attribute}>
        <label>Billing Contact</label>
        <Input
          disabled
          key={data?.billingContactName}
          defaultValue={data?.billingContactName}
        />
      </div>
      <div className={classes.attribute}>
        <label>Position</label>
        <Input
          disabled
          key={data?.billingContactPosition}
          defaultValue={data?.billingContactPosition}
        />
      </div>
      <div className={classes.attribute}>
        <label>Email</label>
        <Input
          disabled
          key={data?.primaryBillingEmail}
          defaultValue={data?.primaryBillingEmail}
        />
      </div>
      <div className={classes.attribute}>
        <label>Phone</label>
        <Input
          disabled
          key={data?.billingPhone}
          defaultValue={data?.billingPhone}
        />
        <div
          className={classes.cTA}
          onClick={() => HandlePageChange('Contacts')}
        >
          Manage Contact Info
        </div>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Ext</label>
        <Input
          disabled
          key={data?.billingExt}
          defaultValue={data?.billingExt}
        />
      </div>
      <div className={classes.attribute}>
        <label>Payment Method</label>
        <Select defaultValue={data?.paymentMethodId} name="paymentMethodId">
          <OptionList
            optionData={data?.paymentMethodOptions}
            attributeID="paymentMethodId"
            attributeName="method"
          />
        </Select>
      </div>
      <div className={classes.attribute}>
        <label>Payment Term</label>
        <Select defaultValue={data?.paymentTermId} name="paymentTermId">
          <OptionList
            optionData={data?.paymentTermOptions}
            attributeID="paymentTermId"
            attributeName="term"
          />
        </Select>
      </div>
      <div
        className={`${classes.attribute} ${classesMultipleEmailInput.container}`}
      >
        <label>Invoice Email</label>
        <MultipleEmailInput
          emails={emails}
          setEmails={setEmails}
          submitButtonId={submitButtonId}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>Special Billing Instructions</label>
        <TextArea
          defaultValue={data?.billingNote}
          type="text"
          name="billingNote"
        />
      </div>
    </>
  );
}

export default Billing;
