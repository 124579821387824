import React, { useState } from 'react';
import classes from './File.module.css';
import classesGlobal from '../../../assets/Global.module.css';
import classesMultipleEmailInput from '../../../helpers/inputs/MultipleEmailInput.module.css';
import Input from '../../../assets/essentials/Input';
import Button, { ButtonStatus } from '../../../assets/essentials/Button';
import MultipleEmailInput from '../../../helpers/inputs/MultipleEmailInput';
import TextArea from '../../../assets/essentials/TextArea';
import { FileCategory } from './Files';
import PostBody from '../../../api/internal/PostBody';
import useTimeout from '../../../hooks/useTimeout';

const EmailAllFiles = ({
  fileCategory,
  categoryId,
  emailRecipients,
  setEmailRecipients,
  isOpen,
  setIsOpen,
}: {
  fileCategory: FileCategory;
  categoryId: number;
  emailRecipients: string[];
  setEmailRecipients: React.Dispatch<React.SetStateAction<string[]>>;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [sendEmailStatus, setSendEmailStatus] = useState<ButtonStatus>('idle');

  useTimeout(() => {
    if (sendEmailStatus === 'success' || sendEmailStatus === 'failure') {
      setSendEmailStatus('idle');
    }
  }, 10000);

  function SubmitForm(e) {
    e.preventDefault();
    setSendEmailStatus('pending');
    const payload = {
      fileCategory,
      categoryId,
      toRecipientAddresses: emailRecipients,
      emailSubject: e.target.subject.value,
      emailBody: e.target.body.value,
    };
    PostBody(`/File/EmailAllFilesInContainer`, payload).then( (response) => {
      if (response) {
        setSendEmailStatus('success');
      } else {
        setSendEmailStatus('failure');
      }
    })
  }

  return (
    <div className={classes.formHolder}>
      {isOpen && (
        <form onSubmit={e => SubmitForm(e)}>
          <div
            className={`${classesGlobal.attribute} ${classesMultipleEmailInput.container}`}
          >
            <label htmlFor="toRecipientAddresses">
              To: <span className={classesGlobal.required}>*</span>
            </label>
            <MultipleEmailInput
              submitButtonId="email-asset-files"
              emails={emailRecipients}
              setEmails={setEmailRecipients}
              id="toRecipientAddresses"
              name="toRecipientAddresses"
              required={emailRecipients?.length === 0}
            />
          </div>
          <div className={`${classesGlobal.attribute}`}>
            <label htmlFor="subject">
              Subject <span className={classes.required}>*</span>
            </label>
            <Input
              required
              type="text"
              defaultValue={``}
              id="subject"
              name="subject"
            />
          </div>
          <div className={`${classesGlobal.attribute}`}>
            <label htmlFor="body">Body: </label>
            <TextArea
              required
              defaultValue="See attached."
              id="body"
              name="body"
            />
          </div>
          <div className={classesGlobal.pairedButtons}>
            <div>
              <Button
                type="submit"
                variant="good"
                id="email-asset-files"
                status={sendEmailStatus}
                disabled={sendEmailStatus !== 'idle'}
              >
                Send Email
              </Button>
            </div>
            <div>
              <Button onClick={() => setIsOpen(!isOpen)}>Cancel</Button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default EmailAllFiles;
