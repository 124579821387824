/**
 * Assemble Updated Financials
 * 
 * @param {Object} e - The event object containing the form data.
 * @param {Function} e.preventDefault - A function to prevent the default form submission behavior.
 * @param {HTMLFormElement} e.target - The HTML form element containing the financial data.
 * @param {number} assignmentId - The unique identifier for the assignment.
 * @param {string} assignmentType - The type of the assignment (e.g., 'Asset', 'Customer', 'Vendor').
 *
 * @returns {Object} payload - The assembled payload containing updated financial data.
 * @returns {number} payload.assignmentId - The unique identifier for the assignment.
 * @returns {string} payload.assignmentType - The type of the assignment.
 * @returns {Array} payload.financialsList - An array containing financial data rows.
 *
 * @example
 * This function will be passed down to the FinancialsTable as the onSubmit prop
 * 
   function SaveDraftPayroll(e, assignmentId) {
      PostBody(
        'Load/SaveDraftFinancial',
        AssembleUpdatedFinancials(e, assignmentId, 'Asset'),
      ).then(response => {
        if (response) {
          setDisableSaveChanges(true);
        }
      });
    }

    @example
     * This function will be passed down to the FinancialsTable as the onSubmit prop

    function SaveDraftPayroll(e, assignmentId) {
      const payload = AssembleUpdatedFinancials(e, assignmentId, 'Asset');
      
      PostBody('Load/SaveDraftFinancial', payload).then(response => {
        if (response) {
          GetLoadCompletePayroll(loadId);
          setDisableSaveChanges(true);
        }
      });
    }
 */

export default function AssembleUpdatedFinancial(
  e: { preventDefault: () => void; target: HTMLFormElement },
  assignmentId: number,
  assignmentType: string,
) {
  e.preventDefault();
  const formData = new FormData(e.target);
  const financialsList = [];

  formData.forEach(function (value, key) {
    const [field, index] = key.split('-');
    //financialId needs to be null to be handled on backend for creates
    //formData converts nulls to empty strings
    if (field === 'financialId' && value === '') {
      value = null;
    }
    if (!financialsList[index]) {
      financialsList[index] = {};
    }
    financialsList[index][field] = value;
  });

  const payload = {
    assignmentId: assignmentId,
    assignmentType: assignmentType,
    financialsList: financialsList,
  };

  return payload;
}
