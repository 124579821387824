import React from 'react';
import CreateDriverMaintenanceEntry from '../../../creates/driver/DriverMaintenance';
import BBITable from '../../../../helpers/bBITable/BBITable';
import DateOnlyFormatter from '../../../../helpers/inputs/DateOnlyFormatter';

function DriverMaintenance({
  data,
  employeeId,
  RefreshPage,
}: {
  data: Array<DriverMaintenanceEntry>;
  employeeId: number;
  RefreshPage: Function;
}) {
  let formattedData = [];
  if (data) {
    formattedData = data.map(maintenanceEntry => ({
      ...maintenanceEntry,
      date: DateOnlyFormatter(maintenanceEntry.date),
      amount: maintenanceEntry.amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    }));
  }

  let columnConfig = [
    {
      key: '1',
      attribute: 'reference',
      header: 'Reference',
    },
    {
      key: '2',
      attribute: 'date',
      header: 'Date',
    },
    {
      key: '3',
      attribute: 'type',
      header: 'Type',
    },
    {
      key: '4',
      attribute: 'amount',
      header: 'Amount',
    },
  ];

  return (
    <>
      <CreateDriverMaintenanceEntry employeeId={employeeId} RefreshPage={RefreshPage} />
      <BBITable data={formattedData} columns={columnConfig} />
    </>
  );
}
export default DriverMaintenance;
