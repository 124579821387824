import React, { useState, useEffect, useRef } from 'react';
import Get from '../../../api/internal/Get';
import BBITable from '../../../helpers/bBITable/BBITable';
import classesGlobal from '../../../assets/Global.module.css';
import classesCustom from '../../../helpers/search/CustomSearch.module.css';
import OptionList from '../../../helpers/options/OptionList';
import Select from '../../../assets/essentials/Select';
import View from '../../../helpers/slab/View';
import DateOnlyFormatter from '../../../helpers/inputs/DateOnlyFormatter';
import Vendor from '../../../features/views/vendor/Vendor';
import Input from '../../../assets/essentials/Input';
import Button from '../../../assets/essentials/Button';
import {
  ParseUrlForSearchParams,
  GenerateQueryString,
  GetThirtyDaysAgoIsoString,
} from '../../../helpers/search/HelperFunctions';
import APAppliedPayments from '../../../features/views/payable/AppliedPayments/APAppliedPayments';
import { useOutletContext } from 'react-router-dom';
import FilterData from '../../../helpers/filter/FilterData';

function PayablePayments({
  toggleBoardRefresh,
}: {
  toggleBoardRefresh: boolean;
}) {
  const [paymentBoard, setPaymentBoard] = useState<Array<APCashFlow>>([]);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [paymentOptionsList, setPaymentOptionsList] = useState([]);
  const emptySearchParams = {
    vendorId: null,
    reference: null,
    loadId: null,
    paymentMethodId: null,
    dateMin: null, //must use name "dateMin" if we want to use defaultSearchDate
    dateMax: null,
  };
  const [payablePaymentsSearchParams, setPayablePaymentsSearchParams] =
    useState(emptySearchParams);
  const isFirstRender = useRef(true);
  const filterValue = useOutletContext();

  useEffect(() => {
    if (vendorOptions.length === 0) {
      Get(`/Vendor/GetVendorList`).then(response => {
        if (response) {
          setVendorOptions(response.data);
        }
      });
    }

    if (paymentOptionsList.length === 0) {
      Get(`/Accounting/GetPaymentMethodList`).then(response => {
        if (response) {
          setPaymentOptionsList(response.data);
        }
      });
    }

    const defaultSearchDate = GetThirtyDaysAgoIsoString();

    const { vendorId, reference, loadId, paymentMethodId, dateMin, dateMax } =
      ParseUrlForSearchParams(
        emptySearchParams,
        defaultSearchDate,
        isFirstRender.current,
      );

    setPayablePaymentsSearchParams({
      vendorId: vendorId,
      reference: reference,
      loadId: loadId,
      paymentMethodId: paymentMethodId,
      dateMin: dateMin,
      dateMax: dateMax,
    });
    SearchPayablePayments({
      vendorId: vendorId,
      reference: reference,
      loadId: loadId,
      paymentMethodId: paymentMethodId,
      dateMin: dateMin,
      dateMax: dateMax,
    });
    isFirstRender.current = false;
  }, [toggleBoardRefresh]);

  function FormatPaymentBoard(paymentBoard) {
    return paymentBoard.map(payment => ({
      ...payment,
      avgDaysToPay: Math.trunc(payment.avgDaysToPay) || 0,
      paymentDate: DateOnlyFormatter(payment.paymentDate),
    }));
  }

  function HandleFormSubmit(e) {
    e.preventDefault();
    const formData = {
      vendorId: e.target.vendorId?.value,
      reference: e.target.reference?.value,
      loadId: e.target.loadId?.value,
      paymentMethodId: e.target.paymentMethodId?.value,
      dateMin: e.target.dateMin?.value,
      dateMax: e.target.dateMax?.value,
    };
    setPayablePaymentsSearchParams(formData);
    SearchPayablePayments(formData);
  }

  function SearchPayablePayments(SearchParamsFromForm?) {
    //if there is no params passed in, search with default params derived from URL
    const searchParams = SearchParamsFromForm
      ? SearchParamsFromForm
      : payablePaymentsSearchParams;
    const queryString = '?' + GenerateQueryString(searchParams);
    const newUrl =
      window.location.origin + window.location.pathname + queryString;
    window.history.replaceState(null, null, newUrl);
    Get(`/Accounting/SearchPayablePayments${queryString}`).then(response => {
      if (response) {
        setPaymentBoard(FormatPaymentBoard(response.data));
      }
    });
  }

  function ClearSearchParams() {
    setPayablePaymentsSearchParams(emptySearchParams);
  }

  const SearchForm = () => {
    return (
      <div
        className={`${classesCustom.customSearch} ${classesGlobal.marginBottom05Rem}`}
      >
        <form onSubmit={e => HandleFormSubmit(e)}>
          <label>
            Vendor
            <Select
              defaultValue={payablePaymentsSearchParams.vendorId || ''}
              onChange={e => {
                payablePaymentsSearchParams.vendorId = e.target.value;
              }}
              name="vendorId"
            >
              <option value="">All Vendors</option>
              <OptionList
                optionData={vendorOptions}
                attributeID="vendorId"
                attributeName="vendorName"
              />
            </Select>
          </label>
          <label>
            Payment Method
            <Select
              defaultValue={payablePaymentsSearchParams.paymentMethodId || ''}
              onChange={e => {
                payablePaymentsSearchParams.paymentMethodId = e.target.value;
              }}
              name="paymentMethodId"
            >
              <option value="">All Methods</option>
              <OptionList
                optionData={paymentOptionsList}
                attributeID="paymentMethodId"
                attributeName="method"
              />
            </Select>
          </label>

          <label>
            Load #
            <Input
              defaultValue={payablePaymentsSearchParams.loadId}
              onChange={e => {
                payablePaymentsSearchParams.loadId = e.target.value;
              }}
              name="loadId"
            />
          </label>
          <label>
            Check #/Reference
            <Input
              defaultValue={payablePaymentsSearchParams.reference}
              onChange={e => {
                payablePaymentsSearchParams.reference = e.target.value;
              }}
              name="reference"
            />
          </label>

          <label>
            Payment Date Start
            <Input
              type="date"
              name="dateMin"
              defaultValue={payablePaymentsSearchParams.dateMin}
            />
          </label>
          <label>
            Payment Date End
            <Input
              type="date"
              name="dateMax"
              defaultValue={payablePaymentsSearchParams.dateMax}
            />
          </label>

          <div className={classesCustom.submitHolder}>
            <Button type="submit" variant="good">
              Search
            </Button>

            <Button
              type="button"
              onClick={() => ClearSearchParams()}
              variant="bad"
            >
              Clear
            </Button>
          </div>
        </form>
      </div>
    );
  };

  let columnConfig = [
    {
      key: '1',
      attribute: 'paymentDate',
      header: 'Date',
      dataType: 'date',
    },
    {
      key: '2',
      attribute: 'reference',
      header: 'Check #/Reference',
      attributeprimaryhidden: 'cashFlowId',
      onClick: e =>
        View(
          <APAppliedPayments
            cashFlowId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '3',
      attribute: 'vendorName',
      attributeprimaryhidden: 'vendorId',
      header: 'Vendor',
      onClick: e =>
        View(
          <Vendor
            vendorName={e.target.innerText}
            vendorId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '4',
      attribute: 'paymentMethod',
      header: 'Payment Method',
    },
    {
      key: '5',
      attribute: 'quickBooksPaymentId',
      header: 'QuickBooks ID',
      dataType: 'number',
    },
    {
      key: '6',
      attribute: 'avgDaysToPay',
      header: 'Average Days To Pay',
    },
    {
      key: '7',
      attribute: 'totalPayment',
      header: 'Amount',
      dataType: 'currency',
    },
  ];

  return (
    <>
      <SearchForm />
      <>
        {paymentBoard != null ? (
          <BBITable
            data={FormatPaymentBoard(FilterData(paymentBoard, filterValue))}
            columns={columnConfig}
          />
        ) : (
          <>No customer selected.</>
        )}
      </>
    </>
  );
}

export default PayablePayments;
