import React from 'react';

import classes from '../../../../assets/Global.module.css';
import Input from '../../../../assets/essentials/Input';
import Select from '../../../../assets/essentials/Select';
import OptionList from '../../../../helpers/options/OptionList';
import TextArea from '../../../../assets/essentials/TextArea';
import Button from '../../../../assets/essentials/Button';
import PutForm from '../../../../api/internal/PutForm';
import Form from '../../../../assets/essentials/Form';
import StatesAndProvinces from '../../../../helpers/options/StatesAndProvinces';

function Details({
  assetId,
  data,
  RefreshPage,
}: {
  assetId: number;
  data: TruckDetails;
  RefreshPage: Function;
}) {
  function UpdateTruckDetails(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const appendix = {
      assetId: assetId,
    };
    PutForm(`/Asset/UpdateTruckDetails`, formData, appendix).then(() =>
      RefreshPage('Details', ['Summary', 'Audit', 'Tracking']),
    );
  }
  return (
    <Form onSubmit={e => UpdateTruckDetails(e)}>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label htmlFor="truckTypeId">Truck Type</label>
        <Select
          defaultValue={data.truckTypeId}
          id="truckTypeId"
          name="truckTypeId"
        >
          <OptionList
            optionData={data.truckTypeOptions}
            attributeID="truckTypeId"
            attributeName="type"
            attributeGroup="group"
          />
        </Select>
      </div>
      <div className={classes.attribute}>
        <label htmlFor="truckName">
          Truck Unit # <span className={classes.required}>*</span>
        </label>
        <Input
          defaultValue={data.truckName}
          id="truckName"
          name="truckName"
          required
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor="vin">
          VIN <span className={classes.required}>*</span>
        </label>
        <Input defaultValue={data.vin} id="vin" name="vin" required />
      </div>
      <div className={classes.attribute}>
        <label htmlFor="licensePlateNumber">License Plate Number</label>
        <Input
          defaultValue={data.licensePlateNumber}
          id="licensePlateNumber"
          name="licensePlateNumber"
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor="licensePlateState">License Plate State</label>
        <Select
          defaultValue={data.licensePlateState}
          id="licensePlateState"
          name="licensePlateState"
        >
          <option value="" />
          <StatesAndProvinces />
        </Select>
      </div>
      <div className={classes.attribute}>
        <label htmlFor="year">
          Year <span className={classes.required}>*</span>
        </label>
        <Input defaultValue={data.year} id="year" name="year" required />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor="make">
          Make <span className={classes.required}>*</span>
        </label>
        <Input defaultValue={data.make} id="make" name="make" required />
      </div>
      <div className={classes.attribute}>
        <label htmlFor="model">
          Model <span className={classes.required}>*</span>
        </label>
        <Input defaultValue={data.model} id="model" name="model" required />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor="color">Color</label>
        <Input defaultValue={data.color} id="color" name="color" />
      </div>
      <div className={classes.attribute}>
        <label htmlFor="mileage">Mileage</label>
        <Input
          defaultValue={data.mileage ? data.mileage.toLocaleString() : ''}
          id="mileage"
          name="mileage"
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor="mileageAsOfDate">Mileage As Of Date</label>
        <Input
          defaultValue={data.mileageAsOfDate}
          id="mileageAsOfDate"
          name="mileageAsOfDate"
          type="date"
        />
      </div>
      <div className={classes.attribute}>
        <label htmlFor="eldId">ELD ID</label>
        <Input defaultValue={data.eldId} id="eldId" name="eldId" />
      </div>
      <div className={classes.attribute}>
        <label htmlFor="eldMake">ELD Make</label>
        <Input defaultValue={data.eldMake} id="eldMake" name="eldMake" />
      </div>
      <div className={classes.attribute}>
        <label htmlFor="eldModel">ELD Model</label>
        <Input defaultValue={data.eldModel} id="eldModel" name="eldModel" />
      </div>
      <div className={`${classes.attribute}`}>
        <label htmlFor="grossVehicleWeightRating">
          Gross Vehicle Weight Rating
        </label>
        <Input
          defaultValue={data.grossVehicleWeightRating}
          id="grossVehicleWeightRating"
          name="grossVehicleWeightRating"
          type="number"
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor="weightPermitExpirationDate">
          Weight Permit Expiration Date
        </label>
        <Input
          defaultValue={data.weightPermitExpirationDate}
          id="weightPermitExpirationDate"
          name="weightPermitExpirationDate"
          type="date"
        />
      </div>
      <div className={`${classes.attribute}`}>
        <label htmlFor="internationalFuelTaxAgreementNumber">IFTA #</label>
        <Input
          defaultValue={data.internationalFuelTaxAgreementNumber}
          id="internationalFuelTaxAgreementNumber"
          name="internationalFuelTaxAgreementNumber"
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor="samsaraAssetId">Samsara ID</label>
        <Input
          defaultValue={data.samsaraAssetId}
          id="samsaraAssetId"
          name="samsaraAssetId"
        />
      </div>
      <div className={`${classes.attribute} `}>
        <label htmlFor="branchId">Branch</label>
        <Select defaultValue={data.branchId} id="branchId" name="branchId">
          <OptionList
            optionData={data.branchOptions}
            attributeID="branchId"
            attributeName="branchName"
            attributeGroup="group"
          />
        </Select>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor="isOwned">Ownership</label>
        <Select
          id="isOwned"
          name="isOwned"
          defaultValue={data.isOwned ? 'true' : 'false'}
        >
          <option value="true">Owned</option>
          <option value="false">Leased</option>
        </Select>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label htmlFor="note">Note</label>
        <TextArea defaultValue={data.note} id="note" name="note" />
      </div>
      <div>
        <Button type="submit" variant="good" disabled={true}>
          Save Changes
        </Button>
      </div>
    </Form>
  );
}
export default Details;
