import React, { useState } from 'react';
import { MdOutlineSpeakerNotes } from 'react-icons/md';

import classes from './DriverEntry.module.css';
import classesGlobal from '../../../assets/Global.module.css';
import PostForm from '../../../api/internal/PostForm';
import Button from '../../../assets/essentials/Button';
import Select from '../../../assets/essentials/Select';
import Input from '../../../assets/essentials/Input';
import Form from '../../../assets/essentials/Form';

function ESA({ employeeId, RefreshPage }) {
  const [isOpen, setIsOpen] = useState(false);
  const [amount, setAmount] = useState<number>();
  const [isPositive, setIsPositive] = useState(true);

  function CreateEsaEntry(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    PostForm(`/Accounting/CreateEmployeeEsaEntry`, formData, {
      employeeId: employeeId,
    }).then(() => RefreshPage('ESA', ['Audit']));
    setIsOpen(false);
  }

  function HandleToggle(e) {
    if (e.target.value === 'negative') {
      setIsPositive(!isPositive);
      setAmount(-amount);
    }
    if (e.target.value === 'positive' && !isPositive) {
      setIsPositive(!isPositive);
      setAmount(-amount);
    }
  }

  return (
    <>
      <div className={classes.btnHolder}>
        <button type="button" onClick={() => setIsOpen(!isOpen)}>
          <MdOutlineSpeakerNotes />
          <span>Create Entry</span>
        </button>
      </div>
      <div className={classes.createNoteHolder}>
        {isOpen && (
          <Form
            onSubmit={e => CreateEsaEntry(e)}
            className={classesGlobal.viewGrid}
          >
            <div className={classesGlobal.attribute}>
              <label>Check #/Reference</label>
              <Input name="checknumber" type="text" />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>
                Date <span className={classesGlobal.required}>*</span>
              </label>
              <Input name="date" type="date" required />
            </div>
            <div className={classesGlobal.attribute}>
              <label>
                Entry Type <span className={classesGlobal.required}>*</span>
              </label>
              <Select defaultValue="" onChange={e => HandleToggle(e)}>
                <option disabled value="">
                  -- Required --
                </option>
                <option value="positive">Deposit</option>
                <option value="negative">Withdrawal</option>
              </Select>
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>
                Amount <span className={classesGlobal.required}>*</span>
              </label>
              <Input
                required
                name="amount"
                type="number"
                value={isPositive ? Math.abs(amount) : -Math.abs(amount)}
                onChange={e => setAmount(Number(e.target.value))}
              />
            </div>
            <div>
              <Button type="submit" variant="good" disabled={true}>
                Submit
              </Button>
            </div>
            <div>
              <Button onClick={() => setIsOpen(!isOpen)}>Cancel</Button>
            </div>
          </Form>
        )}
      </div>
    </>
  );
}

export default ESA;
