import React, { useEffect, useState, useId } from 'react';
import { RxCheck, RxCross2 } from 'react-icons/rx';

import classes from '../../../assets/Global.module.css';
import classesLoad from '../../../../src/pages/Load.module.css';
import Input from '../../../assets/essentials/Input';
import Button from '../../../assets/essentials/Button';
import Get from '../../../api/internal/Get';
import PostBody from '../../../api/internal/PostBody';
import PutBody from '../../../api/internal/PutBody';
import FinancialTable from '../../../helpers/financialsEditableTable/FinancialsTable';
import AssembleUpdatedFinancial from '../../../helpers/financialsEditableTable/AssembleFinancialObject';
import GoogleSearchBoxCity from '../../../helpers/inputs/GoogleSearchBoxCity';
import Close from '../../../helpers/slab/Close';

function Complete({
  loadId,
  RefreshPage,
}: {
  loadId: number;
  RefreshPage: Function;
}) {
  const [driverList, setDriverList] = useState<Array<LoadCompleteDriver>>();
  const [loadInfo, setLoadInfo] = useState({
    loadedMiles: 0,
    isProofOfDeliveryUploadedToLoadFiles: false,
    accountTypeOptions: [],
  });

  const deadheadLocation = {
    city: null,
    stateAbbreviation: null,
    zip: null,
    country: null,
    latitude: null,
    longitude: null,
  };

  useEffect(() => {
    GetLoadCompletePayroll(loadId);
  }, [loadId]);

  useEffect(() => {
    if (document.querySelector('input#status[value="Draft"]')) {
      HandleDisableSendToButton();
    }
  }, [driverList]);

  function GetLoadCompletePayroll(loadId: number) {
    Get(`/Load/GetLoadCompletePayroll/${loadId}`).then(response => {
      if (response) {
        setDriverList(FormatDriverList(response.data.driverList));
        setLoadInfo({
          loadedMiles: response.data.loadedMiles,
          isProofOfDeliveryUploadedToLoadFiles:
            response.data.isProofOfDeliveryUploadedToLoadFiles,
          accountTypeOptions: response.data.accountTypeOptions,
        });
      }
    });
  }

  function FormatDriverList(driverList) {
    return driverList.map( (driver) => ({
      ...driver,
      loadedMiles: driver.loadedMiles.toLocaleString('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
      }),
      deadheadMiles: driver.deadheadMiles.toLocaleString('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
      }),
      totalMiles: driver.totalMiles.toLocaleString('en-US', {
        style: 'decimal',
        minimumFractionDigits: 2,
      }),
    }))
  }

  function UpdateLoadStatusAccountingInProgess(loadId: number) {
    const payload = {
      loadId: Number(loadId),
    };
    PutBody(`/Load/UpdateLoadToComplete`, payload).then(response => {
      const OK_RESULT_STATUS_CODE = 204;
      if (response) {
        if (response.status === OK_RESULT_STATUS_CODE) {
          Close();
        }
      }
    });
  }

  function GetDraftPayrolls(
    e,
    assignmentId: number,
    calculateDeadheadWith: string,
  ) {
    e.preventDefault();
    const payload = {
      assetAssignmentId: assignmentId,
      calculateDeadheadWith: calculateDeadheadWith,
      currentLoadId: loadId,
      deadheadLocationLatitude: deadheadLocation.latitude,
      deadheadLocationLongitude: deadheadLocation.longitude,
      deadheadLocationCity: deadheadLocation.city,
      deadheadLocationStateAbbreviation: deadheadLocation.stateAbbreviation,
      loadedMiles: e.target.loadedMiles.value,
      nextLoadId: e.target.nextLoadId?.value,
    };
    PostBody(`/Load/GetLoadCompleteDraftPayroll`, payload).then(response => {
      if (response) {
        setDriverList(prevData => {
          return prevData.map(driver => {
            if (driver.assignmentId === assignmentId) {
              return {
                ...driver,
                loadedMiles: response.data.loadedMiles.toLocaleString('en-US', {
                  style: 'decimal',
                  minimumFractionDigits: 2,
                }),
                deadheadMiles: response.data.deadheadMiles.toLocaleString('en-US', {
                  style: 'decimal',
                  minimumFractionDigits: 2,
                }),
                totalMiles: response.data.totalMiles.toLocaleString('en-US', {
                  style: 'decimal',
                  minimumFractionDigits: 2,
                }),
                payrollList: [
                  ...driver.payrollList.filter(x => x.status === 'Saved'),
                  ...response.data.payrollList,
                ],
              };
            }
            return driver;
          });
        });        
        RefreshPage('Complete', ['Audit']);
      }
    });
  }

  const DeadheadSection = ({ calculateDeadheadWith, setCalculateDeadWith }) => {
    const uniqueInputId = useId();

    const CallBackPlace = place => {
      place.address_components.forEach(component => {
        component.types.forEach(type => {
          if (type === 'locality') {
            deadheadLocation.city = component.short_name;
          } else if (type === 'administrative_area_level_1') {
            deadheadLocation.stateAbbreviation = component.short_name;
          } else if (type === 'postal_code') {
            deadheadLocation.zip = component.short_name;
          } else if (type === 'country') {
            deadheadLocation.country = component.short_name;
          }
        });
      });
      deadheadLocation.latitude = place.geometry.location.lat();
      deadheadLocation.longitude = place.geometry.location.lng();
    };

    const DeadheadInput = () => {
      switch (calculateDeadheadWith) {
        case 'cityState':
          return <GoogleSearchBoxCity assignPlace={CallBackPlace} />;
        case 'nextLoad':
          return (
            <Input
              type="number"
              name="nextLoadId"
              id="nextLoadId"
              placeholder="Enter Load ID"
              required
            />
          );
        default:
          return <Input disabled />;
      }
    };

    const RadioInput = ({ id, value, label, checked, onChange }) => (
      <label htmlFor={id} className={classes.flexAlignCenterJustifyLeft}>
        <input
          type="radio"
          id={id + uniqueInputId}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        {label}
      </label>
    );

    return (
      <>
        <div className={`${classes.attribute}`}>
          <label>Add Deadhead:</label>
          <DeadheadInput />
        </div>
        <RadioInput
          id="noDeadhead"
          value="noDeadhead"
          label="None"
          onChange={() => setCalculateDeadWith('noDeadhead')}
          checked={calculateDeadheadWith === 'noDeadhead'}
        />
        <RadioInput
          id="cityState"
          value="cityState"
          label="Calculate Deadhead to Destination"
          onChange={() => setCalculateDeadWith('cityState')}
          checked={calculateDeadheadWith === 'cityState'}
        />
        <RadioInput
          id="nextLoad"
          value="nextLoad"
          label="Next Load"
          onChange={() => setCalculateDeadWith('nextLoad')}
          checked={calculateDeadheadWith === 'nextLoad'}
        />
      </>
    );
  };

  const DriverForm = ({ driver, isSingleDriverOnLoad, GetDraftPayrolls }) => {
    const [calculateDeadheadWith, setCalculateDeadWith] =
      useState('noDeadhead');
    const formId = useId();

    const DriverRatePerMile = () => {
      return driver.ratePerMile ? (
        <h4>
          Driver Rate Per Mile:{' '}
          {driver.ratePerMile.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </h4>
      ) : null;
    };

    const DriverLoadRevenuePercentage = () => {
      return driver.loadRevenuePercentage > 0 ? (
        <h4>
          Driver Load Revenue Percentage:{' '}
          {driver.loadRevenuePercentage * 100 + '%'}
        </h4>
      ) : null;
    };

    return (
      <form
        key={driver.assignmentId}
        className={`${classes.span3}`}
        id={formId}
        onSubmit={e =>
          GetDraftPayrolls(e, driver.assignmentId, calculateDeadheadWith)
        }
      >
        <h3>Driver: {driver.driverName}</h3>
        <div className={`${classes.viewGrid}`}>
          <div className={`${classes.attribute}`}>
            <label htmlFor="loadedMiles">Loaded Miles:</label>
            <Input
              type="number"
              name="loadedMiles"
              id="loadedMiles"
              defaultValue={isSingleDriverOnLoad ? loadInfo.loadedMiles : null}
              step={0.01}
              min={0}
              required
            />
            <DriverRatePerMile />
            <DriverLoadRevenuePercentage />
          </div>
          <div className={`${classes.attribute}`}>
            <DeadheadSection
              key={driver.assignmentId}
              calculateDeadheadWith={calculateDeadheadWith}
              setCalculateDeadWith={setCalculateDeadWith}
            />
          </div>
          <div
            className={`${classes.attribute} ${classesLoad.completedMilesBreakdown}`}
          >
            <span>
              <b>Loaded miles:</b> <p>{driver.loadedMiles}</p>
            </span>
            <span>
              <b>Deadhead miles:</b> <p>{driver.deadheadMiles}</p>
            </span>
            <span>
              <b>Total miles:</b> <p>{driver.totalMiles}</p>
            </span>
          </div>
          <div>
            <Button variant="good" type="submit" form={formId}>
              Draft Payroll
            </Button>
          </div>
        </div>
      </form>
    );
  };

  const SUBMIT_BUTTON_ID = 'submitButtonId';

  const HandleDisableSendToButton = () => {
    const sendToSubmitButton = document.getElementById(SUBMIT_BUTTON_ID);
    sendToSubmitButton.setAttribute('disabled', 'true');
  };

  const PayrollSection = ({ driverList }) => {
    const isSingleDriverOnLoad = driverList?.length === 1;

    function SaveDraftPayroll(e, assignmentId) {
      e.preventDefault();
      PostBody(
        'Accounting/SaveDraftFinancial',
        AssembleUpdatedFinancial(e, assignmentId, 'Asset'),
      ).then(response => {
        if (response) {
          GetLoadCompletePayroll(loadId);
          RefreshPage('Complete', ['Audit', 'Summary', 'Financials']);
        }
      });
    }

    return (
      <>
        <h2 className={`${classes.grayBottomDivider} ${classes.span2}`}>
          Load Miles: {loadInfo.loadedMiles.toLocaleString()}
        </h2>
        {driverList?.map(driver => (
          <div key={driver.assignmentId} className={`${classes.span3}`}>
            <DriverForm
              driver={driver}
              isSingleDriverOnLoad={isSingleDriverOnLoad}
              GetDraftPayrolls={GetDraftPayrolls}
            />
            {driver?.payrollList.length > 0 && (
              <FinancialTable
                rowData={driver?.payrollList}
                assignmentId={driver?.assignmentId}
                assignmentType={'Asset'}
                accountTypeOptions={loadInfo.accountTypeOptions}
                onSubmit={SaveDraftPayroll}
                key={driver.assignmentId}
                onChange={HandleDisableSendToButton}
              />
            )}
          </div>
        ))}
      </>
    );
  };

  const SendToSection = () => {
    // commenting out in case we revert to using waiting on paperwork board again
    // const boardToSendLoadTo = loadInfo.isProofOfDeliveryUploadedToLoadFiles
    //   ? 'Accounting'
    //   : 'Awaiting Paperwork Board';

    const boardToSendLoadTo = 'Accounting';

    const PodIcon = () => {
      return loadInfo.isProofOfDeliveryUploadedToLoadFiles ? (
        <RxCheck />
      ) : (
        <RxCross2 className={classes.error} />
      );
    };

    return (
      <div>
        <h3 className={classes.flexAlignCenterJustifyLeft}>
          <PodIcon /> Proof of Delivery Uploaded
        </h3>
        <Button
          variant="good"
          type="button"
          onClick={() => UpdateLoadStatusAccountingInProgess(loadId)}
          id={SUBMIT_BUTTON_ID}
        >
          Send Load To {boardToSendLoadTo}
        </Button>
      </div>
    );
  };

  return (
    <div className={`${classes.viewGrid}`}>
      <h2 className={`${classes.grayBottomDivider}`}>Payroll</h2>
      <PayrollSection driverList={driverList} />
      <h2 className={`${classes.span3} ${classes.grayBottomDivider}`}>
        Submit
      </h2>
      <SendToSection />
    </div>
  );
}
export default Complete;
