import React, { useRef, useEffect } from 'react';
import useGoogleMapsApi from './useGoogleMapsAPI';
import classes from '../../assets/essentials/Input.module.css';

function GoogleSearchBoxEstablishment(props) {
  const inputRef = useRef();
  const autocompleteRef = useRef();
  const googleMapsApi = useGoogleMapsApi();
  // google api information obtaining
  useEffect(() => {
    if (googleMapsApi) {
      autocompleteRef.current = new googleMapsApi.places.Autocomplete(
        inputRef.current,
        {
          fields: [
            'name',
            'address_components',
            'formatted_phone_number',
            'website',
          ],
          types: ['establishment'],
          componentRestrictions: { country: ['us', 'ca'] },
        },
      );

      autocompleteRef.current.addListener('place_changed', () => {
        const place = autocompleteRef.current.getPlace();
        if (props && place) {
          props.assignPlace(place);
        }
      });
    }
  }, [googleMapsApi]);

  return (
    <input
      name="location"
      id={props.id}
      className={classes.input}
      aria-label="Search places"
      ref={inputRef}
      placeholder="Search for locations on the web..."
      autoComplete="off"
      value={undefined}
    />
  );
}

export default GoogleSearchBoxEstablishment;
