import React, { useEffect, useRef, useState } from 'react';
import Get from '../../api/internal/Get';
import OptionList from '../../helpers/options/OptionList';
import Select from '../../assets/essentials/Select';
import BBITable from '../../helpers/bBITable/BBITable';
import { useNavigate, useParams } from 'react-router-dom';
import View from '../../helpers/slab/View';
import ViewLoad from '../../features/views/load/Load';
import ViewRental from '../../features/views/rental/Rental';
import FilterData from '../../helpers/filter/FilterData';
import Header from '../../layouts/Header';
import Filter from '../../helpers/filter/Filter';
import classesCustom from '../../helpers/search/CustomSearch.module.css';
import { MdDownload, MdOutlineRefresh } from 'react-icons/md';
import DateOnlyFormatter from '../../helpers/inputs/DateOnlyFormatter';

export default function AccountReceivableReport() {
  const [accountReceivableReportData, setAccountReceivableReportData] =
    useState<AccountReceivableReport>();
  const [filterValue, setFilterValue] = useState<string>('');
  const customerList = useRef(null);
  const { customerId } = useParams();
  const navigate = useNavigate();
  const currentCustomer = customerList?.current?.find(
    x => x.customerId === Number(customerId),
  );

  useEffect(() => {
    if (!customerList.current) {
      Get(`/Customer/GetCustomerList`).then(response => {
        if (response) {
          customerList.current = response.data;
        }
      });
    }
    if (customerId) {
      GetAccountReceivableReport(customerId);
    }
  }, [customerId, customerList]);

  function GetAccountReceivableReport(customerId) {
    Get(`/Accounting/GetAccountReceivableReport/${customerId}`).then(
      response => {
        if (response) {
          setAccountReceivableReportData(response.data);
        }
      },
    );
  }

  function DownloadAccountReceivableReportCSV(customerId) {
    Get(`Accounting/DownloadAccountReceivableReportCSV/${customerId}`).then(
      response => {
        if (response) {
          const blob = new Blob([response.data], { type: 'text/csv' });
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download =
            new Date().toISOString().split('T')[0].replace(/[-]/g, '') +
            '_Aging Receivables Report.csv';
          a.click();
          URL.revokeObjectURL(url);
          GetAccountReceivableReport(customerId);
        }
      },
    );
  }

  const HandleCustomerChange = e => {
    navigate(`../accountreceivablereport/${e.target.value}`);
  };

  const AccountReceivableStats = ({
    reportData,
  }: {
    reportData: AccountReceivableReport;
  }) => {
    const formattedTotalAR = reportData.totalAR.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    const formattedCurrentAR = reportData.currentAR.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    const formattedThirtyDayAR = reportData.thirtyDayAR.toLocaleString(
      'en-US',
      {
        style: 'currency',
        currency: 'USD',
      },
    );
    const formattedSixtyDayAR = reportData.sixtyDayAR.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    const formattedNinetyDayAR = reportData.ninetyDayAR.toLocaleString(
      'en-US',
      {
        style: 'currency',
        currency: 'USD',
      },
    );
    const formattedOneHundredTwentyDayAR =
      reportData.oneHundredTwentyDayAR.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    return (
      <div className={classesCustom.customerStats}>
        <h3>Loads with Balance: {reportData.countOfInvoices}</h3>
        <h3>Total AR: {formattedTotalAR}</h3>
        <h3>Current: {formattedCurrentAR}</h3>
        <h3>30+: {formattedThirtyDayAR}</h3>
        <h3>60+: {formattedSixtyDayAR}</h3>
        <h3>90+: {formattedNinetyDayAR}</h3>
        <h3>120+: {formattedOneHundredTwentyDayAR}</h3>
      </div>
    );
  };

  const AccountReceivableReportTable = ({
    reportLineList,
  }: {
    reportLineList: Array<AccountReceivableAgingReportLine>;
  }) => {
    const formattedAccountReceivableList = reportLineList.map(line => ({
      ...line,
      attribute: line.loadId
        ? line.loadId
        : line.rentalId && 'Rental: ' + line.rentalId,
      invoiceTotal:
        line.invoiceTotal.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }) ?? 0,
      remainingBalance:
        line.remainingBalance.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }) ?? 0,
      invoiceDate: DateOnlyFormatter(line.invoiceDate),
      dueDate: DateOnlyFormatter(line.dueDate),
    }));
    const filteredData = FilterData(
      formattedAccountReceivableList,
      filterValue,
    );

    let columnConfig = [
      {
        key: '1',
        attribute: 'attribute',
        attributeprimaryhidden: 'loadId',
        attributesecondaryhidden: 'rentalId',
        header: 'Load #',
        width: '8ch',
        onClick: e =>
          View(
            e.target.dataset.attributeprimaryhidden ? (
              <ViewLoad loadId={e.target.dataset.attributeprimaryhidden} />
            ) : (
              e.target.dataset.attributesecondaryhidden && (
                <ViewRental
                  rentalId={e.target.dataset.attributesecondaryhidden}
                />
              )
            ),
          ),
      },
      {
        key: '2',
        attribute: 'reference',
        header: 'Reference',
        width: '15ch',
      },
      // {
      //   key: '3',
      //   attribute: 'assignmentNumber',
      //   attributeprimaryhidden: 'customerAssignmentId',
      //   header: 'Assignment #',
      //   width: '8ch',
      //   onClick: e =>
      //     View(
      //       <ViewReceivable
      //         customerAssignmentId={e.target.dataset.attributeprimaryhidden}
      //       />,
      //     ),
      // },
      {
        key: '4',
        attribute: 'invoiceTotal',
        header: 'Invoice Total',
        width: '25ch',
        dataType: 'currency',
      },
      {
        key: '5',
        attribute: 'remainingBalance',
        header: 'Remaining Balance',
        width: '14ch',
        dataType: 'currency',
      },
      {
        key: '6',
        attribute: 'invoiceDate',
        header: 'Invoice Date',
        width: '14ch',
        dataType: 'date',
      },
      {
        key: '7',
        attribute: 'daysInAR',
        header: 'Days In AR',
        width: '6ch',
      },
      {
        key: '8',
        attribute: 'dueDate',
        header: 'Due Date',
        width: '6ch',
        dataType: 'date',
      },
      {
        key: '9',
        attribute: 'status',
        header: 'Status',
        width: '6ch',
      },
    ];
    return <BBITable data={filteredData ?? []} columns={columnConfig} />;
  };

  return (
    <>
      <h1>Account Receivable Report: {currentCustomer?.customerName}</h1>
      {accountReceivableReportData && (
        <AccountReceivableStats reportData={accountReceivableReportData} />
      )}
      <Header>
        <div>
          <Select onChange={HandleCustomerChange} value={customerId}>
            <OptionList
              optionData={customerList.current}
              attributeID="customerId"
              attributeName="customerName"
              attributeGroup=""
            />
          </Select>
          <button onClick={() => GetAccountReceivableReport(customerId)}>
            <MdOutlineRefresh />
          </button>

          <button
            onClick={() => DownloadAccountReceivableReportCSV(customerId)}
          >
            <MdDownload />
            <span>Download</span>
          </button>
        </div>

        <div>
          <Filter
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            placeholder="Filter"
          />
        </div>
      </Header>
      {accountReceivableReportData && (
        <AccountReceivableReportTable
          reportLineList={
            accountReceivableReportData.accountReceivableReportLines
          }
        />
      )}
    </>
  );
}
