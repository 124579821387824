import React, { useState } from 'react';
import classes from '../../../assets/Global.module.css';
import Input from '../../../assets/essentials/Input';
import Select from '../../../assets/essentials/Select';
import OptionList from '../../../helpers/options/OptionList';
import {
  CreateLogOptions,
  MaintenanceLog,
  OptionLists,
} from './MaintenanceLog';
import TextArea from '../../../assets/essentials/TextArea';

interface CreateMaintenanceLogProps {
  log: MaintenanceLog;
  UpdateLog: Function;
  createLogOptions: CreateLogOptions;
  setCreateLogOptions: Function;
  optionLists: OptionLists;
}

function Details({
  log,
  UpdateLog,
  createLogOptions,
  setCreateLogOptions,
  optionLists,
}: CreateMaintenanceLogProps) {
  const [totalCost, setTotalCost] = useState(sumCosts(log));

  function sumCosts(log: MaintenanceLog) {
    return (
      Number(log.laborCost || 0) +
      Number(log.partsCost || 0) +
      Number(log.roadsideCost || 0) +
      Number(log.miscellaneousCost || 0) +
      Number(log.taxesCost || 0)
    );
  }

  //if user changes asset type, reset tire costs
  function resetTireCosts(assetType: 'truck' | 'trailer') {
    if (assetType === 'trailer') {
      UpdateLog(null, 'truckDriverSideSteerTireCost');
      UpdateLog(null, 'truckPassengerSideSteerTireCost');
      UpdateLog(null, 'truckFrontAxleDriverSideInnerTireCost');
      UpdateLog(null, 'truckFrontAxleDriverSideOuterTireCost');
      UpdateLog(null, 'truckFrontAxlePassengerSideInnerTireCost');
      UpdateLog(null, 'truckFrontAxlePassengerSideOuterTireCost');
      UpdateLog(null, 'truckRearAxleDriverSideInnerTireCost');
      UpdateLog(null, 'truckRearAxleDriverSideOuterTireCost');
      UpdateLog(null, 'truckRearAxlePassengerSideInnerTireCost');
      UpdateLog(null, 'truckRearAxlePassengerSideOuterTireCost');
    } else if (assetType === 'truck') {
      UpdateLog(null, 'trailerFrontAxleDriverSideOuterTireCost');
      UpdateLog(null, 'trailerFrontAxleDriverSideInnerTireCost');
      UpdateLog(null, 'trailerFrontAxlePassengerSideInnerTireCost');
      UpdateLog(null, 'trailerFrontAxlePassengerSideOuterTireCost');
      UpdateLog(null, 'trailerRearAxleDriverSideInnerTireCost');
      UpdateLog(null, 'trailerRearAxleDriverSideOuterTireCost');
      UpdateLog(null, 'trailerRearAxlePassengerSideInnerTireCost');
      UpdateLog(null, 'trailerRearAxlePassengerSideOuterTireCost');
    }
  }

  const TruckOptionList = () => {
    const truckOptions = optionLists.assetOptions?.filter(
      option => option.group === 'Truck',
    );
    return (
      <OptionList
        optionData={truckOptions}
        attributeID="assetId"
        attributeName="name"
      />
    );
  };
  const TrailerOptionList = () => {
    const trailerOptions = optionLists.assetOptions?.filter(
      option => option.group === 'Trailer',
    );
    return (
      <OptionList
        optionData={trailerOptions}
        attributeID="assetId"
        attributeName="name"
      />
    );
  };

  const handleAssetChange = e => {
    const selectedIndex = e.target.selectedIndex;
    const selectedOption = e.target.options[selectedIndex];
    UpdateLog(selectedOption.text, 'assetName');
    UpdateLog(e.target.value, 'assetId');
  };

  const handleVendorChange = e => {
    const selectedIndex = e.target.selectedIndex;
    const selectedOption = e.target.options[selectedIndex];
    UpdateLog(selectedOption.text, 'vendorName');
    UpdateLog(e.target.value, 'vendorId');
  };

  const handleCostChange = (value: number, field: keyof MaintenanceLog) => {
    UpdateLog(value, field);
    setTotalCost(sumCosts({ ...log, [field]: value }));
  };

  const handleAssetTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newAssetType = e.target.value as 'truck' | 'trailer';
    setCreateLogOptions(prevState => ({
      ...prevState,
      assetType: newAssetType,
    }));
    resetTireCosts(newAssetType);
  };
  return (
    <form className={classes.viewGrid}>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label htmlFor="assetType">
          Asset Type <span className={classes.required}>*</span>
        </label>
        <Select
          id="assetType"
          defaultValue={createLogOptions.assetType ?? ''}
          onChange={e => handleAssetTypeChange(e)}
        >
          <option value="truck">Truck</option>
          <option value="trailer">Trailer</option>
        </Select>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label htmlFor="assetId">
          Asset <span className={classes.required}>*</span>
        </label>
        <Select
          id="assetId"
          name="assetId"
          defaultValue={log.assetId ?? ''}
          onChange={e => handleAssetChange(e)}
        >
          <option disabled value="">
            -- Required --
          </option>
          {createLogOptions.assetType === 'truck' ? (
            <TruckOptionList />
          ) : (
            <TrailerOptionList />
          )}
        </Select>
      </div>
      <div className={classes.attribute}>
        <label>
          Invoice # <span className={classes.required}>*</span>
        </label>
        <Input
          id="invoiceNumber"
          name="invoiceNumber"
          onChange={e => UpdateLog(e.target.value, 'invoiceNumber')}
          defaultValue={log.invoiceNumber ?? ''}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor="vendorId">
          Vendor <span className={classes.required}>*</span>
        </label>
        <Select
          defaultValue={log.vendorId ?? ''}
          id="vendorId"
          name="vendorId"
          onChange={e => handleVendorChange(e)}
        >
          <option disabled value="">
            -- Required --
          </option>
          <OptionList
            optionData={optionLists.vendorOptions}
            attributeID="vendorId"
            attributeName="name"
          />
        </Select>
      </div>
      <div className={classes.attribute}>
        <label htmlFor="billDate">Bill Date</label>
        <Input
          defaultValue={log.billDate ?? ''}
          id="billDate"
          name="billDate"
          type="date"
          onChange={e => UpdateLog(e.target.value, 'billDate')}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor="billBack">Billing Back</label>
        <Select
          defaultValue={log.billingBack ? 'true' : 'false'}
          id="billingBack"
          name="billingBack"
          onChange={e => UpdateLog(Boolean(e.target.value), 'billingBack')}
        >
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label htmlFor="description">Description</label>
        <TextArea
          rows={4}
          defaultValue={log.description}
          id="description"
          name="description"
          onChange={e => UpdateLog(e.target.value, 'description')}
        />
      </div>
      <h3>Costs</h3>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label htmlFor="labor">Labor</label>
        <Input
          defaultValue={log.laborCost ?? ''}
          id="labor"
          type="number"
          min="0"
          step="0.01"
          onChange={e => handleCostChange(Number(e.target.value), 'laborCost')}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label htmlFor="parts">Parts</label>
        <Input
          defaultValue={log.partsCost ?? ''}
          id="parts"
          type="number"
          min="0"
          step="0.01"
          onChange={e => handleCostChange(Number(e.target.value), 'partsCost')}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label htmlFor="roadsideCharges">Roadside Charges</label>
        <Input
          defaultValue={log.roadsideCost ?? ''}
          id="roadsideCharges"
          type="number"
          min="0"
          step="0.01"
          onChange={e =>
            handleCostChange(Number(e.target.value), 'roadsideCost')
          }
        />
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label htmlFor="miscCharges">Miscellaneous</label>
        <Input
          defaultValue={log.miscellaneousCost ?? ''}
          id="miscCharges"
          type="number"
          min="0"
          step="0.01"
          onChange={e =>
            handleCostChange(Number(e.target.value), 'miscellaneousCost')
          }
        />
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label htmlFor="taxes">Taxes</label>
        <Input
          defaultValue={log.taxesCost ?? ''}
          id="taxes"
          type="number"
          min="0"
          step="0.01"
          onChange={e => handleCostChange(Number(e.target.value), 'taxesCost')}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label htmlFor="totalCost">Total Cost (Excluding Tires)</label>
        <Input
          value={totalCost.toFixed(2)}
          id="totalCost"
          type="number"
          readOnly
        />
      </div>
      <label htmlFor="includesTires">
        <input
          type="checkbox"
          id="includesTires"
          defaultChecked={createLogOptions.includesTires}
          onChange={e =>
            setCreateLogOptions(prevState => ({
              ...prevState,
              includesTires: e.target.checked,
            }))
          }
        />
        Includes Tires
      </label>
    </form>
  );
}

export default Details;
