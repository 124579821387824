import React, { useEffect, useState } from 'react';

import Slab from '../../../helpers/slab/Slab';
import Files from './../../creates/file/Files';
import Audit from '../generic/Audit';
import Contacts from './Contacts';
import Notes from './Notes';
import Billing from './Billing';
import Get from '../../../api/internal/Get';
import Lanes from './Lanes';
import Summary from './Summary';
import { useRoleAuthorization } from '../../../hooks/useRoleAuthorization';
import { ADMIN_AUTHORIZED_ROLES } from '../../../auth/AuthRoles';
import Admin from './Admin';

function Customer({
  customerId,
  customerName,
  startPage,
}: {
  customerId: number;
  customerName: string;
  startPage?: string;
}) {
  const [currentPage, setCurrentPage] = useState();
  const [customerDataObject] = useState<CustomerDataObject>({
    Admin: null,
    Audit: null,
    Billing: null,
    Contacts: null,
    Files: null,
    Notes: null,
    Lanes: null,
    Summary: null,
  });

  useEffect(() => {
    startPage ? HandlePageChange(startPage) : HandlePageChange('Summary');
  }, []);

  function HandlePageChange(params) {
    if (!customerDataObject[params] && pages[params]?.callerPath) {
      if (Array.isArray(pages[params].callerPath)) {
        let dataObj = {};
        let attributeList = [];
        for (let i = 0; i < pages[params].callerPath.length; i++) {
          attributeList.push(i + 1);
          Get(pages[params].callerPath[i]).then(response => {
            if (response) {
              dataObj[i + 1] = response.data;
              if (HasAllAttributes(dataObj, attributeList)) {
                customerDataObject[params] = dataObj;
                AssignPages();
                setCurrentPage(params);
              }
            }
          });
        }
      } else {
        Get(pages[params].callerPath).then(response => {
          if (response) {
            customerDataObject[params] = response.data;
          }
          AssignPages();
          setCurrentPage(params);
        });
      }
    } else {
      setCurrentPage(params);
    }

    function HasAllAttributes(obj, attributeList) {
      for (const attribute of attributeList) {
        if (!obj.hasOwnProperty(attribute)) {
          return false;
        }
      }
      return true;
    }
  }

  function RefreshPage(currentPage: string, additionalPages: string[]) {
    if (customerDataObject) {
      setCurrentPage(null);
      customerDataObject[currentPage] = null;
      additionalPages?.forEach(pageName => {
        customerDataObject[pageName] = null;
      });
      HandlePageChange(currentPage);
    }
  }

  let pages = {};
  AssignPages();
  function AssignPages() {
    pages = {
      Summary: {
        content: (
          <Summary
            data={customerDataObject.Summary}
            customerId={customerId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Customer/GetCustomerSummary/${customerId}`,
      },
      Billing: {
        content: (
          <Billing
            customerId={customerId}
            data={customerDataObject.Billing}
            HandlePageChange={HandlePageChange}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Customer/GetCustomerBilling/${customerId}`,
      },
      Lanes: {
        content: (
          <Lanes
            data={customerDataObject.Lanes}
            customerId={customerId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Customer/GetCustomerLanes/${customerId}`,
      },
      Contacts: {
        content: (
          <Contacts
            data={customerDataObject.Contacts}
            customerId={customerId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Customer/GetCustomerContacts/${customerId}`,
      },
      Notes: {
        content: (
          <Notes
            data={customerDataObject.Notes}
            customerId={customerId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Customer/GetCustomerNotes/${customerId}`,
      },
      Files: {
        content: (
          <Files
            fileCategory={'customer'}
            categoryId={customerId}
            data={customerDataObject.Files}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/File/GetAllFiles/customer-${customerId}`,
      },
      Audit: {
        content: <Audit data={customerDataObject.Audit} />,
        callerPath: `/Customer/GetCustomerAudit/${customerId}`,
      },
    };
    if (useRoleAuthorization(ADMIN_AUTHORIZED_ROLES))
      pages['Admin'] = {
        content: (
          <Admin
            data={customerDataObject.Admin}
            customerId={customerId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Customer/GetCustomerAdminDetails/${customerId}`,
      };
  }

  return (
    <Slab
      title={
        <>
          {customerName}
          <p>Customer</p>
        </>
      }
      pages={pages}
      currentPage={currentPage}
      HandlePageChange={HandlePageChange}
      dataObject={customerDataObject}
    />
  );
}
export default Customer;
