import React, { useState } from 'react';
import classesError from '../../../../helpers/error/Error.module.css';
import Button from '../../../../assets/essentials/Button';
import PutBody from '../../../../api/internal/PutBody';
function SendLoadToClaimsSection({
  loadId,
  loadStatus,
  isButtonDisabled,
  setIsButtonDisabled,
  UnsendLoadToClaims,
  GetLoad,
  RefreshPage,
}: {
  loadId: number;
  loadStatus: string;
  isButtonDisabled: boolean;
  setIsButtonDisabled: Function;
  UnsendLoadToClaims: Function;
  GetLoad: Function;
  RefreshPage: Function;
}) {
  const [isClaimsModalOpen, setIsClaimsModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const isLoadInClaims = loadStatus.includes('Claim');

  function SendLoadToClaims() {
    setIsButtonDisabled(true);
    const payload = {
      loadId: loadId,
    };
    PutBody(`/Load/UpdateLoadToSendToClaims`, payload)
      .then(() => {
        GetLoad();
        RefreshPage('Admin', ['Audit', 'Summary']);
      })
      .finally(() => {
        setIsButtonDisabled(false);
      });
  }

  const SendToClaimsModal = () => (
    <div className={classesError.errorContainer}>
      <div
        className={classesError.overlay}
        onClick={() => setIsCancelModalOpen(false)}
      />
      <div className={classesError.errorHolder}>
        <div className={classesError.yesNoModal}>
          <h3>Are you sure you want to send the load to claims?</h3>
          <div className={classesError.buttonHolder}>
            <Button
              disabled={isButtonDisabled}
              onClick={() => SendLoadToClaims()}
              variant="good"
            >
              Yes
            </Button>
            <Button variant="bad" onClick={() => setIsClaimsModalOpen(false)}>
              No
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  const SendToClaimsSection = () => (
      <Button
        onClick={() => setIsClaimsModalOpen(true)}
        variant={isLoadInClaims ? 'bad' : 'good'}
      >
        {isLoadInClaims ? 'Unsend Load To Claims' : 'Send Load To Claims'}
      </Button>
  );

  const UnsendToClaimsModal = () => (
    <div className={classesError.errorContainer}>
      <div
        className={classesError.overlay}
        onClick={() => setIsClaimsModalOpen(false)}
      />
      <div className={classesError.errorHolder}>
        <div className={classesError.yesNoModal}>
          <h3>
            Unsending a to claims load will return it to its most recent load
            status.
          </h3>
          <h3>Are you sure you want to unsend load to claims?</h3>
          <div className={classesError.buttonHolder}>
            <Button
              disabled={isButtonDisabled}
              onClick={() => UnsendLoadToClaims()}
              variant="good"
            >
              Yes
            </Button>
            <Button variant="bad" onClick={() => setIsCancelModalOpen(false)}>
              No
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <SendToClaimsSection />
      {isClaimsModalOpen &&
        (isLoadInClaims ? <UnsendToClaimsModal /> : <SendToClaimsModal />)}
    </>
  );
}

export default SendLoadToClaimsSection;
