import React from 'react';
import { BsTrash } from 'react-icons/bs';

import BBITable from '../../../helpers/bBITable/BBITable';
import Service from '../../assign/service/Location';
import Delete from '../../../api/internal/Delete';
import classes from '../../../assets/Global.module.css';

function Services({ locationId, data, RefreshPage }) {
  const formattedLocationServiceData = data.locationServiceList?.map(
    locationServices => ({
      ...locationServices,
      delete: (
        <BsTrash
          className={classes.clickable}
          onClick={() => {
            DeleteLocationService(locationServices.locationServiceId);
          }}
        />
      ),
    }),
  );

  let columnConfig = [
    {
      key: '1',
      attribute: 'locationServiceType',
      header: 'Service',
    },
    {
      key: '2',
      attribute: 'note',
      header: 'Note',
    },
    {
      key: '3',
      attribute: 'delete',
      header: '',
    },
  ];

  function DeleteLocationService(locationServiceId) {
    Delete(`/Location/DeleteLocationService/${locationServiceId}`).then(() =>
      RefreshPage('Services', ['Audit', 'Summary']),
    );
  }

  return (
    <>
      {data.locationServiceTypeOptions.length !== 0 && (
        <Service
          locationId={locationId}
          data={data}
          RefreshPage={RefreshPage}
        />
      )}
      <BBITable data={formattedLocationServiceData} columns={columnConfig} />
    </>
  );
}

export default Services;
