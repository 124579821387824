import React, { useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import classes from '../../../assets/Global.module.css';
import FinancialTable from '../../../helpers/financialsEditableTable/FinancialsTable';
import PostBody from '../../../api/internal/PostBody';
import ViewDriver from '../users/driver/Driver';
import View from '../../../helpers/slab/View';
import ViewVendor from '../vendor/Vendor';
import ViewCustomer from '../customer/Customer';
import AssembleUpdatedFinancial from '../../../helpers/financialsEditableTable/AssembleFinancialObject';
import ViewReceivable from '../receivable/Receivable';
import ViewPayable from '../payable/Payable';
import ViewPayroll from '../payroll/Payroll';

function LoadFinancials({
  data,
  loadId,
  RefreshPage,
}: {
  data: LoadFinancials;
  loadId: number;
  RefreshPage: Function;
}) {
  const [receivablesVisible, setReceivablesVisible] = useState(true);
  const [payrollsVisible, setPayrollsVisible] = useState(true);
  const [payablesVisible, setPayablesVisible] = useState(true);
  const ReceivableSection = ({
    customer,
  }: {
    customer: LoadCustomerAssignmentFinancialObject;
  }) => {
    function SaveDraftReceivable(e, assignmentId) {
      e.preventDefault();
      PostBody(
        'Accounting/SaveDraftFinancial',
        AssembleUpdatedFinancial(e, assignmentId, 'Customer'),
      ).then(response => {
        if (response) {
          RefreshPage('Financials', ['Audit', 'Itinerary']);
        }
      });
    }
    return (
      <div key={customer.customerAssignmentId}>
        {receivablesVisible && (
          <>
            <div className={classes.flexColumn}>
              <h3 className={classes.headerWithoutMargin}>
                {customer.customerName}
              </h3>
              <div className={classes.sideBySideButtons}>
                <div
                  className={`${classes.cTA}`}
                  onClick={() =>
                    View(
                      <ViewCustomer
                        customerId={customer.customerId}
                        customerName={customer.customerName}
                      />,
                    )
                  }
                >
                  View Customer
                </div>
                <div
                  className={`${classes.cTA}`}
                  onClick={() =>
                    View(
                      <ViewReceivable
                        customerAssignmentId={customer.customerAssignmentId}
                        loadId={loadId}
                        rentalId={null}
                      />,
                    )
                  }
                >
                  View Receivable
                </div>
              </div>
            </div>
            <FinancialTable
              rowData={customer.receivables}
              assignmentId={customer.customerAssignmentId}
              assignmentType="Customer"
              accountTypeOptions={customer.receivableAccountTypeOptions}
              onSubmit={SaveDraftReceivable}
            />
          </>
        )}
      </div>
    );
  };

  const PayableSection = ({
    vendor,
  }: {
    vendor: VendorAssignmentFinancialObject;
  }) => {
    function SaveDraftPayable(e, assignmentId) {
      e.preventDefault();
      PostBody(
        'Accounting/SaveDraftFinancial',
        AssembleUpdatedFinancial(e, assignmentId, 'Vendor'),
      ).then(response => {
        if (response) {
          RefreshPage('Financials', ['Audit']);
        }
      });
    }
    return (
      <div key={vendor.vendorAssignmentId}>
        {payablesVisible && (
          <>
            <div className={classes.flexColumn}>
              <h3 className={classes.headerWithoutMargin}>
                {vendor.vendorName}
              </h3>
              <div className={classes.sideBySideButtons}>
                <div
                  className={`${classes.cTA}`}
                  onClick={() =>
                    View(
                      <ViewVendor
                        vendorId={vendor.vendorId}
                        vendorName={vendor.vendorName}
                      />,
                    )
                  }
                >
                  View Vendor
                </div>
                <div
                  className={`${classes.cTA}`}
                  onClick={() =>
                    View(
                      <ViewPayable
                        vendorAssignmentId={vendor.vendorAssignmentId}
                      />,
                    )
                  }
                >
                  View Payable
                </div>
              </div>
            </div>
            <FinancialTable
              rowData={vendor.payables}
              assignmentId={vendor.vendorAssignmentId}
              assignmentType="Vendor"
              accountTypeOptions={vendor.payableAccountTypeOptions}
              onSubmit={SaveDraftPayable}
            />
          </>
        )}
      </div>
    );
  };

  const PayrollSection = ({
    driver,
  }: {
    driver: AssetAssignmentFinancialObject;
  }) => {
    function SaveDraftPayroll(e, assignmentId) {
      e.preventDefault();
      PostBody(
        'Accounting/SaveDraftFinancial',
        AssembleUpdatedFinancial(e, assignmentId, 'Asset'),
      ).then(response => {
        if (response) {
          RefreshPage('Financials', ['Audit']);
        }
      });
    }
    return (
      <div key={driver.assetAssignmentId}>
        {payrollsVisible && (
          <>
            <div className={classes.flexColumn}>
              <h3 className={classes.headerWithoutMargin}>
                {driver.driverName}
              </h3>
              <div className={classes.sideBySideButtons}>
                <div
                  className={`${classes.cTA} ${classes.attribute}`}
                  onClick={() =>
                    View(
                      <ViewDriver
                        employeeId={driver.employeeId}
                        driverName={driver.driverName}
                      />,
                    )
                  }
                >
                  View Driver
                </div>
                <div
                  className={`${classes.cTA}`}
                  onClick={() =>
                    View(
                      <ViewPayroll
                        assetAssignmentId={driver.assetAssignmentId}
                      />,
                    )
                  }
                >
                  View Payroll
                </div>
              </div>
            </div>
            <FinancialTable
              rowData={driver.payrolls}
              assignmentId={driver.assetAssignmentId}
              assignmentType="Asset"
              accountTypeOptions={driver.payrollAccountTypeOptions}
              onSubmit={SaveDraftPayroll}
            />
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {/*Receivables per Customer */}
      <div className={classes.grayBottomDivider}>
        <span className={classes.flexSpaceBetween}>
          <h2 onClick={() => setReceivablesVisible(!receivablesVisible)}>
            Receivables{' '}
            {receivablesVisible ? <BsChevronDown /> : <BsChevronUp />}
          </h2>
          <h3>
            Total Receivable: $
            {data.totalReceivable?.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h3>
        </span>
        {data.customerFinancialsList?.map(customer => (
          <ReceivableSection
            key={customer.customerAssignmentId}
            customer={customer}
          />
        ))}
      </div>

      {/*Payrolls per Driver */}
      {data.isLoadDelivered && (
        <div className={classes.grayBottomDivider}>
          <span className={classes.flexSpaceBetween}>
            <h2 onClick={() => setPayrollsVisible(!payrollsVisible)}>
              Payrolls {payrollsVisible ? <BsChevronDown /> : <BsChevronUp />}
            </h2>
            <h3>
              Total Payroll: $
              {data.totalPayroll?.toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h3>
          </span>
          {data.assetFinancialsList?.map(driver => (
            <PayrollSection key={driver.assetAssignmentId} driver={driver} />
          ))}
        </div>
      )}

      {/*Payables per Vendor */}
      <div className={classes.grayBottomDivider}>
        <span className={classes.flexSpaceBetween}>
          <h2 onClick={() => setPayablesVisible(!payablesVisible)}>
            Payables {payablesVisible ? <BsChevronDown /> : <BsChevronUp />}
          </h2>
          {data.vendorFinancialsList.length === 0 && 'No Vendors Assigned'}
          <h3>
            Total Payable: $
            {data.totalPayable?.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h3>
        </span>
        {data.vendorFinancialsList?.map(vendor => (
          <PayableSection key={vendor.vendorAssignmentId} vendor={vendor} />
        ))}
      </div>
      {!data.isLoadDelivered && (
        <footer>
          Looking for Payrolls? Payrolls are available once a Load is marked{' '}
          <b>Delivered.</b>
        </footer>
      )}
    </>
  );
}

export default LoadFinancials;
