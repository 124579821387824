import { useEffect, useState } from 'react';
import { RiGroupLine } from 'react-icons/ri';

import Header from '../layouts/Header';
import Filter from '../helpers/filter/Filter';
import BBITable from '../helpers/bBITable/BBITable';
import FilterData from '../helpers/filter/FilterData';
import classes from '../helpers/bBITable/BBITable.module.css';
import classesGlobal from '../assets/Global.module.css';
import Get from '../api/internal/Get';
import React from 'react';
import { FiAlignLeft } from 'react-icons/fi';
import { TbAlignLeft } from 'react-icons/tb';
import { AiOutlineCheck } from 'react-icons/ai';
import Create from '../helpers/panel/Create';
import CreatePosting from '../features/creates/posting/Posting';

function Posting({ toggleBoardRefresh }) {
  const [filterValue, setFilterValue] = useState('');
  const [postingBoard, setPostingBoard] = useState([]);
  const [isCompact, setIsCompact] = useState(false);
  const [showViewOption, setShowViewOption] = useState(false);

  useEffect(() => {
    GetPostingBoard();
  }, [toggleBoardRefresh]);

  function GetPostingBoard() {
    Get(`/Posting/GetPostingBoard/`).then(response => {
      if (response) {
        setPostingBoard(response.data);
      }
    });
  }

  let columnConfig = [
    {
      key: '1',
      attribute: 'postId',
      header: '',
    },
    {
      key: '2',
      attribute: 'posted',
      header: 'Posted',
    },
    {
      key: '3',
      attribute: 'availability',
      header: 'Availability',
    },
    {
      key: '4',
      attribute: 'equipment',
      header: 'Equipment',
    },
    {
      key: '5',
      attribute: 'weight',
      header: 'Weight',
    },
    {
      key: '6',
      attribute: 'numOfTrucks',
      header: 'Number of trucks',
    },
    {
      key: '7',
      attribute: 'origin',
      header: 'Origin',
    },
    {
      key: '8',
      attribute: 'destination',
      header: 'Destination',
    },
  ];

  const PostBoardSection = ({
    title,
    posts,
    filterValue,
    columnConfig,
    compactView,
  }) => (
    <>
      <div className={classes.title}>{title}</div>
      {posts.length > 0 ? (
        <div className={compactView ? classes.compactView : classes.normalView}>
          <BBITable
            data={FilterData(posts, filterValue)}
            columns={columnConfig}
            compactView={compactView}
          />
        </div>
      ) : (
        <p>No {title} loads.</p>
      )}
    </>
  );

  return (
    <>
      <h1>Posting</h1>
      <Header>
        <div>
          <button
            onClick={() =>
              Create(<CreatePosting GetPostingBoard={GetPostingBoard} />)
            }
          >
            <RiGroupLine />
            <span>Create a Posting</span>
          </button>
        </div>
        <div>
          <div className={classesGlobal.dropdown}>
            <button
              aria-label="Open Toggle Compact Menu"
              onClick={() => setShowViewOption(!showViewOption)}
            >
              {isCompact ? <FiAlignLeft /> : <TbAlignLeft />}
            </button>
            {showViewOption && (
              <div className={classesGlobal.dropdownOptions}>
                <div
                  onClick={() => {
                    setIsCompact(false);
                    setShowViewOption(false);
                  }}
                >
                  {!isCompact ? <AiOutlineCheck /> : <div />}
                  <TbAlignLeft />
                  <span>Normal View</span>
                </div>
                <div
                  onClick={() => {
                    setIsCompact(true);
                    setShowViewOption(false);
                  }}
                >
                  {isCompact ? <AiOutlineCheck /> : <div />}
                  <FiAlignLeft />
                  <span>Compact View</span>
                </div>
              </div>
            )}
          </div>
          <Filter
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            placeholder="Filter postings"
          />
        </div>
      </Header>
      <PostBoardSection
        title="DAT"
        posts={postingBoard || []}
        filterValue={filterValue}
        columnConfig={columnConfig}
        compactView={isCompact}
      />
      <PostBoardSection
        title="Truckstop"
        posts={postingBoard || []}
        filterValue={filterValue}
        columnConfig={columnConfig}
        compactView={isCompact}
      />
    </>
  );
}

export default Posting;
