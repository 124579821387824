import { FiPhoneCall } from 'react-icons/fi';
import { useState } from 'react';

import OptionList from '../../../helpers/options/OptionList';
import PostBody from '../../../api/internal/PostBody';
import PostForm from '../../../api/internal/PostForm';
import classes from './Load.module.css';
import classesGlobal from '../../../assets/Global.module.css';
import GoogleSearchBoxCity from '../../../helpers/inputs/GoogleSearchBoxCity';
import Button from '../../../assets/essentials/Button';
import Select from '../../../assets/essentials/Select';
import Input from '../../../assets/essentials/Input';
import TextArea from '../../../assets/essentials/TextArea';
import Form from '../../../assets/essentials/Form';

function Load({ data, loadId, page, RefreshPage }) {
  const [isOpen, setIsOpen] = useState(false);
  const [loadNoteTypeId, setLoadNoteTypeId] = useState('');
  const [appendix, setAppendix] = useState({
    city: '',
    state: '',
    zip: '',
    country: '',
    latitude: '',
    longitude: '',
    loadId: loadId,
    nextStopLocationId: data?.nextStopLocationId ?? '',
    hoursToNextStop: '',
    milesToNextStop: '',
  });

  function CreateCheckCall(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    // Passing in 'page' and filtering this pageArray to ensure that when RefreshPage
    // runs the user is kept on the expected page and all other pages still get refreshed
    const pageArray = ['Summary', 'Itinerary', 'Audit'];
    PostForm(`/Load/CreateLoadNote`, formData, appendix).then(() =>
      RefreshPage(
        page,
        pageArray.filter(item => item !== page),
      ),
    );
    setIsOpen(false);
  }

  //takes current location params from google api and assigns appendix values
  const CallBackPlace = place => {
    place.address_components.forEach(component => {
      component.types.forEach(type => {
        if (type === 'locality') {
          appendix.city = component.short_name;
        } else if (type === 'administrative_area_level_1') {
          appendix.state = component.short_name;
        } else if (type === 'postal_code') {
          appendix.zip = component.short_name;
        } else if (type === 'country') {
          appendix.country = component.short_name;
        }
      });
    });
    appendix.latitude = place.geometry.location.lat();
    appendix.longitude = place.geometry.location.lng();

    GetEstimatedMilesHours(
      place.geometry.location.lat(),
      place.geometry.location.lng(),
    );
  };

  //calculates est miles/hours with google api callback
  function GetEstimatedMilesHours(currentLat, currentLong) {
    const params = {
      locationId: data?.nextStopLocationId,
      currentLatitude: currentLat,
      currentLongitude: currentLong,
    };
    PostBody(`/Load/GetEstimatedMilesAndHoursForLoadNote`, params).then(
      response => {
        const { miles, hours } = response.data;
        setAppendix(prevAppendix => ({
          ...prevAppendix,
          milesToNextStop: miles ?? '',
          hoursToNextStop: hours ?? '',
        }));
      },
    );
  }

  //rounds up to nearest mile
  const roundedEstimatedMiles = appendix.milesToNextStop
    ? Math.ceil(appendix.milesToNextStop).toLocaleString()
    : null;

  return (
    <>
      <div className={classes.btnHolder}>
        <button
          type="button"
          onClick={() => {
            if (!isOpen) {
              setLoadNoteTypeId('');
            }
            setIsOpen(!isOpen);
          }}
        >
          <FiPhoneCall />
          <span>Create a Load Note</span>
        </button>
      </div>
      <div className={classes.createCheckCallHolder}>
        {isOpen && (
          <Form onSubmit={e => CreateCheckCall(e)}>
            <div className={classesGlobal.attribute}>
              <label>Next Stop</label>
              <Input
                disabled
                defaultValue={data?.nextStopCityState}
                name="nextStop"
              />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>
                Call Status <span className={classesGlobal.required}>*</span>
              </label>
              <Select
                required
                defaultValue={data?.loadNoteTypeId ? data.loadNoteTypeId : ''}
                name="loadNoteTypeId"
                onChange={e => setLoadNoteTypeId(e.target.value)}
              >
                <option disabled value="">
                  -- Required --
                </option>
                <OptionList
                  optionData={data.loadNoteTypeOptions}
                  attributeID="loadNoteTypeId"
                  attributeName="type"
                />
              </Select>
            </div>
            <div
              className={`${classesGlobal.attribute} ${
                !data.isTemperatureRequired && classesGlobal.span3
              }`}
            >
              <label>
                Current Location{' '}
                {loadNoteTypeId === '1' && (
                  <span className={classesGlobal.required}>*</span>
                )}
              </label>
              <GoogleSearchBoxCity
                assignPlace={CallBackPlace}
                onBlur={CallBackPlace}
                required={loadNoteTypeId === '1'}
              />
            </div>
            {data.isTemperatureRequired && (
              <div
                className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
              >
                <label>Current Temp (℉)</label>
                <Input type="number" defaultValue={data?.temp} name="temp" />
              </div>
            )}
            <div className={classesGlobal.attribute}>
              <label>Est Mileage</label>
              <Input
                disabled
                defaultValue={roundedEstimatedMiles}
                name="milesToNextStop"
              />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>Est Hours</label>
              <Input
                disabled
                defaultValue={appendix.hoursToNextStop}
                name="hoursToNextStop"
              />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span3}`}
            >
              <label>
                Note <span className={classesGlobal.required}>*</span>
              </label>
              <TextArea required defaultValue={data?.note} name="note" />
            </div>
            <div>
              <Button type="submit" variant="good" disabled={true}>
                Submit
              </Button>
            </div>
            <div>
              <Button
                onClick={() => {
                  setLoadNoteTypeId('');
                  setIsOpen(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </div>
    </>
  );
}

export default Load;
