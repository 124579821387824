import React, { useEffect, useState } from 'react';
import Get from '../../api/internal/Get';
import Button from '../../assets/essentials/Button';
import { SiQuickbooks } from 'react-icons/si';
import classes from './QuickBooksAuth.module.css';
import { LoadingSpinner } from '../../helpers/loadingSpinner/LoadingSpinner';

const QuickBooksAuth = () => {
  const [isTokenValid, setIsTokenValid] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [displayMessage, setDisplayMessage] = useState<boolean>(false);

  useEffect(() => GetTokenStatus(), []);

  const HandleSignIn = () => {
    Get('/Accounting/GetRedirectURL').then(response => {
      if (response) {
        window.open(response.data, '_blank');
        setDisplayMessage(true);
      }
    });
  };

  function GetTokenStatus() {
    setIsLoading(true);
    Get('/Accounting/GetTokenStatus').then(response => {
      if (response) {
        setDisplayMessage(false);
        setIsLoading(false);
        setIsTokenValid(response.data);
      }
    });
  }

  return (
    <>
      <h1 className={classes.title}>QuickBooks Integration</h1>
      <div className={classes.contentContainer}>
        <div className={classes.statusContainer}>
          <strong>Connection Status</strong>
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            <SiQuickbooks
              className={isTokenValid ? classes.good : classes.bad}
            />
          )}
          <strong>
            {isLoading
              ? 'Loading...'
              : isTokenValid
              ? 'Connected'
              : 'Disconnected'}
          </strong>
        </div>
        <div className={classes.buttonContainer}>
          <Button variant="good" onClick={GetTokenStatus} disabled={isLoading}>
            Check Status
          </Button>
          <Button variant="good" onClick={HandleSignIn}>
            Sign In
          </Button>
        </div>
        {displayMessage && (
          <p className={classes.message}>
            Check Status after successful Sign-In.
          </p>
        )}
      </div>
    </>
  );
};

export default QuickBooksAuth;
