import React, { useState, useEffect } from 'react';

import Details from './Details';
import Panel from '../../../helpers/panel/Panel';
import PostBody from '../../../api/internal/PostBody';
import Close from '../../../helpers/panel/Close';
import Get from '../../../api/internal/Get';

function Trailer({ GetTrailerBoard }) {
  const [trailerTypeOptions, setTrailerTypeOptions] =
    useState<CreateTrailerOptions>();
  const [trailerType, setTrailerType] = useState('');
  const [createTrailerParam] = useState<CreateTrailerParam>({
    trailerTypeId: null,
    trailerName: null,
    vin: null,
    licensePlateNumber: null,
    licensePlateState: null,
    make: null,
    year: null,
    mileage: null,
    mileageAsOfDate: null,
    eldId: null,
    eldMake: null,
    eldModel: null,
    note: null,
    isOwned: true
  });

  useEffect(() => {
    Get(`/Asset/GetCreateTrailer`).then(response => {
      if (response) {
        setTrailerTypeOptions(response.data);
      }
    });
  }, []);

  function CreateTrailer() {
    PostBody('Asset/CreateTrailer', createTrailerParam).then(response => {
      if (response) {
        GetTrailerBoard();
        Close();
      }
    });
  }

  let pages = [
    {
      title: 'Details',
      displayedIcon: '',
      content: (
        <Details
          trailer={createTrailerParam}
          createTrailer={trailerTypeOptions}
          setTrailerType={setTrailerType}
          UpdateCreateTrailer={UpdateCreateTrailer}
        />
      ),
      require: [
        {
          label: 'Trailer Type',
          FunctionBool: () => createTrailerParam.trailerTypeId,
          assignedValue: () => trailerType,
          missingDesc: 'Trailer Type has not been assigned',
        },
        {
          label: 'Trailer Name',
          FunctionBool: () => createTrailerParam.trailerName,
          assignedValue: () => createTrailerParam.trailerName,
          missingDesc: 'Trailer Name has not been assigned',
        },
        {
          label: 'VIN',
          FunctionBool: () => createTrailerParam.vin,
          assignedValue: () => createTrailerParam.vin,
          missingDesc: 'VIN has not been assigned',
        },
        {
          label: 'Make',
          FunctionBool: () => createTrailerParam.make,
          assignedValue: () => createTrailerParam.make,
          missingDesc: 'Make has not been assigned',
        },
        {
          label: 'Year',
          FunctionBool: () => createTrailerParam.year,
          assignedValue: () => createTrailerParam.year,
          missingDesc: 'Year has not been assigned',
        },
        //logic to allow user to input no mileage or date, but if mileage is given a date is required
        {
          label: 'Mileage as of Date',
          FunctionBool: () =>
            createTrailerParam.mileage === null ||
            createTrailerParam.mileage === ''
              ? true
              : createTrailerParam.mileageAsOfDate,
          assignedValue: () => {
            if (
              createTrailerParam.mileageAsOfDate &&
              createTrailerParam.mileage !== null &&
              createTrailerParam.mileage !== ''
            ) {
              let parts = createTrailerParam?.mileageAsOfDate
                .toString()
                .split('-');
              let formattedDate = `${parts[1]}/${parts[2]}/${parts[0]}`;
              return createTrailerParam.mileage + ' as of ' + formattedDate;
            }
            return 'No Mileage Entered';
          },
          missingDesc: 'Please provide a date.',
        },
      ],
    },
  ];

  function OnSubmit() {
    let fulfillsFormRequirements = true;
    pages.forEach((page, index) => {
      if (!FulFillsPageRequirements(index)) {
        fulfillsFormRequirements = false;
      }
    });
    if (fulfillsFormRequirements) {
      CreateTrailer();
    } else alert('Not all info included');
  }

  function UpdateCreateTrailer(newValue, attributeName) {
    if (newValue?.toString().trim().length !== 0) {
      createTrailerParam[attributeName] = newValue;
    } else {
      createTrailerParam[attributeName] = null;
    }
  }

  function FulFillsPageRequirements(pageIndex) {
    let fulfillsRequirements = true;
    pages[pageIndex].require.forEach(requirement => {
      if (!requirement.FunctionBool()) {
        fulfillsRequirements = false;
      }
    });
    return fulfillsRequirements;
  }
  return <Panel OnSubmit={OnSubmit} title="Create Trailer" pages={pages} />;
}

export default Trailer;
