import { createRoot } from 'react-dom/client';
import CloseSlab from './Close';
function Create(component) {
  CloseSlab();
  const div = document.createElement('div');
  createRoot(
    document.getElementById('error-container').appendChild(div),
  ).render(component);
}

export default Create;
