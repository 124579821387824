import React, { useState } from 'react';

import classes from '../buttons/DeleteButton.module.css';
import Button from '../../assets/essentials/Button';

function DeleteButton({
  OnDelete,
  buttonLabel,
}: {
  OnDelete: Function;
  buttonLabel?: string;
}) {
  const [isDisplayed, setIsDisplayed] = useState<boolean>(false);

  const HandleDelete = () => {
    OnDelete();
    setIsDisplayed(false);
  };

  return (
    <>
      {isDisplayed ? (
        <div className={classes.confirm}>
          <span>Are you sure?</span>
          <div className={classes.btnContainer}>
            <Button onClick={HandleDelete} variant="bad">
              Yes
            </Button>
            <Button onClick={() => setIsDisplayed(false)}>No</Button>
          </div>
        </div>
      ) : (
        <div>
          <Button onClick={() => setIsDisplayed(true)} variant="bad">
            {buttonLabel ? buttonLabel : 'Delete'}
          </Button>
        </div>
      )}
    </>
  );
}

export default DeleteButton;
