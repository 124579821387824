import { useEffect, useRef } from "react";

export default function ChildCountObserver({
  targetElementId,
  onChildCountChange,
  children,
}) {
  const targetElementRef = useRef(null);

  useEffect(() => {
    const targetElement = targetElementRef.current;

    if (!targetElement) return;

    // Create a new MutationObserver
    const observer = new MutationObserver(mutationsList => {
      mutationsList.forEach(mutation => {
        if (mutation.type === 'childList') {
          // Check if the immediate children of the target element's children have changed
          const currentChildCount = Array.from(targetElement.children).reduce(
            (count, child) => count + child.childElementCount,
            0,
          );
          onChildCountChange(currentChildCount);
        }
      });
    });

    // Configure and start observing the target element
    const config = { childList: true, subtree: true };
    observer.observe(targetElement, config);

    return () => {
      // Disconnect the observer when the component unmounts
      observer.disconnect();
    };
  }, [targetElementRef, onChildCountChange]);

  return (
    <div ref={targetElementRef} id={targetElementId}>
      {children}
    </div>
  );
}
