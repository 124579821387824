import React, { useEffect, useState } from 'react';

import Slab from '../../../helpers/slab/Slab';
import Itinerary from './stop/Itinerary';
import Summary from './Summary';
import LoadFinancials from './Financials';
import Audit from '../generic/Audit';
import Files from './../../creates/file/Files';
import Get from '../../../api/internal/Get';
import Complete from './Complete';
import { useRoleAuthorization } from '../../../hooks/useRoleAuthorization';
import { ADMIN_AUTHORIZED_ROLES } from '../../../auth/AuthRoles';
import Admin from './Admin/Admin';
import LoadDispatch from './LoadDispatch';

function Load({ loadId, startPage }: { loadId: number; startPage?: string }) {
  const [currentPage, setCurrentPage] = useState();
  const [loadDataObject] = useState<LoadDataObject>({
    Admin: null,
    Summary: null,
    Itinerary: null,
    Financials: null,
    'Load Dispatch': null,
    Notes: null,
    Files: null,
    Audit: null,
    Complete: null,
  });
  const [loadStatus, setLoadStatus] = useState();
  const DELIVERED_LOAD_STATUS = 'Delivered';

  useEffect(() => {
    GetLoad();
    startPage ? HandlePageChange(startPage) : HandlePageChange('Summary');
  }, []);

  function GetLoad() {
    Get(`/Load/GetLoad/${loadId}`).then(response => {
      if (response) {
        setLoadStatus(response.data.loadStatus);
      }
    });
  }

  function HandlePageChange(params) {
    if (!loadDataObject[params] && pages[params]?.callerPath) {
      if (Array.isArray(pages[params].callerPath)) {
        let dataObj = {};
        let attributeList = [];
        for (let i = 0; i < pages[params].callerPath.length; i++) {
          attributeList.push(i + 1);
          Get(pages[params].callerPath[i]).then(response => {
            if (response) {
              dataObj[i + 1] = response.data;
              if (HasAllAttributes(dataObj, attributeList)) {
                loadDataObject[params] = dataObj;
                AssignPages();
                setCurrentPage(params);
              }
            }
          });
        }
      } else {
        Get(pages[params].callerPath).then(response => {
          if (response) {
            loadDataObject[params] = response.data;
          }
          AssignPages();
          setCurrentPage(params);
        });
      }
    } else {
      setCurrentPage(params);
    }

    function HasAllAttributes(obj, attributeList) {
      for (const attribute of attributeList) {
        if (!obj.hasOwnProperty(attribute)) {
          return false;
        }
      }
      return true;
    }
  }

  function RefreshPage(currentPage: string, additionalPages: string[]) {
      additionalPages?.forEach(pageName => {
        loadDataObject[pageName] = null;
      });
      if (currentPage) {
        loadDataObject[currentPage] = null;
        setCurrentPage(null);
        HandlePageChange(currentPage);
      }
  }

  let pages = {};
  AssignPages();
  function AssignPages() {
    pages = {
      Summary: {
        content: (
          <Summary
            HandlePageChange={HandlePageChange}
            data={loadDataObject.Summary}
            loadId={loadId}
            RefreshPage={RefreshPage}
            GetLoad={GetLoad}
          />
        ),
        callerPath: `/Load/GetLoadSummary/${loadId}`,
      },
      Itinerary: {
        content: (
          <Itinerary
            data={loadDataObject.Itinerary}
            RefreshPage={RefreshPage}
            loadId={loadId}
            GetLoad={GetLoad}
          />
        ),
        callerPath: `/Load/GetLoadItinerary/${loadId}`,
      },
      Financials: {
        content: (
          <LoadFinancials
            data={loadDataObject.Financials}
            loadId={loadId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Load/GetLoadFinancials/${loadId}`,
      },
      'Load Dispatch': {
        content: (
          <LoadDispatch
            loadId={loadId}
            data={loadDataObject['Load Dispatch']}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Employee/GetDriverEmailsByLoadId/${loadId}`,
      },
      Files: {
        content: (
          <Files
            data={loadDataObject.Files}
            categoryId={loadId}
            fileCategory={'load'}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/File/GetAllFiles/load-${loadId}`,
      },
      Audit: {
        content: <Audit data={loadDataObject.Audit} />,
        callerPath: `/Load/GetLoadAudit/${loadId}`,
      },
    };
    if (loadStatus === DELIVERED_LOAD_STATUS)
      pages['Complete'] = {
        content: <Complete loadId={loadId} RefreshPage={RefreshPage} />,
      };
    if (useRoleAuthorization(ADMIN_AUTHORIZED_ROLES))
      pages['Admin'] = {
        content: (
          <Admin
            data={loadDataObject.Admin}
            loadId={loadId}
            RefreshPage={RefreshPage}
            GetLoad={GetLoad}
            loadStatus={loadStatus}
          />
        ),
        callerPath: `/Load/GetLoadAdmin/${loadId}`,
      };
  }

  return (
    <Slab
      title={
        <>
          Load #{loadId}
          <p>{loadStatus}</p>
        </>
      }
      pages={pages}
      currentPage={currentPage}
      HandlePageChange={HandlePageChange}
      dataObject={loadDataObject}
    />
  );
}
export default Load;
