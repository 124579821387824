import React, { useState } from 'react';
import PutBody from '../../../../api/internal/PutBody';
import classesError from '../../../../helpers/error/Error.module.css';
import Button from '../../../../assets/essentials/Button';

function CancelLoadSection({
  loadId,
  loadStatus,
  isButtonDisabled,
  setIsButtonDisabled,
  UncancelLoad,
  RefreshPage,
  GetLoad,
}: {
  loadId: number;
  loadStatus: string;
  isButtonDisabled: boolean;
  setIsButtonDisabled: Function;
  UncancelLoad: Function;
  RefreshPage: Function;
  GetLoad: Function;
}) {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const isLoadCancelled = loadStatus === 'Load Cancelled';

  function CancelLoad() {
    setIsButtonDisabled(true);
    const payload = {
      loadId: loadId,
    };
    PutBody(`/Load/UpdateLoadToCancelled`, payload)
      .then(() => {
        GetLoad();
        RefreshPage('Admin', ['Audit', 'Summary']);
      })
      .finally(() => {
        setIsButtonDisabled(false);
      });
  }

  const CancelLoadModal = () => (
    <div className={classesError.errorContainer}>
      <div
        className={classesError.overlay}
        onClick={() => setIsCancelModalOpen(false)}
      />
      <div className={classesError.errorHolder}>
        <div className={classesError.yesNoModal}>
          <h3>Are you sure you want to cancel the load?</h3>
          <div className={classesError.buttonHolder}>
            <Button
              disabled={isButtonDisabled}
              onClick={() => CancelLoad()}
              variant="good"
            >
              Yes
            </Button>
            <Button variant="bad" onClick={() => setIsCancelModalOpen(false)}>
              No
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
  const UncancelLoadModal = () => (
    <div className={classesError.errorContainer}>
      <div
        className={classesError.overlay}
        onClick={() => setIsCancelModalOpen(false)}
      />
      <div className={classesError.errorHolder}>
        <div className={classesError.yesNoModal}>
          <h3>
            Uncancelling a load will change the load status to "Needs
            Attention."
          </h3>
          <h3>Are you sure you want to uncancel the load?</h3>
          <div className={classesError.buttonHolder}>
            <Button
              disabled={isButtonDisabled}
              onClick={() => UncancelLoad()}
              variant="good"
            >
              Yes
            </Button>
            <Button variant="bad" onClick={() => setIsCancelModalOpen(false)}>
              No
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
  const CancelLoadSection = () => (
    <>
      <Button
        onClick={() => setIsCancelModalOpen(true)}
        variant={isLoadCancelled ? 'good' : 'bad'}
      >
        {isLoadCancelled ? 'Uncancel Load' : 'Cancel Load'}
      </Button>
    </>
  );
  return (
    <>
      <CancelLoadSection />
      {isCancelModalOpen &&
        (isLoadCancelled ? <UncancelLoadModal /> : <CancelLoadModal />)}
    </>
  );
}

export default CancelLoadSection;
