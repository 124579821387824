import React from 'react';
import BBITable from '../../../helpers/bBITable/BBITable';
import DateTimeUTCFormatter from '../../../helpers/inputs/DateTimeUTCFormatter';

function Audit({ data }: { data: Array<Audit> }) {
  const formattedAuditData = data?.map(audit => ({
    ...audit,
    timeStamp: DateTimeUTCFormatter(audit.timeStamp),
  }));

  let columnConfig = [
    {
      key: '1',
      attribute: 'timeStamp',
      header: 'Time Stamp',
      sortable: true,
    },
    {
      key: '2',
      attribute: 'changedBy',
      header: 'Changed By',
      sortable: true,
    },
    {
      key: '3',
      attribute: 'entry',
      header: 'Entry',
      sortable: true,
    },
    {
      key: '4',
      attribute: 'event',
      header: 'Event',
      sortable: true,
    },

    {
      key: '5',
      attribute: 'oldValue',
      header: 'Old Value',
      sortable: true,
    },
    {
      key: '6',
      attribute: 'newValue',
      header: 'New Value',
      sortable: true,
    },
  ];
  return (
    <BBITable
      data={formattedAuditData}
      columns={columnConfig}
      stickyTableHead={false}
    />
  );
}
export default Audit;
