import React from 'react';
import { BsTrash } from 'react-icons/bs';

import classes from '../../../assets/Global.module.css';
import PutForm from '../../../api/internal/PutForm';
import Select from '../../../assets/essentials/Select';
import Input from '../../../assets/essentials/Input';
import Button from '../../../assets/essentials/Button';
import TextArea from '../../../assets/essentials/TextArea';
import Form from '../../../assets/essentials/Form';
import StatesAndProvinces from '../../../helpers/options/StatesAndProvinces';
import Delete from '../../../api/internal/Delete';
import BBITable from '../../../helpers/bBITable/BBITable';
import Commodity from './Commodity';
import classesCustomer from './Customer.module.css';
import Phone from '../../../helpers/inputs/Phone';

export default function Summary({
  data,
  customerId,
  RefreshPage,
}: {
  data: CustomerSummary;
  customerId: Number;
  RefreshPage: Function;
}) {
  function UpdateCustomerDetails(e) {
    const formData = new FormData(e.target);
    e.preventDefault();
    PutForm(`/Customer/UpdateCustomerDetails`, formData, {
      customerId: customerId,
    }).then(() => RefreshPage('Summary', ['Audit']));
  }

  const formattedCustomerCommodityData = data?.customerCommodities?.map(
    customerCommodities => ({
      ...customerCommodities,
      delete: (
        <BsTrash
          className={classes.clickable}
          onClick={() => {
            DeleteCustomerCommodity(customerCommodities.customerCommodityId);
          }}
        />
      ),
    }),
  );

  let columnConfig = [
    {
      key: '1',
      attribute: 'commodityName',
      header: 'Commodity',
    },
    {
      key: '2',
      attribute: 'commodityGroup',
      header: 'Group',
    },
    {
      key: '3',
      attribute: 'note',
      header: 'Note',
    },
    {
      key: '4',
      attribute: 'delete',
      header: '',
    },
  ];

  function DeleteCustomerCommodity(customerCommodityId) {
    Delete(`/customer/DeleteCustomerCommodity/${customerCommodityId}`).then(
      () => RefreshPage('Summary', ['Audit']),
    );
  }

  return (
    <>
      <Form onSubmit={e => UpdateCustomerDetails(e)}>
        <div className={classes.attribute}>
          <label>Customer</label>
          <Input
            required
            type="text"
            defaultValue={data?.customerName}
            name="customerName"
          />
        </div>
        {/* <div className={`${classes.attribute} ${classes.span2}`}>
          <label>Status</label>
          <Input
            required
            type="text"
            defaultValue={data?.customerStatus}
            disabled
          />
        </div> */}
        <div className={classes.attribute}>
          <label>Phone</label>

          <Phone type="text" defaultValue={data?.phone} name="phone" />
        </div>
        <div className={classes.attribute}>
          <label>Ext</label>
          <Input type="text" defaultValue={data?.phoneExt} name="phoneExt" />
        </div>
        <div className={classes.attribute}>
          <label>
            Address Line One <span className={classes.required}>*</span>
          </label>
          <Input
            required
            type="text"
            key={data?.addressLineOne}
            defaultValue={data?.addressLineOne}
            name="addressLineOne"
          />
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>Address Line Two</label>
          <Input
            type="text"
            key={data?.addressLineTwo}
            defaultValue={data?.addressLineTwo}
            name="addressLineTwo"
          />
        </div>
        <div className={classes.attribute}>
          <label>
            City <span className={classes.required}>*</span>
          </label>
          <Input
            required
            type="text"
            key={data?.city}
            defaultValue={data?.city}
            name="city"
          />
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>State/Province</label>
          <Select key={data?.state} defaultValue={data?.state} name="state">
            <StatesAndProvinces />
          </Select>
        </div>
        <div className={classes.attribute}>
          <label>
            Zip <span className={classes.required}>*</span>
          </label>
          <Input
            required
            type="text"
            key={data?.zip}
            defaultValue={data?.zip}
            name="zip"
          />
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>Country</label>
          <Select
            required
            key={data?.country}
            defaultValue={data?.country}
            name="country"
          >
            <option key={0} value="US">
              US
            </option>
            <option key={1} value="CA">
              Canada
            </option>
          </Select>
        </div>
        <div className={classes.attribute}>
          <label>Website</label>
          <Input type="text" defaultValue={data?.website} name="website" />
        </div>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>Note</label>
          <TextArea type="text" defaultValue={data?.note} name="note" />
        </div>
        <div>
          <Button type="submit" variant="good" disabled={true}>
            Save Changes
          </Button>
        </div>
      </Form>
      <Commodity
        customerId={customerId}
        data={data}
        RefreshPage={RefreshPage}
      />
      <BBITable data={formattedCustomerCommodityData} columns={columnConfig} />
      <div className={classesCustomer.reports}>
        <h3>Reports</h3>
        <a
          href={`/reports/customerloadreport/${customerId}`}
          className={classes.cTA}
        >
          Load Report
        </a>
        <a
          href={`/reports/accountreceivablereport/${customerId}`}
          className={classes.cTA}
        >
          Account Receivable Report
        </a>
      </div>
    </>
  );
}
