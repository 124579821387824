import React from 'react';

import classes from '../../../assets/Global.module.css';
import Input from '../../../assets/essentials/Input';
import Select from '../../../assets/essentials/Select';
import GoogleSearchBoxEstablishmentSingleObject from '../../../helpers/inputs/GoogleSearchBoxEstablishment.js';
import Phone from '../../../helpers/inputs/Phone';
import { useRoleAuthorization } from '../../../hooks/useRoleAuthorization';
import { ADMIN_AUTHORIZED_ROLES } from '../../../auth/AuthRoles';
import StatesAndProvinces from '../../../helpers/options/StatesAndProvinces';

function Details({
  displayBilling,
  createCustomerParam,
  ToggleBilling,
  UpdateCustomerForAutoComplete,
  UpdateParam,
}: {
  createCustomerOptions: CreateCustomerOptions;
  displayBilling: Boolean;
  createCustomerParam: CreateCustomerParam;
  ToggleBilling: Function;
  UpdateCustomerForAutoComplete: Function;
  UpdateParam: Function;
}) {
  const isUserAdmin = useRoleAuthorization(ADMIN_AUTHORIZED_ROLES);
  const CallBackPlace = place => {
    let streetNumber: string;
    let streetName: string;
    let city: string;
    let state: string;
    let zip: string;
    let country: string;
    place.address_components.forEach(component => {
      component.types.forEach(type => {
        if (type === 'locality') {
          city = component.short_name;
        } else if (type === 'administrative_area_level_1') {
          state = component.short_name;
        } else if (type === 'postal_code') {
          zip = component.short_name;
        } else if (type === 'country') {
          country = component.short_name;
        } else if (type === 'street_number') {
          streetNumber = component.short_name;
        } else if (type === 'route') {
          streetName = component.short_name;
        }
      });
    });
    let addressLineOne = streetNumber + ' ' + streetName;
    UpdateCustomerForAutoComplete(
      place.name,
      addressLineOne,
      city,
      state,
      zip,
      country,
      place.formatted_phone_number,
      place.website,
    );
  };

  return (
    <>
      <h1>Details</h1>
      <div className={classes.viewGrid}>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>Auto-Complete</label>
          <GoogleSearchBoxEstablishmentSingleObject
            assignPlace={CallBackPlace}
            required={true}
            id="location"
          />
        </div>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>
            Name <span className={classes.required}>*</span>
          </label>
          <Input
            defaultValue={createCustomerParam.customerName}
            id={createCustomerParam.customerName}
            onChange={e => UpdateParam(e.target.value, 'customerName')}
            type="text"
            disabled={!isUserAdmin}
          />
        </div>
        <div className={classes.attribute}>
          <label>
            Address Line One <span className={classes.required}>*</span>
          </label>
          <Input
            defaultValue={createCustomerParam.addressLineOne}
            id={createCustomerParam.addressLineOne}
            onChange={e => UpdateParam(e.target.value, 'addressLineOne')}
            type="text"
            disabled={!isUserAdmin}
          />
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>Address Line Two</label>
          <Input
            defaultValue={createCustomerParam.addressLineTwo}
            id={createCustomerParam.addressLineTwo}
            onChange={e => UpdateParam(e.target.value, 'addressLineTwo')}
            type="text"
            disabled={!isUserAdmin}
          />
        </div>
        <div className={classes.attribute}>
          <label>
            City <span className={classes.required}>*</span>
          </label>
          <Input
            defaultValue={createCustomerParam.city}
            id={createCustomerParam.city}
            onChange={e => UpdateParam(e.target.value, 'city')}
            type="text"
            disabled={!isUserAdmin}
          />
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>
            State/Province <span className={classes.required}>*</span>
          </label>
          <Select
            defaultValue={createCustomerParam.state || ''}
            key={createCustomerParam.state}
            onChange={e => UpdateParam(e.target.value, 'state')}
            disabled={!isUserAdmin}
          >
            <option disabled value="">
              -- Required --
            </option>
            <StatesAndProvinces />
          </Select>
        </div>
        <div className={classes.attribute}>
          <label>
            Zip <span className={classes.required}>*</span>
          </label>
          <Input
            defaultValue={createCustomerParam.zip}
            id={createCustomerParam.zip}
            onChange={e => UpdateParam(e.target.value, 'zip')}
            type="text"
            disabled={!isUserAdmin}
          />
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>
            Country <span className={classes.required}>*</span>
          </label>
          <Select
            defaultValue={createCustomerParam.country || ''}
            key={createCustomerParam.country}
            onChange={e => UpdateParam(e.target.value, 'country')}
            disabled={!isUserAdmin}
          >
            <option disabled value="">
              -- Required --
            </option>
            <option value="US">US</option>
            <option value="CA">Canada</option>
          </Select>
        </div>
        <div className={classes.attribute}>
          <label>
            Phone <span className={classes.required}>*</span>
          </label>
          <Phone
            defaultValue={createCustomerParam.phone}
            id={createCustomerParam.phone}
            onChange={e => UpdateParam(e.target.value, 'phone')}
            name="phone"
            expectsEventObject={true}
          />
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>Ext</label>
          <Input
            defaultValue={createCustomerParam.phoneExt}
            id={createCustomerParam.phoneExt}
            onChange={e => UpdateParam(e.target.value, 'phoneExt')}
            type="text"
          />
        </div>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>
            <Input
              type="checkbox"
              defaultChecked={displayBilling}
              onChange={ToggleBilling}
            />
            Matches Billing Address
          </label>
        </div>
      </div>
    </>
  );
}

export default Details;
