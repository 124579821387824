import React, { useEffect, useState } from 'react';
import { MdOutlineRefresh } from 'react-icons/md';
import { FiTruck } from 'react-icons/fi';
import Header from '../../layouts/Header';
import Filter from '../../helpers/filter/Filter';
import View from '../../helpers/slab/View';
import ViewTrailer from '../../features/views/assets/trailer/Trailer';
import Create from '../../helpers/panel/Create';
import CreateTrailer from '../../features/creates/trailer/Trailer';
import FilterData from '../../helpers/filter/FilterData';
import BBITable from '../../helpers/bBITable/BBITable';
import Get from '../../api/internal/Get';

function Trailer({ toggleBoardRefresh }) {
  const [filterValue, setFilterValue] = useState('');
  const [trailerBoard, setTrailerBoard] = useState<Array<TrailerBoard>>([]);

  useEffect(() => {
    GetTrailerBoard();
  }, [toggleBoardRefresh]);

  function GetTrailerBoard() {
    Get(`/Asset/GetTrailerBoard`).then(response => {
      if (response) {
        setTrailerBoard(FormatTrailerBoard(response.data));
      }
    });
  }

  function FormatTrailerBoard(trailers) {
    return trailers.map(trailer => ({
      ...trailer,
      mileage: trailer.mileage?.toLocaleString() ?? null,
    }));
  }

  let columnConfig = [
    {
      key: '1',
      attribute: 'trailerName',
      attributeprimaryhidden: 'assetId',
      header: 'Trailer Unit #',
      onClick: e =>
        View(
          <ViewTrailer
            trailerName={e.target.innerText}
            assetId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '2',
      attribute: 'vin',
      header: 'VIN',
    },
    {
      key: '3',
      attribute: 'make',
      header: 'Make',
    },
    {
      key: '4',
      attribute: 'model',
      header: 'Model',
    },
    {
      key: '5',
      attribute: 'year',
      header: 'Year',
    },
    {
      key: '6',
      attribute: 'licensePlateNumber',
      header: 'Plate',
    },
    {
      key: '7',
      attribute: 'licensePlateState',
      header: 'Plate State',
    },
    {
      key: '8',
      attribute: 'ownership',
      header: 'Owned / Leased',
    },
    {
      key: '9',
      attribute: 'trailerType',
      header: 'Type',
    },
  ];

  let tableData = FilterData(trailerBoard, filterValue);

  return (
    <>
      <h1>Trailer</h1>
      <Header>
        <div>
          <button
            onClick={() =>
              Create(<CreateTrailer GetTrailerBoard={GetTrailerBoard} />)
            }
          >
            <FiTruck />
            <span>Create a Trailer</span>
          </button>
          <button onClick={GetTrailerBoard}>
            <MdOutlineRefresh />
            <span>Refresh Board</span>
          </button>
        </div>
        <div>
          <Filter
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            placeholder="Filter trailers"
          />
        </div>
      </Header>
      <BBITable data={tableData} columns={columnConfig} />
    </>
  );
}

export default Trailer;
