import { RxCross2 } from 'react-icons/rx';
import { useEffect } from 'react';

import classes from './Slab.module.css';
import Close from './Close';
import React, { useId } from 'react';

function Slab({ title, pages, currentPage, HandlePageChange, dataObject,  }) {
  const errorContainer = document.getElementById('error-container');
  const slabId = `slab-${useId()}`;
  
  useEffect(() => {
    const handleEsc = event => {
      let escapeKeyCode = 27;
      if (event.keyCode === escapeKeyCode && !errorContainer) {
        Close();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [errorContainer]);

  let navigation = [];
  for (let pageTitle in pages) {
    navigation.push(
      <button key={pageTitle} onClick={() => HandlePageChange(pageTitle)}>
        <span>
          {pageTitle === currentPage ? <u>{pageTitle}</u> : <>{pageTitle}</>}
        </span>
      </button>,
    );
  }

  const handleClose = () => Close(slabId);

  return (
    <div className={classes.slab} id={slabId}>
      <div className={classes.title}>
        <h1>{title}</h1>
        <RxCross2 title="Close Slab" onClick={handleClose} />
      </div>
      <div className={classes.navigation}>{navigation}</div>
      <div className={classes.content}>
        {((pages[currentPage]?.callerPath && dataObject[currentPage]) ||
          !pages[currentPage]?.callerPath) &&
          pages[currentPage]?.content}
      </div>
    </div>
  );
}

export default Slab;
