import React, { useState, useEffect, useRef } from 'react';
import BBITable from '../../../helpers/bBITable/BBITable';
import classesGlobal from '../../../assets/Global.module.css';
import classesCustom from '../../../helpers/search/CustomSearch.module.css';
import classesFinancialReport from './FinancialReport.module.css';
import Filter from '../../../helpers/filter/Filter';
import FilterData from '../../../helpers/filter/FilterData';
import Get from '../../../api/internal/Get';
import Header from '../../../layouts/Header';
import View from '../../../helpers/slab/View';
import { MdDownload } from 'react-icons/md';
import DateOnlyFormatter from '../../../helpers/inputs/DateOnlyFormatter';
import PostBody from '../../../api/internal/PostBody';
import Select from '../../../assets/essentials/Select';
import OptionList from '../../../helpers/options/OptionList';
import Input from '../../../assets/essentials/Input';
import Button from '../../../assets/essentials/Button';
import {
  GenerateQueryString,
  GetLastFullWeekMonday,
  ParseUrlForSearchParams,
} from '../../../../src/helpers/search/HelperFunctions';
import ViewRental from '../../../features/views/rental/Rental';
import { useOutletContext } from 'react-router-dom';

function RentalFinancialReport({ toggleBoardRefresh }) {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [RentalFinancialsBoard, setRentalFinancialsBoard] = useState<
    Array<RentalFinancialsBoard>
  >([]);

  const emptySearchParams = {
    rentalId: null,
    customerId: null,
    reference: null,
    dateMin: null, //must use name "dateMin" if we want to use defaultSearchDate
    dateMax: null,
  };
  const [
    rentalFinancialReportSearchParams,
    setRentalFinancialReportSearchParams,
  ] = useState(emptySearchParams);
  const [customerOptions, setCustomerOptions] = useState([]);
  const isFirstRender = useRef(true);
  const filterValue = useOutletContext();

  useEffect(() => {
    setIsLoading(true);
    if (customerOptions.length === 0) {
      GetCustomerOptions();
    }

    const { rentalId, customerId, reference, dateMin, dateMax } =
      ParseUrlForSearchParams(
        emptySearchParams,
        GetLastFullWeekMonday(),
        isFirstRender.current,
      );

    setRentalFinancialReportSearchParams({
      rentalId: rentalId,
      customerId: customerId,
      reference: reference,
      dateMin: dateMin,
      dateMax: dateMax,
    });
    SearchRentalFinancialReport({
      rentalId: rentalId,
      customerId: customerId,
      reference: reference,
      dateMin: dateMin,
      dateMax: dateMax,
    });
    isFirstRender.current = false;
  }, [toggleBoardRefresh]);

  function SearchRentalFinancialReport(SearchParamsFromForm?: Object) {
    const searchParams = SearchParamsFromForm
      ? SearchParamsFromForm
      : rentalFinancialReportSearchParams;
    const queryString = '?' + GenerateQueryString(searchParams);
    const newUrl =
      window.location.origin + window.location.pathname + queryString;
    window.history.replaceState(null, null, newUrl);
    Get(`/Accounting/SearchRentalFinancialReport${queryString}`).then(
      response => {
        if (response) {
          setRentalFinancialsBoard(response.data);
          setIsLoading(false);
        }
      },
    );
  }

  function GetCustomerOptions() {
    Get(`/Customer/GetCustomerLoadReportOptions/`).then(response => {
      if (response) {
        setCustomerOptions(response?.data);
      }
    });
  }

  function ClearSearchParams() {
    setRentalFinancialReportSearchParams(emptySearchParams);
  }

  function HandleFormSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    const formData = {
      rentalId: e.target.rentalId?.value,
      reference: e.target.reference?.value,
      customerId: e.target.customerId?.value,
      dateMin: e.target.dateMin?.value,
      dateMax: e.target.dateMax?.value,
    };
    setRentalFinancialReportSearchParams(formData);
    SearchRentalFinancialReport(formData);
  }

  function DownloadReportCSV(rentals: RentalFinancialsBoard[]) {
    const rentalIds = rentals?.map(x => x.rentalId);
    if (rentalIds) {
      setIsLoading(true);
      PostBody('/Accounting/DownloadRentalFinancialReportCSV', {
        rentalIds: [...rentalIds],
      }).then(response => {
        if (response) {
          const blob = new Blob([response.data], { type: 'text/csv' });
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download =
            new Date().toISOString().split('T')[0].replace(/[-]/g, '') +
            '_Rental Financials Report.csv';
          a.click();
          URL.revokeObjectURL(url);
          setIsLoading(false);
        }
      });
    }
  }

  const formattedRentalFinancialsBoard = RentalFinancialsBoard.map(
    rentalFinancial => ({
      ...rentalFinancial,
      startDate: DateOnlyFormatter(rentalFinancial.startDate),
      endDate: DateOnlyFormatter(rentalFinancial.endDate),
      totalInvoice: rentalFinancial.totalInvoice.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    }),
  );

  const columnConfig = [
    {
      key: '1',
      attribute: 'rentalId',
      header: 'Rental #',
      onClick: e => View(<ViewRental rentalId={e.target.innerText} />),
      dataType: 'number',
    },
    {
      key: '2',
      attribute: 'rentalStatus',
      header: 'Rental Status',
    },
    {
      key: '3',
      attribute: 'customerNames',
      header: 'Customer',
    },
    {
      key: '4',
      attribute: 'startDate',
      header: 'Start Date',
      dataType: 'date',
    },
    {
      key: '5',
      attribute: 'endDate',
      header: 'End Date',
      dataType: 'date',
    },
    {
      key: '6',
      attribute: 'reference',
      header: 'Reference',
    },
    {
      key: '7',
      attribute: 'invoiceDate',
      header: 'Invoice Date',
      dataType: 'date',
    },
    {
      key: '8',
      attribute: 'totalInvoice',
      header: 'Revenue',
      dataType: 'currency',
      includeInTotal: true,
    },
  ];

  let tableData = FilterData(formattedRentalFinancialsBoard, filterValue);

  const SearchForm = () => {
    return (
      <div className={classesCustom.customSearch}>
        <form onSubmit={e => HandleFormSubmit(e)}>
          <label>
            Rental #
            <Input
              type="text"
              name="rentalId"
              defaultValue={rentalFinancialReportSearchParams.rentalId}
            />
          </label>
          <label>
            Customer
            <Select
              defaultValue={rentalFinancialReportSearchParams.customerId || ''}
              name="customerId"
            >
              <option value="">All Customers</option>
              <OptionList
                optionData={customerOptions}
                attributeID="customerId"
                attributeName="customerName"
              />
            </Select>
          </label>
          <label>
            Reference #
            <Input
              type="text"
              name="reference"
              defaultValue={rentalFinancialReportSearchParams.reference}
            />
          </label>
          <label>
            End Date Min
            <Input
              type="date"
              name="dateMin"
              defaultValue={rentalFinancialReportSearchParams.dateMin}
            />
          </label>
          <label>
            End Date Max
            <Input
              type="date"
              name="dateMax"
              defaultValue={rentalFinancialReportSearchParams.dateMax}
            />
          </label>
          <div className={classesCustom.submitHolder}>
            <Button type="submit" variant="good">
              Search
            </Button>

            <Button
              onClick={() => ClearSearchParams()}
              type="button"
              variant="bad"
            >
              Clear
            </Button>
          </div>
        </form>
      </div>
    );
  };

  const Summary = () => {
    const rentalCount = tableData?.length;
    const totalRevenue =
      tableData?.reduce(
        (sum, row) => sum + Number(row.totalInvoice.replace(/[^\d.-]/g, '')),
        0,
      ) ?? 0;

    return (
      <div className={classesFinancialReport.summaryRow}>
        <div>
          <span className={classesFinancialReport.summaryLabel}>
            Rental Count:
          </span>
          {rentalCount}
        </div>
        <div>
          <span className={classesFinancialReport.summaryLabel}>
            Total Revenue:
          </span>
          {totalRevenue.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <Header>
        <button
          onClick={() => {
            DownloadReportCSV(tableData);
          }}
          className={classesFinancialReport.downloadButton}
        >
          <MdDownload />
          <span>Download</span>
        </button>
      </Header>
      <SearchForm />
      <Summary />
      {isLoading ? (
        <h3>Generating Report...</h3>
      ) : (
        <BBITable data={tableData} columns={columnConfig} />
      )}
    </>
  );
}

export default RentalFinancialReport;
