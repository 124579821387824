import React from 'react';
import classes from '../../../assets/Global.module.css';
import classesEmail from './BulkEmailPDFs.module.css';
import BBITable from '../../../helpers/bBITable/BBITable';
import View from '../../../helpers/slab/View';
import PayrollPaymentDetail from '../../views/payroll/AppliedPayments/PayrollAppliedPayments';
import ViewDriver from '../../views/users/driver/Driver';
import DateOnlyFormatter from '../../../helpers/inputs/DateOnlyFormatter';

const EmailPayrollConfirmation = ({ email, selectedPayrolls }) => {
  let columnConfig = [
    {
      key: '1',
      attribute: 'cashFlowId',
      header: 'Payment ID #',
      onClick: e =>
        View(<PayrollPaymentDetail cashFlowId={e.target.innerText} />),
    },
    {
      key: '2',
      attribute: 'driverName',
      header: 'Driver',
      attributeprimaryhidden: 'employeeId',
      onClick: e =>
        View(
          <ViewDriver
            driverName={e.target.innerText}
            employeeId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '3',
      attribute: 'paymentDate',
      header: 'Date',
    },
    {
      key: '4',
      attribute: 'emailAddress',
      header: 'Email',
    },
    {
      key: '5',
      attribute: 'amount',
      header: 'Amount',
    },
  ];

  const tableData = selectedPayrolls.map(payment => ({
    ...payment,
    paymentDate: DateOnlyFormatter(payment.paymentDate),
    amount: payment.amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }),
  }));

  return (
    <>
      <h1>Review and Confirm</h1>
      <div className={`${classesEmail.emailConfirm}`}>
        <input disabled defaultValue={email.subject} />
        <textarea disabled defaultValue={email.body} rows={8} />
      </div>
      <div>
        {selectedPayrolls.length > 0 ? (
          <BBITable data={tableData} columns={columnConfig} />
        ) : (
          <p>No payrolls selected.</p>
        )}
      </div>
    </>
  );
};

export default EmailPayrollConfirmation;
