import React from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { RxCross2 } from 'react-icons/rx';

function Finish({ pages, ChangePageTo }) {
  let constraints = [];

  pages.forEach((page, index) => {
    let pageRequirements = [];
    page.require.forEach((requirement, requireIndex) =>
      pageRequirements.push(
        <li key={requireIndex}>
          {requirement.FunctionBool() ? (
            <>
              <AiOutlineCheck
                style={{ color: 'var(--colorGood)' }}
                title="Good to Go"
              />
            </>
          ) : (
            <>
              <RxCross2
                style={{ color: 'var(--colorBad)' }}
                title="Missing Inputs"
              />
            </>
          )}
          <b>{requirement.label}</b>
          {requirement.FunctionBool() ? (
            <>: {requirement.assignedValue()} </>
          ) : (
            <>
              : {requirement.missingDesc}{' '}
              <u
                onClick={() => ChangePageTo(index)}
                style={{ color: 'var(--colorBad)', cursor: 'pointer' }}
              >
                Edit
              </u>
            </>
          )}
        </li>,
      ),
    );
    if (pageRequirements.length) {
      constraints.push(
        <React.Fragment key={index}>
          <h3>{page.title}</h3>
          <ul>{pageRequirements}</ul>
        </React.Fragment>,
      );
    }
  });

  return (
    <>
      <h1>Review and finish</h1>
      {constraints}
    </>
  );
}

export default Finish;
