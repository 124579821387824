import React, { useState } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { RxCross2 } from 'react-icons/rx';
import { IoIosAddCircleOutline, IoMdRemoveCircleOutline } from 'react-icons/io';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import classes from '../../../../assets/Accordion.module.css';
import classesGlobal from '../../../../assets/Global.module.css';
import classesLoad from '../../../creates/notes/Load.module.css';
import View from '../../../../helpers/slab/View';
import ViewLocation from '../../location/Location';
import PutForm from '../../../../api/internal/PutForm';
import TimeFormatter from '../../../../helpers/inputs/TimeOnlyFormatter';
import DateTimeFormatter from '../../../../helpers/inputs/DateTimeFormatter';
import Delete from '../../../../api/internal/Delete';
import DeleteButton from '../../../../helpers/buttons/DeleteButton';
import Button from '../../../../assets/essentials/Button';
import Input from '../../../../assets/essentials/Input';
import Form from '../../../../assets/essentials/Form';
import StopInfo from './StopInfo';
import PutBody from '../../../../api/internal/PutBody';
import LocationForm from '../../../creates/location/LocationForm';
import Select from '../../../../assets/essentials/Select';
import OptionList from '../../../../helpers/options/OptionList';

function Stop({
  stop,
  timeZoneOptions,
  customerAssignmentOptions,
  stopTypeOptions,
  index,
  RefreshPage,
  GetLoad,
  numberOfStops,
  HandleStopOrderChange,
}: {
  stop: Stop;
  timeZoneOptions: Array<TimeZoneOption>;
  customerAssignmentOptions: Array<CustomerAssignmentOption> | null;
  stopTypeOptions: Array<StopTypeOption>;
  index: number;
  RefreshPage: Function;
  GetLoad: Function;
  numberOfStops: number;
  HandleStopOrderChange: Function;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [editLocation, setEditLocation] = useState<boolean>(false);
  const [showCreateLocation] = useState<boolean>(false);
  const [isAppointmentRange, setIsAppointmentRange] = useState(
    stop ? stop.appointmentTimeUpperRange != null : false,
  );

  // stop view model combines city/state; need destructured for city and state inputs
  const locationCityState = stop?.locationCityState;
  const [city, state] = locationCityState.split(',').map(item => item.trim());
  const [locationInfo, setLocationInfo] = useState({
    locationName: stop.locationName,
    locationId: stop.locationId,
    addressLineOne: stop.locationAddressLineOne,
    addressLineTwo: stop.locationAddressLineTwo,
    phone: stop.locationPhone,
    city: city,
    state: state,
    timeZone: stop.timeZone,
    zip: stop.locationZip,
    country: stop.locationCountry,
    specialInstructions: stop.locationSpecialInstructions,
  });

  function UpdateItinerary(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const appendix = {
      stopId: stop.stopId,
    };
    if (locationInfo) {
      Object.keys(locationInfo).forEach(param => {
        const value = locationInfo[param];
        if (value != null) {
          // not appending null values
          formData.append(param, value);
        }
      });
    }

    PutForm('/Load/UpdateStop', formData, appendix)
      .then(() => {
        RefreshPage('Itinerary', ['Audit', 'Summary', 'Financials']);
        GetLoad();
      })
      .catch(error => {
        if (error) {
          setLocationInfo(null);
        }
      });
  }

  function QuickCompleteStop() {
    const currentDateTime = new Date();
    const formattedDateTime = new Date(
      currentDateTime.getTime() - currentDateTime.getTimezoneOffset() * 60000,
    );
    formattedDateTime.setSeconds(0);
    formattedDateTime.setMilliseconds(0);
    const payload = {
      stopId: stop.stopId,
      stopCompleteTime: formattedDateTime,
    };
    PutBody(`/Load/QuickCompleteStop`, payload).then(() => {
      RefreshPage('Itinerary', ['Audit', 'Summary', 'Financials']);
      GetLoad();
    });
  }

  function DeleteLoadStop() {
    Delete(`/Load/DeleteStop/${stop.stopId}`).then(() => {
      RefreshPage('Itinerary', ['Audit', 'Summary']);
      GetLoad();
    });
    setIsOpen(false);
  }

  function formatDate(appointmentDate) {
    const date = new Date(appointmentDate).toISOString().split('T')[0];
    const dateParts = date.split('-');
    const year = dateParts[0];
    const month = dateParts[1].startsWith('0') ? dateParts[1][1] : dateParts[1];
    const day = dateParts[2].startsWith('0') ? dateParts[2][1] : dateParts[2];

    return `${month}/${day}/${year}`;
  }

  return (
    <>
      <div className={classes.accordionSummary}>
        <div className={classesGlobal.viewGrid}>
          <div
            className={classesGlobal.attribute}
            onClick={() => setIsOpen(!isOpen)}
          >
            <h4>
              {index + 1}. {stop.stopType}
              {stop.isLoaded ? <AiOutlineCheck /> : <RxCross2 />}
            </h4>
            <span>
              {stop?.appointmentDate != null
                ? formatDate(stop.appointmentDate)
                : 'PENDING APPOINTMENT'}
            </span>
            <span>
              {stop.appointmentTime && TimeFormatter(stop.appointmentTime)}
              {isAppointmentRange && stop.appointmentTimeUpperRange && (
                <> - {TimeFormatter(stop.appointmentTimeUpperRange)}</>
              )}
            </span>
            <span>{stop.miles} mi</span>
          </div>
          <div className={classesGlobal.attribute}>
            <h4 onClick={() => setIsOpen(!isOpen)}>{stop.locationName}</h4>
            <span onClick={() => setIsOpen(!isOpen)}>
              {stop.locationAddressLineOne}
            </span>
            <span onClick={() => setIsOpen(!isOpen)}>
              {stop.locationAddressLineTwo}
            </span>
            <span onClick={() => setIsOpen(!isOpen)}>
              {stop.locationCityState}
            </span>
            <div
              className={classesGlobal.cTA}
              onClick={() =>
                View(
                  <ViewLocation
                    locationId={stop.locationId}
                    locationName={stop.locationName}
                  />,
                )
              }
            >
              Manage Location Info
            </div>
          </div>
          <div
            className={`${classesGlobal.attribute} ${classesGlobal.splitRow}`}
          >
            <div>
              {stop.stopCompleteTime ? (
                <>
                  <h4>Completed</h4>
                  <span>{DateTimeFormatter(stop.stopCompleteTime)}</span>
                </>
              ) : (
                <>
                  <h4>
                    {stop.stopType === 'Pickup'
                      ? 'Needs Pickup'
                      : 'Needs Dropoff'}
                  </h4>
                  <div>
                    <Button
                      variant="good"
                      disabled={false}
                      onClick={QuickCompleteStop}
                    >
                      Mark Completed
                    </Button>
                  </div>
                </>
              )}
            </div>
            <div className={`${classesGlobal.flexColumn}`}>
              <button
                value="up"
                className={classesLoad.stopOrderArrowButton}
                disabled={index === 0}
                onClick={event => HandleStopOrderChange(event, stop.stopId)}
              >
                <IoIosArrowUp
                  className={
                    index === 0 ? classesLoad.disabled : classesLoad.enabled
                  }
                />
              </button>

              <button
                value="down"
                className={classesLoad.stopOrderArrowButton}
                disabled={index === numberOfStops - 1}
                onClick={event => HandleStopOrderChange(event, stop.stopId)}
              >
                <IoIosArrowDown
                  className={
                    index === numberOfStops - 1
                      ? classesLoad.disabled
                      : classesLoad.enabled
                  }
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={`${classes.expandable} ${classes.stop}`}>
        {isOpen && (
          <>
            <div className={classesGlobal.viewGrid}>
              <div className={classesGlobal.attribute}>
                <label>Phone</label>
                <Input type="text" readOnly={true} value={stop.locationPhone} />
              </div>
              <div
                className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
              >
                <label>Ext</label>
                <Input type="text" readOnly={true} value={stop.phoneExt} />
              </div>
            </div>
            <Form onSubmit={e => UpdateItinerary(e)} autoComplete="off">
              <strong>Appointment Information</strong>
              <div
                className={`${classesGlobal.viewGrid} ${classesGlobal.span3}`}
              >
                <div
                  className={`${classesGlobal.attribute} ${classesGlobal.span}`}
                >
                  <label>Date</label>
                  <Input
                    type="date"
                    defaultValue={stop?.appointmentDate}
                    name="appointmentDate"
                  />
                </div>
                <div
                  className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
                >
                  <label htmlFor='stopTypeId'>Type</label>
                  <Select defaultValue={stop?.stopTypeId} name='stopTypeId'>
                    <OptionList
                      optionData={stopTypeOptions}
                      attributeID="stopTypeId"
                      attributeName="type"
                    />
                  </Select>
                </div>
                <div className={`${classesGlobal.attribute}`}>
                  <label>
                    Time
                    {!isAppointmentRange ? (
                      <IoIosAddCircleOutline
                        onClick={() => setIsAppointmentRange(true)}
                        title="Add Appointment Window"
                      />
                    ) : (
                      <>
                        {' '}
                        Lower
                        <IoMdRemoveCircleOutline
                          onClick={() => setIsAppointmentRange(false)}
                          title="Remove Appointment Window"
                        />
                      </>
                    )}
                  </label>
                  <Input
                    type="time"
                    defaultValue={stop?.appointmentTime}
                    name="appointmentTime"
                    required={isAppointmentRange}
                  />
                </div>
                {isAppointmentRange && (
                  <div className={classesGlobal.attribute}>
                    <label>Time Upper</label>
                    <Input
                      type="time"
                      defaultValue={stop?.appointmentTimeUpperRange}
                      name="appointmentTimeUpperRange"
                      required={isAppointmentRange}
                    />
                  </div>
                )}
                <div className={classesGlobal.attribute}>
                  <label>Time Zone</label>
                  <Select defaultValue={stop?.timeZone} name="ianaTimeZone">
                    <OptionList
                      optionData={timeZoneOptions}
                      attributeID="iana"
                      attributeName="abbreviation"
                    />
                  </Select>
                </div>
              </div>
              <>
                <LocationForm
                  locationInfo={locationInfo}
                  setLocationInfo={setLocationInfo}
                />
              </>
              <>
                <StopInfo
                  stop={stop}
                  customerAssignmentOptions={customerAssignmentOptions}
                />
              </>
              {!showCreateLocation && (
                <>
                  <div>
                    <Button type="submit" variant="good">
                      Save Changes
                    </Button>
                  </div>
                  {editLocation && (
                    <div>
                      <Button
                        disabled={false}
                        onClick={() => setEditLocation(!editLocation)}
                      >
                        Back
                      </Button>
                    </div>
                  )}
                </>
              )}

              <DeleteButton
                OnDelete={DeleteLoadStop}
                buttonLabel={'Delete Stop'}
              />
            </Form>
          </>
        )}
      </div>
    </>
  );
}

export default Stop;
