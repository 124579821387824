import { useState, useEffect } from 'react';
import Input from '../../assets/essentials/Input';

/**
 * Phone component for formatting phone numbers.
 *
 *
 * @param {*} defaultValue - `string` The default value for the phone number input
 * @param {*} name - `string` The name of the phone number input field
 * @param {*} [onChange] - `function, nullable` The callback function needed for when the input changes as a user types
 * @param {*} [expectsEventObject=false] - `boolean` Whether or not the onChange callback function expects an event object. Defaults to falsey but explicitly stating false for clarity. If you need onChange in your component because you're not using Form, pass your typical on change and make this value true
 * @param {*} [required=false] - `boolean` Determines whether the input is required on submit. False by default.
 * @returns {*} `JSX.Element` The input field with a formatted number as a user types
 *
 * @example
 * <Phone
 *  name="phone"
 *  defaultValue={contactData.phone ? contactData.phone : null}
 *  onChange={null}
 *  expectsEventObject={false}
 * />
 * <Phone
 *  name="phone"
 *  defaultValue={contactData.phone ? contactData.phone : null}
 *  onChange={e => UpdateParams(e.target.value, 'phone')}
 *  expectsEventObject={true}
 * />
 */
function Phone({ defaultValue, name, onChange, expectsEventObject = false, required = false }) {
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState(
    defaultValue || null,
  );

  useEffect(() => {
    if (defaultValue) {
      formatPhoneNumber(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (onChange) {
      expectsEventObject
        ? onChange({ target: { value: formattedPhoneNumber, name: name } })
        : onChange(formattedPhoneNumber);
    }
  }, [formattedPhoneNumber]);

  const formatPhoneNumber = phoneNumber => {
    let rawNumber = phoneNumber.replace(/[^0-9]/g, '');
    let formattedNumber = '';

    if (rawNumber.length > 0 && rawNumber.charAt(0) === '1') {
      formattedNumber = '+1 ';
      rawNumber = rawNumber.substring(1);
    }

    if (rawNumber.length > 6) {
      formattedNumber += `(${rawNumber.substring(0, 3)}) ${rawNumber.substring(
        3,
        6,
      )}-${rawNumber.substring(6, 10)}`;
    } else if (rawNumber.length > 3) {
      formattedNumber += `(${rawNumber.substring(0, 3)}) ${rawNumber.substring(
        3,
      )}`;
    } else {
      formattedNumber += rawNumber;
    }

    setFormattedPhoneNumber(formattedNumber);
  };

  return (
    <Input
      value={formattedPhoneNumber}
      onChange={e => formatPhoneNumber(e.target.value)}
      type="text"
      name={name}
      required={required}
    />
  );
}

export default Phone;
