import BBITable from '../../../helpers/bBITable/BBITable';
import classes from '../../../assets/Global.module.css';
import DateOnlyFormatter from '../../../helpers/inputs/DateOnlyFormatter';
import View from '../../../helpers/slab/View';
import ViewLoad from '../../views/load/Load';
import ViewCustomer from '../../views/customer/Customer';

function Summary({ data }) {
  const formattedJournalEntries = data?.journalEntries.map(journalEntry => ({
    ...journalEntry,
    isApproved: journalEntry.isApproved ? 'X' : null,
    isImportedIntoQuickBooks: journalEntry.isImportedIntoQuickBooks
      ? 'X'
      : null,
    amount: journalEntry.amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }),
  }));

  let journalEntryColumnConfig = [
    {
      key: '1',
      attribute: 'journalEntryId',
      header: 'Journal Entry #',

      width: '12ch',
    },
    {
      key: '2',
      attribute: 'isApproved',
      header: 'Approved',

      width: '12ch',
    },
    {
      key: '3',
      attribute: 'isImportedIntoQuickBooks',
      header: 'Uploaded To QuickBooks',

      width: '12ch',
    },
    {
      key: '4',
      attribute: 'accountName',
      header: 'Account Name',

      width: '12ch',
    },
    {
      key: '5',
      attribute: 'amount',
      header: 'Amount',

      width: '6ch',
    },
  ];

  return (
    <div className={classes.viewGrid}>
      <div className={classes.attribute}>
        <h3>Load</h3>
        <span>{data?.loadId}</span>
        <div
          className={classes.cTA}
          onClick={() => View(<ViewLoad loadID={data.loadId} />)}
        >
          View Load Info
        </div>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <h3>{data?.assignmentType}</h3>
        <span>{data?.assignmentName}</span>
      </div>
      <div className={classes.attribute}>
        <h3>Date</h3>
        <span>{DateOnlyFormatter(data?.dueDate)}</span>
      </div>
      <div className={classes.attribute}>
        <h3>Amount</h3>
        <span>
          {data?.amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </span>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <h3>Journal Entries</h3>
        <BBITable
          data={formattedJournalEntries ?? []}
          columns={journalEntryColumnConfig}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <h3>Note</h3>
        <span>{data?.Note}</span>
      </div>
    </div>
  );
}
export default Summary;
