import React, { useState } from 'react';
import { MdOutlineCleaningServices } from 'react-icons/md';

import classes from '../Assign.module.css';
import classesGlobal from '../../../assets/Global.module.css';
import Button from '../../../assets/essentials/Button';
import Select from '../../../assets/essentials/Select';
import OptionList from '../../../helpers/options/OptionList';
import PostForm from '../../../api/internal/PostForm';
import TextArea from '../../../assets/essentials/TextArea';
import Form from '../../../assets/essentials/Form';

function Vendor({
  vendorId,
  data,
  RefreshPage,
}: {
  vendorId: Number;
  data: VendorServices;
  RefreshPage: Function;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function AssignService(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    PostForm(`/Vendor/CreateVendorService`, formData, {
      vendorId: vendorId,
    }).then(() => RefreshPage('Services', ['Audit']));
    setIsOpen(false);
  }

  return (
    <>
      <div className={classes.btnHolder}>
        <button type="button" onClick={() => setIsOpen(!isOpen)}>
          <MdOutlineCleaningServices />
          <span>Assign Service</span>
        </button>
      </div>
      <div className={`${classes.expandable} ${classes.service}`}>
        {isOpen && (
          <Form onSubmit={e => AssignService(e)}>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span3}`}
            >
              <label>
                Service <span className={classesGlobal.required}>*</span>
              </label>
              <Select required name="vendorServiceTypeId">
                <option disabled selected value="">
                  -- Required --
                </option>
                <OptionList
                  optionData={data.vendorServiceTypeOptions}
                  attributeID="vendorServiceTypeId"
                  attributeName="service"
                ></OptionList>
              </Select>
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span3}`}
            >
              <label>Note</label>
              <TextArea type="text" name="note" />
            </div>

            <div>
              <Button type="submit" variant="good" disabled={true}>
                Submit
              </Button>
            </div>
            <div>
              <Button onClick={() => setIsOpen(!isOpen)}>Cancel</Button>
            </div>
          </Form>
        )}
      </div>
    </>
  );
}

export default Vendor;
