import { AiOutlineCheck } from 'react-icons/ai';
import { RxCross2 } from 'react-icons/rx';
import React, { useEffect, useState } from 'react';

import classes from './Panel.module.css';
import Finish from './Finish';
import Button from '../../assets/essentials/Button';
import Close from './Close';

// set isCreate to false for BulkEmailPDFs component ONLY.
function Panel({ OnSubmit, title, pages, isCreate = true }) {
  const [currentPage, setCurrentPage] = useState(0);
  const panelId = Date.now();
  const errorContainer = document.getElementById('error-container');

  useEffect(() => {
    const handleEsc = event => {
      let escapeKeyCode = 27;
      if (event.keyCode === escapeKeyCode && !errorContainer) {
        Close();
      }
    };

    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [errorContainer]);

  let navigation = [];
  let finalPage = [
    {
      title: 'Finish',
      displayedIcon: '',
      content: <Finish pages={pages} ChangePageTo={ChangePageTo} />,
    },
  ];
  
  let panelPages = pages;

  // finalPage should only be added to Create panels (not BulkEmailPDFs)
  if (isCreate) {
    panelPages = pages.concat(finalPage);
  };

  panelPages.forEach((page, index) => {
    navigation.push(
      <tr
        key={index}
        style={{ cursor: 'pointer' }}
        onClick={() => ChangePageTo(index)}
      >
        <td>
          {index === currentPage ? <b>{index + 1}.</b> : <>{index + 1}.</>}
        </td>
        <td>
          {index === currentPage ? <b>{page.title}</b> : <>{page.title}</>}
        </td>
        <td>{page.displayedIcon}</td>
      </tr>,
    );
  });

  function ChangePageTo(destinationPage) {
    CheckRequirement(currentPage);
    setCurrentPage(destinationPage);
  }

  function CheckRequirement(pageIndex) {
    if (FulFillsPageRequirements(pageIndex)) {
      panelPages[pageIndex].displayedIcon = (
        <AiOutlineCheck
          style={{ color: 'var(--colorGood)' }}
          title="Good to Go"
        />
      );
    } else {
      panelPages[pageIndex].displayedIcon = (
        <RxCross2 style={{ color: 'var(--colorBad)' }} title="Missing Inputs" />
      );
    }
  }

  function FulFillsPageRequirements(pageIndex) {
    let fulfillsRequirements = true;
    panelPages[pageIndex].require?.forEach(requirement => {
      if (!requirement.FunctionBool()) {
        fulfillsRequirements = false;
      }
    });
    return fulfillsRequirements;
  }

  function HandleSubmission() {
    let submittedPanel = document.getElementById(panelId);
    const submitButtonList = submittedPanel.querySelectorAll(
      'button[type="submit"]',
    );
    for (const submitButton of submitButtonList) {
      submitButton.setAttribute('disabled', 'true');
    }
    OnSubmit();
  }

  return (
    <div className={classes.panel} id={panelId}>
      <div className={classes.title}>
        <h1>{title}</h1>
      </div>
      <div className={classes.navigation}>
        <table>
          <tbody>{navigation}</tbody>
        </table>
      </div>
      <div className={classes.content}>{panelPages[currentPage].content}</div>
      <div className={classes.footerSpacer} />
      <div className={classes.footer}>
        <div className={classes.navBtns}>
          {currentPage > 0 && (
            <Button onClick={() => ChangePageTo(currentPage - 1)}>Back</Button>
          )}
          {currentPage === panelPages.length - 1 && (
            <Button onClick={HandleSubmission} type="submit" variant="good">
              Submit
            </Button>
          )}
          {currentPage !== panelPages.length - 1 && (
            <Button onClick={() => ChangePageTo(currentPage + 1)} type="button">
              Next
            </Button>
          )}
        </div>
        <Button onClick={Close}>Cancel</Button>
      </div>
    </div>
  );
}

export default Panel;
