import React, { useEffect, useRef, useState } from 'react';
import Get from '../../../api/internal/Get';
import Select from '../../../assets/essentials/Select';
import OptionList from '../../../helpers/options/OptionList';
import DateOnlyFormatter from '../../../helpers/inputs/DateOnlyFormatter';
import Header from '../../../layouts/Header';
import FilterData from '../../../helpers/filter/FilterData';
import Input from '../../../assets/essentials/Input';
import TextArea from '../../../assets/essentials/TextArea';
import Button from '../../../assets/essentials/Button';
import PostBody from '../../../api/internal/PostBody';
import classes from '../../../helpers/bBITable/BBITable.module.css';
import classesApplyPayroll from './ApplyPayrollPayments.module.css';
import classesGlobal from '../../../assets/Global.module.css';
import ViewLoad from '../../../features/views/load/Load';
import ViewPayroll from '../../../features/views/payroll/Payroll';
import View from '../../../helpers/slab/View';
import ViewCustomer from '../../../features/views/customer/Customer';
import ViewLocation from '../../../features/views/location/Location';
import { RxCheck } from 'react-icons/rx';
import { useOutletContext } from 'react-router-dom';

type ApplyPayrollStatus = 'success' | 'pending' | 'idle';

export default function PayrollApplyPayments({
  toggleBoardRefresh,
}: {
  toggleBoardRefresh: boolean;
}) {
  const [assetAssignments, setAssetAssignments] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [paymentDate, setPaymentDate] = useState(GetCurrentDate());
  const [appliedPayrollStatus, setAppliedPayrollStatus] =
  useState<ApplyPayrollStatus>('idle');
  const driverSearchDateInput = useRef(GetMostRecentFriday());
  const inputValues = useRef({
    driverId: '',
    date: '',
    note: '',
  });
  const filterValue = useOutletContext();

  useEffect(() => {
    GetDriverOptionsForPayrollApplyPayments(GetMostRecentFriday());
  }, [toggleBoardRefresh]);

  const HandleDateChange = e => {
    driverSearchDateInput.current = e.target.value;
    GetDriverOptionsForPayrollApplyPayments(e.target.value);
  };

  function GetAssetAssignmentsFromForm(e) {
    e.preventDefault();
    GetAssetAssignments(e.target.driver.value, e.target.completedDate.value);
  }

  function GetAssetAssignments(driverId: string, date: string) {
    Get(
      `/Accounting/GetAssetAssignmentsForPayrollApplyPayments/${driverId}/${date}`,
    ).then(response => {
      if (response) {
        setAssetAssignments(response.data);
        inputValues.current.driverId = driverId;
        inputValues.current.date = date;
      }
    });
  }

  function GetDriverOptionsForPayrollApplyPayments(beforeDate: string) {
    if (beforeDate)
      Get(
        `/Accounting/GetDriverOptionsForPayrollApplyPayments/${beforeDate}`,
      ).then(response => {
        if (response) {
          setDriverList(response.data);
        }
      });
  }

  function GetMostRecentFriday() {
    const currentDate = new Date();
    const day = currentDate.getDay();
    if (day === 5) {
      return currentDate.toISOString().split('T')[0];
    }
    const diff = day <= 5 ? 7 - 5 + day : day - 5;
    currentDate.setDate(currentDate.getDate() - diff);
    return currentDate.toISOString().split('T')[0];
  }

  function GetCurrentDate() {
    return new Date().toISOString().split('T')[0];
  }

  const tableData = FilterData(assetAssignments, filterValue);

  const total = tableData
    .reduce((sum, row) => sum + Number(row.applyToBalance ?? 0), 0)
    .toFixed(2);

  const SearchAssignmentsForm = () => (
    <form
      onSubmit={e => GetAssetAssignmentsFromForm(e)}
      className={classesApplyPayroll.applyPaymentPayrollSearch}
    >
      <label htmlFor="driverId">
        <h3>Driver</h3>
      </label>
      <Select
        required
        name="driver"
        defaultValue={inputValues.current.driverId}
      >
        <option value="" hidden>
          {driverList.length > 0
            ? 'Select Driver'
            : '-- No Drivers For Selected Date --'}
        </option>
        <OptionList
          optionData={driverList}
          attributeID="driverId"
          attributeName="name"
          attributeGroup=""
        />
      </Select>
      <label htmlFor="completedDate">
        <h3>Date</h3>
      </label>
      <Input
        required
        name="completedDate"
        id="completedDate"
        type="date"
        value={driverSearchDateInput.current}
        onChange={HandleDateChange}
      />
      <Button variant="good" type="submit">
        Search
      </Button>
    </form>
  );

  const ApplyPaymentToPayrollForm = () => {
    const HandleSubmitApplyPayments = e => {
      e.preventDefault();
      setAppliedPayrollStatus('pending');
      const selectedAssetAssignments = tableData
        .filter(x => x.applyToBalance && x.isSelected === true)
        .map(x => ({
          assetAssignmentId: x.assetAssignmentId,
          applyToBalanceAmount: x.applyToBalance.toFixed(2),
        }));
      const applyPaymentParam = {
        driverId: inputValues.current.driverId,
        note: inputValues.current.note,
        paymentDate: paymentDate,
        assetAssignments: selectedAssetAssignments,
      };
      PostBody(
        'Accounting/ApplyPaymentAssetAssignment',
        applyPaymentParam,
      ).then(response => {
        if (response) {
          GetAssetAssignments(
            inputValues.current.driverId,
            inputValues.current.date,
          );
          HandleAppliedPaymentSuccessfully();
        }
      });
    };

    const HandleAppliedPaymentSuccessfully = () => {
      inputValues.current.note = '';
      setAppliedPayrollStatus('success');
      setPaymentDate(GetCurrentDate());
      GetDriverOptionsForPayrollApplyPayments(driverSearchDateInput.current);
    };

    return (
      <form
        className={classesApplyPayroll.applyPayrollsSection}
        onSubmit={e => HandleSubmitApplyPayments(e)}
      >
        <div className={classesGlobal.attribute}>
          <label htmlFor="paymentDate">Payroll Ending</label>
          <Input
            type="date"
            id="paymentDate"
            max={GetCurrentDate()}
            defaultValue={paymentDate}
            required
            onChange={e => {
              setPaymentDate(e.target.value);
            }}
          />
          <label htmlFor="balance">Payment Amount</label>
          <Input
            id="paymentAmount"
            readOnly
            value={total.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          />
        </div>
        <div className={`${classesGlobal.attribute} ${classesGlobal.span2}`}>
          <label htmlFor="note">Note</label>
          <TextArea
            id="note"
            name="note"
            defaultValue={inputValues.current.note}
            onChange={e => {
              inputValues.current.note = e.target.value;
            }}
          />
          <Button
            variant="good"
            type="submit"
            disabled={
              paymentDate === '' ||
              total <= 0 ||
              appliedPayrollStatus === 'pending'
            }
          >
            Submit Payment
          </Button>
          {appliedPayrollStatus === 'success' && (
            <span>
              Payroll Applied <RxCheck />
            </span>
          )}
        </div>
      </form>
    );
  };

  const AssignmentsTable = () => {
    const isBalanceApplied = applyToBalance =>
      !isNaN(applyToBalance) && applyToBalance && applyToBalance > 0;

    const HandleInputChange = (index: number, value: string) => {
      setAssetAssignments(prevAssetAssignment => {
        return prevAssetAssignment.map((assignment, i) => {
          if (i === index) {
            return {
              ...assignment,
              isSelected: isBalanceApplied(value),
              applyToBalance: Number(value),
            };
          }
          return assignment;
        });
      });
    };

    function HandleRowSelect(selected, index) {
      if (selected === true) {
        setAssetAssignments(prevAssignments => {
          return prevAssignments.map((assignment, i) => {
            if (i === index) {
              return {
                ...assignment,
                isSelected: true,
                applyToBalance: assignment.remainingPayroll,
              };
            }
            return assignment;
          });
        });
      } else {
        setAssetAssignments(prevAssignments => {
          return prevAssignments.map((assignment, i) => {
            if (i === index) {
              return {
                ...assignment,
                isSelected: false,
                applyToBalance: null,
              };
            }
            return assignment;
          });
        });
      }
    }

    const isEveryAssignmentSelected =
      tableData.every(assignment => assignment.isSelected) &&
      tableData.length > 0;

    const ToggleSelectAll = () => {
      const updatedAssetAssignments = assetAssignments.map(item => {
        const assetAssignment = {
          ...item,
          isSelected: !isEveryAssignmentSelected,
        };
        if (isEveryAssignmentSelected) {
          assetAssignment.applyToBalance = null;
        } else if (!isEveryAssignmentSelected) {
          assetAssignment.applyToBalance = assetAssignment.remainingPayroll;
        }
        return assetAssignment;
      });
      setAssetAssignments(updatedAssetAssignments);
    };

    return (
      <div className={classesApplyPayroll.tableContainer}>
        <table>
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={ToggleSelectAll}
                  checked={isEveryAssignmentSelected}
                />
              </th>
              <th>Load #</th>
              <th>Reference</th>
              <th>Status</th>
              <th>Customer</th>
              <th>Pickup</th>
              <th>Drop Off</th>
              <th>Delivered Date</th>
              <th>Load Mileage</th>
              <th>Total Payroll</th>
              <th>Remaining Payroll</th>
              <th>Apply to Balance</th>
            </tr>
          </thead>
          <tbody>
            {tableData?.map((row, index) => (
              <tr key={row.loadId}>
                <td>
                  <input
                    type="checkbox"
                    name="selected"
                    checked={row.isSelected}
                    onChange={e => HandleRowSelect(e.target.checked, index)}
                  />
                </td>
                <td
                  className={classes.clickable}
                  onClick={() => View(<ViewLoad loadId={row.loadId} />)}
                >
                  {row.loadId}
                </td>
                <td
                  className={row.reference ? classes.clickable : ''}
                  onClick={() => View(<ViewLoad loadId={row.loadId} />)}
                >
                  {row.reference}
                </td>
                <td
                  className={classes.clickable}
                  onClick={() =>
                    View(
                      <ViewPayroll assetAssignmentId={row.assetAssignmentId} />,
                    )
                  }
                >
                  {row.paymentStatus}
                </td>
                <td
                  className={row.customerId ? classes.clickable : ''}
                  onClick={() =>
                    row.customerId &&
                    View(
                      <ViewCustomer
                        customerId={row.customerId}
                        customerName={row.customerName}
                      />,
                    )
                  }
                >
                  {row.customerName}
                </td>
                <td
                  className={row.pickupStopLocationId ? classes.clickable : ''}
                  onClick={() =>
                    row.pickupStopLocationId &&
                    View(
                      <ViewLocation
                        locationId={row.pickupStopLocationId}
                        locationName={row.pickupStopLocationName}
                      />,
                    )
                  }
                >
                  {row.pickupStopLocationName}
                </td>
                <td
                  className={row.dropOffStopLocationId ? classes.clickable : ''}
                  onClick={() =>
                    row.dropOffStopLocationId &&
                    View(
                      <ViewLocation
                        locationId={row.dropOffStopLocationId}
                        locationName={row.dropOffStopLocationName}
                      />,
                    )
                  }
                >
                  {row.dropOffStopLocationName}
                </td>
                <td>{DateOnlyFormatter(row.deliveredDate)}</td>
                <td>{row.loadMileage.toLocaleString()}</td>
                <td>
                  {row.totalPayroll.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </td>
                <td>
                  {row.remainingPayroll.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </td>
                <td>
                  <input
                    placeholder="0.00"
                    min={0}
                    max={row.remainingBalance}
                    defaultValue={row.applyToBalance}
                    type="number"
                    onBlur={e => {
                      HandleInputChange(index, e.target.value);
                    }}
                  ></input>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      <Header>
        <SearchAssignmentsForm />
      </Header>
      <ApplyPaymentToPayrollForm />
      <Header>
        <h3>Payroll Details</h3>
      </Header>
      <AssignmentsTable />
    </>
  );
}
