function DateOnlyFormatter(dateTimeObject) {
  if (dateTimeObject) {
    const dateTime = new Date(dateTimeObject);
    if (!dateTime) {
      return null;
    }
    const dateString = dateTime.toLocaleDateString();
    return dateString;
  }
  return null;
}

export default DateOnlyFormatter;
