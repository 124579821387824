import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Get from '../../api/internal/Get';
import View from '../../helpers/slab/View';
import ViewLocation from '../../features/views/location/Location';
import FilterData from '../../helpers/filter/FilterData';
import BBITable from '../../helpers/bBITable/BBITable';

const LocationBoard = ({ toggleBoardRefresh }) => {
  const [locationBoard, setLocationBoard] = useState<Array<LocationBoard>>([]);
  const filterValue = useOutletContext();

  useEffect(() => {
    GetLocationBoard();
  }, [toggleBoardRefresh]);

  function GetLocationBoard() {
    Get(`/Location/GetLocationBoard`).then(response => {
      if (response) {
        setLocationBoard(response.data);
      }
    });
  }

  let columnConfig = [
    {
      key: '1',
      attribute: 'locationName',
      attributeprimaryhidden: 'locationId',
      header: 'Location Name',
      onClick: e =>
        View(
          <ViewLocation
            locationName={e.target.innerText}
            locationId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '2',
      attribute: 'address',
      header: 'Address',
    },
    {
      key: '3',
      attribute: 'city',
      header: 'City',
    },
    {
      key: '4',
      attribute: 'state',
      header: 'State',
    },
    {
      key: '5',
      attribute: 'phone',
      header: 'Phone',
    },
  ];

  let tableData = FilterData(locationBoard, filterValue);

  return <BBITable data={tableData} columns={columnConfig} />;
};

export default LocationBoard;
