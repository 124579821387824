import { LogLevel } from '@azure/msal-browser';
// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check


export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AAD_CLIENT_ID,
    authority: process.env.REACT_APP_AAD_AUTHORITY_URL,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};
export const protectedResources = {
  transportAPI: {
    transportEndpoint: process.env.REACT_APP_SERVICE_FABRIC_BASE_URL,
    scopes: `api://${process.env.REACT_APP_API_TOKEN}/.default`,
  }
};

export const transportTokenRequest = {
  scopes: [protectedResources.transportAPI.scopes],
};

export const loginRequest = {
  scopes: [protectedResources.transportAPI.scopes],
};

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
  graphPhotoEndpoint: 'https://graph.microsoft.com/v1.0/me/photo/$value'
}

export const securityGroups = {
  GroupMember: process.env.REACT_APP_USER_ID,
  GroupAdmin: process.env.REACT_APP_ADMIN_ID,
}