import React, { useState, useEffect } from 'react';
import Select from '../../../../assets/essentials/Select';
import OptionList from '../../../../helpers/options/OptionList';
import classes from '../../../../assets/Global.module.css';
import PutBody from '../../../../api/internal/PutBody';
import Delete from '../../../../api/internal/Delete';

function UpdateCustomer({
  customerOptions,
  customerAssignments,
  RefreshPage,
  loadId,
}) {
  const [selectedCustomerIds] = useState(
    customerAssignments.map(assignment => assignment.customerId),
  );

  function HandleCustomerChange(customerAssignmentId, event) {
    const newCustomerId = parseInt(event.target.value);

    const updatedAssignment = {
      loadId: loadId,
      customerAssignmentId: customerAssignmentId,
      newCustomerId: newCustomerId,
    };

    PutBody(`/Load/UpdateCustomerAssignment`, updatedAssignment).then(
      response => {
        if (response) {
          RefreshPage('Admin', ['Audit', 'Summary']);
        }
      },
    );
  }

  function DeleteCustomerAssignment(customerAssignmentId) {
    const isConfirmed = window.confirm(
      'Are you sure you want to delete this customer assignment?',
    );
    if (isConfirmed) {
      Delete(
        `/Load/DeleteCustomerAssignment/${loadId}/${customerAssignmentId}`,
      ).then(response => {
        if (response) {
          RefreshPage('Admin', ['Audit', 'Summary']);
        }
      });
    }
  }

  return (
    <div className={classes.viewGrid}>
      {customerAssignments.map((customer, index) => (
        <div key={customer.customerAssignmentId}>
          <label htmlFor={`customerSelect-${customer.customerAssignmentId}`}>
            Customer
          </label>
          <Select
            id={`customerSelect-${customer.customerAssignmentId}`}
            onChange={event =>
              HandleCustomerChange(customer.customerAssignmentId, event)
            }
            value={selectedCustomerIds[index]}
            name={`customerSelect-${customer.customerId}`}
          >
            <OptionList
              optionData={customerOptions}
              attributeID="customerId"
              attributeName="customerName"
            />
          </Select>
          <label
            onClick={() =>
              DeleteCustomerAssignment(customer.customerAssignmentId)
            }
            className={classes.bad}
          >
            Delete
          </label>
        </div>
      ))}
    </div>
  );
}

export default UpdateCustomer;
