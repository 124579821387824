import React, { useEffect } from 'react';
import TrimbleMaps, { LngLat } from '@trimblemaps/trimblemaps-js';

export type AssetLocationCoordinates = {
  latitude: number;
  longitude: number;
  date: Date | null;
};

type AssetLocationMapProps = {
  coordinates: AssetLocationCoordinates[];
};

export default function AssetLocationMap({
  coordinates,
}: AssetLocationMapProps) {
  useEffect(() => {
    RenderMap(trimbleAssetLocationCoordinates);
  });

  //most recent location needs to be the "end" of the route
  function SortAscendingByDate(a, b) {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA.getTime() - dateB.getTime();
  }

  TrimbleMaps.APIKey = process.env.REACT_APP_TRIMBLE_MAPS_API_KEY;

  const trimbleAssetLocationCoordinates = coordinates
    ?.sort(SortAscendingByDate)
    .map(
      location => new TrimbleMaps.LngLat(location.longitude, location.latitude),
    );

  function RenderMap(assetLocations: Array<LngLat>) {
    const map = new TrimbleMaps.Map({
      container: 'assetMap',
      style: TrimbleMaps.Common.Style.TRANSPORTATION,
      center: new TrimbleMaps.LngLat(
        coordinates[0].longitude,
        coordinates[0].latitude,
      ),
      zoom: 8,
    });

    const myRoute = new TrimbleMaps.Route({
      routeId: 'myRoute',
      showArrows: true,
      stops: assetLocations,
      bordersOpen: false,
      originIcon: {
        opacity: 0,
        textOpacity: 0,
      },
      destinationIcon: {
        size: 0.2,
        opacity: 1,
        textOpacity: 0,
      },
      stopIcon: {
        size: 0.15,
        opacity: 1,
        textOpacity: 0,
      },
      arrowOptions: {
        size: 0.5,
      },
    });

    map.on('load', function () {
      myRoute.addTo(map);
    });
  }
  return <div id="assetMap" />;
}