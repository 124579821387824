import React, { useState, useEffect } from 'react';
import Get from '../../../api/internal/Get';
import AdditionalInfo from './AdditionalInfo';
import Details from './Details';
import CreateBilling from './Billing';
import Panel from '../../../helpers/panel/Panel';
import PostBody from '../../../api/internal/PostBody';
import Close from '../../../helpers/panel/Close';
import View from '../../../helpers/slab/View';
import ViewCustomer from '../../views/customer/Customer';

function Customer() {
  const [displayBilling, setDisplayBilling] = useState(true);
  const [createCustomerOptions, setCreateCustomerOptions] =
    useState<CreateCustomerOptions>();
  const [createCustomerParam, setCreateCustomerParam] =
    useState<CreateCustomerParam>({
      customerName: null,
      addressLineOne: null,
      addressLineTwo: null,
      city: null,
      state: null,
      zip: null,
      country: null,
      phone: null,
      phoneExt: null,
      commodityId: null,
      website: null,
      note: null,
      billingEntity: null,
      billingAddressLineOne: null,
      billingAddressLineTwo: null,
      billingCity: null,
      billingState: null,
      billingZip: null,
      billingCountry: null,
      billingNote: null,
      paymentMethodId: 3,
      paymentTermId: 2,
    });

  useEffect(() => {
    GetCreateCustomerOptions();
  }, []);

  function GetCreateCustomerOptions() {
    Get(`/Customer/GetCreateCustomerOptions`).then(response => {
      if (response) {
        setCreateCustomerOptions(response.data);
        createCustomerParam.paymentMethodId =
          response.data.defaultPaymentMethodId;
        createCustomerParam.paymentTermId = response.data.defaultPaymentTermId;
      }
    });
  }

  function UpdateParam(newValue, attributeName) {
    if (newValue?.toString().trim().length !== 0) {
      createCustomerParam[attributeName] = newValue;
    } else {
      createCustomerParam[attributeName] = null;
    }
  }

  function FulFillsPageRequirements(pageIndex) {
    let fulfillsRequirements = true;
    pages[pageIndex].require.forEach(requirement => {
      if (!requirement.FunctionBool()) {
        fulfillsRequirements = false;
      }
    });
    return fulfillsRequirements;
  }

  function UpdateCustomerForAutoComplete(
    customerName: string,
    addressLineOne: string,
    city: string,
    state: string,
    zip: string,
    country: string,
    phone: string,
    website: string,
  ) {
    let tempCustomer = { ...createCustomerParam };
    tempCustomer.customerName = customerName;
    tempCustomer.addressLineOne = addressLineOne;
    tempCustomer.city = city;
    tempCustomer.state = state;
    tempCustomer.zip = zip;
    tempCustomer.country = country;
    tempCustomer.phone = phone;
    tempCustomer.website = website;
    setCreateCustomerParam(tempCustomer);
  }

  function OnSubmit() {
    let fulfillsFormRequirements = true;
    pages.forEach((page, index) => {
      if (!FulFillsPageRequirements(index)) {
        fulfillsFormRequirements = false;
      }
    });
    if (fulfillsFormRequirements) {
      CreateCustomer();
      Close();
    } else alert('Not all info included');
  }

  function CreateCustomer() {
    !createCustomerParam.billingEntity &&
      UpdateParam(createCustomerParam.customerName, 'billingEntity');
    !createCustomerParam.billingAddressLineOne &&
      UpdateParam(createCustomerParam.addressLineOne, 'billingAddressLineOne');
    !createCustomerParam.billingAddressLineTwo &&
      UpdateParam(createCustomerParam.addressLineTwo, 'billingAddressLineTwo');
    !createCustomerParam.billingCity &&
      UpdateParam(createCustomerParam.city, 'billingCity');
    !createCustomerParam.billingState &&
      UpdateParam(createCustomerParam.state, 'billingState');
    !createCustomerParam.billingZip &&
      UpdateParam(createCustomerParam.zip, 'billingZip');
    !createCustomerParam.billingCountry &&
      UpdateParam(createCustomerParam.country, 'billingCountry');

    PostBody(`/Customer/CreateCustomer`, createCustomerParam).then(response => {
      if (response) {
        Close();
        View(
          <ViewCustomer
            customerId={response.data.customerId}
            customerName={response.data.customerName}
          />,
        );
      }
    });
  }

  let pages = [
    {
      title: 'Details',
      displayedIcon: '',
      content: (
        <Details
          createCustomerOptions={createCustomerOptions}
          displayBilling={displayBilling}
          createCustomerParam={createCustomerParam}
          ToggleBilling={() => setDisplayBilling(!displayBilling)}
          UpdateCustomerForAutoComplete={UpdateCustomerForAutoComplete}
          UpdateParam={UpdateParam}
        />
      ),
      require: [
        {
          label: 'Customer Name',
          FunctionBool: () => createCustomerParam.customerName !== null,
          assignedValue: () => createCustomerParam.customerName,
          missingDesc: 'Customer Name has not been assigned.',
        },
        {
          label: 'Address',
          FunctionBool: () =>
            createCustomerParam.addressLineOne !== null &&
            createCustomerParam.city !== null &&
            createCustomerParam.state !== null &&
            createCustomerParam.zip !== null &&
            createCustomerParam.country !== null,
          assignedValue: () =>
            createCustomerParam.addressLineOne +
            ' ' +
            (createCustomerParam.addressLineTwo
              ? createCustomerParam.addressLineTwo + ' '
              : '') +
            createCustomerParam.city +
            ', ' +
            createCustomerParam.state +
            ' ' +
            createCustomerParam.zip +
            ' ' +
            createCustomerParam.country,
          missingDesc: 'Address has not been properly assigned.',
        },
        {
          label: 'Phone',
          FunctionBool: () =>
            createCustomerParam.phone !== null &&
            createCustomerParam.phone !== '',
          assignedValue: () =>
            createCustomerParam.phone +
            (createCustomerParam.phoneExt
              ? ' Ext: ' + createCustomerParam.phoneExt
              : ''),
          missingDesc: 'Phone Number has not been assigned.',
        },
      ],
    },
    {
      title: 'Additional Info',
      displayedIcon: '',
      content: (
        <AdditionalInfo
          createCustomerOptions={createCustomerOptions}
          param={createCustomerParam}
          UpdateParam={UpdateParam}
        />
      ),
      require: [],
    },
  ];

  !displayBilling &&
    pages.splice(1, 0, {
      title: 'Billing',
      displayedIcon: '',
      content: (
        <CreateBilling
          createParam={createCustomerParam}
          UpdateParam={UpdateParam}
        />
      ),
      require: [
        {
          label: 'Billing Entity Name',
          FunctionBool: () => createCustomerParam.billingEntity !== null,
          assignedValue: () => createCustomerParam.billingEntity,
          missingDesc: 'Billing Entity Name has not been assigned.',
        },
        {
          label: 'Billing Address',
          FunctionBool: () =>
            createCustomerParam.billingAddressLineOne !== null &&
            createCustomerParam.billingCity !== null &&
            createCustomerParam.billingState !== null &&
            createCustomerParam.billingZip !== null &&
            createCustomerParam.billingCountry !== null,
          assignedValue: () =>
            createCustomerParam.billingAddressLineOne +
            ' ' +
            (createCustomerParam.billingAddressLineTwo
              ? createCustomerParam.billingAddressLineTwo + ' '
              : '') +
            createCustomerParam.billingCity +
            ', ' +
            createCustomerParam.billingState +
            ' ' +
            createCustomerParam.billingZip +
            ' ' +
            createCustomerParam.billingCountry,
          missingDesc: 'Billing Address has not been properly assigned.',
        },
      ],
    });

  return <Panel OnSubmit={OnSubmit} title="Create Customer" pages={pages} />;
}

export default Customer;
