import React from 'react';
import classes from '../../../../assets/Global.module.css';

function Summary({ data }: { data: DispatchSummary }) {
  return (
    <>
      <div className={classes.viewGrid}>
        <div className={classes.attribute}>
          <h3>Dispatch Phone</h3>
          <span>{data?.dispatchPhone}</span>
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <h3>Dispatch Mobile</h3>
          <span>{data?.dispatchMobile}</span>
        </div>
        <div className={classes.attribute}>
          <h3>Note</h3>
          <span>{data?.note}</span>
        </div>
      </div>
    </>
  );
}
export default Summary;
