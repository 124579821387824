import React, { useState, useEffect } from 'react';
import { MdDownload } from 'react-icons/md';
import BBITable from '../../../helpers/bBITable/BBITable';
import classesCustom from '../../../helpers/search/CustomSearch.module.css';
import classesGlobal from '../../../assets/Global.module.css';
import classes from '../../../helpers/error/Error.module.css';
import DateOnlyFormatter from '../../../helpers/inputs/DateOnlyFormatter';
import FilterData from '../../../helpers/filter/FilterData';
import Header from '../../../layouts/Header';
import View from '../../../helpers/slab/View';
import ViewCustomer from '../../../features/views/customer/Customer';
import ViewReceivable from '../../../features/views/receivable/Receivable';
import Input from '../../../assets/essentials/Input';
import Button from '../../../assets/essentials/Button';
import PostBody from '../../../api/internal/PostBody';
import Get from '../../../api/internal/Get';
import ViewLoad from '../../../features/views/load/Load';
import ViewRental from '../../../features/views/rental/Rental';
import { GenerateQueryString } from '../../../helpers/search/HelperFunctions';
import { useOutletContext } from 'react-router-dom';

function QuickBooks() {
  const [quickBooksSearchResults, setQuickBooksSearchResults] = useState([]);
  const [displayDownloadModal, setDisplayDownloadModal] = useState(false);
  const [displayDownloadErrorModal, setDisplayDownloadErrorModal] =
    useState(false);
  const [hasMixedQbIds, setHasMixedQbIds] = useState(false);
  const today = new Date();
  const todayString = today.toISOString().split('T')[0];
  //default search parameter for invoice begin date in yyyy-mm-dd
  const thirtyDaysAgo = new Date(today.setDate(today.getDate() - 30));
  const thirtyDaysAgoString = thirtyDaysAgo.toISOString().split('T')[0];
  const [quickBooksSearchParams, setQuickBooksSearchParams] = useState(
    ParseUrlForSearchParams(),
  );
  const filterValue = useOutletContext();

  useEffect(() => {
    SearchQuickBooks();
  }, []);

  function ParseUrlForSearchParams() {
    const url = window.location.href.toString();
    const queryString = url.split('?')[1];
    // Split the query string into an array of key-value pairs
    const queryParamsArray = queryString?.split('&');
    // Create an object to store the decoded parameters
    const decodedParams = {
      loadId: null,
      rentalId: null,
      reference: null,
      quickBooksInvoiceId: null,
      customerName: null,
      invoiceDateBegin: thirtyDaysAgoString,
      invoiceDateEnd: null,
      dueDate: null,
      hasOnlyNullQbIds: true,
    };
    // Loop through the key-value pairs and decode each one
    queryParamsArray?.forEach(function (param) {
      const [key, value] = param.split('=');
      decodedParams[key] = decodeURIComponent(value);
    });
    return decodedParams;
  }

  function HandleDownloadButtonClick(receivables) {
    let isDownloadable = receivables.length > 0;
    let hasNullQbId = false;
    let hasNonNullQbId = false;

    receivables.map(receivable => {
      if (receivable.quickBooksInvoiceId == null) {
        hasNullQbId = true;
      } else {
        hasNonNullQbId = true;
      }
    });

    if (isDownloadable) {
      if (hasNonNullQbId && hasNullQbId) {
        setHasMixedQbIds(true);
      } else {
        setHasMixedQbIds(false);
      }
      setDisplayDownloadModal(true);
    } else {
      setDisplayDownloadErrorModal(true);
    }
  }

  function FormatQuickbooks(quickBooks) {
    return quickBooks.map(quickBooks => ({
      ...quickBooks,
      dueDate: DateOnlyFormatter(quickBooks.dueDate),
      invoiceDate: DateOnlyFormatter(quickBooks.invoiceDate),
      rate: quickBooks.rate.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      total: quickBooks.total.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    }));
  }

  function SearchQuickBooks(quickBooksSearchParamsFromForm?) {
    //if there is no params passed in, search with default params derived from URL
    const searchParams = quickBooksSearchParamsFromForm
      ? quickBooksSearchParamsFromForm
      : quickBooksSearchParams;
    const queryString = '?' + GenerateQueryString(searchParams);
    const newUrl =
      window.location.origin + window.location.pathname + queryString;
    window.history.replaceState(null, null, newUrl);
    Get(`/Accounting/SearchQuickBooksAR${queryString}`).then(response => {
      if (response) {
        setQuickBooksSearchResults(FormatQuickbooks(response.data));
      }
    });
  }

  function ClearQuickBooksSearchparams() {
    setQuickBooksSearchParams({
      loadId: null,
      rentalId: null,
      reference: null,
      quickBooksInvoiceId: null,
      customerName: null,
      invoiceDateBegin: null,
      invoiceDateEnd: null,
      dueDate: null,
      hasOnlyNullQbIds: true,
    });
  }

  function DownloadQuickBooksCSV(receivables) {
    let receivableIdsToDownload = [];
    receivableIdsToDownload = receivables?.map(x => x.receivableId);
    const payload = {
      ReceivableIdList: [...receivableIdsToDownload],
    };
    PostBody('Accounting/DownloadARQuickBooksCSV', payload).then(response => {
      if (response) {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download =
          new Date().toISOString().split('T')[0].replace(/[-]/g, '') +
          '_QuickBooks Report.csv';
        a.click();
        URL.revokeObjectURL(url);
      }
    });
    setDisplayDownloadModal(false);
  }

  const columnConfig = [
    {
      key: '1',
      attribute: 'invoiceNumber',
      attributeprimaryhidden: 'customerAssignmentId',
      attributesecondaryhidden: 'loadId',
      attributetertiaryhidden: 'rentalId',
      header: 'Invoice #',
      onClick: e =>
        View(
          <ViewReceivable
            customerAssignmentId={e.target.dataset.attributeprimaryhidden}
            loadId={e.target.dataset.attributesecondaryhidden}
            rentalId={e.target.dataset.attributetertiaryhidden}
          />,
        ),
      dataType: 'string',
    },
    {
      key: '2',
      attribute: 'quickBooksInvoiceId',
      header: 'QuickBooks #',
    },
    {
      key: '3',
      attribute: 'reference',
      header: 'Reference',
      attributeprimaryhidden: 'loadId',
      attributesecondaryhidden: 'rentalId',
      onClick: e =>
        View(
          e.target.dataset.attributeprimaryhidden ? (
            <ViewLoad loadId={e.target.dataset.attributeprimaryhidden} />
          ) : (
            e.target.dataset.attributesecondaryhidden && (
              <ViewRental
                rentalId={e.target.dataset.attributesecondaryhidden}
              />
            )
          ),
        ),
    },
    {
      key: '4',
      attribute: 'customerName',
      attributeprimaryhidden: 'customerId',
      header: 'Customer',
      onClick: e =>
        View(
          <ViewCustomer
            customerName={e.target.innerText}
            customerId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '5',
      attribute: 'invoiceDate',
      header: 'Invoice Date',
      dataType: 'date',
    },
    {
      key: '6',
      attribute: 'dueDate',
      header: 'Due Date',
      dataType: 'date',
    },
    {
      key: '7',
      attribute: 'accountType',
      header: 'Account Type',
      dataType: 'string',
    },
    {
      key: '8',
      attribute: 'note',
      header: 'Note',
      dataType: 'string',
    },
    {
      key: '9',
      attribute: 'quantity',
      header: 'Quantity',
      dataType: 'currency',
    },
    {
      key: '10',
      attribute: 'rate',
      header: 'Rate',
      dataType: 'currency',
    },
    {
      key: '11',
      attribute: 'total',
      header: 'Total',
      dataType: 'currency',
    },
    {
      key: '12',
      attribute: 'drivers',
      header: 'Drivers',
    },
    {
      key: '13',
      attribute: 'driversPayrollStatus',
      header: 'Driver Payroll Status',
    },
  ];

  const QuickBooksTable = () => {
    return (
      <BBITable
        data={FilterData(quickBooksSearchResults, filterValue)}
        columns={columnConfig}
      />
    );
  };

  function HandleFormSubmit(e) {
    e.preventDefault();
    const formData = {
      loadId: e.target.loadId?.value,
      rentalId: e.target.rentalId?.value,
      reference: e.target.reference?.value,
      quickBooksInvoiceId: e.target.quickBooksInvoiceId?.value,
      customerName: e.target.customerName?.value,
      invoiceDateBegin: e.target.invoiceDateBegin.value,
      invoiceDateEnd: e.target.invoiceDateEnd?.value,
      dueDate: e.target.dueDate?.value,
      hasOnlyNullQbIds: e.target.hasOnlyNullQbIds.checked,
    };
    setQuickBooksSearchParams(formData);
    SearchQuickBooks(formData);
  }

  const ReceivableSearchForm = () => {
    return (
      <div className={`${classesCustom.customSearch}`}>
        <form
          onSubmit={e => {
            HandleFormSubmit(e);
          }}
        >
          <label>
            Load #
            <Input
              defaultValue={quickBooksSearchParams?.loadId}
              name="loadId"
            />
          </label>
          <label>
            Rental #
            <Input
              defaultValue={quickBooksSearchParams?.rentalId}
              name="rentalId"
            />
          </label>
          <label>
            Reference
            <Input
              defaultValue={quickBooksSearchParams?.reference}
              name="reference"
            />
          </label>
          <label>
            QuickBooks #
            <Input
              defaultValue={quickBooksSearchParams?.quickBooksInvoiceId}
              name="quickBooksInvoiceId"
            />
          </label>
          <label>
            Customer Name
            <Input
              defaultValue={quickBooksSearchParams?.customerName}
              name="customerName"
            />
          </label>
          <label>
            Beginning Invoice Date
            <Input
              defaultValue={quickBooksSearchParams.invoiceDateBegin}
              type="date"
              name="invoiceDateBegin"
            />
          </label>
          <label>
            Ending Invoice Date
            <Input
              defaultValue={quickBooksSearchParams?.invoiceDateEnd}
              type="date"
              name="invoiceDateEnd"
            />
          </label>
          <label>
            Due Date
            <Input
              defaultValue={quickBooksSearchParams?.dueDate}
              type="date"
              name="dueDate"
            />
          </label>
          <label
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'end',
            }}
          >
            <div>
              <Input
                type="checkbox"
                name="hasOnlyNullQbIds"
                defaultChecked={Boolean(
                  quickBooksSearchParams.hasOnlyNullQbIds,
                )}
              />
            </div>
            Only Empty QuickBooks #s
          </label>
          <div className={classesCustom.submitHolder}>
            <Button
              type="submit"
              variant="good"
              onClick={() => SearchQuickBooks()}
            >
              Search
            </Button>
            <Button
              type="submit"
              onClick={() => ClearQuickBooksSearchparams()}
              variant="bad"
            >
              Clear
            </Button>
          </div>
        </form>
      </div>
    );
  };

  const DownloadButton = filterValue => {
    return (
      <button
        onClick={() =>
          HandleDownloadButtonClick(
            FilterData(quickBooksSearchResults, filterValue.filterValue),
          )
        }
      >
        <MdDownload />
        <span>Download</span>
      </button>
    );
  };

  const DownloadModal = filterValue => {
    return (
      <div className={classes.errorContainer}>
        <div
          className={classes.overlay}
          onClick={() => setDisplayDownloadModal(false)}
        ></div>
        <div className={classes.errorHolder}>
          <div className={classes.yesNoModal}>
            {hasMixedQbIds ? (
              <h3>
                Displayed receivables contain existing QuickBooks #'s.
                Downloading receivables will set QuickBooks #s to "Manually
                Updated:
                {' ' + todayString}". Are you sure you want to continue?"
              </h3>
            ) : (
              <h3>
                Downloading will mark all displayed receivables as uploaded to
                QuickBooks.
              </h3>
            )}

            <h3>Do you wish to proceed?</h3>
            <div className={classes.buttonHolder}>
              <Button
                onClick={() =>
                  DownloadQuickBooksCSV(
                    FilterData(
                      quickBooksSearchResults,
                      filterValue.filterValue,
                    ),
                  )
                }
                variant="good"
              >
                Yes
              </Button>
              <Button
                variant="bad"
                onClick={() => setDisplayDownloadModal(false)}
              >
                No
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const DownloadErrorModal = () => {
    return (
      <div className={classes.errorContainer}>
        <div
          className={classes.overlay}
          onClick={() => setDisplayDownloadModal(false)}
        ></div>
        <div className={classes.errorHolder} id="error-container">
          <div className={classes.yesNoModal}>
            <h3>There are no QuickBooks Displayed</h3>
            <Button
              variant="bad"
              onClick={() => setDisplayDownloadErrorModal(false)}
            >
              Ok
            </Button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Header>
        <DownloadButton filterValue={filterValue} />
      </Header>
      <ReceivableSearchForm />
      <QuickBooksTable />
      {displayDownloadErrorModal && <DownloadErrorModal />}
      {displayDownloadModal && <DownloadModal filterValue={filterValue} />}
    </>
  );
}
export default QuickBooks;
