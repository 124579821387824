import React, { useState } from 'react';

import classes from '../../../assets/Accordion.module.css';
import classesGlobal from '../../../assets/Global.module.css';
import View from '../../../helpers/slab/View';
import ViewDriver from '../users/driver/Driver';
import OptionList from '../../../helpers/options/OptionList';
import PutForm from '../../../api/internal/PutForm';
import Delete from '../../../api/internal/Delete';
import DeleteButton from '../../../helpers/buttons/DeleteButton';
import Button from '../../../assets/essentials/Button';
import Select from '../../../assets/essentials/Select';
import Input from '../../../assets/essentials/Input';
import TextArea from '../../../assets/essentials/TextArea';
import Form from '../../../assets/essentials/Form';

function Driver({
  loadId,
  driver,
  driverList,
  truckList,
  trailerList,
  RefreshPage,
  GetLoad,
}: {
  loadId: number;
  driver: LoadAssignmentDriver;
  driverList: Array<DriverOption>;
  truckList: Array<TruckOption>;
  trailerList: Array<TrailerOption>;
  RefreshPage: Function;
  GetLoad: Function;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [assetAssignment, setAssetAssignment] = useState({
    employeeId: driver.employeeId,
    driverId: driver.driverId,
    driverName: driver.driverName,
    truckId: driver.truckId,
    trailerId: driver.trailerId,
    truckNumber: driver.truckNumber,
    trailerNumber: driver.trailerNumber,
    phone: driver.phone,
    note: driver.note,
  });

  function UpdateAssetAssignment(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    PutForm(`/Load/UpdateAssetAssignment`, formData, {
      loadId: loadId,
      assetAssignmentId: driver.assetAssignmentId,
    }).then(() => {
      RefreshPage('Summary', ['Itinerary']);
    });
    setIsOpen(false);
  }

  function DeleteAssetAssignment() {
    Delete(`/Load/DeleteAssetAssignment/${driver.assetAssignmentId}/`).then(
      () => {
        RefreshPage('Summary', ['Itinerary']);
        GetLoad();
      },
    );
    setIsOpen(false);
  }

  const HandleDriverChange = e => {
    const currentSelectedDriver = driverList.find(
      driver => driver.driverId === parseInt(e.target.value),
    );
    setAssetAssignment(prevAssignment => ({
      ...prevAssignment,
      driverId: currentSelectedDriver.driverId,
      driverName: currentSelectedDriver.name,
      phone: currentSelectedDriver.phone,
    }));
  };

  const HandleTruckChange = e => {
    const currentSelectedTruck = truckList.find(
      truck => truck.truckId === parseInt(e.target.value),
    );
    setAssetAssignment(prevAssignment => ({
      ...prevAssignment,
      truckId: currentSelectedTruck.truckId,
      truckNumber: currentSelectedTruck.truckNumber,
    }));
  };

  const HandleTrailerChange = e => {
    const currentSelectedTrailer = trailerList.find(
      trailer => trailer.trailerId === parseInt(e.target.value),
    );
    setAssetAssignment(prevAssignment => ({
      ...prevAssignment,
      trailerId: currentSelectedTrailer?.trailerId,
      trailerNumber: currentSelectedTrailer?.trailerNumber ?? '',
    }));
  };

  const HandleInputChange = e => {
    const { name, value } = e.target;
    setAssetAssignment(prevAssignment => ({
      ...prevAssignment,
      [name]: value,
    }));
  };
  return (
    <>
      <div className={classes.accordionSummary}>
        <div className={classesGlobal.viewGrid}>
          <div
            className={`${classesGlobal.attribute} ${classesGlobal.span3}`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <span>
              <b>Driver Name: </b> {driver.driverName}
            </span>
            <span>
              <b>Mobile: </b> {driver.phone}
            </span>
            <span>
              <b>Truck: </b>{' '}
              {driver.truckDescription && driver.truckDescription}
              {driver.trailerDescription &&
                ', Trailer: ' + driver.trailerDescription}
            </span>
            <div
              className={`${classesGlobal.cTA} ${classesGlobal.attribute}`}
              onClick={() =>
                View(
                  <ViewDriver
                    employeeId={assetAssignment.employeeId}
                    driverName={assetAssignment.driverName}
                  />,
                )
              }
            >
              Manage Driver Info
            </div>
          </div>
        </div>
      </div>
      <div className={`${classes.expandable} ${classes.driver}`}>
        {isOpen && (
          <Form onSubmit={e => UpdateAssetAssignment(e)}>
            <div className={classesGlobal.attribute}>
              <label>Driver</label>
              <Select
                defaultValue={assetAssignment.driverId}
                onChange={HandleDriverChange}
                name="driverId"
              >
                <OptionList
                  optionData={driverList}
                  attributeID="driverId"
                  attributeName="driverName"
                />
              </Select>
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>Phone</label>
              <Input
                type="text"
                value={assetAssignment.phone}
                name="phone"
                disabled
              />
            </div>
            <div className={classesGlobal.attribute}>
              <label>Truck</label>
              <Select
                defaultValue={assetAssignment.truckId}
                onChange={HandleTruckChange}
                name="truckId"
              >
                <OptionList
                  optionData={truckList}
                  attributeID="truckId"
                  attributeName="description"
                />
              </Select>
            </div>
            {true && (
              <div
                className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
              >
                <label>Truck License Plate #</label>
                <Input value={assetAssignment.truckNumber ?? ''} disabled />
              </div>
            )}
            <div className={classesGlobal.attribute}>
              <label>Trailer</label>
              <Select
                defaultValue={assetAssignment.trailerId}
                onChange={HandleTrailerChange}
                name="trailerId"
              >
                <option value="" />
                <OptionList
                  optionData={trailerList}
                  attributeID="trailerId"
                  attributeName="description"
                />
              </Select>
            </div>
            {true && (
              <div
                className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
              >
                <label>Trailer License Plate #</label>
                <Input
                  value={assetAssignment.trailerNumber}
                  name="trailerNumber"
                  disabled
                />
              </div>
            )}
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span3}`}
            >
              <label>Notes</label>
              <TextArea
                defaultValue={assetAssignment.note}
                name="note"
                onChange={HandleInputChange}
              />
            </div>
            <div>
              <Button type="submit" variant="good" disabled={true}>
                Save Changes
              </Button>
            </div>
            <div className={`${classesGlobal.span2}`}>
              <DeleteButton OnDelete={DeleteAssetAssignment} />
            </div>
          </Form>
        )}
      </div>
    </>
  );
}

export default Driver;
