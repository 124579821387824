import React from 'react';

import BBITable from '../../../helpers/bBITable/BBITable';
import CreateNotes from '../../creates/notes/Customer';
import DateTimeUTCFormatter from '../../../helpers/inputs/DateTimeUTCFormatter';

function Notes({
  data,
  customerId,
  RefreshPage,
}: {
  data: CustomerNotes;
  customerId: Number;
  RefreshPage: Function;
}) {
  const formattedCustomerNoteData = data.notes?.map(customerNote => ({
    ...customerNote,
    date: DateTimeUTCFormatter(customerNote.date),
  }));

  let columnConfig = [
    {
      key: '1',
      attribute: 'createdBy',
      header: 'Created By',

      width: '20ch',
    },
    {
      key: '2',
      attribute: 'contactName',
      header: 'Contact',

      width: '25ch',
    },
    {
      key: '3',
      attribute: 'date',
      header: 'Date',

      width: '10ch',
    },
    {
      key: '4',
      attribute: 'note',
      header: 'Note',

      width: 'auto',
    },
  ];

  function CreateNote(e) {
    alert('Emulate Create Note');
  }
  return (
    <>
      <CreateNotes
        contactOptions={data.contactOptions}
        customerId={customerId}
        RefreshPage={RefreshPage}
      />
      <BBITable data={formattedCustomerNoteData} columns={columnConfig} />
    </>
  );
}
export default Notes;
