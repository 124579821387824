import React, { useState, useEffect } from 'react';
import { MdDownload } from 'react-icons/md';
import BBITable from '../../../helpers/bBITable/BBITable';
import DateOnlyFormatter from '../../../helpers/inputs/DateOnlyFormatter';
import FilterData from '../../../helpers/filter/FilterData';
import Get from '../../../api/internal/Get';
import Header from '../../../layouts/Header';
import View from '../../../helpers/slab/View';
import ViewCustomer from '../../../features/views/customer/Customer';
import ViewLoad from '../../../features/views/load/Load';
import ViewRental from '../../../features/views/rental/Rental';
import ViewReceivable from '../../../features/views/receivable/Receivable';
import PostBody from '../../../api/internal/PostBody';
import { useOutletContext } from 'react-router-dom';

function OutstandingReceivablesBoard({
  toggleBoardRefresh,
}: {
  toggleBoardRefresh: boolean;
}) {
  const [receivableBoard, setReceivableBoard] = useState<
    Array<ReceivableBoard>
  >([]);
  const filterValue = useOutletContext();

  useEffect(() => {
    Get(`/Accounting/GetReceivableBoard/`).then(response => {
      if (response) {
        setReceivableBoard(FormatReceivables(response.data));
      }
    });
  }, [toggleBoardRefresh]);

  function FormatReceivables(receivables) {
    return receivables?.map(receivable => ({
      ...receivable,
      attribute: receivable.loadId
        ? receivable.loadId
        : receivable.rentalId && 'Rental: ' + receivable.rentalId,
      dueDate: DateOnlyFormatter(receivable.dueDate),
      invoiceDate: DateOnlyFormatter(receivable.invoiceDate),
      remainingBalance: receivable.remainingBalance.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      total: receivable.total.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    }));
  }

  function DownloadReceivableReportCSV(receivables) {
    let receivableIdsToDownload = [];
    receivableIdsToDownload = receivables?.map(x => x.customerAssignmentId);
    if (receivableIdsToDownload.length > 0) {
      const payload = {
        CustomerAssignmentIdList: [...receivableIdsToDownload],
      };
      PostBody('Accounting/DownloadReceivableReportCSV', payload).then(
        response => {
          if (response) {
            const blob = new Blob([response.data], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download =
              new Date().toISOString().split('T')[0].replace(/[-]/g, '') +
              '_Receivables Report.csv';
            a.click();
            URL.revokeObjectURL(url);
          }
        },
      );
    }
  }

  const columnConfig = [
    {
      key: '1',
      attribute: 'attribute',
      attributeprimaryhidden: 'loadId',
      attributesecondaryhidden: 'rentalId',
      header: 'Load #',
      onClick: e =>
        View(
          e.target.dataset.attributeprimaryhidden ? (
            <ViewLoad loadId={e.target.dataset.attributeprimaryhidden} />
          ) : (
            e.target.dataset.attributesecondaryhidden && (
              <ViewRental
                rentalId={e.target.dataset.attributesecondaryhidden}
              />
            )
          ),
        ),
      dataType: 'number',
    },
    {
      key: '2',
      attribute: 'reference',
      attributeprimaryhidden: 'loadId',
      attributesecondaryhidden: 'rentalId',
      header: 'Reference',
      onClick: e =>
        View(
          e.target.dataset.attributeprimaryhidden ? (
            <ViewLoad loadId={e.target.dataset.attributeprimaryhidden} />
          ) : (
            e.target.dataset.attributesecondaryhidden && (
              <ViewRental
                rentalId={e.target.dataset.attributesecondaryhidden}
              />
            )
          ),
        ),
    },
    {
      key: '3',
      attribute: 'paymentStatus',
      attributeprimaryhidden: 'customerAssignmentId',
      attributesecondaryhidden: 'loadId',
      attributetertiaryhidden: 'rentalId',
      header: 'Status',
      onClick: e =>
        View(
          <ViewReceivable
            customerAssignmentId={e.target.dataset.attributeprimaryhidden}
            loadId={e.target.dataset.attributesecondaryhidden}
            rentalId={e.target.dataset.attributetertiaryhidden}
          />,
        ),
      dataType: 'string',
    },
    {
      key: '4',
      attribute: 'customerName',
      attributeprimaryhidden: 'customerId',
      header: 'Customer',
      onClick: e =>
        View(
          <ViewCustomer
            customerName={e.target.innerText}
            customerId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '5',
      attribute: 'quickBooksInvoiceId',
      header: 'QuickBooks ID',
      dataType: 'number',
    },
    {
      key: '6',
      attribute: 'branchName',
      header: 'Branch',
    },
    {
      key: '7',
      attribute: 'invoiceDate',
      header: 'Invoice Date',
      dataType: 'date',
    },
    {
      key: '8',
      attribute: 'dueDate',
      header: 'Due Date',
      dataType: 'date',
    },
    {
      key: '9',
      attribute: 'total',
      header: 'Total',
      dataType: 'currency',
    },
    {
      key: '10',
      attribute: 'remainingBalance',
      header: 'Remaining Balance',
      dataType: 'currency',
    },
  ];

  const ReceivableTable = () => {
    return (
      <BBITable
        data={FilterData(receivableBoard, filterValue)}
        columns={columnConfig}
      />
    );
  };

  return (
    <>
      <Header>
        <button
          onClick={() =>
            DownloadReceivableReportCSV(
              FilterData(receivableBoard, filterValue),
            )
          }
        >
          <MdDownload />
          <span>Download</span>
        </button>
      </Header>
      <ReceivableTable />
    </>
  );
}

export default OutstandingReceivablesBoard;
