import React, { useState } from 'react';
import { MdOutlineSpeakerNotes } from 'react-icons/md';

import classes from './Vendor.module.css';
import classesGlobal from '../../../assets/Global.module.css';
import PostForm from '../../../api/internal/PostForm';
import OptionList from '../../../helpers/options/OptionList';
import Button from '../../../assets/essentials/Button';
import Select from '../../../assets/essentials/Select';
import TextArea from '../../../assets/essentials/TextArea';
import Form from '../../../assets/essentials/Form';

function Vendor({
  vendorId,
  contactOptions,
  RefreshPage,
}: {
  vendorId: Number;
  contactOptions: Array<ContactOption>;
  RefreshPage: Function;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function CreateNote(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    PostForm(`/Vendor/CreateVendorNote`, formData, {
      vendorId: vendorId,
    }).then(() => RefreshPage('Notes', ['Audit']));
    setIsOpen(false);
  }

  return (
    <>
      <div className={classes.btnHolder}>
        <button type="button" onClick={() => setIsOpen(!isOpen)}>
          <MdOutlineSpeakerNotes />
          <span>Create a Note</span>
        </button>
      </div>
      <div className={classes.createNoteHolder}>
        {isOpen && (
          <Form onSubmit={e => CreateNote(e)}>
            <div className={classesGlobal.attribute}>
              <label>Contact</label>
              <Select defaultValue="" name="vendorContactId">
                <option value="" />
                <OptionList
                  optionData={contactOptions}
                  attributeID="vendorContactId"
                  attributeName="contactNamePosition"
                />
              </Select>
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span3}`}
            >
              <label>
                Note <span className={classesGlobal.required}>*</span>
              </label>
              <TextArea required name="note" />
            </div>
            <div>
              <Button type="submit" variant="good" disabled={true}>
                Submit
              </Button>
            </div>
            <div>
              <Button onClick={() => setIsOpen(!isOpen)}>Cancel</Button>
            </div>
          </Form>
        )}
      </div>
    </>
  );
}

export default Vendor;
