import { BsMoon } from 'react-icons/bs';
import { HiOutlineSun } from 'react-icons/hi';

import classes from './PageContent.module.css';
import Sidebar from './Sidebar';

function PageContent({ theme, ThemeToggler, children }) {
  return (
    <div className={classes.pageContent}>
      <Sidebar />
      <div className={classes.content}>
        <div className={classes.subHeader}>
          <div
            className={`${classes.themeToggle}`}
            onClick={() => ThemeToggler()}
          >
            {theme === 'light' ? <BsMoon /> : <HiOutlineSun />}
            <button style={{ cursor: 'pointer' }}>
              {theme === 'light' ? 'Dark ' : 'Light '}
              Mode
            </button>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
}
export default PageContent;
