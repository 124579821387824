import React, { useState } from 'react';
import PutBody from '../../../../api/internal/PutBody';
import CancelLoadSection from './CancelLoadSection';
import SendLoadToClaimsSection from './SendToClaims';
import UpdateCustomer from './UpdateCustomer';
import CreateCustomerAssignment from './CreateCustomerAssignment';
import UpdateAdmin from './UpdateAdmin';

type LoadAdminProps = {
  data: LoadAdminData;
  loadId: number;
  RefreshPage: Function;
  GetLoad: Function;
  loadStatus: string;
};

function Admin({
  data,
  loadId,
  RefreshPage,
  GetLoad,
  loadStatus,
}: LoadAdminProps) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const {
    customerOptions,
    customerAssignments,
    currentBranchId,
    branchOptions,
    loadStatusOptions,
    loadStatusId
  } = data;

  function ResetLoadStatus() {
    setIsButtonDisabled(true);
    const payload = {
      loadId: loadId,
    };
    PutBody(`/Load/UncancelLoad`, payload)
      .then(() => {
        GetLoad();
        RefreshPage('Admin', ['Audit', 'Summary']);
      })
      .finally(() => {
        setIsButtonDisabled(false);
      });
  }

  return (
    <>
      <h2>Update Load</h2>
      <UpdateAdmin
        currentBranchId={currentBranchId}
        loadId={loadId}
        branchOptions={branchOptions}
        RefreshPage={RefreshPage}
        loadStatusOptions={loadStatusOptions}
        loadStatusId={loadStatusId}
      />
      <UpdateCustomer
        customerOptions={customerOptions}
        customerAssignments={customerAssignments}
        RefreshPage={RefreshPage}
        loadId={loadId}
      />
      <CreateCustomerAssignment
        customerOptions={data.customerOptions}
        loadId={loadId}
        RefreshPage={RefreshPage}
      />
      <SendLoadToClaimsSection
        loadId={loadId}
        loadStatus={loadStatus}
        isButtonDisabled={isButtonDisabled}
        setIsButtonDisabled={setIsButtonDisabled}
        UnsendLoadToClaims={ResetLoadStatus}
        GetLoad={GetLoad}
        RefreshPage={RefreshPage}
      />
      <CancelLoadSection
        loadId={loadId}
        loadStatus={loadStatus}
        isButtonDisabled={isButtonDisabled}
        setIsButtonDisabled={setIsButtonDisabled}
        UncancelLoad={ResetLoadStatus}
        GetLoad={GetLoad}
        RefreshPage={RefreshPage}
      />
    </>
  );
}

export default Admin;
