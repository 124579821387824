import React from 'react';

import classes from '../../../assets/Global.module.css';
import OptionList from '../../../helpers/options/OptionList';
import Select from '../../../assets/essentials/Select';
import Input from '../../../assets/essentials/Input';
import TextArea from '../../../assets/essentials/TextArea';

function StopList({
  createIndividualPayableParam,
  createPayableOptions,
  index,
  UpdateParam,
}: {
  createIndividualPayableParam: CreateIndividualPayableParam;
  createPayableOptions: CreatePayableOptions;
  index: number;
  UpdateParam: Function;
}) {
  return (
    <React.Fragment key={index}>
      <h1>Payable #{index + 1}</h1>
      <div className={classes.viewGrid}>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>
            Type <span className={classes.required}>*</span>
          </label>
          <Select
            required
            defaultValue={
              createIndividualPayableParam.accountTypeId
                ? createIndividualPayableParam.accountTypeId
                : ''
            }
            onChange={e => {
              UpdateParam(e.target.value, 'accountTypeId', index);
            }}
          >
            <option disabled value="">
              -- Required --
            </option>
            <OptionList
              optionData={createPayableOptions?.accountTypeOptions}
              attributeID="accountTypeId"
              attributeName="type"
              attributeGroup="group"
            />
          </Select>
        </div>
        <div className={classes.attribute}>
          <label>
            Rate <span className={classes.required}>*</span>
          </label>
          <Input
            type="number"
            defaultValue={createIndividualPayableParam.rate}
            onChange={e => {
              UpdateParam(e.target.value, 'rate', index);
            }}
            //Preventing the user from adding negative numbers
            onKeyPress={e => {
              if (e.key === '-') {
                e.preventDefault();
              }
            }}
            min="0"
            step={0.01}
            name="rate"
          ></Input>
        </div>
        <div className={classes.attribute}>
          <label>
            Quantity <span className={classes.required}>*</span>
          </label>
          <Input
            type="number"
            defaultValue={createIndividualPayableParam.quantity}
            onChange={e => {
              UpdateParam(e.target.value, 'quantity', index);
            }}
            //Preventing the user from adding negative numbers
            onKeyPress={e => {
              if (e.key === '-') {
                e.preventDefault();
              }
            }}
            min="0"
            step={0.01}
            name="quantity"
          ></Input>
        </div>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>Note</label>
          <TextArea
            defaultValue={createIndividualPayableParam.note}
            onChange={e => {
              UpdateParam(e.target.value, 'note', index);
            }}
          ></TextArea>
        </div>
      </div>
    </React.Fragment>
  );
}

export default StopList;
