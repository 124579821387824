import React, { useEffect, useState } from 'react';
import BBITable from '../../helpers/bBITable/BBITable';
import { MdOutlineRefresh } from 'react-icons/md';
import Get from '../../api/internal/Get';
import Header from '../../layouts/Header';
import FilterData from '../../helpers/filter/FilterData';
import Filter from '../../helpers/filter/Filter';
import View from '../../helpers/slab/View';
import ViewTruck from '../../features/views/assets/truck/Truck';
import ViewTrailer from '../../features/views/assets/trailer/Trailer';
import ViewVendor from '../../features/views/vendor/Vendor';
import CreateMaintenanceLog from '../../features/creates/maintenance-log/MaintenanceLog';
import ViewMaintenanceLog from '../../features/views/assets/maintenanceLog/MaintenanceLog';
import Create from '../../helpers/panel/Create';
import { FaWrench } from 'react-icons/fa';

function MaintenanceLogs({ toggleBoardRefresh }) {
  const [filterValue, setFilterValue] = useState('');
  const [maintenanceLogBoard, setMaintenanceLogBoard] = useState<
    Array<MaintenanceLogBoard>
  >([]);

  useEffect(() => {
    GetMaintenanceLogBoard();
  }, [toggleBoardRefresh]);

  function GetMaintenanceLogBoard() {
    Get(`/Asset/GetMaintenanceLogBoard`).then(response => {
      if (response) {
        setMaintenanceLogBoard(response.data);
      }
    });
  }
  const formatCurrency = currency => {
    if (isNaN(currency) || currency === null || currency === undefined) {
      return '';
    }
    return currency.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  };
  const formattedMaintenanceLogBoard = maintenanceLogBoard?.map(
    maintenanceLog => ({
      ...maintenanceLog,
      laborCost: formatCurrency(maintenanceLog.laborCost),
      tiresCost: formatCurrency(maintenanceLog.tiresCost),
      partsCost: formatCurrency(maintenanceLog.partsCost),
      roadsideCharges: formatCurrency(maintenanceLog.roadsideCharges),
      taxesCost: formatCurrency(maintenanceLog.taxesCost),
      miscCharges: formatCurrency(maintenanceLog.miscCharges),
      totalCost: formatCurrency(maintenanceLog.totalCost),
      billingBack: maintenanceLog.billingBack ? 'Yes' : 'No',
    }),
  );

  const columnConfig = [
    {
      key: '1',
      attribute: 'invoiceNumber',
      attributeprimaryhidden: 'maintenanceLogId',
      header: 'Invoice #',
      onClick: e =>
        View(
          <ViewMaintenanceLog
            maintenanceLogId={e.target.dataset.attributeprimaryhidden}
            invoiceNumber={e.target.innerText}
          />,
        ),
    },
    {
      key: '2',
      attribute: 'vendorName',
      attributeprimaryhidden: 'vendorId',
      header: 'Vendor',
      onClick: e =>
        View(
          <ViewVendor
            vendorName={e.target.innerText}
            vendorId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '3',
      attribute: 'description',
      header: 'Description',
    },
    {
      key: '4',
      attribute: 'unitType',
      header: 'Unit Type',
    },
    {
      key: '5',
      attribute: 'unitName',
      attributeprimaryhidden: 'assetId',
      attributetertiaryhidden: 'unitType',
      header: 'Unit #',
      onClick: e =>
        e.target.dataset.attributetertiaryhidden === 'Truck'
          ? View(
              <ViewTruck
                truckName={e.target.innerText}
                assetId={e.target.dataset.attributeprimaryhidden}
              />,
            )
          : View(
              <ViewTrailer
                trailerName={e.target.innerText}
                assetId={e.target.dataset.attributeprimaryhidden}
              />,
            ),
    },
    {
      key: '6',
      attribute: 'ownedOrLeased',
      header: 'Ownership',
    },
    {
      key: '7',
      attribute: 'billingBack',
      header: 'Billing Back',
    },
    {
      key: '8',
      attribute: 'laborCost',
      header: 'Labor',
      dataType: 'currency',
    },
    {
      key: '9',
      attribute: 'tiresCost',
      header: 'Tires',
      dataType: 'currency',
    },
    {
      key: '10',
      attribute: 'partsCost',
      header: 'Parts',
      dataType: 'currency',
    },
    {
      key: '11',
      attribute: 'roadsideCharges',
      header: 'Roadside',
      dataType: 'currency',
    },
    {
      key: '12',
      attribute: 'taxesCost',
      header: 'Taxes',
      dataType: 'currency',
    },
    {
      key: '13',
      attribute: 'miscCharges',
      header: 'Misc.',
      dataType: 'currency',
    },
    {
      key: '14',
      attribute: 'totalCost',
      header: 'Total Cost',
      dataType: 'currency',
    },
    {
      key: '15',
      attribute: 'formattedBillDate',
      header: 'Bill Date',
    },
  ];

  const tableData = FilterData(formattedMaintenanceLogBoard, filterValue);

  return (
    <>
      <h1>Maintenance Logs</h1>
      <Header>
        <div>
          <button
            onClick={() =>
              Create(
                <CreateMaintenanceLog GetLogBoard={GetMaintenanceLogBoard} />,
              )
            }
          >
            <FaWrench />
            <span>Create Log</span>
          </button>
          <button onClick={GetMaintenanceLogBoard}>
            <MdOutlineRefresh />
            <span>Refresh Board</span>
          </button>
        </div>
        <Filter
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          placeholder="Filter logs"
        />
      </Header>
      <BBITable data={tableData} columns={columnConfig} />
    </>
  );
}

export default MaintenanceLogs;
