import React, { useEffect, useState } from 'react';
import BBITable from '../helpers/bBITable/BBITable';
import Get from '../api/internal/Get';
import PostForm from '../api/internal/PostForm';
import DateTimeUTCFormatter from '../helpers/inputs/DateTimeUTCFormatter';
import { BsFileEarmarkArrowUp, BsTrash } from 'react-icons/bs';
import classes from '../assets/Global.module.css';
import Delete from '../api/internal/Delete';
import Header from '../layouts/Header';
import Button from '../assets/essentials/Button';
import Input from '../assets/essentials/Input';
import Select from '../assets/essentials/Select';
import { GeneralDocumentTypeOptions } from '../features/creates/file/DocumentTypeOptions';
import classesCustom from '../helpers/search/CustomSearch.module.css';

const Documents = () => {
  const [files, setFiles] = useState<Array<BlobFile>>();
  const [isUploadFormOpen, setIsUploadFormOpen] = useState<boolean>(false);
  const fileCategory = 'general-documents';

  useEffect(() => {
    GetGeneralDocuments();
  }, []);
  function GetGeneralDocuments() {
    Get(`/File/GetAllFiles/${fileCategory}`).then(response => {
      if (response) {
        setFiles(response.data);
      }
    });
  }

  function UploadFile(e) {
    const formData = new FormData(e.target);
    const appendix = {
      DocumentType: e.target.documentType.value,
    };
    PostForm(`/File/UploadFiles/${fileCategory}`, formData, appendix).then(
      response => {
        if (response) {
          GetGeneralDocuments();
        }
      },
    );
  }

  function DeleteFile(fileName: string) {
    const encodedFileName = encodeURIComponent(fileName);
    Delete(`/File/DeleteFile/${fileCategory}/${encodedFileName}`).then(
      response => {
        if (response) {
          GetGeneralDocuments();
        }
      },
    );
  }

  const fileObjects = files
    ?.map(file => ({
      ...file,
      name: (
        <a href={file.uri} target="_blank" rel="noreferrer">
          {file.name}
        </a>
      ),
      type: file.metadata.documentType,
      uploadedDate: DateTimeUTCFormatter(file.createdOn),
      updatedBy: file.metadata.uploadedBy,
      delete: (
        <BsTrash
          className={classes.clickable}
          onClick={() => {
            DeleteFile(file.name);
          }}
        />
      ),
    }))
    .sort((a, b) => a.name.props.children.localeCompare(b.name.props.children));

  const columnConfig = [
    {
      key: '1',
      attribute: 'name',
      header: 'Name',
    },
    {
      key: '2',
      attribute: 'type',
      header: 'Type',
    },
    {
      key: '3',
      attribute: 'uploadedDate',
      header: 'Uploaded Date',
    },
    {
      key: '4',
      attribute: 'updatedBy',
      header: 'Uploaded By',
    },
    {
      key: '5',
      attribute: 'delete',
      width: '3ch',
    },
  ];

  function SubmitForm(e) {
    e.preventDefault();
    UploadFile(e);
    setIsUploadFormOpen(false);
  }

  return (
    <>
      <h1>General Documents</h1>
      <Header>
        <button
          type="button"
          onClick={() => setIsUploadFormOpen(!isUploadFormOpen)}
        >
          <BsFileEarmarkArrowUp />
          <span>Upload File</span>
        </button>
      </Header>

      {isUploadFormOpen && (
        <div className={classesCustom.customSearch}>
          <form onSubmit={e => SubmitForm(e)}>
            <label htmlFor="documentType">
              File Type
              <Select
                name="documentType"
                defaultValue=""
                id="documentType"
                required
              >
                <option value="" disabled>
                  -- Required --
                </option>
                <GeneralDocumentTypeOptions />
              </Select>
            </label>
            <label htmlFor="file">
              Choose File
              <Input type="file" name="file" id="file" required />
            </label>
            <div className={classesCustom.submitHolder}>
              <Button type="submit" variant="good">
                Submit
              </Button>
              <Button
                variant="bad"
                onClick={() => setIsUploadFormOpen(!isUploadFormOpen)}
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      )}

      {files !== undefined && files.length !== 0 ? (
        <BBITable data={fileObjects} columns={columnConfig} />
      ) : (
        <div className={classes.marginTop05Rem}>No files have been uploaded.</div>
      )}
    </>
  );
};

export default Documents;
