import React from 'react';

export const TrailerTireDiagram = () => {
  return (
<svg
  width="139.39131mm"
  height="157.00255mm"
  viewBox="0 0 139.39131 157.00255"
  version="1.1"
  id="svg5"
  xmlns="http://www.w3.org/2000/svg"
>
  <defs id="defs2" />
  <rect
    style={{
      fill: 'none',
      fillOpacity: 1,
      stroke: '#000000',
      strokeWidth: 0.665,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeDasharray: 'none',
      strokeOpacity: 1,
    }}
    id="rect234"
    width="138.72632"
    height="156.33755"
    x="0.33250001"
    y="0.33250001"
  />
  <g
    id="g1924"
    style={{ strokeWidth: 0.888373 }}
    transform="matrix(1.156713,0,0,1.0954279,-68.431083,-144.24959)"
  >
    <g id="g1849" style={{ strokeWidth: 0.888373 }}>
      <g
        id="g1697-4-1"
        transform="translate(30.755192,147.81935)"
        style={{ strokeWidth: 0.888373 }}
      />
      <g
        id="g1697-3-6-6"
        transform="translate(-52.317058,147.85428)"
        style={{ strokeWidth: 0.888373 }}
      />
      <rect
        style={{
          fill: 'none',
          fillOpacity: 1,
          stroke: '#000000',
          strokeWidth: 0.590769,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        id="rect898-4-6"
        width="14.38972"
        height="34.863121"
        x="161.69682"
        y="173.57004"
        ry="3.0666456"
      />
      <rect
        style={{
          fill: 'none',
          fillOpacity: 1,
          stroke: '#000000',
          strokeWidth: 0.590769,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        id="rect898-0-3-9"
        width="14.38972"
        height="34.863121"
        x="146.0683"
        y="173.63989"
        ry="3.0666456"
      />
      <rect
        style={{
          fill: 'none',
          fillOpacity: 1,
          stroke: '#000000',
          strokeWidth: 0.590769,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        id="rect898-9-3-3"
        width="14.38972"
        height="34.863121"
        x="78.62458"
        y="173.60497"
        ry="3.0666456"
      />
      <rect
        style={{
          fill: 'none',
          fillOpacity: 1,
          stroke: '#000000',
          strokeWidth: 0.590769,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        id="rect898-0-5-6-4"
        width="14.38972"
        height="34.863121"
        x="62.996044"
        y="173.67482"
        ry="3.0666456"
      />
      <rect
        style={{
          fill: 'none',
          fillOpacity: 1,
          stroke: '#000000',
          strokeWidth: 0.444186,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        id="rect1825"
        width="53.011513"
        height="2.1482916"
        x="93.104408"
        y="190.19469"
        ry="1.0741458"
        rx="0"
      />
    </g>
  </g>
  <g
    id="g1924-9"
    style={{ strokeWidth: 0.888373 }}
    transform="matrix(1.156713,0,0,1.0954279,-68.284055,-100.61819)"
  >
    <g id="g1849-0" style={{ strokeWidth: 0.888373 }}>
      <g
        id="g1697-4-1-1"
        transform="translate(30.755192,147.81935)"
        style={{ strokeWidth: 0.888373 }}
      />
      <g
        id="g1697-3-6-6-2"
        transform="translate(-52.317058,147.85428)"
        style={{ strokeWidth: 0.888373 }}
      />
      <rect
        style={{
          fill: 'none',
          fillOpacity: 1,
          stroke: '#000000',
          strokeWidth: 0.590769,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        id="rect898-4-6-3"
        width="14.38972"
        height="34.863121"
        x="161.69682"
        y="173.57004"
        ry="3.0666456"
      />
      <rect
        style={{
          fill: 'none',
          fillOpacity: 1,
          stroke: '#000000',
          strokeWidth: 0.590769,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        id="rect898-0-3-9-1"
        width="14.38972"
        height="34.863121"
        x="146.0683"
        y="173.63989"
        ry="3.0666456"
      />
      <rect
        style={{
          fill: 'none',
          fillOpacity: 1,
          stroke: '#000000',
          strokeWidth: 0.590769,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        id="rect898-9-3-3-3"
        width="14.38972"
        height="34.863121"
        x="78.62458"
        y="173.60497"
        ry="3.0666456"
      />
      <rect
        style={{
          fill: 'none',
          fillOpacity: 1,
          stroke: '#000000',
          strokeWidth: 0.590769,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        id="rect898-0-5-6-4-5"
        width="14.38972"
        height="34.863121"
        x="62.996044"
        y="173.67482"
        ry="3.0666456"
      />
      <rect
        style={{
          fill: 'none',
          fillOpacity: 1,
          stroke: '#000000',
          strokeWidth: 0.444186,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
          strokeDasharray: 'none',
          strokeOpacity: 1,
        }}
        id="rect1825-8"
        width="52.944683"
        height="2.1482916"
        x="93.104401"
        y="190.19469"
        ry="1.0741458"
        rx="0"
      />
    </g>
  </g>
  <g
    id="g1697-4-1-2"
    transform="matrix(1.156713,0,0,1.0954279,-33.097778,56.38659)"
    style={{ strokeWidth: 0.888373 }}
  />
  <g
    id="g1697-3-6-6-7"
    transform="matrix(1.156713,0,0,1.0954279,-129.18853,56.42486)"
    style={{ strokeWidth: 0.888373 }}
  />
</svg>
  );
};

export default TrailerTireDiagram;
