import React, { useState } from 'react';
import { BsFileEarmarkArrowUp } from 'react-icons/bs';

import classes from './Receipt.module.css';
import classesGlobal from '../../../assets/Global.module.css';
import Button from '../../../assets/essentials/Button';
import Select from '../../../assets/essentials/Select';
import Input from '../../../assets/essentials/Input';
import Form from '../../../assets/essentials/Form';
import * as XLSX from 'xlsx';

function Receipt({ receiptEntryList, setReceiptEntryList }) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  function SubmitForm(e) {
    e.preventDefault();
    (document.getElementById('gasStation') as HTMLInputElement).value ===
    'Pilot'
      ? ConvertPilotReceipt()
      : ConvertLovesReceipt();
    setIsOpen(false);
  }

  function ConvertPilotReceipt() {
    const currentYear = new Date().getFullYear();
    const file = (document.getElementById('file') as HTMLInputElement).files[0];

    file.arrayBuffer().then(result => {
      let data = new Uint8Array(result);
      let workbook = XLSX.read(data, { type: 'array' });
      let sheetName = workbook.SheetNames[0];
      let worksheet = workbook.Sheets[sheetName];

      const range = XLSX.utils.decode_range(worksheet['!ref']);
      const receiptInfo = [];

      for (let R = 3; R <= range.e.r - 1; R++) {
        const row = {};
        for (let C = range.s.c; C <= range.e.c; C++) {
          const cellAddress = { r: R, c: C };
          const cellRef = XLSX.utils.encode_cell(cellAddress);
          const cellValue = worksheet[cellRef]
            ? worksheet[cellRef].v
            : undefined;
          const columnName = XLSX.utils.encode_col(C);

          row[columnName] = cellValue;
        }
        receiptInfo.push(row);
      }

      let receipts = receiptInfo.map(receipt => ({
        gasStation: 'Pilot',
        cardNumber: receipt.A,
        city: receipt.D.replace(/\s+.*$/, '').trim(),
        state: receipt.D.split(/\s+/).pop(),
        transactionNumber: receipt.E,
        quantity: receipt.K,
        rate: receipt.L,
        transactionDate: receipt.H + '/' + currentYear,
        discount: 0,
        total: receipt.T,
        //delete: <BsTrash onClick={e => DeleteEntry(e)} />
      }));

      const allReceipts = receipts.concat(receiptEntryList);
      setReceiptEntryList(allReceipts);
    });
  }

  function ConvertLovesReceipt() {
    const file = (document.getElementById('file') as HTMLInputElement).files[0];

    file.arrayBuffer().then(result => {
      let data = new Uint8Array(result);
      let workbook = XLSX.read(data, { type: 'array', cellDates: false });
      let sheetName = workbook.SheetNames[0];
      let worksheet = workbook.Sheets[sheetName];

      let json = (XLSX.utils.sheet_to_json as any)(worksheet, {
        raw: false,
        cellText: false,
      });

      let receipts = json.map((receipt: any) => ({
        gasStation: 'Loves',
        cardNumber: receipt.A,
        city: receipt.City,
        state: receipt.State,
        transactionNumber: receipt.Transaction,
        quantity: receipt.Qty,
        rate: receipt.Price,
        transactionDate: receipt['Store Date'],
        discount: receipt.Discount,
        total: receipt['Adjusted SubTotal'],
        //delete: <BsTrash onClick={() => DeleteEntry(receipt.Transaction)}/>
      }));

      const allReceipts = receipts.concat(receiptEntryList);
      setReceiptEntryList(allReceipts);
    });
  }
  return (
    <>
      <div className={classes.btnHolder}>
        <button type="button" onClick={() => setIsOpen(!isOpen)}>
          <BsFileEarmarkArrowUp />
          <span>Upload Receipt</span>
        </button>
      </div>
      <div className={classes.createReceiptHolder}>
        {isOpen && (
          <Form onSubmit={e => SubmitForm(e)}>
            <div className={classesGlobal.attribute}>
              <label>Gas Station</label>
              <Select name="gasStation" id="gasStation" required>
                <option value="">Choose an option</option>
                <option value="Pilot">Pilot</option>
                <option value="Loves">Loves</option>
              </Select>
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>Choose File</label>
              <Input type="file" name="file" id="file" required />
            </div>
            <div>
              <Button type="submit" variant="good" disabled={true}>
                Submit
              </Button>
            </div>
            <div>
              <Button onClick={() => setIsOpen(!isOpen)}>Cancel</Button>
            </div>
          </Form>
        )}
      </div>
    </>
  );
}
export default Receipt;
