import React, { useState, useEffect } from 'react';

import BBITable from '../../../helpers/bBITable/BBITable';
import classesGlobal from '../../../assets/Global.module.css';
import DateOnlyFormatter from '../../../helpers/inputs/DateOnlyFormatter';
import FilterData from '../../../helpers/filter/FilterData';
import Get from '../../../api/internal/Get';
import View from '../../../helpers/slab/View';
import ViewDriver from '../../../features/views/users/driver/Driver';
import ViewLoad from '../../../features/views/load/Load';
import ViewPayroll from '../../../features/views/payroll/Payroll';
import PostBody from '../../../api/internal/PostBody';
import { useOutletContext } from 'react-router-dom';
import Header from '../../../layouts/Header';
import { MdDownload } from 'react-icons/md';

function OutstandingPayrollBoard({
  toggleBoardRefresh,
}: {
  toggleBoardRefresh: boolean;
}) {
  const [payrollBoard, setPayrollBoard] = useState<Array<PayrollBoard>>([]);
  const filterValue = useOutletContext();

  useEffect(() => {
    Get(`/Accounting/GetPayrollBoard/`).then(response => {
      if (response) {
        setPayrollBoard(response.data);
      }
    });
  }, [toggleBoardRefresh]);

  function DownloadPayrollReportCSV(payrolls) {
    let payrollIdsToDownload = [];
    payrollIdsToDownload = payrolls?.map(x => x.assetAssignmentId);
    if (payrollIdsToDownload.length > 0) {
      let testArray = { AssetAssignmentIdList: [...payrollIdsToDownload] };
      PostBody('Accounting/DownloadPayrollReportCSV', testArray).then(response => {
        if (response) {
          const blob = new Blob([response.data], { type: 'text/csv' });
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download =
            new Date().toISOString().split('T')[0].replace(/[-]/g, '') +
            '_Payroll Report.csv';
          a.click();
          URL.revokeObjectURL(url);
        }
      });
    }
  }

  function FormatPayrolls(payrolls) {
    return payrolls.map(payroll => ({
      ...payroll,
      workCompletedDate: DateOnlyFormatter(
        payroll.workCompletedDate,
      ),
      loadMileage: payroll.loadMileage.toLocaleString(),
      total: payroll.total.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      remainingBalance: payroll.remainingBalance.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    }));
  };

  let tableData = FormatPayrolls(FilterData(payrollBoard, filterValue));

  let columnConfig = [
    {
      key: '1',
      attribute: 'loadId',
      header: 'Load #',
      onClick: e => View(<ViewLoad loadId={e.target.innerText} />),
      dataType: 'number',
    },
    {
      key: '2',
      attribute: 'reference',
      attributeprimaryhidden: 'loadId',
      header: 'Reference',
      onClick: e => 
        View(<ViewLoad loadId={e.target.dataset.attributeprimaryhidden} />),
    },
    {
      key: '3',
      attribute: 'paymentStatus',
      attributeprimaryhidden: 'assetAssignmentId',
      header: 'Status',
      onClick: e =>
        View(
          <ViewPayroll
            assetAssignmentId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
      dataType: 'string',
    },
    {
      key: '4',
      attribute: 'driverName',
      attributeprimaryhidden: 'employeeId',
      header: 'Driver',
      onClick: e =>
        View(
          <ViewDriver
            driverName={e.target.innerText}
            employeeId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '5',
      attribute: 'workCompletedDate',
      header: 'Work Completed Date',
      dataType: 'date',
    },
    {
      key: '6',
      attribute: 'loadMileage',
      header: 'Load Mileage',
      dataType: 'formattedNumber',
    },
    {
      key: '7',
      attribute: 'total',
      header: 'Total',
      dataType: 'currency',
    },
    {
      key: '8',
      attribute: 'remainingBalance',
      header: 'Remaining Balance',
      dataType: 'currency',
    },
  ];

  const PayrollTable = ({ payrolls, columnConfig }) => (
    <BBITable data={payrolls} columns={columnConfig} />
  );

  return (
    <>
      <Header>
        <div>
          <button onClick={() => DownloadPayrollReportCSV(tableData)}>
            <MdDownload />
            <span>Download</span>
          </button>
        </div>
      </Header>
      <PayrollTable payrolls={tableData} columnConfig={columnConfig} />
    </>
  );
}
export default OutstandingPayrollBoard;
