import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { MdDownload } from 'react-icons/md';

import BBITable from '../../helpers/bBITable/BBITable';
import DateOnlyFormatter from '../../helpers/inputs/DateOnlyFormatter';
import Filter from '../../helpers/filter/Filter';
import FilterData from '../../helpers/filter/FilterData';
import Get from '../../api/internal/Get';
import Header from '../../layouts/Header';
import View from '../../helpers/slab/View';
import ViewCashFlow from '../../features/views/cashFlow/CashFlow';
import ViewLoad from '../../features/views/load/Load';
import classesGlobal from '../../assets/Global.module.css';

function FixedAsset({ toggleBoardRefresh }) {
  const [filterValue, setFilterValue] = useState('');
  const [fixedAssetBoard, setFixedAssetBoard] = useState<
    Array<FixedAssetBoard>
  >([]);
  // const [isOpen, setIsOpen] = useState(false);

  const csvFileName =
    new Date().toISOString().split('T')[0].replace(/[-]/g, '') +
    '_Fixed Asset Report.csv';

  useEffect(() => {
    Get(`/Accounting/GetFixedAssetBoard`).then(response => {
      if (response) {
        setFixedAssetBoard(response.data);
      }
    });
  }, [toggleBoardRefresh]);

  const formattedFixedAssetBoard = fixedAssetBoard.map(fixedAsset => ({
    ...fixedAsset,
    placeInServiceDate: DateOnlyFormatter(fixedAsset.placeInServiceDate),
    purchasePrice: fixedAsset.purchasePrice?.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }),
  }));

  let columnConfig = [
    {
      key: '1',
      attribute: 'type',
      header: 'Type',
    },
    {
      key: '2',
      attribute: 'assetId',
      header: 'Asset #',
      dataType: 'number',
    },
    {
      key: '3',
      attribute: 'assetName',
      header: 'Asset Name',
    },
    {
      key: '4',
      attribute: 'placeInServiceDate',
      header: 'PlaceIn Service Date',
      dataType: 'date',
    },
    {
      key: '5',
      attribute: 'purchasePrice',
      header: 'Purchase Price',
      dataType: 'currency',
    },
  ];

  let tableData = FilterData(formattedFixedAssetBoard, filterValue);

  return (
    <>
      <h1>Fixed Asset</h1>
      <Header>
        <div>
          <button>
            <CSVLink
              data={formattedFixedAssetBoard}
              headers={[
                { label: 'Type', key: 'type' },
                { label: 'Asset #', key: 'assetId' },
                { label: 'Asset Name', key: 'assetName' },
                { label: 'PlaceInServiceDate', key: 'placeInServiceDate' },
                { label: 'Purchase Price', key: 'purchasePrice' },
              ]}
              filename={csvFileName}
            >
              <MdDownload />
              <span>Download</span>
            </CSVLink>
          </button>
        </div>
        <div>
          <Filter
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            placeholder="Filter fixed assets"
          />
        </div>
      </Header>
      <BBITable data={tableData} columns={columnConfig} />
    </>
  );
}

export default FixedAsset;
