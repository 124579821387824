import React from 'react';

import classes from '../../../assets/Global.module.css';
import Input from '../../../assets/essentials/Input';
import Select from '../../../assets/essentials/Select';
import OptionList from '../../../helpers/options/OptionList';
import TextArea from '../../../assets/essentials/TextArea';
import Phone from '../../../helpers/inputs/Phone';

function Details({
  createDriverOptions,
  createDriverParam,
  setCreateDriverParam,
}: {
  createDriverOptions: CreateDriverOptions;
  createDriverParam: CreateDriverParam;
  setCreateDriverParam: Function;
}) {
  const { truckOptions, trailerOptions, branchOptions } = createDriverOptions;

  function UpdateCreateDriverParam(newValue, attributeName) {
    if (newValue?.toString().trim().length !== 0) {
      setCreateDriverParam(prevState => ({
        ...prevState,
        [attributeName]: newValue,
      }));
    }
  }

  const selectedBranch = branchOptions?.find(
    branch => branch.branchId === Number(createDriverParam?.branchId),
  );

  const selectedDispatch = selectedBranch?.dispatchOptions?.find(
    dispatch =>
      dispatch.dispatchId === Number(createDriverParam?.preferredDispatchId),
  );

  return (
    <form className={classes.viewGrid}>
      <div className={classes.attribute}>
        <label>
          First Name <span className={classes.required}>*</span>
        </label>
        <Input
          defaultValue={createDriverParam?.firstName}
          name="firstName"
          onChange={e => UpdateCreateDriverParam(e.target.value, 'firstName')}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>
          Last Name <span className={classes.required}>*</span>
        </label>
        <Input
          defaultValue={createDriverParam?.lastName}
          name="lastName"
          onChange={e => UpdateCreateDriverParam(e.target.value, 'lastName')}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>Home Phone</label>
        <Phone
          defaultValue={createDriverParam?.phone}
          name="phone"
          onChange={e => UpdateCreateDriverParam(e.target.value, 'phone')}
          expectsEventObject={true}
        />
      </div>
      <div className={classes.attribute}>
        <label>
          Mobile <span className={classes.required}>*</span>
        </label>
        <Phone
          defaultValue={createDriverParam?.mobile}
          name="mobile"
          onChange={e => UpdateCreateDriverParam(e.target.value, 'mobile')}
          expectsEventObject={true}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Email</label>
        <Input
          defaultValue={createDriverParam?.email}
          name="email"
          onChange={e => UpdateCreateDriverParam(e.target.value, 'email')}
        />
      </div>
      <div className={classes.attribute}>
        <label>
          Hire Date <span className={classes.required}>*</span>
        </label>
        <Input
          defaultValue={createDriverParam?.hireDate}
          name="hireDate"
          onChange={e => UpdateCreateDriverParam(e.target.value, 'hireDate')}
          type="date"
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>
          Branch <span className={classes.required}>*</span>
        </label>
        <Select
          defaultValue={createDriverParam?.branchId ?? ''}
          name="branchId"
          onChange={e => {
            UpdateCreateDriverParam(e.target.value, 'branchId');
            UpdateCreateDriverParam(null, 'preferredDispatchId');
          }}
        >
          <option disabled value="">
            -- Required --
          </option>
          <OptionList
            optionData={createDriverOptions?.branchOptions}
            attributeID="branchId"
            attributeName="branchName"
          />
        </Select>
      </div>
      <div className={classes.attribute}>
        <label>Dispatch</label>
        <Select
          key={createDriverParam?.preferredDispatchId}
          name="preferredDispatchId"
          defaultValue={createDriverParam?.preferredDispatchId}
          onChange={e =>
            UpdateCreateDriverParam(e.target.value, 'preferredDispatchId')
          }
        >
          <option value="" />
          <OptionList
            optionData={selectedBranch?.dispatchOptions}
            attributeID="dispatchId"
            attributeName="dispatchName"
          ></OptionList>
        </Select>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Dispatch Phone</label>
        <Input
          disabled
          name="dispatchPhone"
          value={selectedDispatch?.dispatchPhone}
        />
      </div>
      <div className={classes.attribute}>
        <label>Preferred Truck</label>
        <Select
          defaultValue={createDriverParam?.preferredTruckId}
          name="preferredTruckId"
          onChange={e =>
            UpdateCreateDriverParam(e.target.value, 'preferredTruckId')
          }
        >
          <option value="">No preference</option>
          <OptionList
            optionData={truckOptions}
            attributeID="truckId"
            attributeName="description"
          />
        </Select>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Preferred Trailer</label>
        <Select
          defaultValue={createDriverParam?.preferredTrailerId}
          name="preferredTrailerId"
          onChange={e =>
            UpdateCreateDriverParam(e.target.value, 'preferredTrailerId')
          }
        >
          <option value="">No preference</option>
          <OptionList
            optionData={trailerOptions}
            attributeID="trailerId"
            attributeName="description"
          />
        </Select>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>
          <Input
            name="isIndependentContractor"
            onChange={e =>
              UpdateCreateDriverParam(
                e.target.checked,
                'isIndependentContractor',
              )
            }
            type="checkbox"
            defaultChecked={createDriverParam?.isIndependentContractor}
          />
          Independent Contractor
        </label>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>Note</label>
        <TextArea
          defaultValue={createDriverParam?.note}
          rows={4}
          name="note"
          onChange={e => UpdateCreateDriverParam(e.target.value, 'note')}
        />
      </div>
    </form>
  );
}

export default Details;
