import React from 'react';

import classes from '../../../assets/Global.module.css';
import Input from '../../../assets/essentials/Input';
import TextArea from '../../../assets/essentials/TextArea';
import Select from '../../../assets/essentials/Select';
import StatesAndProvinces from '../../../helpers/options/StatesAndProvinces';

function CreateBillingPage({
  createParam,
  UpdateParam,
}: {
  createParam: CreateVendorParam;
  UpdateParam: Function;
}) {
  return (
    <div className={classes.viewGrid}>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>
          Billing Entity Name <span className={classes.required}>*</span>
        </label>
        <Input
          defaultValue={createParam.billingEntity}
          onChange={e => UpdateParam(e.target.value, 'billingEntity')}
          type="text"
        />
      </div>
      <div className={classes.attribute}>
        <label>
          Address Line One <span className={classes.required}>*</span>
        </label>
        <Input
          defaultValue={createParam.billingAddressLineOne}
          onChange={e => UpdateParam(e.target.value, 'billingAddressLineOne')}
          type="text"
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Address Line Two</label>
        <Input
          defaultValue={createParam.billingAddressLineTwo}
          onChange={e => UpdateParam(e.target.value, 'billingAddressLineTwo')}
          type="text"
        />
      </div>
      <div className={classes.attribute}>
        <label>
          City <span className={classes.required}>*</span>
        </label>
        <Input
          defaultValue={createParam.billingCity}
          onChange={e => UpdateParam(e.target.value, 'billingCity')}
          type="text"
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>
          State/Province <span className={classes.required}>*</span>
        </label>
        <Select
          defaultValue={createParam.billingState || ''}
          onChange={e => UpdateParam(e.target.value, 'billingState')}
        >
          <option value="" disabled>
            -- Required --
          </option>
          <StatesAndProvinces />
        </Select>
      </div>
      <div className={classes.attribute}>
        <label>
          Zip <span className={classes.required}>*</span>
        </label>
        <Input
          defaultValue={createParam.billingZip}
          onChange={e => UpdateParam(e.target.value, 'billingZip')}
          type="text"
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>
          Country <span className={classes.required}>*</span>
        </label>
        <Select
          defaultValue={
            createParam.billingCountry ? createParam.billingCountry : ''
          }
          key={createParam.billingCountry}
          onChange={e => UpdateParam(e.target.value, 'billingCountry')}
        >
          <option value="" disabled>
            -- Required --
          </option>
          <option value="US">US</option>
          <option value="CA">Canada</option>
        </Select>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>Special Billing Instructions</label>
        <TextArea
          defaultValue={createParam.billingNote}
          onChange={e => UpdateParam(e.target.value, 'billingNote')}
          type="text"
        />
      </div>
    </div>
  );
}

export default CreateBillingPage;
