import React, { useEffect } from 'react';
import TrimbleMaps, {
  GeoJSONSourceRaw,
  LngLat,
} from '@trimblemaps/trimblemaps-js';
import DateTimeUTCFormatter from './inputs/DateTimeUTCFormatter';

export default function TrimbleMap({
  loadCoordinates,
  assetCoordinates,
}: {
  loadCoordinates: LoadCoordinates;
  assetCoordinates: AssetCoordinates[];
}) {
  /**
   * TrimbleMap Component
   *
   * Renders a Trimble Map using the TrimbleMaps API and displays a route with stops.
   * https://developer.trimblemaps.com/maps-sdk/guide/overview/
   *
   * For styling purposes simply target the returned div id in the parent css module.
   *
   * @component
   * @param {LoadCoordinates} loadCoordinates - A list of stopCoordinates and checkCallCoordinates.
   * @returns {JSX.Element} - The rendered TrimbleMap component.
   */

  useEffect(() => {
    const map = RenderMap(trimbleStops);

    return () => {
      map.remove();
    };
  });

  const { stopCoordinates, checkCallCoordinates } = loadCoordinates;
  TrimbleMaps.APIKey = process.env.REACT_APP_TRIMBLE_MAPS_API_KEY;
  const HEMPHILL_EASTANOLLE_GA_COORDINATES = {
    longitude: -83.2554,
    latitude: 34.5204,
  };

  const trimbleStops = stopCoordinates?.map(
    stop => new TrimbleMaps.LngLat(stop.stopLongitude, stop.stopLatitude),
  );

  function RenderMap(trimbleStops: Array<LngLat>) {
    const map = new TrimbleMaps.Map({
      container: 'map',
      style: TrimbleMaps.Common.Style.TRANSPORTATION,
      center: new TrimbleMaps.LngLat(
        HEMPHILL_EASTANOLLE_GA_COORDINATES.longitude,
        HEMPHILL_EASTANOLLE_GA_COORDINATES.latitude,
      ),
      zoom: 8,
    });

    function CreateCheckCallsGeoJSON(coordinatesArray) {
      const features = coordinatesArray?.map((coordinates, index) => ({
        type: 'Feature',
        properties: {
          Site: index + 1,
        },
        geometry: {
          type: 'Point',
          coordinates,
        },
      }));

      return {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features,
        },
      };
    }

    // Trimble wants an array, not an object, with longitude first then latitude
    const checkCallCoordinatesArray = checkCallCoordinates?.map(
      checkCallCoordinatesObject => {
        const [latitude, longitude] = Object.values(checkCallCoordinatesObject);
        return [longitude, latitude];
      },
    );

    const truckCoordinatesArray = assetCoordinates?.map(
      truckCoordinatesObject => {
        const [latitude, longitude] = Object.values(truckCoordinatesObject);
        return [longitude, latitude];
      },
    );

    const myRoute = new TrimbleMaps.Route({
      routeId: 'myRoute',
      showArrows: true,
      stops: trimbleStops,
      bordersOpen: false,
      stopIcon: {
        size: 0.2,
        opacity: 1,
        textOpacity: 0,
      },
      arrowOptions: {
        size: 0.75,
      },
    });

    function CreateCoordinateGeoJSON(coordinatesArray, popupData) {
      const features = coordinatesArray?.map((coordinates, index) => ({
        type: 'Feature',
        properties: {
          Site: index + 1,
          date: popupData[index].date,
          estimatedAddress: popupData[index].estimatedAddress,
          assetName: popupData[index].assetName,
        },
        geometry: {
          type: 'Point',
          coordinates,
        },
      }));

      return {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features,
        },
      };
    }

    const truckPopupData = assetCoordinates
      ?.filter(coordinate => coordinate.assetType === 'Truck')
      .map(coordinate => ({
        assetName: coordinate.assetName,
        date: DateTimeUTCFormatter(coordinate.locationDate),
        estimatedAddress: coordinate.estimatedAddress,
      }));

    const truckGeoJSON = CreateCoordinateGeoJSON(
      truckCoordinatesArray,
      truckPopupData,
    );
    const checkCallGeoJSON = CreateCheckCallsGeoJSON(checkCallCoordinatesArray);

    map.on('load', function () {
      myRoute.addTo(map);
      map.addSource('checkCalls', checkCallGeoJSON as GeoJSONSourceRaw);
      map.addLayer({
        id: 'checkCalls',
        type: 'symbol',
        source: 'checkCalls',
        layout: {
          'icon-image': 'flag-fill-orange',
          'icon-allow-overlap': true,
        },
      });

      map.on('mouseenter', 'truck', function () {
        map.getCanvas().style.cursor = 'pointer';
      });
      map.on('mouseleave', 'truck', function () {
        map.getCanvas().style.cursor = '';
      });

      map.addSource('truck', truckGeoJSON as GeoJSONSourceRaw);
      map.addLayer({
        id: 'truck',
        type: 'symbol',
        source: 'truck',
        layout: {
          'icon-image': 'truck-fill-blue',
          'icon-allow-overlap': true,
        },
      });
    });

    const popupOptions = {
      closeButton: false,
    };

    const popup = new TrimbleMaps.Popup(popupOptions);

    const popupStyling = `<style>
                              .trimblemaps-popup {
                                will-change: transform;
                                pointer-events: none;
                              }

                              .trimblemaps-popup-content {
                                position: relative;
                                background-color: white;
                                border-radius: .375rem;
                                box-shadow: 0 .063rem .125rem rgba(0, 0, 0, 0.1);
                                padding: .938rem;
                                pointer-events: auto;
                                max-width: 18.75rem;
                                display: flex;
                                flex-direction: column;
                              }

                          .trimblemaps-popup-content p {
                                font-size: .75rem;
                                color: black;
                                margin: 0rem;
                                text-align: left;
                              }

                            </style>`;

    map.on('click', 'truck', function (e) {
      const location = e.lngLat;
      const data = e.features[0].properties;

      popup
        .setLngLat(location)
        .setHTML(
          `${popupStyling}<p><strong>Truck: </strong>${data.assetName}</p><p><strong>Date: </strong>${data.date}</p><p><strong>Location: </strong>${data.estimatedAddress}</p>`,
        );
      popup.addTo(map);
    });

    return map;
  }
  return <div id="map" />;
}
