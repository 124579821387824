import React, { useState } from 'react';
import classes from './Itinerary.module.css';
import classesGlobal from '../../../../assets/Global.module.css';
import Stop from './Stop';
import CreateStop from '../../../assign/Stop';
import TrimbleMap from '../../../../helpers/TrimbleMap';
import Get from '../../../../api/internal/Get';
import Button from '../../../../assets/essentials/Button';
import BBITable from '../../../../helpers/bBITable/BBITable';
import DateTimeUTCFormatter from '../../../../helpers/inputs/DateTimeUTCFormatter';
import CreateNote from '../../../creates/notes/Load';
import PutBody from '../../../../api/internal/PutBody';
import TransportationCaller from '../../../../api/internal/TransportationCaller';
import PostBody from '../../../../api/internal/PostBody';
import Delete from '../../../../api/internal/Delete';

function Itinerary({
  data,
  loadId,
  RefreshPage,
  GetLoad,
}: {
  data: LoadItinerary;
  loadId: number;
  RefreshPage: Function;
  GetLoad: Function;
}) {
  const [displayMap, setDisplayMap] = useState<boolean>(false);
  const [loadCoordinates, setLoadCoordinates] = useState<LoadCoordinates>();
  const [assetCoordinates, setAssetCoordinates] = useState<AssetCoordinates[]>(
    [],
  );

  function GetStopCoordinates() {
    Get(`Load/GetLoadCoordinates/${loadId}`).then(response => {
      if (response) {
        setLoadCoordinates(response.data);
      }
    });
  }

  function HandleStopOrderChange(event, stopId) {
    const payload = {
      direction: event.target.value,
      stopId: stopId,
    };
    PutBody(`/Load/UpdateStopOrder`, payload).then(() => {
      RefreshPage('Itinerary', ['Audit', 'Summary', 'Financials']);
      GetLoad();
    });
  }

  function GetLatestTruckLocation() {
    Get(`Asset/GetLatestTruckLocation/${loadId}`).then(response => {
      if (response) {
        setAssetCoordinates(response.data);
      }
    });
  }

  const LoadStats = () => {
    return (
      <div className={classesGlobal.viewGrid}>
        <div className={classesGlobal.attribute}>
          <h3>Total Miles: {data.totalMiles?.toLocaleString()}</h3>
        </div>
        <div className={classesGlobal.attribute}>
          <h3>Remaining Miles: {data.remainingMiles?.toLocaleString()}</h3>
        </div>
      </div>
    );
  };

  const StopList = ({
    stops,
    timeZoneOptions,
    customerAssignmentOptions,
    RefreshPage,
    GetLoad,
    HandleStopOrderChange,
  }) => {
    if (!stops || stops.length === 0) return null;

    return stops.map((stop, index) => (
      <Stop
        key={stop.stopId}
        stop={stop}
        timeZoneOptions={timeZoneOptions}
        customerAssignmentOptions={customerAssignmentOptions}
        stopTypeOptions={data?.stopTypeOptions}
        index={index}
        RefreshPage={RefreshPage}
        GetLoad={GetLoad}
        numberOfStops={stops.length}
        HandleStopOrderChange={HandleStopOrderChange}
      />
    ));
  };

  const ReOrderStopsButton = () => {
    function UpdateStopOrderByAppointment(loadId) {
      TransportationCaller.put(
        `/Load/UpdateStopOrderByAppointment/${loadId}`,
      ).then(() => {
        RefreshPage('Itinerary', ['Audit', 'Summary', 'Financials']);
        GetLoad();
      });
    }
    return (
      <Button
        onClick={() => UpdateStopOrderByAppointment(loadId)}
        variant="good"
      >
        Reorder Stops by Appointment
      </Button>
    );
  };

  const PostRouteButton = ({ isLoading, onClick }) => {
    return (
      <Button
        disabled={isLoading}
        onClick={onClick}
        variant="good"
        isLoading={isLoading}
      >
        Post Samsara Route
      </Button>
    );
  };

  const UpdateRouteButton = ({ isUpdating, isDeleting, onClick }) => {
    return (
      <Button
        disabled={isUpdating || isDeleting}
        onClick={onClick}
        variant="good"
        isLoading={isUpdating}
      >
        Update Samsara Route
      </Button>
    );
  };

  const DeleteRouteButton = ({ isDeleting, isUpdating, onClick }) => {
    return (
      <Button
        variant="bad"
        onClick={onClick}
        disabled={isDeleting || isUpdating}
        isLoading={isDeleting}
      >
        Delete Route
      </Button>
    );
  };

  const RouteButtons = ({ samsaraRouteId }) => {
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isPosting, setIsPosting] = useState(false);

    function UpdateRoute() {
      setIsUpdating(true);
      PutBody(`Load/UpdateSamsaraRoute/`, {
        samsaraRouteId: samsaraRouteId,
      })
        .then(response => {
          if (response) {
            RefreshPage('Itinerary', ['Audit']);
          }
        })
        .finally(() => setIsUpdating(false));
    }

    function PostRoute() {
      setIsPosting(true);
      PostBody(`Load/PostSamsaraRoute/`, { loadId: loadId })
        .then(response => {
          if (response) {
            RefreshPage('Itinerary', ['Audit']);
          }
        })
        .finally(() => setIsPosting(false));
    }

    function DeleteRoute() {
      setIsDeleting(true);
      Delete(`Load/DeleteSamsaraRoute/${samsaraRouteId}`)
        .then(response => {
          if (response) {
            RefreshPage('Itinerary', ['Audit']);
          }
        })
        .finally(() => setIsDeleting(false));
    }

    return (
      <>
        {samsaraRouteId ? (
          <>
            <UpdateRouteButton
              isUpdating={isUpdating}
              isDeleting={isDeleting}
              onClick={UpdateRoute}
            />
            <DeleteRouteButton
              isDeleting={isDeleting}
              isUpdating={isUpdating}
              onClick={DeleteRoute}
            />
          </>
        ) : (
          <PostRouteButton isLoading={isPosting} onClick={PostRoute} />
        )}
      </>
    );
  };

  const MapSection = () => {
    const UpdateAssetLocationButton = () => {
      const [isLoading, setIsLoading] = useState(false);
      const loadHasAssets = data.assetIds.length > 0;

      if (!loadHasAssets) return <Button disabled>No Truck Assigned</Button>;

      function RefreshAssetLocationTable() {
        setIsLoading(true);
        let requests = [];
        // Put each GET request into an array and then wait to execute GetLatestTruckLocation until each request is finished
        data.assetIds.forEach(assetId => {
          requests.push(
            Get(
              `Asset/GetSamsaraAssetLocation?assetId=${assetId}&loadId=${loadId}`,
            ),
          );
        });

        // Puts all the requests into one promise
        Promise.all(requests)
          .then(() => {
            GetLatestTruckLocation();
          })
          .finally(() => setIsLoading(false));
      }

      return (
        <Button
          variant="good"
          onClick={RefreshAssetLocationTable}
          disabled={isLoading}
          isLoading={isLoading}
        >
          Update Truck Location
        </Button>
      );
    };

    return (
      <>
        {displayMap && loadCoordinates?.stopCoordinates.length > 1 && (
          <div className={classes.mapContainer}>
            <TrimbleMap
              loadCoordinates={loadCoordinates}
              assetCoordinates={assetCoordinates}
            />
            <UpdateAssetLocationButton />
          </div>
        )}
      </>
    );
  };

  const ViewMapButton = () => {
    const HandleClick = () => {
      if (!loadCoordinates) {
        GetLatestTruckLocation();
        GetStopCoordinates();
        setDisplayMap(!displayMap);
      } else setDisplayMap(!displayMap);
    };

    return (
      <Button variant="good" onClick={HandleClick}>
        {displayMap ? 'Hide' : 'View'} Map
      </Button>
    );
  };

  const LoadNotes = () => {
    const formattedLoadNoteData = data.loadNotes?.map(loadNote => ({
      ...loadNote,
      date: DateTimeUTCFormatter(loadNote.date),
      estimatedMilesToNextStop: loadNote.estimatedMilesToNextStop
        ? Math.ceil(loadNote.estimatedMilesToNextStop).toLocaleString()
        : null,
    }));

    const columnConfig = [
      {
        key: '1',
        attribute: 'date',
        header: 'Date',
        width: '18ch',
      },
      {
        key: '2',
        attribute: 'loadNoteType',
        header: 'Status',
        width: '10ch',
      },
      {
        key: '3',
        attribute: 'createdBy',
        header: 'Created By',
        width: '18ch',
      },
      {
        key: '4',
        attribute: 'cityState',
        header: 'City, State',
      },
      {
        key: '5',
        attribute: 'estimatedMilesToNextStop',
        header: 'Est Miles',
        width: '8ch',
      },
      {
        key: '6',
        attribute: 'estimatedHoursToNextStop',
        header: 'Est Hrs',
        width: '8ch',
      },
      {
        key: '7',
        attribute: 'note',
        header: 'Notes',
      },
    ];

    if (data.isTemperatureRequired) {
      const temperatureObject = {
        key: '3',
        attribute: 'temperature',
        header: 'Temp (°F)',
        width: '8ch',
      };
      columnConfig.splice(2, 0, temperatureObject);
    }

    return <BBITable data={formattedLoadNoteData} columns={columnConfig} />;
  };

  return (
    <>
      <CreateStop
        stopTypeOptions={data.stopTypeOptions}
        timeZoneOptions={data.timeZoneOptions}
        loadId={loadId}
        RefreshPage={RefreshPage}
        GetLoad={GetLoad}
      />
      <LoadStats />
      <StopList
        stops={data.stops}
        timeZoneOptions={data.timeZoneOptions}
        customerAssignmentOptions={data.customerAssignmentOptions}
        RefreshPage={RefreshPage}
        GetLoad={GetLoad}
        HandleStopOrderChange={HandleStopOrderChange}
      />
      <div className={classes.buttonContainer}>
        {data.hasCustomOrderStops ? <ReOrderStopsButton /> : null}
        <RouteButtons samsaraRouteId={data?.samsaraRouteId} />
        <ViewMapButton />
      </div>
      {data.stops.length > 1 && <MapSection />}
      <CreateNote
        data={data}
        loadId={loadId}
        page={'Itinerary'}
        RefreshPage={RefreshPage}
      />
      <LoadNotes />
    </>
  );
}

export default Itinerary;
