import React, { useState } from 'react';

import PutForm from '../../../api/internal/PutForm';
import BillingTemplate from '../../templates/CustomerBilling';
import Button from '../../../assets/essentials/Button';
import Form from '../../../assets/essentials/Form';

function Billing({
  customerId,
  data,
  HandlePageChange,
  RefreshPage,
}: {
  customerId: number;
  data: CustomerBilling;
  HandlePageChange: Function;
  RefreshPage: Function;
}) {
  //state being held where the form is submitted, child component manipulates the array
  const [emails, setEmails] = useState<Array<string>>(data.billingEmail);
  
  function UpdateBilling(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const appendix = {
      customerId: customerId,
      //stores list of emails in db as a single string, backend parses string into array in get call
      billingEmail: emails.join('; '),
    };

    PutForm(`/Customer/UpdateCustomerBilling`, formData, appendix).then(() =>
      RefreshPage('Billing', ['Audit', 'Summary']),
    );
  }
  
  return (
    <>
      <Form onSubmit={e => UpdateBilling(e)}>
        <BillingTemplate
          data={data}
          emails={emails}
          HandlePageChange={HandlePageChange}
          setEmails={setEmails}
          submitButtonId={customerId + 'Billing'}
        />
        <div>
          <Button
            type="submit"
            variant="good"
            disabled={true}
            id={customerId + 'Billing'}
          >
            Save Changes
          </Button>
        </div>
      </Form>
    </>
  );
}
export default Billing;
