import React from 'react';
import classes from '../../../assets/Global.module.css';
import Input from '../../../assets/essentials/Input';
import PutForm from '../../../api/internal/PutForm';
import Button from '../../../assets/essentials/Button';
import Form from '../../../assets/essentials/Form';

function Details({
  data,
  customerAssignmentId,
  RefreshPage,
}: {
  data: ReceivableDetails;
  customerAssignmentId: Number;
  RefreshPage: Function;
}) {
  function UpdateReceivableDetails(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const appendix = {
      customerAssignmentId: customerAssignmentId,
    };
    PutForm(`/Accounting/UpdateReceivableDetails`, formData, appendix).then(
      () => {
        RefreshPage('Details', ['Summary','Invoice']);
      },
    );
  }

  return (
    <>
      <Form onSubmit={e => UpdateReceivableDetails(e)}>
        <div className={classes.attribute}>
          <label>Customer</label>
          <Input disabled type="text" value={data?.customerName} />
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>Payment Terms</label>
          <Input disabled type="text" value={data?.paymentTerm} />
        </div>
        <div className={classes.attribute}>
          <label>Invoice Date</label>
          <Input type="date" defaultValue={data?.invoiceDate} name="invoiceDate"/>
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>Due Date</label>
          <Input type="date" defaultValue={data?.dueDate} name="dueDate"/>
        </div>
        <div>
          <Button type="submit" variant="good" disabled={true}>
            Save Changes
          </Button>
        </div>
      </Form>
    </>
  );
}
export default Details;
