import React from 'react';
import classes from './Error.module.css';
import Close from './Close';

function Holder() {
  return (
    <div className={classes.errorContainer}>
      <div className={classes.overlay} onClick={Close} />
      <div className={classes.errorHolder} id="error-container" />
    </div>
  );
}

export default Holder;
