import React, { useEffect, useState } from 'react';
import Button from '../../../assets/essentials/Button';
import ClassesError from '../../../helpers/error/Error.module.css';
import PutBody from '../../../api/internal/PutBody';

type RentalAdminProps = {
  rentalId: number;
  rentalStatus: string;
  RefreshPage: Function;
  GetRental: Function;
};

function Admin({
  rentalId,
  rentalStatus,
  RefreshPage,
  GetRental,
}: RentalAdminProps) {
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isClaimsModalOpen, setIsClaimsModalOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const isRentalCancelled = rentalStatus === 'Cancelled';
  const isRentalInClaims = rentalStatus.includes('Claim');

  function CancelRental() {
    setIsButtonDisabled(true);
    const payload = {
      RentalId: rentalId,
    };
    PutBody(`/Rental/UpdateRentalToCancelled`, payload)
      .then(() => {
        GetRental();
        RefreshPage('Admin', ['Audit', 'Summary']);
      })
      .finally(() => {
        setIsButtonDisabled(false);
        setIsCancelModalOpen(false);
      });
  }

  function UncancelRental() {
    setIsButtonDisabled(true);
    const payload = {
      RentalId: rentalId,
    };
    PutBody(`/Rental/UncancelRental`, payload)
      .then(() => {
        GetRental();
        RefreshPage('Admin', ['Audit', 'Summary']);
      })
      .finally(() => {
        setIsButtonDisabled(false);
        setIsClaimsModalOpen(false);
        setIsCancelModalOpen(false);
      });
  }

  function SendRentalToClaims() {
    setIsButtonDisabled(true);
    const payload = {
      RentalId: rentalId,
    };
    PutBody(`/Rental/UpdateRentalToSendToClaims`, payload)
      .then(() => {
        GetRental();
        RefreshPage('Admin', ['Audit', 'Summary']);
      })
      .finally(() => {
        setIsButtonDisabled(false);
        setIsClaimsModalOpen(false);
      });
  }

  const SendToClaimsModal = () => (
    <div className={ClassesError.errorContainer}>
      <div
        className={ClassesError.overlay}
        onClick={() => setIsClaimsModalOpen(false)}
      />
      <div className={ClassesError.errorHolder}>
        <div className={ClassesError.yesNoModal}>
          <h3>
            Sending a rental to claims will change the rental status to "Send to Claims"
          </h3>
          <h3>Are you sure you want to send the rental to claims?</h3>
          <div className={ClassesError.buttonHolder}>
            <Button
              disabled={isButtonDisabled}
              onClick={() => SendRentalToClaims()}
              variant="good"
            >
              Yes
            </Button>
            <Button variant="bad" onClick={() => setIsClaimsModalOpen(false)}>
              No
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  const UnsendToClaimsModal = () => (
    <div className={ClassesError.errorContainer}>
      <div
        className={ClassesError.overlay}
        onClick={() => setIsClaimsModalOpen(false)}
      />
      <div className={ClassesError.errorHolder}>
        <div className={ClassesError.yesNoModal}>
          <h3>
            Unsending a rental to claims will change the rental status to "Needs
            Attention."
          </h3>
          <h3>Are you sure you want to unsend the rental to claims?</h3>
          <div className={ClassesError.buttonHolder}>
            <Button
              disabled={isButtonDisabled}
              onClick={() => UncancelRental()}
              variant="good"
            >
              Yes
            </Button>
            <Button variant="bad" onClick={() => setIsClaimsModalOpen(false)}>
              No
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  const UncancelRentalModal = () => (
    <div className={ClassesError.errorContainer}>
      <div
        className={ClassesError.overlay}
        onClick={() => setIsCancelModalOpen(false)}
      />
      <div className={ClassesError.errorHolder}>
        <div className={ClassesError.yesNoModal}>
          <h3>
            Uncancelling a rental will change the rental status to "Needs
            Attention."
          </h3>
          <h3>Are you sure you want to uncancel the Rental?</h3>
          <div className={ClassesError.buttonHolder}>
            <Button
              disabled={isButtonDisabled}
              onClick={() => UncancelRental()}
              variant="good"
            >
              Yes
            </Button>
            <Button variant="bad" onClick={() => setIsCancelModalOpen(false)}>
              No
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  const CancelRentalModal = () => (
    <div className={ClassesError.errorContainer}>
      <div
        className={ClassesError.overlay}
        onClick={() => setIsCancelModalOpen(false)}
      />
      <div className={ClassesError.errorHolder}>
        <div className={ClassesError.yesNoModal}>
          <h3>Are you sure you want to cancel the Rental?</h3>
          <div className={ClassesError.buttonHolder}>
            <Button
              disabled={isButtonDisabled}
              onClick={() => CancelRental()}
              variant="good"
            >
              Yes
            </Button>
            <Button variant="bad" onClick={() => setIsCancelModalOpen(false)}>
              No
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  const CancelRentalSection = () => (
    <>
      <h1>{isRentalCancelled ? 'Uncancel Rental' : 'Cancel Rental'}</h1>
      <Button
        onClick={() => setIsCancelModalOpen(true)}
        variant={isRentalCancelled ? 'good' : 'bad'}
      >
        {isRentalCancelled ? 'Uncancel Rental' : 'Cancel Rental'}
      </Button>
    </>
  );

  const SendToClaimsSection = () => (
    <>
      <h1>{isRentalInClaims ? 'Unsend To Claims' : 'Send To Claims'}</h1>
      <Button
        onClick={() => setIsClaimsModalOpen(true)}
        variant={isRentalInClaims ? 'bad' : 'good'}
      >
        {isRentalInClaims ? 'Unsend To Claims' : 'Send To Claims'}
      </Button>
    </>
  );

  return (
    <>
      <CancelRentalSection />
      {isCancelModalOpen &&
        (isRentalCancelled ? <UncancelRentalModal /> : <CancelRentalModal />)}
      <SendToClaimsSection />
      {isClaimsModalOpen &&
        (isRentalInClaims ? <UnsendToClaimsModal /> : <SendToClaimsModal />)}
    </>
  );
}
export default Admin;
