import React, { useState, useEffect } from 'react';

import Details from './Details';
import Panel from '../../../helpers/panel/Panel';
import PostBody from '../../../api/internal/PostBody';
import Close from '../../../helpers/panel/Close';
import Get from '../../../api/internal/Get';

function Posting({ GetPostingBoard }) {
  const [createPostingParam] = useState({
    availability: null,
    equipment: null,
    weight: null,
    numOfTrucks: 1,
    origin: null,
    destination: null,
  });
  const [postingOption, setPostingOption] = useState();

  function CreatePosting() {
    PostBody('Posting/CreatePosting', createPostingParam).then(response => {
      if (response) {
        GetPostingBoard();
        Close();
      }
    });
  }

  useEffect(() => {
    GetOptionsForCreatePosting();
  }, []);

  function GetOptionsForCreatePosting() {
    Get(`Posting/GetOptionsForCreatePosting/`).then(response => {
      if (response) {
        setPostingOption(response.data);
      }
    });
  }

  let pages = [
    {
      title: 'Details',
      displayedIcon: '',
      content: (
        <Details
          posting={createPostingParam}
          postingOption={postingOption}
          UpdateCreatePosting={UpdateCreatePosting}
        />
      ),
      require: [
        {
          label: 'Number of Trucks',
          FunctionBool: () => createPostingParam.numOfTrucks,
          assignedValue: () => createPostingParam.numOfTrucks,
          missingDesc: 'Number of trucks has not been assigned',
        },
      ],
    },
  ];

  function OnSubmit() {
    let fulfillsFormRequirements = true;
    pages.forEach((page, index) => {
      if (!FulFillsPageRequirements(index)) {
        fulfillsFormRequirements = false;
      }
    });
    if (fulfillsFormRequirements) {
      CreatePosting();
    } else alert('Not all info included');
  }

  function UpdateCreatePosting(newValue, attributeName) {
    if (newValue?.toString().trim().length !== 0) {
      createPostingParam[attributeName] = newValue;
    } else {
      createPostingParam[attributeName] = null;
    }
  }

  function FulFillsPageRequirements(pageIndex) {
    let fulfillsRequirements = true;
    pages[pageIndex].require.forEach(requirement => {
      if (!requirement.FunctionBool()) {
        fulfillsRequirements = false;
      }
    });
    return fulfillsRequirements;
  }
  return <Panel OnSubmit={OnSubmit} title="Create Posting" pages={pages} />;
}

export default Posting;
