import React, { useRef, useEffect } from 'react';
import useGoogleMapsApi from './useGoogleMapsAPI';
import classes from '../../assets/essentials/Input.module.css';

function GoogleSearchBoxCity(props) {
  const inputRef = useRef();
  const autocompleteRef = useRef();
  const googleMapsApi = useGoogleMapsApi();
  // google api information obtaining

  useEffect(() => {
    if (googleMapsApi) {
      autocompleteRef.current = new googleMapsApi.places.Autocomplete(
        inputRef.current,
        {
          fields: [
            'address_components',
            'name',
            'formatted_phone_number',
            'formatted_address',
            'geometry'
          ],
          types: ['(cities)'],
          componentRestrictions: { country: ['us', 'ca'] },
        },
      );

      autocompleteRef.current.addListener('place_changed', () => {
        const place = autocompleteRef.current.getPlace();
        if (props && place) {
          props.assignPlace(place);
        }
      });
    }
  }, [googleMapsApi, props]);

  return (
    <input
      name="location"
      className={classes.input}
      aria-label="City, State"
      ref={inputRef}
      placeholder="City, State"
      autoComplete="off"
      required={props.required}
    />
  );
}

export default GoogleSearchBoxCity;