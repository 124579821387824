import React from 'react';
import { useEffect, useState } from 'react';
import Slab from '../../../../helpers/slab/Slab';
import Details from './Details';
import Summary from './Summary';
import Audit from '../../generic/Audit';
import Get from '../../../../api/internal/Get';
import ESA from '../ESA';
import { useRoleAuthorization } from '../../../../hooks/useRoleAuthorization';
import { ADMIN_AUTHORIZED_ROLES } from '../../../../auth/AuthRoles';
import Admin from '../Admin';

function Dispatch({
  employeeId,
  dispatchName,
  startPage,
}: {
  employeeId: number;
  dispatchName: string;
  startPage?: string;
}) {
  const [currentPage, setCurrentPage] = useState();
  const [dispatchDataObject] = useState<DispatchDataObject>({
    Admin: null,
    Audit: null,
    Details: null,
    ESA: null,
    Summary: null,
  }); 

  useEffect(() => {
    startPage ? HandlePageChange(startPage) : HandlePageChange('Summary');
  }, []);

  function HandlePageChange(params) {
    if (!dispatchDataObject[params] && pages[params].callerPath) {
      Get(pages[params].callerPath).then(response => {
        if (response) {
          dispatchDataObject[params] = response.data;
        }
        AssignPages();
        setCurrentPage(params);
      });
    } else {
      setCurrentPage(params);
    }
  }

  function RefreshPage(currentPage: string, additionalPages: string[]) {
    if (dispatchDataObject) {
      setCurrentPage(null);
      dispatchDataObject[currentPage] = null;
      additionalPages?.forEach(pageName => {
        dispatchDataObject[pageName] = null;
      });
      HandlePageChange(currentPage);
    }
  }

  let pages = {};
  AssignPages();
  function AssignPages() {
    pages = {
      Summary: {
        content: <Summary data={dispatchDataObject.Summary} />,
        callerPath: `/Employee/GetDispatchSummary/${employeeId}`,
      },
      Details: {
        content: (
          <Details
            employeeId={employeeId}
            data={dispatchDataObject.Details}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Employee/GetDispatchDetails/${employeeId}`,
      },
      Audit: {
        content: <Audit data={dispatchDataObject.Audit} />,
        callerPath: `/Employee/GetEmployeeAudit/${employeeId}`,
      },
      ESA: {
        content: (
          <ESA
            data={dispatchDataObject.ESA}
            employeeId={employeeId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Accounting/GetEmployeeEsaEntries/${employeeId}`,
      },
    };
    if (useRoleAuthorization(ADMIN_AUTHORIZED_ROLES))
    pages['Admin'] = {
      content: (
        <Admin
          data={dispatchDataObject.Admin}
          employeeId={employeeId}
          RefreshPage={RefreshPage}
        />
      ),
      callerPath: `/Employee/GetEmployeeAdmin/${employeeId}`,
    };
  }

  return (
    <Slab
      title={'Dispatch ' + dispatchName}
      pages={pages}
      currentPage={currentPage}
      HandlePageChange={HandlePageChange}
      dataObject={dispatchDataObject}
    />
  );
}
export default Dispatch;
