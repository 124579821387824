import React, { useState } from 'react';
import { BsFileEarmarkArrowUp } from 'react-icons/bs';

import Receipt from './UploadReceipt';
import BBITable from '../../../helpers/bBITable/BBITable';
import classes from './Receipt.module.css';
import classesGlobal from '../../../assets/Global.module.css';
import Button from '../../../assets/essentials/Button';
import Select from '../../../assets/essentials/Select';
import Input from '../../../assets/essentials/Input';
import Form from '../../../assets/essentials/Form';
import StatesAndProvinces from '../../../helpers/options/StatesAndProvinces';

function Details({}) {
  const [receiptEntryList, setReceiptEntryList] = useState<Object[]>([]);

  // function DeleteEntry(receiptTransaction) {
  //   console.log(receiptTransaction)
  //   console.log(receiptEntryList)
  //   const index = receiptEntryList.findIndex(receipt => receipt.transactionNumber === receiptTransaction);
  //   console.log(index)
  //   setReceiptEntryList(receiptEntryList.splice(index, 1));
  // }

  let columnConfig = [
    {
      key: '1',
      attribute: 'gasStation',
      header: 'Gas Station',
    },
    {
      key: '2',
      attribute: 'city',
      header: 'City',
    },
    {
      key: '3',
      attribute: 'state',
      header: 'State',
    },
    {
      key: '4',
      attribute: 'transactionNumber',
      header: 'Transaction Number',
    },
    {
      key: '5',
      attribute: 'quantity',
      header: 'Quantity',
    },
    {
      key: '6',
      attribute: 'rate',
      header: 'rate',
    },
    {
      key: '7',
      attribute: 'transactionDate',
      header: 'Transaction Date',
    },
    {
      key: '8',
      attribute: 'discount',
      header: 'Discount',
    },
    {
      key: '9',
      attribute: 'total',
      header: 'Total',
    },
  ];

  const [isOpen, setIsOpen] = useState<boolean>(false);

  function SubmitForm(e) {
    e.preventDefault();
    CreateEntry(e);
    setIsOpen(false);
  }
  function CreateEntry(e) {
    const formData = new FormData(e.target);
    const entry = {};
    formData.forEach((value, key) => (entry[key] = value));
    //entry.delete = <BsTrash onClick={e => DeleteEntry(entry.transactionNumber)}/>

    receiptEntryList.push(entry);
    setReceiptEntryList(receiptEntryList);
  }

  return (
    <>
      <Receipt
        receiptEntryList={receiptEntryList}
        setReceiptEntryList={setReceiptEntryList}
      />
      <div className={classes.btnHolder}>
        <button type="button" onClick={() => setIsOpen(!isOpen)}>
          <BsFileEarmarkArrowUp />
          <span>Manually Add Transaction</span>
        </button>
      </div>
      <div>
        {isOpen && (
          <Form
            className={classesGlobal.viewGrid}
            onSubmit={e => SubmitForm(e)}
          >
            <div className={classesGlobal.attribute}>
              <label>Gas Station</label>
              <Select name="gasStation" id="gasStation" required>
                <option value="">Choose an option</option>
                <option value="Pilot">Pilot</option>
                <option value="Loves">Loves</option>
              </Select>
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>Transaction #</label>
              <Input name="transactionNumber" required />
            </div>
            <div className={classesGlobal.attribute}>
              <label>City</label>
              <Input name="city" required />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>State/Province</label>
              <Select required name="state">
              <option value="" />
                <StatesAndProvinces />
              </Select>
            </div>
            <div className={classesGlobal.attribute}>
              <label>Quantity</label>
              <Input name="quantity" required />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>Price</label>
              <Input name="price" required />
            </div>
            <div className={classesGlobal.attribute}>
              <label>Discount</label>
              <Input name="discount" required />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>Total</label>
              <Input name="total" required />
            </div>
            <div className={classesGlobal.attribute}>
              <label>Card Number</label>
              <Input name="cardNumber" required />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>Transaction Date</label>
              <Input name="transactionDate" type="date" required />
            </div>
            <div>
              <Button type="submit" variant="good" disabled>
                Submit
              </Button>
            </div>
            <div>
              <Button onClick={() => setIsOpen(!isOpen)}>Cancel</Button>
            </div>
          </Form>
        )}
      </div>
      {receiptEntryList.length !== 0 ? (
        <BBITable
          data={receiptEntryList}
          columns={columnConfig}
        />
      ) : (
        <div>No entries have been entered.</div>
      )}
    </>
  );
}

export default Details;
