import React from 'react';
import classes from '../../../../assets/Global.module.css';
import Button from '../../../../assets/essentials/Button';
import Select from '../../../../assets/essentials/Select';
import OptionList from '../../../../helpers/options/OptionList';
import PutForm from '../../../../api/internal/PutForm';
import Form from '../../../../assets/essentials/Form';

function UpdateAdmin({
  currentBranchId,
  loadId,
  branchOptions,
  RefreshPage,
  loadStatusOptions,
  loadStatusId,
}: {
  currentBranchId: number;
  loadId: number;
  branchOptions: Array<BranchOption>;
  RefreshPage: Function;
  loadStatusOptions: Array<LoadStatusTypeOption>;
  loadStatusId: number;
}) {
  function UpdateLoadBranch(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const appendix = {
      loadId: loadId,
    };

    PutForm(`/Load/UpdateLoadAdmin`, formData, appendix).then(
      response => {
        if (response) {
          RefreshPage('Admin', ['Audit', 'Summary']);
        }
      },
    );
  }
  return (
    <Form onSubmit={e => UpdateLoadBranch(e)}>
      <div className={`${classes.attribute}`}>
        <label htmlFor="branchId">Branch</label>
        <Select defaultValue={currentBranchId} name="branchId" required>
          <OptionList
            optionData={branchOptions}
            attributeID="branchId"
            attributeName="branchName"
          />
        </Select>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
          <label>Status</label>
          <Select defaultValue={loadStatusId} name="loadStatusId">
            <OptionList
              optionData={loadStatusOptions}
              attributeID="loadStatusId"
              attributeName="status"
              attributeGroup="group"
            />
          </Select>
        </div>
      <div>
        <Button variant="good" type="submit" disabled={true}>
          Save Changes
        </Button>
      </div>
    </Form>
  );
}

export default UpdateAdmin;
