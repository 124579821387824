import React, { useState } from 'react';
import { MdOutlineSummarize, MdList, MdAttachMoney } from 'react-icons/md';
import { VscSearch } from 'react-icons/vsc';
import { GiPayMoney } from 'react-icons/gi';
import { SiQuickbooks } from 'react-icons/si';
import { Outlet } from 'react-router-dom';
import Header from '../../../layouts/Header';
import Filter from '../../../helpers/filter/Filter';
import HeaderNavButton from '../../../helpers/buttons/HeaderNavButton';

export default function Receivables() {
  const [filterValue, setFilterValue] = useState('');

  const navButtons = [
    { to: 'outstanding', icon: MdList, label: 'Outstanding Receivables' },
    { to: 'search', icon: VscSearch, label: 'Search Receivables' },
    { to: 'quickbooks', icon: SiQuickbooks, label: 'QuickBooks' },
    { to: 'summary', icon: MdOutlineSummarize, label: 'A/R Summary' },
    { to: 'apply-payments', icon: GiPayMoney, label: 'Apply Payments' },
    { to: 'payments', icon: MdAttachMoney, label: 'View Payments' },
  ];

  return (
    <>
      <h1>Receivables</h1>
      <Header>
        <div>
          {navButtons.map(button => (
            <HeaderNavButton
              key={button.to}
              to={button.to}
              icon={button.icon}
              label={button.label}
            />
          ))}
        </div>
        <div>
          <Filter
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            placeholder="Filter receivables"
          />
        </div>
      </Header>
      <Outlet context={filterValue} />
    </>
  );
}
