import React, { useEffect, useState } from 'react';

import Details from './Details';
import Panel from '../../../helpers/panel/Panel';
import Get from '../../../api/internal/Get';
import PostBody from '../../../api/internal/PostBody';
import Close from '../../../helpers/panel/Close';
import View from '../../../helpers/slab/View';
import ViewRental from '../../views/rental/Rental';

function Rental({ RefreshBoard }) {
  const [createRentalOptions, setCreateRentalOptions] =
    useState<CreateRentalOptions>();
  const [createRentalParam, setCreateRentalParam] = useState<CreateRentalParam>(
    {
      customerId: null,
      reference: null,
      instructions: null,
      startDate: null,
      endDate: null,
      assets: [],
    },
  );
  const [rentalDisplayItems] = useState<RentalDisplayItems>({
    customer: '',
    startDate: '',
    endDate: '',
  });

  useEffect(() => {
    GetOptionsForCreateRental();
  }, []);

  function GetOptionsForCreateRental() {
    Get(`Rental/GetOptionsForCreateRental/`).then(response => {
      if (response) {
        setCreateRentalOptions(response.data);
      }
    });
  }

  function CreateRental() {
    const modifiedRentalParam = {
      ...createRentalParam,
      assets: createRentalParam.assets.map(asset => asset.id),
    };
    PostBody('Rental/CreateRental', modifiedRentalParam).then(response => {
      if (response) {
        RefreshBoard();
        Close();
        View(<ViewRental rentalId={response.data} />);
      }
    });
  }

  let pages = [
    {
      title: 'Details',
      displayedIcon: '',
      content: (
        <Details
          createRentalParam={createRentalParam}
          createRentalOptions={createRentalOptions}
          UpdateParam={UpdateCreateRentalParam}
          rentalDisplayItems={rentalDisplayItems}
        />
      ),
      require: [
        {
          label: 'Customer',
          FunctionBool: () => createRentalParam.customerId,
          assignedValue: () => rentalDisplayItems.customer,
          missingDesc: 'Customer has not been assigned.',
        },
        {
          label: 'Start Date',
          FunctionBool: () => createRentalParam.startDate,
          assignedValue: () => {
            let parts = createRentalParam.startDate.toString().split('-');
            let formattedDate = `${parts[1]}/${parts[2]}/${parts[0]}`;
            return formattedDate;
          },
          missingDesc: 'Start Date has not been assigned.',
        },
        {
          label: 'End Date',
          FunctionBool: () => createRentalParam.endDate,
          assignedValue: () => {
            let parts = createRentalParam.endDate.toString().split('-');
            let formattedDate = `${parts[1]}/${parts[2]}/${parts[0]}`;
            return formattedDate;
          },
          missingDesc: 'End Date has not been assigned.',
        },
      ],
    },
  ];

  function OnSubmit() {
    let fulfillsFormRequirements = true;
    pages.forEach((page: object, index: number) => {
      if (!FulFillsPageRequirements(index)) {
        fulfillsFormRequirements = false;
      }
    });
    if (fulfillsFormRequirements) {
      CreateRental();
    } else alert('Not all info included');
  }

  function UpdateCreateRentalParam(
    newValue: string | number | [],
    attributeName: string,
  ) {
    setCreateRentalParam(prevCreateLoadParam => {
      // check if newValue is array because emptyArray.toString() evals to "", 
      // which would evaluate to false and set property to null when we want it to be []
      if (Array.isArray(newValue) || newValue?.toString().trim().length !== 0) {
        return {
          ...prevCreateLoadParam,
          [attributeName]: newValue,
        };
      }
      return { ...prevCreateLoadParam, [attributeName]: null };
    });
  }

  function FulFillsPageRequirements(pageIndex: number) {
    let fulfillsRequirements = true;
    pages[pageIndex].require.forEach(requirement => {
      if (!requirement.FunctionBool()) {
        fulfillsRequirements = false;
      }
    });
    return fulfillsRequirements;
  }
  return <Panel OnSubmit={OnSubmit} title="Create Rental" pages={pages} />;
}

export default Rental;
