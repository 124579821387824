import React, { useState, useEffect } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { FiTruck, FiAlignLeft } from 'react-icons/fi';
import { MdOutlineRefresh } from 'react-icons/md';
import { TbAlignLeft } from 'react-icons/tb';

import Header from '../layouts/Header';
import Filter from '../helpers/filter/Filter';
import CreateLoad from '../features/creates/load/Load';
import ViewLoad from '../features/views/load/Load';
import View from '../helpers/slab/View';
import Create from '../helpers/panel/Create';
import BBITable from '../helpers/bBITable/BBITable';
import FilterData from '../helpers/filter/FilterData';
import ViewCustomer from '../features/views/customer/Customer';
import ViewLocation from '../features/views/location/Location';
import ViewDriver from '../features/views/users/driver/Driver';
import classes from '../helpers/bBITable/BBITable.module.css';
import classesGlobal from '../assets/Global.module.css';
import classesLoad from './Load.module.css';
import Get from '../api/internal/Get';

function Claims({ toggleBoardRefresh }) {
  const [filterValue, setFilterValue] = useState('');
  const [showViewOption, setShowViewOption] = useState(false);
  const [isCompact, setIsCompact] = useState(false);
  // options are status and search
  const [claimsBoard, setClaimsBoard] = useState<Array<LoadBoardItem>>();
  const [loadSearchOptions, setLoadSearchOptions] =
    useState<LoadSearchOptions>();

  useEffect(() => {
    GetClaimsBoard();
  }, [toggleBoardRefresh]);

  function GetClaimsBoard() {
    Get(`/Load/GetClaimsBoard`).then(response => {
      if (response) {
        setClaimsBoard(response.data.loads);
        setLoadSearchOptions(response.data.loadSearchOptions);
      }
    });
  }

  function CategorizeLoadsByStatus(loadData) {
    const sendToClaimsLoads = loadData.filter(
      load => load.loadStatus === 'Send To Claims',
    );
    const claimInProcessLoads = loadData.filter(
      load => load.loadStatus === 'Claim In Process',
    );
    const claimResolvedLoads = loadData.filter(
      load => load.loadStatus === 'Claim Resolved',
    );
    return {
      sendToClaimsLoads,
      claimInProcessLoads,
      claimResolvedLoads,
    };
  }

  let filteredLoadBoardByStatusData;

  //filter functions are called when display button is toggled
  if (claimsBoard) {
    filteredLoadBoardByStatusData = CategorizeLoadsByStatus(claimsBoard);
  }

  // passed into BBI Table to build table headers and rows
  let columnConfig = [
    {
      key: '1',
      attribute: 'loadId',
      header: 'ID',
      onClick: e => View(<ViewLoad loadId={e.target.innerText} />),
      dataType: 'number',
    },
    {
      key: '2',
      attribute: 'reference',
      header: 'Reference',
      attributeprimaryhidden: 'loadId',
      onClick: e =>
        View(<ViewLoad loadId={e.target.dataset.attributeprimaryhidden} />),
    },
    {
      key: '3',
      attribute: 'customerNames',
      attributeprimaryhidden: 'customerId',
      header: 'Customer',
      onClick: e =>
      e.target.dataset.attributeprimaryhidden &&
        View(
          <ViewCustomer
            customerName={e.target.innerText}
            customerId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '4',
      attribute: 'pickLocation',
      attributeprimaryhidden: 'pickLocationId',
      header: 'Pickup',
      onClick: e =>
        View(
          <ViewLocation
            locationId={e.target.dataset.attributeprimaryhidden}
            locationName={e.target.innerText}
          />,
        ),
    },
    {
      key: '5',
      attribute: 'pickAppointment',
      header: 'Pickup Appt',
      dataType: 'date',
    },
    {
      key: '6',
      attribute: 'dropLocation',
      attributeprimaryhidden: 'dropLocationId',
      header: 'Drop Off',
      onClick: e =>
        View(
          <ViewLocation
            locationId={e.target.dataset.attributeprimaryhidden}
            locationName={e.target.innerText}
          />,
        ),
    },

    {
      key: '7',
      attribute: 'dropAppointment',
      header: 'Drop Off Appt',
      dataType: 'date',
    },
    {
      key: '8',
      attribute: 'loadLane',
      header: 'Lane',
    },
    {
      key: '9',
      attribute: 'pickDropRatio',
      header: 'Pick/Drop',
    },
    {
      key: '10',
      attribute: 'equipment',
      header: 'Equipment',
    },
    {
      key: '11',
      attribute: 'driverName',
      attributeprimaryhidden: 'employeeId',
      attributesecondaryhidden: 'driverName',
      header: 'Driver',
      onClick: e => {
        // Preventing onClick if there is no driver
        if (e.target.innerText !== null) {
          View(
            <ViewDriver
              // Adding the split as this functionality only allows the user to view the first driver on each load, so not all names should be displayed.
              driverName={e.target.innerText.split(',')[0]}
              employeeId={e.target.dataset.attributeprimaryhidden}
            />,
          );
        }
      },
      class: classesLoad.statusBackgroundColor,
    },
  ];

  const LoadBoardSection = ({
    title,
    loads,
    filterValue,
    columnConfig,
    compactView,
  }) => (
    <>
      <div className={classes.title}>{title}</div>
      {loads.length > 0 ? (
        <div className={compactView ? classes.compactView : classes.normalView}>
          <BBITable
            data={FilterData(loads, filterValue)}
            columns={columnConfig}
            compactView={compactView}
          />
        </div>
      ) : (
        <p>No {title} loads.</p>
      )}
    </>
  );

  return (
    <>
      <h1>Claims</h1>
      <Header>
        <div>
          <button
            onClick={() => Create(<CreateLoad RefreshBoard={GetClaimsBoard} />)}
          >
            <FiTruck />
            <span>Create a Load</span>
          </button>
          <button onClick={GetClaimsBoard}>
            <MdOutlineRefresh />
            <span>Refresh Board</span>
          </button>
        </div>
        <div>
          <div className={classesGlobal.dropdown}>
            <button
              aria-label="Open Toggle Compact Menu"
              onClick={() => setShowViewOption(!showViewOption)}
            >
              {isCompact ? <FiAlignLeft /> : <TbAlignLeft />}
            </button>
            {showViewOption && (
              <div className={classesGlobal.dropdownOptions}>
                <div
                  onClick={() => {
                    setIsCompact(false);
                    setShowViewOption(false);
                  }}
                >
                  {!isCompact ? <AiOutlineCheck /> : <div />}
                  <TbAlignLeft />
                  <span>Normal View</span>
                </div>
                <div
                  onClick={() => {
                    setIsCompact(true);
                    setShowViewOption(false);
                  }}
                >
                  {isCompact ? <AiOutlineCheck /> : <div />}
                  <FiAlignLeft />
                  <span>Compact View</span>
                </div>
              </div>
            )}
          </div>
          <Filter
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            placeholder="Filter loads"
          />
        </div>
      </Header>
      <LoadBoardSection
        title="Send To Claims"
        loads={filteredLoadBoardByStatusData?.sendToClaimsLoads || []}
        filterValue={filterValue}
        columnConfig={columnConfig}
        compactView={isCompact}
      />
      <LoadBoardSection
        title="Claim In Process"
        loads={filteredLoadBoardByStatusData?.claimInProcessLoads || []}
        filterValue={filterValue}
        columnConfig={columnConfig}
        compactView={isCompact}
      />
      <LoadBoardSection
        title="Claim Resolved"
        loads={filteredLoadBoardByStatusData?.claimResolvedLoads || []}
        filterValue={filterValue}
        columnConfig={columnConfig}
        compactView={isCompact}
      />
    </>
  );
}

export default Claims;
