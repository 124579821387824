import { useEffect, useState } from 'react';
import Get from '../api/internal/Get';

export type Branch = {
  branchId: number;
  branchName: string;
  companyName: string | null;
};

export default function useBranchList() {
  const [branchList, setBranchList] = useState<Branch[]>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (isLoading && !branchList) {
      Get('Load/GetBranchList').then(response => {
        if (response) {
          setBranchList(response.data);
        }
        setIsLoading(false);
      });
    }
  }, [isLoading, branchList]);

  return branchList;
}
