import React, { useState } from 'react';

import classes from '../../../assets/Accordion.module.css';
import classesGlobal from '../../../assets/Global.module.css';
import PutForm from '../../../api/internal/PutForm';
import Phone from '../../../helpers/inputs/Phone';
import Button from '../../../assets/essentials/Button';
import Input from '../../../assets/essentials/Input';
import TextArea from '../../../assets/essentials/TextArea';
import Form from '../../../assets/essentials/Form';

function Contact({
  individual,
  RefreshPage,
}: {
  individual: VendorContact;
  RefreshPage: Function;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [phone, setPhone] = useState(individual.phone);
  const [email, setEmail] = useState(individual.email);

  function UpdateContact(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    //parses formData input checkboxes on/off to true/false
    formData.set('isActive', (formData.get('isActive') === 'on').toString());
    formData.set(
      'isPrimaryVendorContact',
      (formData.get('isPrimaryVendorContact') === 'on').toString(),
    );
    formData.set(
      'isBillingContact',
      (formData.get('isBillingContact') === 'on').toString(),
    );
    const appendix = {
      vendorContactId: individual.vendorContactId,
    };
    PutForm(`/Vendor/UpdateVendorContact`, formData, appendix).then(() =>
      RefreshPage('Contacts', ['Summary', 'Billing', 'Audit']),
    );
  }

  return (
    <>
      <div className={classes.accordionSummary}>
        <div className={classesGlobal.viewGrid}>
          <div
            className={`${classesGlobal.attribute} ${classesGlobal.span3}`}
            onClick={() => setIsOpen(!isOpen)}
          >
            <b>
              {individual?.firstName} {individual?.lastName + ' '}
              {individual?.isBillingContact &&
              individual?.isPrimaryVendorContact ? (
                <i>Primary Contact & Primary Billing Contact</i>
              ) : (
                <>
                  {individual?.isPrimaryVendorContact && (
                    <i> Primary Contact</i>
                  )}
                  {individual?.isBillingContact && (
                    <i> Primary Billing Contact</i>
                  )}
                </>
              )}
            </b>
            <span>Position: {individual?.position}</span>
            {individual?.phone && <span>Phone: {individual?.phone}</span>}
            {individual?.email && <span>Email: {individual?.email}</span>}
          </div>
        </div>
      </div>
      <div className={`${classes.expandable} ${classes.contact}`}>
        {isOpen && (
          <Form className={classes.viewGrid} onSubmit={e => UpdateContact(e)}>
            <div className={classesGlobal.attribute}>
              <label>
                First Name <span className={classesGlobal.required}>*</span>
              </label>
              <Input
                required
                type="text"
                defaultValue={individual?.firstName}
                name="firstName"
              />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>
                Last Name <span className={classesGlobal.required}>*</span>
              </label>
              <Input
                required
                type="text"
                defaultValue={individual?.lastName}
                name="lastName"
              />
            </div>
            <div className={classesGlobal.attribute}>
              <label>
                Phone{' '}
                {email ? (
                  '*'
                ) : (
                  <span className={classesGlobal.required}>*</span>
                )}
              </label>
              <Phone
                required={!email}
                defaultValue={individual.phone}
                name="phone"
                onChange={e => setPhone(e.target.value)}
                expectsEventObject={true}
              />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>Ext</label>
              <Input
                type="text"
                defaultValue={individual?.phoneExt}
                name="phoneExt"
              />
            </div>
            <div className={classesGlobal.attribute}>
              <label>Position</label>
              <Input
                type="text"
                defaultValue={individual?.position}
                name="position"
              />
            </div>
            <div className={classesGlobal.attribute}>
              <label>
                Email{' '}
                {phone ? (
                  '*'
                ) : (
                  <span className={classesGlobal.required}>*</span>
                )}
              </label>
              <Input
                required={!phone}
                type="text"
                defaultValue={individual?.email}
                name="email"
                onChange={e => setEmail(e.target.value)}
                expectsEventObject={true}
              />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span3}`}
            >
              <label>Notes</label>
              <TextArea defaultValue={individual?.note} name="note" />
            </div>
            <div className={classesGlobal.attribute}>
              <label>
                <Input
                  defaultChecked={individual?.isPrimaryVendorContact}
                  type="checkbox"
                  name="isPrimaryVendorContact"
                />
                Primary Contact
              </label>
            </div>
            <div className={classesGlobal.attribute}>
              <label>
                <Input
                  defaultChecked={individual?.isBillingContact}
                  type="checkbox"
                  name="isBillingContact"
                />
                Primary Billing
              </label>
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span3}`}
            >
              <label>
                <Input
                  defaultChecked={individual?.isActive}
                  type="checkbox"
                  name="isActive"
                />
                Active
              </label>
            </div>
            <div>
              <Button type="submit" variant="good" disabled={true}>
                Save Changes
              </Button>
            </div>
          </Form>
        )}
      </div>
    </>
  );
}

export default Contact;
