import React, { useState } from 'react';
import Panel from '../../../helpers/panel/Panel';
import Details from './Details';
import Close from '../../../helpers/panel/Close';
import TransportationCaller from '../../../api/internal/TransportationCaller';
import Create from '../../../helpers/error/Create';
import Error from '../../../helpers/error/Error';
import LocationValidationError from '../../../helpers/error/LocationValidationError';

function Location() {
  const [createLocationParam, setCreateLocationParam] = useState<LocationInfo>({
    locationId: null,
    ianaTimeZone: null,
    locationName: null,
    addressLineOne: null,
    addressLineTwo: null,
    city: null,
    state: null,
    zip: null,
    country: null,
    phone: null,
    phoneExt: null,
    email: null,
    hours: null,
    directions: null,
    website: null,
    specialInstructions: null,
    searchResult: null,
  });

  const INVALID_COORDS_ERROR_RESPONSE = 'Unable to validate location.';

  function CreateLocation() {
    //using caller directly to bypass default error catch
    TransportationCaller.post('Location/CreateLocation', createLocationParam, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response) {
          Close();
        }
      })
      .catch(error => {
        if (error.response?.data === INVALID_COORDS_ERROR_RESPONSE) {
          Create(<LocationValidationError location={createLocationParam} />);
        } else Create(<Error error={error} />);
      });
  }

  let pages = [
    {
      title: 'Details',
      displayedIcon: '',
      content: (
        <Details
          location={createLocationParam}
          UpdateLocationForAutoComplete={UpdateLocationForAutoComplete}
          UpdateCreateLocation={UpdateCreateLocation}
        />
      ),
      require: [
        {
          label: 'Location Name',
          FunctionBool: () => createLocationParam.locationName !== null,
          assignedValue: () => createLocationParam.locationName,
          missingDesc: 'Location Name has not been assigned.',
        },
        {
          label: 'Address',
          FunctionBool: () =>
            createLocationParam.addressLineOne &&
            createLocationParam.city &&
            createLocationParam.state &&
            createLocationParam.zip &&
            createLocationParam.country,
          assignedValue: () =>
            createLocationParam.addressLineOne +
            ' ' +
            (createLocationParam.addressLineTwo
              ? createLocationParam.addressLineTwo + ' '
              : '') +
            createLocationParam.city +
            ', ' +
            createLocationParam.state +
            ' ' +
            createLocationParam.zip +
            ' ' +
            createLocationParam.country,
          missingDesc: 'Address has not been properly assigned.',
        },
        {
          label: 'Phone',
          FunctionBool: () =>
            createLocationParam.phone !== null &&
            createLocationParam.phone !== '',
          assignedValue: () =>
            createLocationParam.phone +
            (createLocationParam.phoneExt
              ? ' Ext: ' + createLocationParam.phoneExt
              : ''),
          missingDesc: 'Phone Number has not been assigned.',
        },
      ],
    },
  ];
  function OnSubmit() {
    let fulfillsFormRequirements = true;
    pages.forEach((page, index) => {
      if (!FulFillsPageRequirements(index)) {
        fulfillsFormRequirements = false;
      }
    });
    if (fulfillsFormRequirements) {
      CreateLocation();
    } else alert('Not all info included');
  }

  function UpdateCreateLocation(newValue, attributeName) {
    if (newValue?.toString().trim().length !== 0) {
      createLocationParam[attributeName] = newValue;
    } else {
      createLocationParam[attributeName] = null;
    }
  }

  function UpdateLocationForAutoComplete(
    locationName: string,
    addressLineOne: string,
    city: string,
    state: string,
    zip: string,
    country: string,
    phone: string,
    website: string,
  ) {
    let tempLocation = { ...createLocationParam };
    tempLocation.locationName = locationName;
    tempLocation.addressLineOne = addressLineOne;
    tempLocation.city = city;
    tempLocation.state = state;
    tempLocation.zip = zip;
    tempLocation.country = country;
    tempLocation.phone = phone;
    tempLocation.website = website;
    setCreateLocationParam(tempLocation);
  }

  function FulFillsPageRequirements(pageIndex) {
    let fulfillsRequirements = true;
    pages[pageIndex].require.forEach(requirement => {
      if (!requirement.FunctionBool()) {
        fulfillsRequirements = false;
      }
    });
    return fulfillsRequirements;
  }

  return <Panel OnSubmit={OnSubmit} title="Create Location" pages={pages} />;
}

export default Location;
