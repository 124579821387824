import React from 'react';
import { BsTrash } from 'react-icons/bs';

import BBITable from '../../../helpers/bBITable/BBITable';
import Service from '../../assign/service/Vendor';
import Delete from '../../../api/internal/Delete';
import classes from '../../../assets/Global.module.css';

function Services({
  data,
  vendorId,
  RefreshPage,
}: {
  data: VendorServices;
  vendorId: Number;
  RefreshPage: Function;
}) {
  const formattedVendorServiceData = data.vendorServiceList.map(
    vendorServices => ({
      ...vendorServices,
      delete: (
        <BsTrash
          className={classes.clickable}
          onClick={() => {
            DeleteVendorService(vendorServices.vendorServiceId);
          }}
        />
      ),
    }),
  );

  let columnConfig = [
    {
      key: '1',
      attribute: 'vendorServiceType',
      header: 'Service',
    },
    {
      key: '2',
      attribute: 'note',
      header: 'Note',
    },
    {
      key: '3',
      attribute: 'delete',
      header: '',
    },
  ];

  function DeleteVendorService(vendorServiceId) {
    Delete(`/Vendor/DeleteVendorService/${vendorServiceId}`).then(() =>
      RefreshPage('Services', ['Summary', 'Audit']),
    );
  }

  return (
    <>
      <Service vendorId={vendorId} data={data} RefreshPage={RefreshPage} />
      <BBITable data={formattedVendorServiceData} columns={columnConfig} />
    </>
  );
}

export default Services;
