export function GetCurrentDate() {
  return new Date().toISOString().split('T')[0];
}

export function GetSixMonthsAgoDate() {
  const currentDate = new Date();
  // in milliseconds (6 months * 30 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
  const sixMonthsAgoTimestamp =
    currentDate.getTime() - 6 * 30 * 24 * 60 * 60 * 1000;
  // Create a new Date object from the timestamp
  const sixMonthsAgo = new Date(sixMonthsAgoTimestamp);
  // Get the ISO string for 30 days ago
  const isoStringSixMonthsAgo = sixMonthsAgo.toISOString();
  return isoStringSixMonthsAgo.split('T')[0];
}
