import React, { useState, useEffect } from 'react';
import Slab from '../../../../helpers/slab/Slab';
import PayablesAppliedCashFlowTable from './Summary';
import Admin from '../../receivable/AppliedPayments/Admin';
import Get from '../../../../api/internal/Get';
import { useRoleAuthorization } from '../../../../hooks/useRoleAuthorization';
import { ADMIN_AUTHORIZED_ROLES } from '../../../../auth/AuthRoles';

interface APAppliedPaymentsDataObject {
  Summary?: APAppliedPaymentsSummary;
  Admin?: AppliedPaymentsAdmin;
}

function APAppliedPayments({
  cashFlowId,
  startPage,
}: {
  cashFlowId: number;
  startPage?: String;
}) {
  const [currentPage, setCurrentPage] = useState();
  const [dataObject] = useState<APAppliedPaymentsDataObject>({});

  useEffect(() => {
    startPage ? HandlePageChange(startPage) : HandlePageChange('Summary');
  }, []);
  const HandlePageChange = params => {
    if (!dataObject[params] && pages[params].callerPath) {
      Get(pages[params].callerPath).then(response => {
        if (response) {
          dataObject[params] = response.data;
          AssignPages();
          setCurrentPage(params);
        }
      });
    } else {
      setCurrentPage(params);
    }
  };

  function RefreshPage(currentPage, additionalPages) {
    setCurrentPage(null);
    delete dataObject[currentPage];
    additionalPages?.forEach(pageName => {
      delete dataObject[pageName];
    });
    HandlePageChange(currentPage);
  }

  let pages = {};
  AssignPages();
  function AssignPages() {
    pages = {
      Summary: {
        content: (
          <PayablesAppliedCashFlowTable
            cashFlowId={cashFlowId}
            data={dataObject.Summary}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Accounting/GetVendorAppliedCashFlows/${cashFlowId}`,
      },
    };
    if (useRoleAuthorization(ADMIN_AUTHORIZED_ROLES))
      pages['Admin'] = {
        content: (
          <Admin
            cashFlowId={cashFlowId}
            data={dataObject.Admin}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Accounting/GetAppliedPaymentAdmin/${cashFlowId}`,
      };
  }

  return (
    <Slab
      title={'Applied Payments'}
      pages={pages}
      currentPage={currentPage}
      HandlePageChange={HandlePageChange}
      dataObject={dataObject}
    />
  );
}

export default APAppliedPayments;
