import React, { useEffect, useState } from 'react';

import AdditionalInfo from './AdditionalInfo';
import Panel from '../../../helpers/panel/Panel';
import Details from './Details';
import Close from '../../../helpers/panel/Close';
import PostBody from '../../../api/internal/PostBody';
import Get from '../../../api/internal/Get';
import CreateBilling from './Billing';
import View from '../../../helpers/slab/View';
import ViewVendor from '../../views/vendor/Vendor';

function Vendor() {
  const [displayBilling, setDisplayBilling] = useState(true);
  const [vendorOptions, setVendorOptions] = useState<CreateVendorOptions>();
  const [createVendorParam, setCreateVendorParam] = useState<CreateVendorParam>(
    {
      vendorName: null,
      website: null,
      phone: null,
      phoneExt: null,
      note: null,
      addressLineOne: null,
      addressLineTwo: null,
      city: null,
      state: null,
      zip: null,
      country: null,
      billingEntity: null,
      billingAddressLineOne: null,
      billingAddressLineTwo: null,
      billingCity: null,
      billingState: null,
      billingZip: null,
      billingCountry: null,
      billingNote: null,
      vendorTypeId: null,
      paymentMethodId: null,
      paymentTermId: null,
    },
  );
  const [requirementDisplay] = useState({
    vendorType: null,
    paymentMethod: null,
    paymentTerm: null,
  });

  useEffect(() => {
    GetCreateVendorOptions();
  }, []);

  function GetCreateVendorOptions() {
    Get(`/Vendor/GetCreateVendorOptions`).then(response => {
      if (response) {
        setVendorOptions(response.data);
        createVendorParam.paymentMethodId =
          response.data.defaultPaymentMethodId;
        createVendorParam.paymentTermId = response.data.defaultPaymentTermId;
      }
    });
  }

  function UpdateParam(newValue, attributeName) {
    if (newValue?.toString().trim().length !== 0) {
        createVendorParam[attributeName] = newValue;  
    } else {
      createVendorParam[attributeName] = null;
    }
  }

  function FulFillsPageRequirements(pageIndex) {
    let fulfillsRequirements = true;
    pages[pageIndex].require.forEach(requirement => {
      if (!requirement.FunctionBool()) {
        fulfillsRequirements = false;
      }
    });
    return fulfillsRequirements;
  }

  function UpdateVendorForAutoComplete(
    vendorName: string,
    addressLineOne: string,
    city: string,
    state: string,
    zip: string,
    country: string,
    phone: string,
    website: string,
  ) {
    let tempVendor = { ...createVendorParam };
    tempVendor.vendorName = vendorName;
    tempVendor.addressLineOne = addressLineOne;
    tempVendor.city = city;
    tempVendor.state = state;
    tempVendor.zip = zip;
    tempVendor.country = country;
    tempVendor.phone = phone;
    tempVendor.website = website;
    setCreateVendorParam(tempVendor);
  }

  function OnSubmit(e) {
    let fulfillsFormRequirements = true;
    pages.forEach((page, index) => {
      if (!FulFillsPageRequirements(index)) {
        fulfillsFormRequirements = false;
      }
    });
    if (fulfillsFormRequirements) {
      CreateVendor(e);
      Close();
    } else alert('Not all info included');
  }

  function CreateVendor(e) {
    !createVendorParam.billingEntity &&
      UpdateParam(createVendorParam.vendorName, 'billingEntity');
    !createVendorParam.billingAddressLineOne &&
      UpdateParam(createVendorParam.addressLineOne, 'billingAddressLineOne');
    !createVendorParam.billingAddressLineTwo &&
      UpdateParam(createVendorParam.addressLineTwo, 'billingAddressLineTwo');
    !createVendorParam.billingCity &&
      UpdateParam(createVendorParam.city, 'billingCity');
    !createVendorParam.billingState &&
      UpdateParam(createVendorParam.state, 'billingState');
    !createVendorParam.billingZip &&
      UpdateParam(createVendorParam.zip, 'billingZip');
    !createVendorParam.billingCountry &&
      UpdateParam(createVendorParam.country, 'billingCountry');

    PostBody(`/Vendor/CreateVendor`, createVendorParam).then(response => {
      if (response) {
        Close();
        View(
          <ViewVendor
            vendorId={response.data.vendorId}
            vendorName={response.data.vendorName}
          />,
        );
      }
    });
  }

  let pages = [
    {
      title: 'Details',
      displayedIcon: '',
      content: (
        <Details
          param={createVendorParam}
          UpdateParam={UpdateParam}
          UpdateVendorForAutoComplete={UpdateVendorForAutoComplete}
          displayBilling={displayBilling}
          ToggleBilling={() => setDisplayBilling(!displayBilling)}
        />
      ),
      require: [
        {
          label: 'Vendor Name',
          FunctionBool: () => createVendorParam.vendorName !== null,
          assignedValue: () => createVendorParam.vendorName,
          missingDesc: 'Vendor Name has not been assigned.',
        },
        {
          label: 'Address',
          FunctionBool: () =>
            createVendorParam.addressLineOne !== null &&
            createVendorParam.city !== null &&
            createVendorParam.state !== null &&
            createVendorParam.zip !== null &&
            createVendorParam.country !== null,
          assignedValue: () =>
            createVendorParam.addressLineOne +
            ' ' +
            (createVendorParam.addressLineTwo
              ? createVendorParam.addressLineTwo + ' '
              : '') +
            createVendorParam.city +
            ', ' +
            createVendorParam.state +
            ' ' +
            createVendorParam.zip +
            ' ' +
            createVendorParam.country,
          missingDesc: 'Address has not been properly assigned.',
        },
        {
          label: 'Phone',
          FunctionBool: () =>
            createVendorParam.phone !== null &&
            createVendorParam.phone !== '' &&
            createVendorParam.phone?.startsWith('+')
              ? createVendorParam?.phone?.length >= 17
              : createVendorParam?.phone?.length >= 14,
          assignedValue: () =>
            createVendorParam.phone +
            (createVendorParam.phoneExt
              ? ' Ext: ' + createVendorParam.phoneExt
              : ''),
          missingDesc: 'Phone Number has not been properly assigned.',
        },
      ],
    },
    {
      title: 'Additional Info',
      displayedIcon: '',
      content: (
        <AdditionalInfo
          param={createVendorParam}
          requirementDisplay={requirementDisplay}
          UpdateParam={UpdateParam}
          vendorOptions={vendorOptions}
        />
      ),
      require: [
        {
          label: 'Vendor Type',
          FunctionBool: () => createVendorParam.vendorTypeId !== null,
          assignedValue: () => requirementDisplay.vendorType,
          missingDesc: 'Vendor Type has not been assigned.',
        },
      ],
    },
  ];

  !displayBilling &&
    pages.splice(1, 0, {
      title: 'Billing',
      displayedIcon: '',
      content: (
        <CreateBilling
          createParam={createVendorParam}
          UpdateParam={UpdateParam}
        />
      ),
      require: [
        {
          label: 'Billing Entity Name',
          FunctionBool: () => createVendorParam.billingEntity !== null,
          assignedValue: () => createVendorParam.billingEntity,
          missingDesc: 'Billing Entity Name has not been assigned',
        },
        {
          label: 'Billing Address',
          FunctionBool: () =>
            createVendorParam.billingAddressLineOne !== null &&
            createVendorParam.billingCity !== null &&
            createVendorParam.billingState !== null &&
            createVendorParam.billingZip !== null &&
            createVendorParam.billingCountry !== null,
          assignedValue: () =>
            createVendorParam.billingAddressLineOne +
            ' ' +
            (createVendorParam.billingAddressLineTwo
              ? createVendorParam.billingAddressLineTwo + ' '
              : '') +
            createVendorParam.billingCity +
            ', ' +
            createVendorParam.billingState +
            ' ' +
            createVendorParam.billingZip +
            ' ' +
            createVendorParam.billingCountry,
          missingDesc: 'Billing address has not been properly assigned ',
        },
      ],
    });

  return <Panel OnSubmit={OnSubmit} title="Create Vendor" pages={pages} />;
}

export default Vendor;
