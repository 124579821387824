import React from 'react';

export const DefaultDocumentTypeOptions = () => {
  return (
    <>
      <option value="W9">W9</option>
      <option value="Comchek Release">Comchek Release</option>
      <option value="Image">Image</option>
      <option value="Claim Notice Form">Claim Notice Form</option>
      <option value="POD">POD</option>
      <option value="Unloading Receipts">Unloading Receipts</option>
      <option value="Other">Other</option>
    </>
  );
};

export const CustomerDocumentTypeOptions = () => {
  return (
    <>
      <option value="Customer Packet">Customer Packet</option>
      <option value="Customer Contract">Customer Contract</option>
      <option value="Load Waiver">Load Waiver</option>
      <option value="Other - Misc">Other - Misc</option>
    </>
  );
};

export const LoadDocumentTypeOptions = () => {
  return (
    <>
      <option value="Rate Confirmation">Rate Confirmation</option>
      <option value="POD">POD</option>
      <option value="Unloading Receipts">Unloading Receipts</option>
      <option value="Repairs">Repairs</option>
      <option value="Other">Other</option>
    </>
  );
};

export const GeneralDocumentTypeOptions = () => {
  return (
    <>
      <option value="Training Module">Training Module</option>
      <option value="Customer Packet">Customer Packet</option>
      <option value="Safety Manual">Safety Manual</option>
      <option value="Other">Other</option>
    </>
  );
};

export const MaintenanceLogDocumentTypeOptions = () => {
  return (
    <>
      <option value="Maintenance">Maintenance</option>
    </>
  );
};
