import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { MdDownload } from 'react-icons/md';

import BBITable from '../../helpers/bBITable/BBITable';
import Filter from '../../helpers/filter/Filter';
import FilterData from '../../helpers/filter/FilterData';
import Get from '../../api/internal/Get';
import Header from '../../layouts/Header';
import View from '../../helpers/slab/View';
import ViewLoad from '../../features/views/load/Load';

function Ledger({ toggleBoardRefresh }) {
  const [filterValue, setFilterValue] = useState('');
  const [ledegerBoard, setLedgerBoard] = useState<Array<LedgerBoard>>([]);
  // const [isOpen, setIsOpen] = useState(false);

  const csvFileName =
    new Date().toISOString().split('T')[0].replace(/[-]/g, '') +
    '_Ledger Report.csv';

  useEffect(() => {
    Get(`/Accounting/GetLedgerBoard/`).then(response => {
      if (response) {
        setLedgerBoard(response.data);
      }
    });
  }, [toggleBoardRefresh]);

  const formattedLedgerBoard = ledegerBoard.map(journalEntry => ({
    ...journalEntry,
    amount: journalEntry.amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }),
    isApproved: journalEntry.isApproved ? 'X' : null,
    isImportedIntoQuickBooks: journalEntry.isImportedIntoQuickBooks
      ? 'X'
      : null,
  }));

  let columnConfig = [
    {
      key: '1',
      attribute: 'journalEntryId',
      header: 'Journal Entry #',
      dataType: 'number',
    },
    {
      key: '2',
      attribute: 'relationType',
      header: 'Relation Type',
    },
    {
      key: '4',
      attribute: 'relationName',
      header: 'Relation Name',
    },
    {
      key: '5',
      attribute: 'loadId',
      header: 'Load #',
      onClick: e => View(<ViewLoad loadId={e.target.innerText} />),
      dataType: 'number',
    },
    {
      key: '6',
      attribute: 'assetName',
      header: 'Asset Name',
    },
    {
      key: '7',
      attribute: 'note',
      header: 'Note',
    },
    {
      key: '8',
      attribute: 'date',
      header: 'Date',
      dataType: 'date',
    },
    {
      key: '9',
      attribute: 'amount',
      header: 'Amount',
      dataType: 'currency',
    },
    {
      key: '10',
      attribute: 'isApproved',
      header: 'Approved',
    },
    {
      key: '11',
      attribute: 'isImportedIntoQuickBooks',
      header: 'Uploaded To QuickBooks',
    },
  ];

  let tableData = FilterData(formattedLedgerBoard, filterValue);

  return (
    <>
      <h1>Ledger</h1>
      <Header>
        <div>
          <button>
            <CSVLink
              data={ledegerBoard}
              headers={[
                { label: 'Journal Entry Number', key: 'journalEntryId' },
                { label: 'Relation Type', key: 'relationType' },
                { label: 'Relation Number', key: 'relationNumber' },
                { label: 'Relation Name', key: 'relationName' },
                { label: 'Load Number', key: 'loadId' },
                { label: 'Asset Number', key: 'assetId' },
                { label: 'Asset Name', key: 'assetName' },
                { label: 'Note', key: 'note' },
                { label: 'Amount', key: 'amount' },
                { label: 'Approved', key: 'isApproved' },
                {
                  label: 'Uploaded To QuickBooks',
                  key: 'isImportedIntoQuickBooks',
                },
              ]}
              filename={csvFileName}
            >
              <MdDownload />
              <span>Download</span>
            </CSVLink>
          </button>
        </div>
        <div>
          <Filter
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            placeholder="Filter journal entries"
          />
        </div>
      </Header>
      <BBITable data={tableData} columns={columnConfig} />
    </>
  );
}

export default Ledger;
