function TimeFormatter(timeObject) {
  const time = new Date();
  const [hours, minutes] = timeObject.split(':');

  time.setHours(hours);
  time.setMinutes(minutes);

  const timeString = time.toLocaleTimeString([], {
    hour: 'numeric',
    minute: '2-digit',
  });

  return timeString;
}

export default TimeFormatter;
