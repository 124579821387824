import React from 'react';

import BBITable from '../../../helpers/bBITable/BBITable';
import CreateNotes from '../../creates/notes/Vendor';
import DateTimeUTCFormatter from '../../../helpers/inputs/DateTimeUTCFormatter';

function Notes({
  data,
  vendorId,
  RefreshPage,
}: {
  data: VendorNotes;
  vendorId: Number;
  RefreshPage: Function;
}) {
  const formattedVendorNoteData = data.notes.map(vendorNote => ({
    ...vendorNote,
    date: DateTimeUTCFormatter(vendorNote.date),
  }));

  let columnConfig = [
    {
      key: '1',
      attribute: 'createdBy',
      header: 'Created By',
      width: '12ch',
    },
    {
      key: '2',
      attribute: 'contactName',
      header: 'Contact',
      width: '12ch',
    },
    {
      key: '3',
      attribute: 'date',
      header: 'Date',
      width: '6ch',
    },
    {
      key: '4',
      attribute: 'note',
      header: 'Note',
      width: 'auto',
    },
  ];

  return (
    <>
      <CreateNotes
        contactOptions={data.contactOptions}
        vendorId={vendorId}
        RefreshPage={RefreshPage}
      />
      <BBITable data={formattedVendorNoteData} columns={columnConfig} />
    </>
  );
}
export default Notes;
