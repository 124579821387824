import React from 'react';

export const TruckTireDiagram = () => {
  return (
    <svg
      width="210mm"
      height="297mm"
      viewBox="0 0 210 297"
      version="1.1"
      id="svg5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs id="defs2" />
      <g id="layer1">
        <rect
          style={{
            fill: 'none',
            stroke: '#000000',
            strokeWidth: 0.499999,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
          id="rect234"
          width="107.15585"
          height="70.758781"
          x="50.030174"
          y="108.01315"
          ry="6.5755444"
        />
        <g id="g452" transform="translate(-6.3500003)">
          <path
            id="rect445"
            style={{
              fill: 'none',
              stroke: '#000000',
              strokeWidth: 0.499999,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
            d="M 82.818246,35.172195 H 109.60637 V 108.0727 H 73.616219 Z"
          />
          <path
            id="path448"
            style={{
              fill: 'none',
              stroke: '#000000',
              strokeWidth: 0.499999,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
            }}
            d="M 136.38791,35.172195 H 109.59963 V 108.0727 h 35.9903 z"
          />
        </g>
        <path
          style={{
            fill: 'none',
            stroke: '#000000',
            strokeWidth: 0.499999,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
          d="m 81.603836,178.7781 h 43.351774 v 47.91573 H 81.603836 Z"
          id="rect462"
        />
        <path
          style={{
            fill: 'none',
            stroke: '#000000',
            strokeWidth: 0.499999,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
          d="m 81.565594,228.75964 h 43.351766 v 36.05887 H 81.565594 Z"
          id="rect462-2"
        />
        <path
          style={{
            fill: 'none',
            stroke: '#000000',
            strokeWidth: 0.499999,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
          d="M 81.421002,266.98152 H 124.77276 V 288.4194 H 81.421002 Z"
          id="rect462-2-0"
        />
        <g
          id="g1697-4-1-1"
          transform="matrix(0.98796334,0,0,0.96304286,15.598198,149.89808)"
          style={{ strokeWidth: 1.0252 }}
        />
        <g
          id="g1697-3-6-6-2"
          transform="matrix(0.98796334,0,0,0.96304286,-66.474139,149.93171)"
          style={{ strokeWidth: 1.0252 }}
        />
        <g
          id="g808"
          transform="matrix(1,0,0,1.1245204,0,-4.873306)"
          style={{ strokeWidth: 0.94301 }}
        >
          <rect
            style={{
              fill: 'none',
              fillOpacity: 1,
              stroke: '#000000',
              strokeWidth: 0.611691,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeDasharray: 'none',
              strokeOpacity: 1,
            }}
            id="rect898-9-3-3-3-3"
            width="14.045396"
            height="32.333855"
            x="54.864353"
            y="39.647091"
            ry="2.8441653"
          />
          <rect
            style={{
              fill: 'none',
              fillOpacity: 1,
              stroke: '#000000',
              strokeWidth: 0.611691,
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeDasharray: 'none',
              strokeOpacity: 1,
            }}
            id="rect898-9-3-3-3-3-5"
            width="14.045396"
            height="32.333855"
            x="137.42644"
            y="39.136608"
            ry="2.8441653"
          />
        </g>
        <g id="g827" transform="translate(0,15.875004)">
          <g id="g782" transform="translate(0,20.10834)">
            <path
              id="rect898-4-6-3"
              style={{
                fill: 'none',
                stroke: '#000000',
                strokeWidth: 0.665001,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
              }}
              d="m 147.91704,174.6971 h 8.30989 c 1.63614,0 2.95332,1.31717 2.95332,2.95331 v 27.66806 c 0,1.63613 -1.31718,2.95331 -2.95332,2.95331 h -8.30989 c -1.63613,0 -2.95331,-1.31718 -2.95331,-2.95331 v -27.66806 c 0,-1.63614 1.31718,-2.95331 2.95331,-2.95331 z"
            />
            <path
              id="rect898-0-3-9-1"
              style={{
                fill: 'none',
                stroke: '#000000',
                strokeWidth: 0.665001,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
              }}
              d="m 132.47663,174.76436 h 8.30989 c 1.63613,0 2.95331,1.31718 2.95331,2.95331 v 27.66806 c 0,1.63613 -1.31718,2.95331 -2.95331,2.95331 h -8.30989 c -1.63614,0 -2.95331,-1.31718 -2.95331,-2.95331 v -27.66806 c 0,-1.63613 1.31717,-2.95331 2.95331,-2.95331 z"
            />
            <path
              id="rect898-9-3-3-3"
              style={{
                fill: 'none',
                stroke: '#000000',
                strokeWidth: 0.665001,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
              }}
              d="m 65.844711,174.73073 h 8.309893 c 1.636134,0 2.953311,1.31717 2.953311,2.95331 v 27.66806 c 0,1.63613 -1.317177,2.95331 -2.953311,2.95331 h -8.309893 c -1.636135,0 -2.953312,-1.31718 -2.953312,-2.95331 v -27.66806 c 0,-1.63614 1.317177,-2.95331 2.953312,-2.95331 z"
            />
            <path
              id="rect898-0-5-6-4-5"
              style={{
                fill: 'none',
                stroke: '#000000',
                strokeWidth: 0.665001,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
              }}
              d="m 50.404289,174.798 h 8.309893 c 1.636134,0 2.953311,1.31718 2.953311,2.95332 v 27.66805 c 0,1.63614 -1.317177,2.95331 -2.953311,2.95331 h -8.309893 c -1.636135,0 -2.953312,-1.31717 -2.953312,-2.95331 v -27.66805 c 0,-1.63614 1.317177,-2.95332 2.953312,-2.95332 z"
            />
            <path
              style={{
                fill: 'none',
                stroke: '#000000',
                strokeWidth: 0.499999,
                strokeLinecap: 'round',
                strokeLinejoin: 'round',
              }}
              d="m 77.19693,190.70734 h 52.30741 v 2.06889 H 77.19693 Z"
              id="rect1825-8"
            />
          </g>
          <g
            id="g1924-9-5"
            style={{ strokeWidth: 1.0252 }}
            transform="matrix(0.98796334,0,0,0.96304286,-15.08328,65.844473)"
          >
            <g id="g1849-0-4" style={{ strokeWidth: 1.0252 }}>
              <g
                id="g1697-4-1-1-3"
                transform="translate(30.755192,147.81935)"
                style={{ strokeWidth: 1.0252 }}
              />
              <g
                id="g1697-3-6-6-2-6"
                transform="translate(-52.317058,147.85428)"
                style={{ strokeWidth: 1.0252 }}
              />
              <path
                id="rect898-4-6-3-1"
                style={{
                  fill: 'none',
                  stroke: '#000000',
                  strokeWidth: 0.681756,
                  strokeLinecap: 'round',
                  strokeLinejoin: 'round',
                }}
                d="m 164.76347,173.57004 h 8.25643 c 1.69892,0 3.06664,1.36772 3.06664,3.06664 v 28.72983 c 0,1.69892 -1.36772,3.06665 -3.06664,3.06665 h -8.25643 c -1.69892,0 -3.06665,-1.36773 -3.06665,-3.06665 v -28.72983 c 0,-1.69892 1.36773,-3.06664 3.06665,-3.06664 z"
              />
              <path
                id="rect898-0-3-9-1-7"
                style={{
                  fill: 'none',
                  stroke: '#000000',
                  strokeWidth: 0.681756,
                  strokeLinecap: 'round',
                  strokeLinejoin: 'round',
                }}
                d="m 149.13494,173.63989 h 8.25643 c 1.69892,0 3.06665,1.36773 3.06665,3.06665 v 28.72983 c 0,1.69892 -1.36773,3.06664 -3.06665,3.06664 h -8.25643 c -1.69892,0 -3.06664,-1.36772 -3.06664,-3.06664 v -28.72983 c 0,-1.69892 1.36772,-3.06665 3.06664,-3.06665 z"
              />
              <path
                id="rect898-9-3-3-3-6"
                style={{
                  fill: 'none',
                  stroke: '#000000',
                  strokeWidth: 0.681756,
                  strokeLinecap: 'round',
                  strokeLinejoin: 'round',
                }}
                d="m 81.691226,173.60497 h 8.256429 c 1.698921,0 3.066645,1.36772 3.066645,3.06664 v 28.72983 c 0,1.69892 -1.367724,3.06665 -3.066645,3.06665 h -8.256429 c -1.698922,0 -3.066646,-1.36773 -3.066646,-3.06665 v -28.72983 c 0,-1.69892 1.367724,-3.06664 3.066646,-3.06664 z"
              />
              <path
                id="rect898-0-5-6-4-5-2"
                style={{
                  fill: 'none',
                  stroke: '#000000',
                  strokeWidth: 0.681756,
                  strokeLinecap: 'round',
                  strokeLinejoin: 'round',
                }}
                d="m 66.06269,173.67482 h 8.256428 c 1.698922,0 3.066646,1.36772 3.066646,3.06665 v 28.72983 c 0,1.69892 -1.367724,3.06664 -3.066646,3.06664 H 66.06269 c -1.698922,0 -3.066646,-1.36772 -3.066646,-3.06664 v -28.72983 c 0,-1.69893 1.367724,-3.06665 3.066646,-3.06665 z"
              />
              <path
                style={{
                  fill: 'none',
                  stroke: '#000000',
                  strokeWidth: 0.512597,
                  strokeLinecap: 'round',
                  strokeLinejoin: 'round',
                }}
                d="m 93.104401,190.19469 h 52.944679 v 2.14829 H 93.104401 Z"
                id="rect1825-8-3"
              />
            </g>
          </g>
        </g>
        <rect
          style={{
            fill: 'none',
            stroke: '#000000',
            strokeWidth: 0.499999,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
          id="rect810"
          width="75.189873"
          height="4.3378778"
          x="65.449677"
          y="30.654335"
          ry="1.1226969"
        />
        <path
          id="rect829-3"
          style={{
            fill: 'none',
            stroke: '#000000',
            strokeWidth: 0.499999,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
          d="m 58.071908,178.80443 v 17.78134 c -0.0206,0.0808 -0.03129,0.48717 -0.03204,0.56822 4.8e-5,1.82459 4.927405,2.97836 11.00553,2.97833 5.810923,-1.7e-4 10.620076,-1.3565 10.982792,-3.09748 h 0.02222 v -0.20619 -18.02422 z"
        />
        <path
          id="rect829-3-1"
          style={{
            fill: 'none',
            stroke: '#000000',
            strokeWidth: 0.499999,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
          d="m 126.49879,178.83481 v 17.78134 c -0.0206,0.0808 -0.0313,0.48717 -0.032,0.56822 4e-5,1.82459 4.9274,2.97836 11.00553,2.97833 5.81092,-1.7e-4 10.62007,-1.3565 10.98279,-3.09748 h 0.0222 v -0.20619 -18.02422 z"
        />
        <ellipse
          style={{
            fill: 'none',
            stroke: '#000000',
            strokeWidth: 0.499999,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
          id="path893"
          cx="58.892975"
          cy="99.093102"
          rx="8.9975376"
          ry="8.5885582"
        />
        <ellipse
          style={{
            fill: 'none',
            stroke: '#000000',
            strokeWidth: 0.499999,
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
          }}
          id="path893-6"
          cx="147.64142"
          cy="99.261703"
          rx="8.9975376"
          ry="8.5885582"
        />
      </g>
    </svg>
  );
};

export default TruckTireDiagram;
