import React, { useState } from 'react';

import Form from '../../../assets/essentials/Form';
import classes from '../../../assets/Global.module.css';
import Select from '../../../assets/essentials/Select';
import PutForm from '../../../api/internal/PutForm';
import Button from '../../../assets/essentials/Button';
import OptionList from '../../../helpers/options/OptionList';
import CurrencyInput from '../../../helpers/inputs/CurrencyInput';
import Input from '../../../assets/essentials/Input';
import PostBody from '../../../api/internal/PostBody';

export default function Admin({
  data,
  customerId,
  RefreshPage,
}: {
  data: CustomerAdmin;
  customerId: number;
  RefreshPage: Function;
}) {
  function FormatCurrency(amount) {
    // Format as currency with two decimal places and a comma for thousands separator
    return amount?.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  const customerHasQuickbooksId =
    data?.quickBooksCustomerId !== null && data?.quickBooksCustomerId !== '';

  function UpdateCustomerAdminDetails(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.set(
      'creditLimit',
      e.target.creditLimit.value.replace(/[\$,]/g, ''),
    ); //removes $ and , from string
    const appendix = {
      CustomerId: customerId,
    };
    PutForm(`/Customer/UpdateCustomerAdminDetails`, formData, appendix).then(
      response => {
        if (response) {
          RefreshPage('Admin', ['Audit', 'Summary']);
        }
      },
    );
  }

  const CreateQuickbooksIdButton = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    function PostCustomerToQuickbooks() {
      setIsLoading(true);
      if (customerId)
        PostBody('Accounting/PostCustomerToQuickBooks', {
          customerId: Number(customerId),
        })
          .then(response => {
            if (response) {
              RefreshPage('Admin', ['Audit']);
            }
          })
          .finally(() => setIsLoading(false));
    }
    return (
      <div>
        {isLoading ? (
          <Button disabled>Pending...</Button>
        ) : (
          <Button
            variant="good"
            disabled={customerHasQuickbooksId}
            onClick={() => PostCustomerToQuickbooks()}
            type="button"
          >
            {customerHasQuickbooksId
              ? 'ID Already Exists'
              : 'Post To QuickBooks'}
          </Button>
        )}
      </div>
    );
  };

  return (
    <Form onSubmit={e => UpdateCustomerAdminDetails(e)}>
      <div className={`${classes.attribute}`}>
        <label>Customer Status</label>
        <Select defaultValue={data?.customerStatusId} name="customerStatusId">
          <OptionList
            optionData={data?.customerStatusOptions}
            attributeID="customerStatusId"
            attributeName="status"
          />
        </Select>
      </div>
      <div className={classes.attribute}>
        <label>QuickBooks Customer ID</label>
        <Input
          defaultValue={data?.quickBooksCustomerId}
          name="quickBooksCustomerId"
        />
      </div>
      <div className={`${classes.marginTop1Rem} ${classes.attribute}`}>
        <CreateQuickbooksIdButton />
      </div>
      <div className={classes.attribute}>
        <label>Credit Limit</label>
        <CurrencyInput defaultValue={data?.creditLimit} name="creditLimit" />
      </div>
      <div className={`${classes.span2} ${classes.attribute}`}>
        <label>Available Credit</label>
        <p>{FormatCurrency(data.availableCredit)}</p>
      </div>
      <div>
        <Button variant="good" type="submit">
          Save Changes
        </Button>
      </div>
    </Form>
  );
}
