import React, { useEffect, useState } from 'react';
import classes from '../../assets/Global.module.css';
import classesSearch from '../../helpers/search/CustomSearch.module.css';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import Get from '../../api/internal/Get';
import View from '../../helpers/slab/View';
import ViewLocation from '../../features/views/location/Location';
import FilterData from '../../helpers/filter/FilterData';
import Input from '../../assets/essentials/Input';
import Select from '../../assets/essentials/Select';
import StatesAndProvinces from '../../helpers/options/StatesAndProvinces';
import Button from '../../assets/essentials/Button';
import BBITable from '../../helpers/bBITable/BBITable';

const LocationSearch = ({ toggleBoardRefresh }) => {
  const [locationBoard, setLocationBoard] = useState<Array<LocationBoard>>([]);
  const [searchLocationParams, setSearchLocationParams] = useSearchParams();
  const filterValue = useOutletContext();

  useEffect(() => {
    Get(`/Location/SearchLocation?${searchLocationParams.toString()}`).then(
      response => {
        if (response) {
          setLocationBoard(response.data);
        }
      },
    );
  }, [toggleBoardRefresh, searchLocationParams]);

  const handleFormSubmit = event => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const newParams = new URLSearchParams();
    formData.forEach((value, key) => {
      if (value !== '') {
        newParams.set(key, value.toString());
      }
    });
    setSearchLocationParams(newParams, { replace: true });
  };

  let columnConfig = [
    {
      key: '1',
      attribute: 'locationName',
      attributeprimaryhidden: 'locationId',
      header: 'Location Name',
      onClick: e =>
        View(
          <ViewLocation
            locationName={e.target.innerText}
            locationId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '2',
      attribute: 'address',
      header: 'Address',
    },
    {
      key: '3',
      attribute: 'city',
      header: 'City',
    },
    {
      key: '4',
      attribute: 'state',
      header: 'State',
    },
    {
      key: '5',
      attribute: 'phone',
      header: 'Phone',
    },
  ];

  let tableData = FilterData(locationBoard, filterValue);

  return (
    <>
      <div className={`${classesSearch.customSearch}`}>
        <form onSubmit={handleFormSubmit}>
          <label>
            Location Name
            <Input type="text" name="locationName" />
          </label>
          <label>
            Address Line One
            <Input type="text" name="addressLineOne" />
          </label>
          <label>
            City
            <Input type="text" name="city" />
          </label>
          <label>
            State
            <Select name="state">
              <option value="" />
              <StatesAndProvinces />
            </Select>
          </label>
          <div className={classesSearch.submitHolder}>
            <Button type="submit" variant="good">
              Search
            </Button>
            <Button
              type={'reset'}
              variant="bad"
              onClick={() => setSearchLocationParams({})}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
      <BBITable data={tableData} columns={columnConfig} />
    </>
  );
};

export default LocationSearch;
