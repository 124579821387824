import React, { useState } from 'react';
import { BsFileEarmarkArrowUp, BsTrash } from 'react-icons/bs';

import CreateFile from './CreateFile';
import BBITable from '../../../helpers/bBITable/BBITable';
import PostForm from '../../../api/internal/PostForm';
import Delete from '../../../api/internal/Delete';
import DateTimeUTCFormatter from '../../../helpers/inputs/DateTimeUTCFormatter';
import classes from '../../../assets/Global.module.css';
import classesFiles from './File.module.css';
import Create from '../../../helpers/error/Create';
import Error from '../../../helpers/error/Error';
import EmailAllFiles from './EmailAllFiles';

export type FileCategory =
  | 'driver'
  | 'asset'
  | 'customer'
  | 'load'
  | 'rental'
  | 'invoice'
  | 'maintenance-log'
  | 'general-documents';

function Files({
  data,
  fileCategory,
  categoryId,
  RefreshPage,
}: {
  data: Array<BlobFile>;
  categoryId: number;
  fileCategory: FileCategory;
  RefreshPage: Function;
}) {
  const [isCreateFileOpen, setIsCreateFileOpen] = useState<boolean>(false);
  const [isEmailFilesOpen, setIsEmailFilesOpen] = useState<boolean>(false);
  const [emailRecipients, setEmailRecipients] = useState<string[]>([]);

  const newData = data.map(file => ({
    ...file,
    name: (
      <a href={file.uri} target="_blank" rel="noreferrer">
        {file.name}
      </a>
    ),
    type: file.metadata.documentType,
    date: DateTimeUTCFormatter(file.createdOn),
    updatedBy: file.metadata.uploadedBy,
    delete: (
      <BsTrash
        className={`${classes.clickable} ${classes.center}`}
        onClick={() => {
          DeleteFile(file.name);
        }}
      />
    ),
  }));

  const columnConfig = [
    {
      key: '1',
      attribute: 'name',
      header: 'Name',
    },
    {
      key: '2',
      attribute: 'type',
      header: 'Type',
    },
    {
      key: '3',
      attribute: 'date',
      header: 'Date',
    },
    {
      key: '4',
      attribute: 'updatedBy',
      header: 'Uploaded By',
    },
    {
      key: '5',
      attribute: 'delete',
      width: '3ch',
    },
  ];

  function UploadFiles(e) {
    const formData = new FormData(e.target);
    PostForm(`/File/UploadFiles/${fileCategory}-${categoryId}`, formData).then(
      response => {
        if (response) {
          RefreshPage('Files', ['Audit', 'Maintenance Logs']);
          if (response.status === 207)
            Create(
              <Error
                customMessage={response.data ?? 'Some files failed to upload.'}
                messageCharacterLimit={400}
              />,
            );
        }
      },
    );
  }

  function DeleteFile(fileName: string) {
    const encodedFileName = encodeURIComponent(fileName);
    Delete(
      `/File/DeleteFile/${fileCategory}-${categoryId}/${encodedFileName}`,
    ).then(() => {
      RefreshPage('Files', ['Audit', 'Maintenance Logs']);
    });
  }
  return (
    <>
      <div className={classesFiles.btnHolder}>
        <button
          type="button"
          onClick={() => {
            setIsCreateFileOpen(!isCreateFileOpen);
            setIsEmailFilesOpen(false);
          }}
        >
          <BsFileEarmarkArrowUp />
          <span>Upload File</span>
        </button>
        {fileCategory === 'asset' ? (
          <button
            type="button"
            onClick={() => {
              setIsEmailFilesOpen(!isEmailFilesOpen);
              setIsCreateFileOpen(false);
            }}
          >
            <BsFileEarmarkArrowUp />
            <span>Email All Files</span>
          </button>
        ) : null}
      </div>
      <CreateFile
        UploadFile={UploadFiles}
        uploadFileListType={fileCategory}
        isOpen={isCreateFileOpen}
        setIsOpen={setIsCreateFileOpen}
      />
      {fileCategory === 'asset' && isEmailFilesOpen ? (
        <EmailAllFiles
          fileCategory={fileCategory}
          categoryId={categoryId}
          emailRecipients={emailRecipients}
          setEmailRecipients={setEmailRecipients}
          isOpen={isEmailFilesOpen}
          setIsOpen={setIsEmailFilesOpen}
        />
      ) : null}
      {data.length !== 0 ? (
        <BBITable data={newData} columns={columnConfig} />
      ) : (
        <div>No files have been uploaded.</div>
      )}
    </>
  );
}
export default Files;
