import React, { useEffect, useState } from 'react';
import Get from '../../api/internal/Get';
import ViewLoad from '../../features/views/load/Load';
import DateTimeUTCFormatter from '../../helpers/inputs/DateTimeUTCFormatter';
import BBITable from '../../helpers/bBITable/BBITable';
import View from '../../helpers/slab/View';
import ViewDriver from '../../features/views/users/driver/Driver';
import ViewTrailer from '../../features/views/assets/trailer/Trailer';
import ViewTruck from '../../features/views/assets/truck/Truck';
import classes from '../../features/views/assets/AssetLocations.module.css';
import Header from '../../layouts/Header';
import { FaMapMarkedAlt, FaMapMarkerAlt } from 'react-icons/fa';
import Filter from '../../helpers/filter/Filter';
import FilterData from '../../helpers/filter/FilterData';
import { FcCheckmark } from 'react-icons/fc';
import AllAssetsLocationMap from '../../helpers/AllAssetsLocationMap';
import GreenTruckIcon from '../../assets/greenTruckIcon.png';
import BlueTruckIcon from '../../assets/blueTruckIcon.png';

type LoadingStatusOption = 'idle' | 'pending' | 'success';

function AssetLocations() {
  const [assetLocations, setAssetLocations] = useState<AssetLocation[]>([]);
  const [loadingStatus, setLoadingStatus] =
    useState<LoadingStatusOption>('idle');
  const [filterValue, setFilterValue] = useState('');
  const [toggleRerender, setToggleRerender] = useState(false);

  useEffect(() => {
    // Makes table
    GetLatestAssetLocationTableData();
  }, []);

  function UpdateAssetLocations() {
    setLoadingStatus('pending');
    Get('/Asset/GetAllSamsaraLocationsForAllAssets').then(response => {
      if (response) {
        GetLatestAssetLocationTableData();
        setLoadingStatus('success');
      } else {
        setLoadingStatus('idle');
      }
    });
  }

  function GetLatestAssetLocationTableData() {
    Get('/Asset/GetSingleLocationForAllAssets').then(response => {
      if (response) {
        setAssetLocations(response.data);
      }
    });
  }

  const filteredAssetLocations: AssetLocation[] = FilterData(
    assetLocations,
    filterValue,
  );

  const AssetLocationTable = ({ filteredData }) => {
    const formattedAssetLocations = filteredData?.map(assetLocation => ({
      ...assetLocation,
      locationDate: DateTimeUTCFormatter(assetLocation.locationDate),
      // Changing assetType to uppercase on the backend breaks the Samsara API call so it's being formatted on the frontend
      assetType:
        assetLocation.assetType.charAt(0).toUpperCase() +
        assetLocation.assetType.slice(1),
    }));

    const columnConfig = [
      {
        key: '1',
        attribute: 'assetName',
        attributeprimaryhidden: 'assetId',
        attributesecondaryhidden: 'assetType',
        attributetertiaryhidden: 'assetName',
        header: 'Asset Name',
        onClick: e =>
          View(
            e.target.dataset.attributesecondaryhidden === 'Truck' ? (
              <ViewTruck
                assetId={e.target.dataset.attributeprimaryhidden}
                truckName={e.target.dataset.attributetertiaryhidden}
              />
            ) : (
              <ViewTrailer
                assetId={e.target.dataset.attributeprimaryhidden}
                trailerName={e.target.dataset.attributetertiaryhidden}
              />
            ),
          ),
      },
      {
        key: '2',
        attribute: 'assetType',
        header: 'Type',
      },
      {
        key: '3',
        attribute: 'driverName',
        attributeprimaryhidden: 'employeeId',
        header: 'Driver',
        onClick: e =>
          e.target.dataset.attributeprimaryhidden &&
          View(
            <ViewDriver
              employeeId={e.target.dataset.attributeprimaryhidden}
              driverName={e.target.innerText}
            />,
          ),
      },
      {
        key: '4',
        attribute: 'loadId',
        header: 'Load ID',
        onClick: e => View(<ViewLoad loadId={e.target.innerText} />),
      },
      {
        key: '5',
        attribute: 'estimatedAddress',
        header: 'Estimated Address',
      },
      {
        key: '6',
        attribute: 'locationDate',
        header: 'Location Date',
      },
      {
        key: '7',
        attribute: 'odometerMiles',
        header: 'Odometer',
      },
    ];

    return (
      <div className={classes.tableContainer}>
        <BBITable data={formattedAssetLocations} columns={columnConfig} />
      </div>
    );
  };

  const filteredLocationCoordinates: AssetCoordinates[] =
    filteredAssetLocations.map(assetLocation => ({
      latitude: assetLocation.latitude,
      longitude: assetLocation.longitude,
      locationDate: assetLocation.locationDate,
      assetType: assetLocation.assetType as 'Truck' | 'Trailer',
      assetName: assetLocation.assetName,
      estimatedAddress: assetLocation.estimatedAddress,
    }));

  const hasMultipleAssetCoordinates = filteredLocationCoordinates.length > 0;

  const LoadingStatusButton = () => {
    if (loadingStatus === 'success') {
      return (
        <button>
          {'Success'} <FcCheckmark />
        </button>
      );
    } else if (loadingStatus === 'pending') {
      return <button>Loading...</button>;
    } else {
      return null;
    }
  };

  return (
    <>
      <h1>Asset Locations</h1>
      <Header>
        <div>
          <button
            onClick={() => UpdateAssetLocations()}
            disabled={loadingStatus === 'pending'}
          >
            <FaMapMarkerAlt />
            Update Asset Locations
          </button>
          <LoadingStatusButton />
        </div>
        <div>
          <button onClick={() => setToggleRerender(!toggleRerender)}>
            <FaMapMarkedAlt />
            Refresh Map
          </button>
          <Filter
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            placeholder="Filter assets"
          />
        </div>
      </Header>
      <div className={classes.mapContainer}>
        {hasMultipleAssetCoordinates && (
          <AllAssetsLocationMap
            coordinates={filteredLocationCoordinates}
            toggleRerender={toggleRerender}
          />
        )}
      </div>
      <div className={classes.legend}>
        <div className={classes.legendItem}>
          <img
            src={BlueTruckIcon}
            alt="blue truck legend icon"
            className={classes.icon}
          />
          <strong>Truck</strong>
        </div>
        <div className={classes.legendItem}>
          <img
            src={GreenTruckIcon}
            alt="green trailer legend icon"
            className={classes.icon}
          />
          <strong>Trailer</strong>
        </div>
      </div>
      <AssetLocationTable filteredData={filteredAssetLocations} />
    </>
  );
}

export default AssetLocations;
