import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import classes from '../../assets/Global.module.css';

interface HeaderNavButtonProps {
  /** The route to navigate to when the button is clicked. */
  to: string;
  /** The icon component to display in the button. */
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  /** The text label for the button. */
  label: string;
}

/**
 * @component
 * @example
 * <NavButton to="outstanding" icon={MdList} label="Outstanding Receivables" />
 * This could probably just be a NavLink
 */

function HeaderNavButton({
  to,
  icon: Icon,
  label,
}: HeaderNavButtonProps): React.ReactElement {
  const navigate = useNavigate();
  const pathName = useLocation().pathname.split('/').slice(-1)[0];

  return (
    <button
      onClick={() => navigate(to)}
      className={pathName === to ? classes.buttonActive : ''}
    >
      <Icon />
      <span>{label}</span>
    </button>
  );
}

export default HeaderNavButton;
