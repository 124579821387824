import React from 'react';
import CreateESAEntry from '../../creates/driver/ESA';
import BBITable from '../../../helpers/bBITable/BBITable';
import DateOnlyFormatter from '../../../helpers/inputs/DateOnlyFormatter';

function ESA({
  data,
  employeeId,
  RefreshPage,
}: {
  data: Array<ESAEntry>;
  employeeId: number;
  RefreshPage: Function;
}) {
  let formattedData = [];
  if (data) {
    formattedData = data.map(esaEntry => ({
      ...esaEntry,
      date: DateOnlyFormatter(esaEntry.date),
    }));
  }

  let columnConfig = [
    {
      key: '1',
      attribute: 'checkNumber',
      header: 'Check Number',
    },
    {
      key: '2',
      attribute: 'date',
      header: 'Date',
    },
    {
      key: '3',
      attribute: 'type',
      header: 'Type',
    },
    {
      key: '4',
      attribute: 'amount',
      header: 'Amount',
    },
  ];
  return (
    <>
      <CreateESAEntry employeeId={employeeId} RefreshPage={RefreshPage} />
      <BBITable data={formattedData} columns={columnConfig} />
    </>
  );
}
export default ESA;
