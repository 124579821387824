import React from 'react';
import Form from '../../../../assets/essentials/Form';
import Input from '../../../../assets/essentials/Input';
import classes from '../../../../assets/Global.module.css';
import Button from '../../../../assets/essentials/Button';
import PutForm from '../../../../api/internal/PutForm';

const Admin = ({
  cashFlowId,
  data,
  RefreshPage,
}: {
  cashFlowId;
  data: AppliedPaymentsAdmin;
  RefreshPage: Function;
}) => {
  function UpdateQuickBooksPaymentId(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const appendix = { cashFlowId };
    PutForm(`/Accounting/UpdateAppliedPaymentAdmin`, formData, appendix).then(
      response => {
        if (response) {
          RefreshPage('Admin', ['Summary']);
        }
      },
    );
  }

  return (
    <>
      <Form
        onSubmit={e => {
          UpdateQuickBooksPaymentId(e);
        }}
      >
        <div className={`${classes.span3} ${classes.attribute}`}>
          <label htmlFor='quickBooksPaymentId'>QuickBooks Invoice ID</label>
          <Input
            defaultValue={data.quickBooksPaymentId}
            name="quickBooksPaymentId"
            id="quickBooksPaymentId"
          />
        </div>
        <div>
          <Button disabled variant="good" type="submit">
            Save Changes
          </Button>
        </div>
      </Form>
    </>
  );
};

export default Admin;
