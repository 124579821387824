import React, { useState, useEffect, useRef } from 'react';
import { VscSearch } from 'react-icons/vsc';
import { RxCross1 } from 'react-icons/rx';
import Get from '../../api/internal/Get';
import classesSearch from './CustomSearch.module.css';
import View from '../slab/View';
import ViewLoad from '../../features/views/load/Load';
import ViewRental from '../../features/views/rental/Rental';
import ViewCustomer from '../../features/views/customer/Customer';
import ViewDriver from '../../features/views/users/driver/Driver';

const AdvancedSearch = () => {
  const [isResultsVisible, setIsResultsVisible] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [searchResults, setSearchResults] = useState<AdvancedSearchResults>(null);
  const resultRefs = useRef<(HTMLParagraphElement | null)[]>([]);
  const [focusedIndex, setFocusedIndex] = useState<number>(-1);

  useEffect(() => {
    function GetAdvanceSearchResults(query?: string) {
      if (query.trim() !== '') {
        const queryString = `?queryString=${encodeURIComponent(query)}`;
        Get(`/Search/GetAdvancedSearch${queryString}`).then(response => {
          if (response) {
            setSearchResults(response.data);
            setIsResultsVisible(true);
          }
        });
      } else {
        setSearchResults(null);
      }
    }

    const timeOut = setTimeout(() => GetAdvanceSearchResults(searchQuery), 300);

    return () => clearTimeout(timeOut);
  }, [searchQuery]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!isResultsVisible || !searchResults) return;

      const totalResults =
        searchResults.loads.length +
        searchResults.rentals.length +
        searchResults.customers.length +
        searchResults.drivers.length;

      switch (event.key) {
        case 'Enter':
          event.preventDefault();
          setIsResultsVisible(false);

          if (focusedIndex >= 0 && focusedIndex < totalResults) {
            if (focusedIndex < searchResults.loads.length) {
              const selectedLoad = searchResults.loads[focusedIndex];
              View(
                <ViewLoad loadId={selectedLoad.loadId} startPage={'Summary'} />,
              );
            } else if (
              focusedIndex <
              searchResults.loads.length + searchResults.rentals.length
            ) {
              const selectedRental =
                searchResults.rentals[
                  focusedIndex - searchResults.loads.length
                ];
              View(
                <ViewRental
                  rentalId={selectedRental.rentalId}
                  startPage={'Summary'}
                />,
              );
            } else if (
              focusedIndex <
              searchResults.loads.length +
                searchResults.rentals.length +
                searchResults.customers.length
            ) {
              const selectedCustomer =
                searchResults.customers[
                  focusedIndex -
                    searchResults.loads.length -
                    searchResults.rentals.length
                ];
              View(
                <ViewCustomer
                  customerId={selectedCustomer.customerId}
                  customerName={selectedCustomer.customerName}
                  startPage={'Summary'}
                />,
              );
            } else {
              const selectedDriver =
                searchResults.drivers[
                  focusedIndex -
                    searchResults.loads.length -
                    searchResults.rentals.length -
                    searchResults.customers.length
                ];
              View(
                <ViewDriver
                  employeeId={selectedDriver.employeeId}
                  driverName={selectedDriver.employeeName}
                  startPage={'Summary'}
                />,
              );
            }
          }
          setFocusedIndex(-1);
          break;
        case 'ArrowDown':
          event.preventDefault();
          if (focusedIndex === totalResults - 1) {
            setFocusedIndex(0);
            resultRefs.current[0]?.focus();
          } else {
            setFocusedIndex(prevIndex => {
              const nextIndex = prevIndex === null ? 0 : prevIndex + 1;
              resultRefs.current[nextIndex]?.focus();
              return nextIndex;
            });
          }
          break;
        case 'ArrowUp':
          event.preventDefault();
          setFocusedIndex(prevIndex => {
            const nextIndex =
              prevIndex === null || prevIndex === 0
                ? totalResults - 1
                : prevIndex - 1;
            resultRefs.current[nextIndex]?.focus();
            return nextIndex;
          });
          break;
        case 'Tab':
          if (event.shiftKey) {
            event.preventDefault();
            if (focusedIndex === 0) {
              // Allow shifting focus to previous element outside the list
              setFocusedIndex(-1);
              return;
            } else if (focusedIndex === -1) {
              setFocusedIndex(totalResults - 1);
              return;
            }
            setFocusedIndex(prevIndex => {
              const nextIndex =
                prevIndex === null || prevIndex === 0
                  ? totalResults - 1
                  : prevIndex - 1;
              resultRefs.current[nextIndex]?.focus();
              return nextIndex;
            });
          } else {
            if (focusedIndex === totalResults - 1) {
              // Allow focusing next element outside the list
              setFocusedIndex(0);
              return;
            }
            event.preventDefault();
            setFocusedIndex(prevIndex => {
              const nextIndex =
                prevIndex === null ? 0 : (prevIndex + 1) % totalResults;
              resultRefs.current[nextIndex]?.focus();
              return nextIndex;
            });
          }
          break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isResultsVisible, searchResults, focusedIndex]);
  const handleMouseEnter = (index: number) => {
    setFocusedIndex(index);
  };

  const LoadsResults = () => {
    return (
      <section>
        <h1>Loads</h1>
        {searchResults?.loads.map((load, index) => (
          <p
            id={`load-results-index-${index}`}
            ref={item => (resultRefs.current[index] = item)}
            onClick={() =>
              View(<ViewLoad loadId={load.loadId} startPage={'Summary'} />)
            }
            key={load.loadId}
            tabIndex={focusedIndex === -1 ? -1 : 0}
            className={focusedIndex === index ? classesSearch.focused : ''}
            onMouseEnter={() => handleMouseEnter(index)}
          >
            {load.loadId} <span>{load.customerName}</span>
          </p>
        ))}
      </section>
    );
  };

  const RentalsResults = () => {
    const startIndex = searchResults?.loads.length || 0;
    return (
      <section>
        <h1>Rentals</h1>
        {searchResults?.rentals.map((rental, index) => (
          <p
            id={`rental-results-index-${index}`}
            ref={item => (resultRefs.current[startIndex + index] = item)}
            onClick={() =>
              View(
                <ViewRental rentalId={rental.rentalId} startPage={'Summary'} />,
              )
            }
            key={rental.rentalId}
            tabIndex={focusedIndex === -1 ? -1 : 0}
            className={
              focusedIndex === startIndex + index ? classesSearch.focused : ''
            }
            onMouseEnter={() => handleMouseEnter(startIndex + index)}
          >
            {rental.rentalId} <span>{rental.customerName}</span>
          </p>
        ))}
      </section>
    );
  };

  const CustomersResults = () => {
    const startIndex =
      searchResults?.loads.length + searchResults?.rentals.length || 0;
    return (
      <section>
        <h1>Customers</h1>
        {searchResults?.customers.map((customer, index) => (
          <p
            id={`customer-results-index-${index}`}
            ref={item => (resultRefs.current[startIndex + index] = item)}
            onClick={() =>
              View(
                <ViewCustomer
                  customerId={customer.customerId}
                  customerName={customer.customerName}
                  startPage={'Summary'}
                />,
              )
            }
            key={customer.customerId}
            tabIndex={focusedIndex === -1 ? -1 : 0}
            className={
              focusedIndex === startIndex + index ? classesSearch.focused : ''
            }
            onMouseEnter={() => handleMouseEnter(startIndex + index)}
          >
            {customer.customerName}
          </p>
        ))}
      </section>
    );
  };

  const DriversResults = () => {
    const startIndex =
      searchResults?.loads.length +
        searchResults?.rentals.length +
        searchResults?.customers.length || 0;
    return (
      <section>
        <h1>Drivers</h1>
        {searchResults?.drivers.map((driver, index) => (
          <p
            id={`driver-results-index-${index}`}
            ref={item => (resultRefs.current[startIndex + index] = item)}
            onClick={() =>
              View(
                <ViewDriver
                  employeeId={driver.employeeId}
                  driverName={driver.employeeName}
                  startPage={'Summary'}
                />,
              )
            }
            key={driver.employeeId}
            tabIndex={focusedIndex === -1 ? -1 : 0}
            className={
              focusedIndex === startIndex + index ? classesSearch.focused : ''
            }
            onMouseEnter={() => handleMouseEnter(startIndex + index)}
          >
            {driver.employeeName}
          </p>
        ))}
      </section>
    );
  };

  const toggleResultsPane = () => {
    setIsResultsVisible(!isResultsVisible);
    setFocusedIndex(-1);
  };

  return (
    <div className={classesSearch.advancedSearch}>
      <form
        onClick={() => toggleResultsPane()}
        onSubmit={e => e.preventDefault()}
      >
        <VscSearch />
        <input
          id="advanced-search"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          type="text"
          placeholder="Search..."
        />
        {searchQuery ? (
          <RxCross1
            className={classesSearch.displayX}
            onClick={() => setSearchQuery('')}
          />
        ) : (
          <RxCross1 className={classesSearch.hiddenX} />
        )}
      </form>
      {isResultsVisible && (
        <div
          className={classesSearch.advancedSearchResults}
          onClick={() => setIsResultsVisible(!isResultsVisible)}
        >
          {!searchResults && <span>Begin typing to search...</span>}
          {searchResults && searchResults.loads.length !== 0 && (
            <LoadsResults />
          )}
          {searchResults && searchResults.rentals.length !== 0 && (
            <RentalsResults />
          )}
          {searchResults && searchResults.customers.length !== 0 && (
            <CustomersResults />
          )}
          {searchResults && searchResults.drivers.length !== 0 && (
            <DriversResults />
          )}
          {searchResults &&
            searchResults.loads.length === 0 &&
            searchResults.rentals.length === 0 &&
            searchResults.customers.length === 0 &&
            searchResults.drivers.length === 0 && <span>No results found</span>}
        </div>
      )}
    </div>
  );
};

export default AdvancedSearch;
