import React, { useState, useEffect } from 'react';
import { MdOutlineRefresh } from 'react-icons/md';
import { FiTruck } from 'react-icons/fi';
import Header from '../../layouts/Header';
import Filter from '../../helpers/filter/Filter';
import View from '../../helpers/slab/View';
import ViewTruck from '../../features/views/assets/truck/Truck';
import CreateTruck from '../../features/creates/truck/Truck';
import Create from '../../helpers/panel/Create';
import FilterData from '../../helpers/filter/FilterData';
import BBITable from '../../helpers/bBITable/BBITable';
import Get from '../../api/internal/Get';

function Truck({ toggleBoardRefresh }) {
  const [filterValue, setFilterValue] = useState('');
  const [truckBoard, setTruckBoard] = useState<Array<TruckBoard>>([]);

  useEffect(() => {
    GetTruckBoard();
  }, [toggleBoardRefresh]);

  function GetTruckBoard() {
    Get(`/Asset/GetTruckBoard`).then(response => {
      if (response) {
        setTruckBoard(FormatTruckBoard(response.data));
      }
    });
  }

  function FormatTruckBoard(trucks) {
    return trucks.map(truck => ({
      ...truck,
      mileage: truck.mileage?.toLocaleString() ?? null,
    }));
  }

  let columnConfig = [
    {
      key: '1',
      attribute: 'truckName',
      attributeprimaryhidden: 'assetId',
      header: 'Truck Unit #',
      onClick: e =>
        View(
          <ViewTruck
            truckName={e.target.innerText}
            assetId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '2',
      attribute: 'vin',
      header: 'VIN',
    },
    {
      key: '3',
      attribute: 'make',
      header: 'Make',
    },
    {
      key: '4',
      attribute: 'model',
      header: 'Model',
    },
    {
      key: '5',
      attribute: 'year',
      header: 'Year',
    },
    {
      key: '6',
      attribute: 'licensePlateNumber',
      header: 'Plate',
    },
    {
      key: '7',
      attribute: 'licensePlateState',
      header: 'Plate State',
    },
    {
      key: '8',
      attribute: 'ownership',
      header: 'Owned / Leased',
    },
    {
      key: '9',
      attribute: 'truckType',
      header: 'Type',
    },
  ];

  let tableData = FilterData(truckBoard, filterValue);

  return (
    <>
      <h1>Truck</h1>
      <Header>
        <div>
          <button
            onClick={() =>
              Create(<CreateTruck GetTruckBoard={GetTruckBoard} />)
            }
          >
            <FiTruck />
            <span>Create a Truck</span>
          </button>
          <button onClick={GetTruckBoard}>
            <MdOutlineRefresh />
            <span>Refresh Board</span>
          </button>
        </div>
        <div>
          <Filter
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            placeholder="Filter trucks"
          />
        </div>
      </Header>
      <BBITable data={tableData} columns={columnConfig} />
    </>
  );
}

export default Truck;
