import React, { useState, useEffect, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AiOutlineCheck, AiOutlineUser } from 'react-icons/ai';
import { FiTruck, FiAlignLeft } from 'react-icons/fi';
import { MdOutlineRefresh } from 'react-icons/md';
import { TbAlignLeft } from 'react-icons/tb';
import { VscSearch } from 'react-icons/vsc';
import { BiBus } from 'react-icons/bi';
import { CgKey } from 'react-icons/cg';
import { FaCircle } from 'react-icons/fa';
import Cookies from 'js-cookie';

import Header from '../layouts/Header';
import Filter from '../helpers/filter/Filter';
import CreateLoad from '../features/creates/load/Load';
import ViewLoad from '../features/views/load/Load';
import View from '../helpers/slab/View';
import Create from '../helpers/panel/Create';
import FilterData from '../helpers/filter/FilterData';
import ViewCustomer from '../features/views/customer/Customer';
import ViewLocation from '../features/views/location/Location';
import ViewDriver from '../features/views/users/driver/Driver';
import classesGlobal from '../assets/Global.module.css';
import classesLoad from './Load.module.css';
import classesCustom from '../helpers/search/CustomSearch.module.css';
import Get from '../api/internal/Get';
import Input from '../assets/essentials/Input';
import Select from '../assets/essentials/Select';
import Button from '../assets/essentials/Button';
import OptionList from '../helpers/options/OptionList';
import StatesAndProvinces from '../helpers/options/StatesAndProvinces';
import ResizableTable from '../helpers/resizableTable/ResizableTable';
import useBranchList, { Branch } from '../hooks/useBranchList';
import { BranchContext } from '../helpers/BranchContext';

function Load({ toggleBoardRefresh }: { toggleBoardRefresh: Function }) {
  const [compactViewOptionOpen, setCompactViewOptionOpen] =
    useState<boolean>(false);
  const [isCompact, setIsCompact] = useState(
    Cookies.get('loadIsCompact') === 'true',
  );
  const [filterValue, setFilterValue] = useState<string>('');
  const [displayedBoard, setDisplayedBoard] = useState<string>('status');
  const [loadData, setLoadData] = useState<LoadBoardItem[]>();
  const [loadDataFilteredBySearch, setLoadDataFilteredBySearch] =
    useState<LoadBoardItem[]>();
  const [loadSearchOptions, setLoadSearchOptions] =
    useState<LoadSearchOptions>();
  const [branchSelectOpen, setBranchSelectOpen] = useState<boolean>(false);
  const [selectedBranches, setSelectedBranches] = useContext(BranchContext);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    GetLoadBoard();
  }, [toggleBoardRefresh, selectedBranches]);

  const branchList = useBranchList();

  function GetLoadBoard() {
    const queryString = GenerateLoadBoardQueryString(selectedBranches);
    Get(`/Load/GetLoadBoard?${queryString}`).then(response => {
      if (response) {
        setLoadData(response.data.loads);
        setLoadSearchOptions(response.data.loadSearchOptions);
      }
    });
  }

  function GenerateLoadBoardQueryString(values: string[]): string {
    //branchIds are strings due to being stored with cookies
    const intValues = values.map(value => {
      let parsedValue = parseInt(value, 10);
      return isNaN(parsedValue) ? null : parsedValue;
    });
    const validIntValues = intValues.filter(value => value !== null);
    const queryString = validIntValues
      .map(value => `branchId=${value}`)
      .join('&');
    return queryString;
  }

  const columnConfig = [
    {
      key: '1',
      attribute: 'loadId',
      attributesecondaryhidden: 'loadStatus',
      header: 'ID',
      onClick: e => View(<ViewLoad loadId={e.target.innerText} />),
      dataType: 'number',
      class: classesLoad.statusBackgroundColor,
    },
    {
      key: '2',
      attribute: 'driverName',
      attributeprimaryhidden: 'employeeId',
      attributesecondaryhidden: 'driverName',
      attributetertiaryhidden: 'dailyLoadStatus',
      header: 'Driver',
      onClick: e =>
        e.target.dataset.attributeprimaryhidden &&
        View(
          <ViewDriver
            driverName={e.target.innerText.split(', ')[0]}
            employeeId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
      class: `${classesLoad.statusBackgroundColor} ${classesLoad.dailyStatusBackgroundColor}`,
    },
    {
      key: '3',
      attribute: 'customerNames',
      attributeprimaryhidden: 'customerId',
      header: 'Customer',
      onClick: e =>
        e.target.dataset.attributeprimaryhidden &&
        View(
          <ViewCustomer
            customerName={e.target.innerText}
            customerId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '4',
      attribute: 'reference',
      header: 'Reference',
      attributeprimaryhidden: 'loadId',
      onClick: e =>
        View(<ViewLoad loadId={e.target.dataset.attributeprimaryhidden} />),
    },
    {
      key: '5',
      attribute: 'pickLocation',
      attributeprimaryhidden: 'pickLocationId',
      header: 'Pickup',
      onClick: e =>
        e.target.dataset.attributeprimaryhidden &&
        View(
          <ViewLocation
            locationId={e.target.dataset.attributeprimaryhidden}
            locationName={e.target.innerText}
          />,
        ),
    },
    {
      key: '6',
      attribute: 'pickAppointment',
      header: 'Pickup Appt',
      dataType: 'date',
    },
    {
      key: '7',
      attribute: 'dropLocation',
      attributeprimaryhidden: 'dropLocationId',
      header: 'Drop Off',
      onClick: e =>
        e.target.dataset.attributeprimaryhidden &&
        View(
          <ViewLocation
            locationId={e.target.dataset.attributeprimaryhidden}
            locationName={e.target.innerText}
          />,
        ),
    },
    {
      key: '8',
      attribute: 'dropAppointment',
      header: 'Drop Off Appt',
      dataType: 'date',
    },

    {
      key: '9',
      attribute: 'loadLane',
      header: 'Lane',
    },
    {
      key: '10',
      attribute: 'loadedMiles',
      header: 'Miles',
      dataType: 'formattedNumber',
    },
    {
      key: '11',
      attribute: 'pickDropRatio',
      header: 'Pick/Drop',
    },
    {
      key: '12',
      attribute: 'equipment',
      header: 'Equipment',
    },
    {
      key: '13',
      attribute: 'totalReceivable',
      header: 'Total Receivable',
      dataType: 'currency',
    },
  ];

  const LoadBoardSection = ({
    title,
    loads,
    filterValue,
    columnConfig,
    compactView,
  }) => {
    return (
      <ResizableTable
        title={title}
        noDataMessage={`No ${title} loads.`}
        data={FilterData(loads, filterValue)}
        columns={columnConfig}
        compactView={compactView}
      />
    );
  };

  const BoardNavButtons = () => {
    const BoardButton = ({
      display,
      setDisplay,
      activeClass,
      condition,
      icon,
      text,
    }) => (
      <button
        onClick={() => setDisplay(display)}
        className={condition ? activeClass : ''}
      >
        {icon}
        <span>{text}</span>
      </button>
    );
    return (
      <div>
        <button
          onClick={() => Create(<CreateLoad RefreshBoard={GetLoadBoard} />)}
        >
          <FiTruck />
          <span>Create a Load</span>
        </button>
        <button onClick={GetLoadBoard}>
          <MdOutlineRefresh />
          <span>Refresh Board</span>
        </button>
        <div className={classesGlobal.toggleDisplayHolder}>
          <BoardButton
            display="status"
            setDisplay={setDisplayedBoard}
            activeClass={classesGlobal.buttonActive}
            condition={displayedBoard === 'status'}
            icon={<BiBus />}
            text="Display by Status"
          />
          <BoardButton
            display="driver"
            setDisplay={setDisplayedBoard}
            activeClass={classesGlobal.buttonActive}
            condition={displayedBoard === 'driver'}
            icon={<AiOutlineUser />}
            text="Display by Driver"
          />
          {/* commenting out in case we revert to using this board */}
          {/* <BoardButton
            display="paperwork"
            setDisplay={setDisplayedBoard}
            activeClass={classesGlobal.buttonActive}
            condition={displayedBoard === 'paperwork'}
            icon={<HiOutlineDocumentText />}
            text="Waiting on Paperwork"
          /> */}
          <BoardButton
            display="search"
            setDisplay={setDisplayedBoard}
            activeClass={classesGlobal.buttonActive}
            condition={displayedBoard === 'search'}
            icon={<VscSearch />}
            text="Search Load"
          />
        </div>
      </div>
    );
  };

  const DisplayByStatusBoard = () => {
    const futureLoads = loadData?.filter(load => load.sortStatus === 'Future');
    const needsActionLoads = loadData?.filter(
      load => load.sortStatus === 'Needs Action',
    );
    //requested to display by load status, pick date sort coming from backend
    const otrLoads = loadData
      ?.filter(load => load.sortStatus === 'Over The Road')
      .sort((a, b) => a.loadStatusId - b.loadStatusId);

    return (
      <>
        <LoadBoardSection
          title="Future"
          loads={futureLoads || []}
          filterValue={filterValue}
          columnConfig={columnConfig}
          compactView={isCompact}
        />
        <LoadBoardSection
          title="Needs Action"
          loads={needsActionLoads || []}
          filterValue={filterValue}
          columnConfig={columnConfig}
          compactView={isCompact}
        />
        <LoadBoardSection
          title="Over The Road"
          loads={otrLoads || []}
          filterValue={filterValue}
          columnConfig={columnConfig}
          compactView={isCompact}
        />
      </>
    );
  };

  const DisplayByDriverBoard = () => {
    const assignedLoads = loadData?.filter(
      load => load.isAssigned && load.loadStatus !== 'Waiting On Paperwork',
    );
    const unassignedLoads = loadData?.filter(load => !load.isAssigned);
    return (
      <>
        <LoadBoardSection
          title="Assigned"
          loads={assignedLoads || []}
          filterValue={filterValue}
          columnConfig={columnConfig}
          compactView={isCompact}
        />
        <LoadBoardSection
          title="Unassigned"
          loads={unassignedLoads || []}
          filterValue={filterValue}
          columnConfig={columnConfig}
          compactView={isCompact}
        />
      </>
    );
  };

  const LoadSearchBoard = () => {
    function SearchLoad(e) {
      e.preventDefault();
      setFilterValue('');
      const formData = new FormData(e.target);
      const newParams = new URLSearchParams();
      formData.forEach((value, key) => {
        if (value !== '') {
          newParams.set(key, value.toString());
        }
      });
      setSearchParams(newParams, { replace: true });
      Get(`/Load/SearchLoad?${newParams.toString()}`).then(response => {
        if (response) {
          setLoadDataFilteredBySearch(response.data);
        }
      });
    }

    function HandleCancelSearch() {
      setDisplayedBoard('status');
      setLoadDataFilteredBySearch(null);
      setSearchParams({});
    }

    const SearchForm = () => {
      return (
        <form onSubmit={e => SearchLoad(e)}>
          <label>
            Customer
            <Input
              name="customer"
              defaultValue={searchParams.get('customer') ?? ''}
            />
          </label>
          <label>
            Vendor
            <Input
              name="vendor"
              defaultValue={searchParams.get('vendor') ?? ''}
            />
          </label>
          <label>
            Driver
            <Input
              name="driver"
              defaultValue={searchParams.get('driver') ?? ''}
            />
          </label>
          <label>
            Reference
            <Input
              name="reference"
              defaultValue={searchParams.get('reference') ?? ''}
            />
          </label>
          <label>
            Equipment
            <Select
              name="equipmentTypeId"
              defaultValue={searchParams.get('equipmentTypeId') ?? ''}
            >
              <option value="" />
              <OptionList
                optionData={loadSearchOptions?.equipmentOptions}
                attributeID="equipmentTypeId"
                attributeName="type"
              />
            </Select>
          </label>
          <label>
            Commodity
            <Select
              name="commodityId"
              defaultValue={searchParams.get('commodityId') ?? ''}
            >
              <option value="" />
              <OptionList
                optionData={loadSearchOptions?.commodityOptions}
                attributeID="commodityId"
                attributeName="type"
                attributeGroup="group"
              />
            </Select>
          </label>
          <label>
            Branch
            <Select
              name="branchId"
              defaultValue={searchParams.get('branchId') ?? ''}
            >
              <option value="" />
              <OptionList
                optionData={loadSearchOptions?.branchOptions}
                attributeID="branchId"
                attributeName="branchName"
              />
            </Select>
          </label>
          <label>
            Status
            <Select
              name="loadStatusId"
              defaultValue={searchParams.get('loadStatusId') ?? ''}
            >
              <option value="">Any Status</option>
              <option value="0">Displayed On Load Board</option>
              <OptionList
                optionData={loadSearchOptions?.loadStatusOptions}
                attributeID="loadStatusId"
                attributeName="status"
                attributeGroup="group"
              />
            </Select>
          </label>
          <label>
            Picks
            <Input
              type="number"
              step="1"
              name="picksCount"
              defaultValue={searchParams.get('picksCount') ?? ''}
            />
          </label>
          <label>
            Drops
            <Input
              type="number"
              step="1"
              name="dropsCount"
              defaultValue={searchParams.get('dropsCount') ?? ''}
            />
          </label>
          <label>
            Location
            <Input
              name="location"
              defaultValue={searchParams.get('location') ?? ''}
            />
          </label>
          <label>
            Origin City
            <Input
              name="originCity"
              defaultValue={searchParams.get('originCity') ?? ''}
            />
          </label>
          <label>
            Origin State
            <Select
              name="originState"
              defaultValue={searchParams.get('originState') ?? ''}
            >
              <option value="" />
              <StatesAndProvinces />
            </Select>
          </label>
          <label>
            Destination City
            <Input
              name="destinationCity"
              defaultValue={searchParams.get('destinationCity') ?? ''}
            />
          </label>
          <label>
            Destination State
            <Select
              name="destinationState"
              defaultValue={searchParams.get('destinationState') ?? ''}
            >
              <option value="" />
              <StatesAndProvinces />
            </Select>
          </label>
          <div className={classesCustom.separateRow}>
            <div className={classesCustom.dateRange}>
              <span className={classesGlobal.span2}>Pickup Date</span>
              <label htmlFor="pickupDateMin">Start: </label>
              <Input
                id="pickupDateMin"
                type="date"
                name="pickupDateMin"
                defaultValue={searchParams.get('pickupDateMin') ?? ''}
              />
              <label htmlFor="pickupDateMax">End: </label>
              <Input
                id="pickupDateMax"
                type="date"
                name="pickupDateMax"
                defaultValue={searchParams.get('pickupDateMax') ?? ''}
              />
            </div>
            <div className={classesCustom.dateRange}>
              <span className={classesGlobal.span2}>Delivery Date</span>
              <label htmlFor="deliveryDateMin">Start: </label>
              <Input
                id="deliveryDateMin"
                type="date"
                name="deliveryDateMin"
                defaultValue={searchParams.get('deliveryDateMin') ?? ''}
              />
              <label htmlFor="deliveryDateMax">End: </label>
              <Input
                id="deliveryDateMax"
                type="date"
                name="deliveryDateMax"
                defaultValue={searchParams.get('deliveryDateMax') ?? ''}
              />
            </div>
          </div>
          <div className={classesCustom.submitHolder}>
            <Button type="submit" variant="good">
              Search
            </Button>
            <Button
              variant="bad"
              type="button"
              onClick={() => HandleCancelSearch()}
            >
              Cancel
            </Button>
          </div>
        </form>
      );
    };

    return (
      <>
        <div className={classesCustom.customSearch}>
          <SearchForm />
        </div>
        <LoadBoardSection
          title="Search Result"
          loads={loadDataFilteredBySearch || []}
          filterValue={filterValue}
          columnConfig={columnConfig}
          compactView={isCompact}
        />
      </>
    );
  };

  const CompactViewToggle = () => {
    return (
      <div className={classesGlobal.dropdown}>
        <button
          aria-label="Open Toggle Compact Menu"
          title="Toggle Compact Menu"
          onClick={() => setCompactViewOptionOpen(!compactViewOptionOpen)}
        >
          {isCompact ? <FiAlignLeft /> : <TbAlignLeft />}
        </button>
        {compactViewOptionOpen && (
          <div className={classesGlobal.dropdownOptions}>
            <div
              onClick={() => {
                setIsCompact(false);
                Cookies.set('loadIsCompact', false);
                setCompactViewOptionOpen(false);
              }}
            >
              {!isCompact ? <AiOutlineCheck /> : <div />}
              <TbAlignLeft />
              <span>Normal View</span>
            </div>
            <div
              onClick={() => {
                setIsCompact(true);
                Cookies.set('loadIsCompact', true);
                setCompactViewOptionOpen(false);
              }}
            >
              {isCompact ? <AiOutlineCheck /> : <div />}
              <FiAlignLeft />
              <span>Compact View</span>
            </div>
          </div>
        )}
      </div>
    );
  };

  const BranchSelectToggle = () => {
    const HandleButtonToggle = () => {
      if (branchSelectOpen) {
        Cookies.set('branches', selectedBranches);
        setBranchSelectOpen(false);
      } else setBranchSelectOpen(true);
    };
    const HandleBranchSelectChange = (
      branchId: number,
      isSelected: boolean,
    ) => {
      //selectedBranches needs to be strings to be stored in cookies
      setSelectedBranches((prevState: string[]) => {
        return isSelected
          ? prevState.filter(id => id !== String(branchId))
          : [...prevState, String(branchId)];
      });
    };

    const BranchSelectOptions = () => (
      <div className={classesLoad.branchSelectOptions}>
        {branchList?.map((branch: Branch) => (
          <BranchOption key={branch.branchId} branch={branch} />
        ))}
      </div>
    );

    const BranchOption = ({ branch }) => {
      const isSelected = selectedBranches.includes(String(branch.branchId));
      return (
        <span
          onClick={() => HandleBranchSelectChange(branch.branchId, isSelected)}
          className={isSelected ? classesLoad.selected : null}
        >
          <label htmlFor={branch.branchName}>{branch.branchName}</label>
          {isSelected ? <AiOutlineCheck /> : null}
        </span>
      );
    };

    return (
      <div className={classesGlobal.dropdown}>
        <button onClick={HandleButtonToggle}>
          <span> {branchSelectOpen ? 'Close' : 'Open'} Branch Select </span>
          <AiOutlineCheck />
        </button>
        {branchSelectOpen && <BranchSelectOptions />}
      </div>
    );
  };

  const ColorLegend = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const idColumnItems = [
      { className: classesLoad.needsLoadInfo, text: 'Needs Load Information' },
      { className: classesLoad.readyToLoad, text: 'Ready to Load' },
      { className: classesLoad.readyToLoad, text: 'Over The Road' },
      { className: classesLoad.delivered, text: 'Delivered' },
      { className: classesLoad.needsAttention, text: 'Needs Attention' },
    ];

    const driverColumnItems = [
      { className: classesLoad.noDriver, text: 'No Driver' },
      { className: classesLoad.needsCall, text: 'Needs Check Call' },
      { className: classesLoad.checkCall, text: 'Check Call Completed' },
      { className: classesLoad.dispatched, text: 'Dispatched' },
      { className: classesLoad.issue, text: 'Issue' },
      // we currently do not have night watch implemented
      // { className: classesLoad.nightWatch, text: 'Night Watch' },
    ];

    const LegendItem = ({ className, text }) => (
      <span>
        <FaCircle className={className} />
        {text}
      </span>
    );

    const LegendSection = ({ title, items }) => (
      <div>
        <h6>{title}</h6>
        {items.map(item => (
          <LegendItem
            key={item.text}
            className={item.className}
            text={item.text}
          />
        ))}
      </div>
    );

    return (
      <div className={classesGlobal.dropdown}>
        <button onClick={() => setIsOpen(prev => !prev)} title="Color Legend">
          <CgKey />
        </button>
        {isOpen && (
          <div className={classesLoad.colorLegend}>
            <h4>Status Colors</h4>
            <LegendSection title="ID column" items={idColumnItems} />
            <LegendSection title="Driver column" items={driverColumnItems} />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <h1>Load</h1>
      <Header>
        <BoardNavButtons />
        <div>
          <BranchSelectToggle />
          <ColorLegend />
          <CompactViewToggle />
          <Filter
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            placeholder="Filter current loads"
          />
        </div>
      </Header>
      {displayedBoard === 'status' && <DisplayByStatusBoard />}
      {displayedBoard === 'driver' && <DisplayByDriverBoard />}
      {displayedBoard === 'search' && <LoadSearchBoard />}
    </>
  );
}

export default Load;
