import React from 'react';

import classes from '../../../assets/Global.module.css';

function Summary({
  data,
  HandlePageChange,
}: {
  data: VendorSummary;
  HandlePageChange: Function;
}) {
  return (
    <>
      <div className={classes.viewGrid}>
        <div className={classes.attribute}>
          <h3>Vendor Name and Number</h3>
          {data?.nameDisplayList.map((line, index) => (
            <span key={index}>{line}</span>
          ))}
          <div
            className={classes.cTA}
            onClick={() => HandlePageChange('Details')}
          >
            Manage Vendor Info
          </div>
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <h3>Address</h3>
          {data.addressDisplayList?.map((line, index) => (
            <span key={index}>{line}</span>
          ))}
        </div>
        <div className={classes.attribute}>
          <h3>Services</h3>
          {data.servicesDisplayList?.map((line, index) => (
            <span key={index}>{line}</span>
          ))}
          <div
            className={classes.cTA}
            onClick={() => HandlePageChange('Services')}
          >
            Manage Vendor Services
          </div>
        </div>
        <div className={classes.attribute}>
          <h3>Status</h3>
          <span>{data?.status}</span>
        </div>
        <div className={classes.attribute}>
          <h3>Type</h3>
          <span>{data?.vendorType}</span>
        </div>
        <div className={classes.attribute}>
          <h3>Primary Contact </h3>
          {data.primaryContactDisplayList.length > 0 ? (
            data.primaryContactDisplayList?.map((line, index) => (
              <span key={index}>{line}</span>
            ))
          ) : (
            <span>None</span>
          )}

          <div
            className={classes.cTA}
            onClick={() => HandlePageChange('Contacts')}
          >
            Manage Contact Info
          </div>
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <h3>Billing Contact</h3>
          {data.billingContactDisplayList.length > 0 ? (
            data.billingContactDisplayList?.map((line, index) => (
              <span key={index}>{line}</span>
            ))
          ) : (
            <span>None</span>
          )}
        </div>
      </div>
    </>
  );
}

export default Summary;
