import axios, { AxiosRequestConfig } from 'axios';

import { pca } from '../..';

const TransportationCaller = axios.create({
  baseURL: process.env.REACT_APP_SERVICE_FABRIC_BASE_URL,
});

TransportationCaller.interceptors.request.use(
  async config => {
    const accounts = pca.getAllAccounts();
    if (accounts.length > 0) {
      const tokenResponse = await pca.acquireTokenSilent({
        account: accounts[0],
        scopes: [`api://${process.env.REACT_APP_AAD_CLIENT_ID}/.default`],
      });
      const token = tokenResponse.accessToken;
      (config as AxiosRequestConfig).headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

export default TransportationCaller;
