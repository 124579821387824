import React, { useState } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { RiGroupLine } from 'react-icons/ri';
import { FiTruck } from 'react-icons/fi';
import { FaTrailer } from 'react-icons/fa';
import { BiDollar } from 'react-icons/bi';
import Header from '../../../layouts/Header';
import HeaderNavButton from '../../../helpers/buttons/HeaderNavButton';
import Filter from '../../../helpers/filter/Filter';
import { Outlet } from 'react-router-dom';

export default function Reports() {
  const [filterValue, setFilterValue] = useState('');

  const navButtons = [
    { to: 'load', icon: FiTruck, label: 'Load' },
    { to: 'customer', icon: RiGroupLine, label: 'Customer' },
    { to: 'driver', icon: AiOutlineUser, label: 'Driver' },
    { to: 'rental', icon: FaTrailer, label: 'Rental' },
    { to: 'broker', icon: BiDollar, label: 'Broker' },
  ];
  
  return (
    <>
      <h1>Financial Reports</h1>
      <Header>
        <div>
          {navButtons.map(button => (
            <HeaderNavButton
              key={button.to}
              to={button.to}
              icon={button.icon}
              label={button.label}
            />
          ))}
        </div>
        <div>
          <Filter
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            placeholder="Filter reports"
          />
        </div>
      </Header>
      <Outlet context={filterValue} />
    </>
  );
}
