import React, { useState, useEffect, useRef } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import View from '../../helpers/slab/View';
import ViewDriver from '../../features/views/users/driver/Driver';
import FilterData from '../../helpers/filter/FilterData';
import BBITable from '../../helpers/bBITable/BBITable';
import classesCustom from '../../helpers/search/CustomSearch.module.css';
import classesGlobal from '../../assets/Global.module.css';
import Get from '../../api/internal/Get';
import Input from '../../assets/essentials/Input';
import Button from '../../assets/essentials/Button';
import Select from '../../assets/essentials/Select';
import OptionList from '../../helpers/options/OptionList';

function SearchDriver({ toggleBoardRefresh }) {
  const [driverBoard, setDriverBoard] = useState<Array<DriverBoard>>([]);
  const [branchOptions, setBranchOptions] = useState([]);
  const [searchDriverParams, setSearchDriverParams] = useSearchParams();

  const filterValue = useOutletContext();

  useEffect(() => {
    Get(`/Employee/SearchDriver?${searchDriverParams.toString()}`).then(
      response => {
        if (response) {
          setDriverBoard(response.data);
        }
      },
    );

    if (branchOptions.length === 0) {
      Get(`/Load/GetBranchList`).then(response => {
        if (response) {
          setBranchOptions(response.data);
        }
      });
    }
  }, [toggleBoardRefresh, searchDriverParams, branchOptions.length]);

  const handleFormSubmit = event => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const newParams = new URLSearchParams();
    formData.forEach((value, key) => {
      if (value !== '') {
        newParams.set(key, value.toString());
      }
    });
    setSearchDriverParams(newParams, { replace: true });
  };

  let columnConfig = [
    {
      key: '1',
      attribute: 'driverName',
      attributeprimaryhidden: 'employeeId',
      header: 'Driver',
      onClick: e =>
        View(
          <ViewDriver
            driverName={e.target.innerText}
            employeeId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '2',
      attribute: 'driverMobile',
      header: 'Mobile',
    },
    {
      key: '3',
      attribute: 'driverBranch',
      header: 'Branch',
    },
  ];

  let tableData = FilterData(driverBoard, filterValue);

  return (
    <>
      {branchOptions.length !== 0 && (
        <>
          <div className={`${classesCustom.customSearch}`}>
            <form onSubmit={handleFormSubmit}>
              <label>
                First Name
                <Input
                  type="text"
                  name="firstName"
                  defaultValue={searchDriverParams.get('firstName') ?? ''}
                />
              </label>
              <label>
                Last Name
                <Input
                  type="text"
                  name="lastName"
                  defaultValue={searchDriverParams.get('lastName') ?? ''}
                />
              </label>
              <label>
                Branch
                <Select
                  name="branchId"
                  defaultValue={searchDriverParams.get('branchId') ?? ''}
                >
                  <option value="">All Branches</option>
                  <OptionList
                    optionData={branchOptions}
                    attributeID="branchId"
                    attributeName="branchName"
                  />
                </Select>
              </label>
              <label>
                Employee Status
                <Select
                  name="isActiveEmployee"
                  defaultValue={
                    searchDriverParams.get('isActiveEmployee') ?? ''
                  }
                >
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </Select>
              </label>
              <div className={classesCustom.submitHolder}>
                <Button type="submit" variant="good">
                  Search
                </Button>
                <Button
                  onClick={() => setSearchDriverParams({})}
                  type="reset"
                  variant="bad"
                >
                  Cancel
                </Button>
              </div>
            </form>
          </div>
          <BBITable data={tableData} columns={columnConfig} />
        </>
      )}
    </>
  );
}

export default SearchDriver;
