export function GetThirtyDaysAgoIsoString() {
  const currentDate = new Date();
  // Subtract 30 days in milliseconds (30 days * 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
  const thirtyDaysAgoTimestamp =
    currentDate.getTime() - 30 * 24 * 60 * 60 * 1000;
  // Create a new Date object from the timestamp
  const thirtyDaysAgo = new Date(thirtyDaysAgoTimestamp);
  // Get the ISO string for 30 days ago
  const isoStringThirtyDaysAgo = thirtyDaysAgo.toISOString();
  return isoStringThirtyDaysAgo.split('T')[0];
}

export function GetLastFullWeekMonday() {
  // Get today's date
  let today = new Date();

  // Calculate the day of the week (0-6, where 0 is Sunday)
  let dayOfWeek = today.getDay();

  // Calculate the date of the Monday of the previous full week
  let lastFullWeekMonday = new Date();
  lastFullWeekMonday.setDate(
    today.getDate() - (dayOfWeek === 0 ? 13 : dayOfWeek + 6),
  );

  // Format the date as "YYYY-MM-DD"
  let year = lastFullWeekMonday.getFullYear();
  let month = (lastFullWeekMonday.getMonth() + 1).toString().padStart(2, '0');
  let day = lastFullWeekMonday.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
}

export function GetPreviousWeekOfYear() {
  const currentDate = new Date();
  const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
  const daysIntoYear = Math.floor(
    (currentDate.getTime() - firstDayOfYear.getTime()) /
      (24 * 60 * 60 * 1000),
  );
  const currentWeek = Math.ceil(
    (daysIntoYear + firstDayOfYear.getDay() + 1) / 7,
  );
  return (currentWeek - 1).toString();
}

export function GenerateQueryString(searchParams) {
  const params = new URLSearchParams();
  for (const key in searchParams) {
    if (
      searchParams[key] !== null &&
      searchParams[key] !== undefined &&
      searchParams[key] !== ''
    ) {
      params.append(key, searchParams[key].toString());
    }
  }
  return params;
}

export function ParseUrlForSearchParams(
  defaultSearchParams,
  defaultSearchDate = null,
  isFirstRender = false,
) {
  const url = window.location.href.toString();
  const queryString = url.split('?')[1];
  // Split the query string into an array of key-value pairs
  const queryParamsArray = queryString?.split('&');
  // Create an object to store the decoded parameters
  const decodedParams = defaultSearchParams;
  // Loop through the key-value pairs and decode each one
  queryParamsArray?.forEach(function (param) {
    const [key, value] = param.split('=');
    decodedParams[key] = decodeURIComponent(value);
  });

  // some search forms we set the dateMin to 30 days before current date on first render
  // if not using, isFirstRender will default to false
  if (defaultSearchDate && !queryParamsArray && isFirstRender) {
    //make sure to use "dateMin" in search params if you want to auto-populate defaultSearchDate
    decodedParams.dateMin = defaultSearchDate;
  }
  return decodedParams;
}
