import React from 'react';
import classes from './File.module.css';
import classesGlobal from '../../../assets/Global.module.css';
import Button from '../../../assets/essentials/Button';
import Select from '../../../assets/essentials/Select';
import Input from '../../../assets/essentials/Input';
import Form from '../../../assets/essentials/Form';
import {
  DefaultDocumentTypeOptions,
  CustomerDocumentTypeOptions,
  LoadDocumentTypeOptions,
  MaintenanceLogDocumentTypeOptions,
} from './DocumentTypeOptions';
import { FileCategory } from './Files';
import { FileModalCategory } from './FilesModal';

function CreateFile({
  UploadFile,
  uploadFileListType,
  defaultValue,
  isOpen,
  setIsOpen
}: {
  UploadFile: Function;
  uploadFileListType?: FileCategory | FileModalCategory | null;
  defaultValue?: string;
  isOpen?: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}) {

  function SubmitForm(e) {
    e.preventDefault();
    UploadFile(e);
    setIsOpen(false);
  }
  const DocumentTypeOptions = () => {
    switch (uploadFileListType) {
      case 'customer':
        return <CustomerDocumentTypeOptions />;
      case 'load':
        return <LoadDocumentTypeOptions />;
      case 'maintenance-log':
        return <MaintenanceLogDocumentTypeOptions />;
      default:
        return <DefaultDocumentTypeOptions />;
    }
  };

  return (
    <>
      <div className={classes.formHolder}>
        {isOpen && (
          <Form onSubmit={e => SubmitForm(e)}>
            <div className={classesGlobal.attribute}>
              <label htmlFor="documentType">
                File Type <span className={classesGlobal.required}>*</span>
              </label>
              <Select
                name="documentType"
                defaultValue={defaultValue || ''}
                id="documentType"
                required
              >
                <option value="" disabled>
                  -- Required --
                </option>
                <DocumentTypeOptions />
              </Select>
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label htmlFor="file">
                Choose Files <span className={classesGlobal.required}>*</span>
              </label>
              <Input type="file" name="file" id="file" required multiple />
            </div>
            <div>
              <Button type="submit" variant="good">
                Submit
              </Button>
            </div>
            <div>
              <Button onClick={() => setIsOpen(!isOpen)}>Cancel</Button>
            </div>
          </Form>
        )}
      </div>
    </>
  );
}

export default CreateFile;
