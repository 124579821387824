function Close(slabId) {
  let slabContainer = document.getElementById('slab-container');
  
  if (slabId) {
    let slabToClose = document.getElementById(slabId);
    if (slabToClose) {
      let parentSlab = slabToClose.closest('#slab-container > div');
      if (parentSlab) {
        parentSlab.remove();
      }
    }
  } else {
    while (slabContainer.firstChild) {
      slabContainer.removeChild(slabContainer.firstChild);
    }
  }
}

export default Close;
