import React from 'react';

import classes from '../../../assets/Global.module.css';
import Input from '../../../assets/essentials/Input';
import Select from '../../../assets/essentials/Select';
import OptionList from '../../../helpers/options/OptionList';
import TextArea from '../../../assets/essentials/TextArea';

function Details({ posting, postingOption, UpdateCreatePosting }) {
  return (
    <form className={classes.viewGrid}>
      <h1>Details</h1>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>
          Availability <span className={classes.required}>*</span>
        </label>
        <Input
          type="date"
          defaultValue={
            posting.availability ? posting.availability : new Date()
          }
          name="availability"
          onChange={e => {
            UpdateCreatePosting(e.target.value, 'availability');
          }}
        />
      </div>
      <div className={classes.attribute}>
        <label>
          Equipment <span className={classes.required}>*</span>
        </label>
        <Select
          defaultValue={posting.equipment ? posting.equipment : ''}
          name="equipment"
          onChange={e => {
            UpdateCreatePosting(e.target.value, 'equipment');
          }}
        >
          <option disabled value="">
            -- Required --
          </option>
          <OptionList
            optionData={postingOption.equipment}
            attributeID="trailerTypeId"
            attributeName="type"
            attributeGroup="group"
          />
        </Select>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>
          Weight <span className={classes.required}>*</span>
        </label>
        <Input
          defaultValue={posting.weight}
          name="weight"
          onChange={e => UpdateCreatePosting(e.target.value, 'weight')}
        />
      </div>

      <div className={classes.attribute}>
        <label>Origin</label>
        <Input
          defaultValue={posting.origin}
          name="origin"
          onChange={e => UpdateCreatePosting(e.target.value, 'origin')}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Destination</label>
        <Input
          defaultValue={posting.destination}
          name="destination"
          onChange={e => UpdateCreatePosting(e.target.value, 'destination')}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>
          Number of Trucks <span className={classes.required}>*</span>
        </label>
        <Input
          defaultValue={posting.numOfTrucks}
          name="numOfTrucks"
          type="number"
          onChange={e => UpdateCreatePosting(e.target.value, 'numOfTrucks')}
        />
      </div>
    </form>
  );
}
export default Details;
