import React from 'react';
import Form from '../../../assets/essentials/Form';
import classes from '../../../assets/Global.module.css';
import Select from '../../../assets/essentials/Select';
import Button from '../../../assets/essentials/Button';
import PutForm from '../../../api/internal/PutForm';

type EmployeeAdminProps = {
  data: EmployeeAdmin;
  employeeId: number;
  RefreshPage: Function;
};

function Admin({ data, employeeId, RefreshPage }: EmployeeAdminProps) {
  const OnSubmit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const appendix = {
      employeeId: employeeId,
    };
    PutForm(`/Employee/UpdateEmployeeAdmin`, formData, appendix).then(
      response => {
        if (response) {
          RefreshPage('Admin', ['Audit']);
        }
      },
    );
  };

  return (
    <Form onSubmit={e => OnSubmit(e)}>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>Employee Status</label>
        <Select name="IsActive" defaultValue={data.isActive ? 'true' : 'false'}>
          <option value="true">Active</option>
          <option value="false">Inactive</option>
        </Select>
      </div>
      <div>
        <Button type="submit" variant="good">
          Submit
        </Button>
      </div>
    </Form>
  );
}

export default Admin;
