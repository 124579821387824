import React, { useState } from 'react';
import { IoIosAddCircleOutline, IoMdRemoveCircleOutline } from 'react-icons/io';
import classesGlobal from '../../../../assets/Global.module.css';
import accordionClasses from '../../../../assets/Accordion.module.css';
import OptionList from '../../../../helpers/options/OptionList';
import Input from '../../../../assets/essentials/Input';
import TextArea from '../../../../assets/essentials/TextArea';
import Select from '../../../../assets/essentials/Select';

function StopInfo({
  stop,
  customerAssignmentOptions,
}: {
  stop: Stop | null;
  customerAssignmentOptions?: Array<CustomerAssignmentOption> | null;
}) {
  const [displayLumper, setDisplayLumper] = useState(
    stop ? stop.lumperCharge : false,
  );
  const today = new Date().toISOString().slice(0, 16);
  const LumperSection = () => {
    // Creating a stop means stop is null and we don't show the lumper section at all
    if (stop) {
      return (
        <label>
          {displayLumper ? 'Remove Lumper Charge' : 'Add Lumper Charge'}
          <div className={classesGlobal.hiddenToggler}>
            <Input
              type="checkbox"
              defaultChecked={displayLumper}
              onChange={() => setDisplayLumper(!displayLumper)}
              name="lumperButton"
            />
          </div>

          {displayLumper ? (
            <IoMdRemoveCircleOutline className={classesGlobal.cTA} />
          ) : (
            <IoIosAddCircleOutline className={classesGlobal.cTA} />
          )}
        </label>
      );
    }
  };

  return (
    <>
      <h4>Stop Information</h4>
      <div className={`${classesGlobal.splitRow} ${classesGlobal.span3}`}>
        <div className={`${classesGlobal.attribute} `}>
          {/* This component is used in both assign load stop and view load stop so it needs
          to account for pickups and dropoffs for view, and undefined for creating a stop */}
          <label>
            {stop?.stopType === 'Pickup'
              ? 'Pickup Info'
              : stop?.stopType === 'Drop'
              ? 'Delivery Info'
              : 'Stop Info'}
          </label>
          <TextArea
            type="text"
            defaultValue={stop?.deliveryInfo}
            name="deliveryInfo"
          />
        </div>
        <div className={classesGlobal.attribute}>
          <label>PO#</label>
          <TextArea
            type="text"
            defaultValue={stop?.poNumbers}
            name="pONumbers"
          />
        </div>
      </div>
      <div className={classesGlobal.attribute}>
        <label>Weight</label>
        <Input
          min={0}
          type="number"
          name="weight"
          defaultValue={stop?.weight}
        />
      </div>
      <div className={`${classesGlobal.attribute}`}>
        <label>Pallets</label>
        <Input
          min={0}
          type="number"
          name="pallets"
          defaultValue={stop?.pallets}
        />
      </div>
      <div className={classesGlobal.attribute}>
        <label>Cases</label>
        <Input min={0} type="number" name="cases" defaultValue={stop?.cases} />
      </div>
      <div className={classesGlobal.attribute}>
        <label>Product</label>
        <Input type="text" name="product" defaultValue={stop?.product} />
      </div>
      <div className={`${classesGlobal.attribute} ${classesGlobal.span2}`}>
        <label>SKU</label>
        <Input type="text" name="sku" defaultValue={stop?.sku} />
      </div>

      <div className={`${classesGlobal.attribute} ${classesGlobal.span3}`}>
        <label>Completed Time</label>
        <Input
          type="datetime-local"
          name="stopCompleteTime"
          defaultValue={stop?.stopCompleteTime}
          max={today}
        />
      </div>
      <div className={`${classesGlobal.attribute} ${classesGlobal.span3}`}>
        <LumperSection />
      </div>
      {displayLumper && (
        <>
          <div
            className={`${classesGlobal.attribute} ${accordionClasses.addLumper}`}
          >
            <label>Charge Customer</label>
            <Select
              defaultValue={stop?.customerAssignmentId}
              name="customerAssignmentId"
            >
              <option value="">None</option>
              <OptionList
                optionData={customerAssignmentOptions}
                attributeID="customerAssignmentId"
                attributeName="customerName"
              />
            </Select>
          </div>
          <div className={`${classesGlobal.attribute} ${classesGlobal.span2}`}>
            <label>Lumper Charge</label>
            <Input
              type="number"
              min={0.01}
              step={0.01}
              defaultValue={stop.lumperCharge}
              name="lumperCharge"
              required={true}
            />
          </div>
        </>
      )}
    </>
  );
}
export default StopInfo;
