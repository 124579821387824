import React, { useState } from 'react';
import classesGlobal from '../../../../assets/Global.module.css';
import Input from '../../../../assets/essentials/Input';
import Select from '../../../../assets/essentials/Select';
import OptionList from '../../../../helpers/options/OptionList';
import Button from '../../../../assets/essentials/Button';
import PostForm from '../../../../api/internal/PostForm';
import Form from '../../../../assets/essentials/Form';

import { IoIosAddCircleOutline, IoMdRemoveCircleOutline } from 'react-icons/io';

function CreateCustomerAssignment({
  customerOptions,
  loadId,
  RefreshPage,
}: {
  customerOptions: Array<CustomerOption>;
  loadId: number;
  RefreshPage: Function;
}) {
  const [showCustomerOptions, setShowCustomerOptions] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({ id: 0, name: '' });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)

  const HandleCustomerChange = event => {
    setIsButtonDisabled(false)
    const newCustomerId = parseInt(event.target.value);
    const newName = event.target.name;
    setSelectedCustomer({ id: newCustomerId, name: newName });
  };

  function CreateCustomerAssignment(e) {
    setIsButtonDisabled(true)
    e.preventDefault();
    const formData = new FormData(e.target);
    const appendix = {
      loadId: loadId,
      customerId: selectedCustomer.id,
    };

    PostForm(`/Load/CreateCustomerAssignment`, formData, appendix).then(
      response => {
        if (response) {
          RefreshPage('Admin', ['Audit', 'Summary']);
        }
      },
    );

  }

  const AddOrCancelLabel = () => (
    <label>
      {showCustomerOptions ? ' Cancel ' : ' Add Customer '}
      <div className={classesGlobal.hiddenToggler}>
        <Input
          type="checkbox"
          defaultChecked={showCustomerOptions}
          onChange={() => setShowCustomerOptions(!showCustomerOptions)}
          name="customer"
        />
      </div>
      {showCustomerOptions ? (
          <IoMdRemoveCircleOutline className={classesGlobal.cTA} />
      ) : (
          <IoIosAddCircleOutline className={classesGlobal.cTA} />
      )}
    </label>
  );

  const CustomerOptionList = () => (
    <div>
      <Select
        id={`newCustomerSelect`}
        onChange={event => HandleCustomerChange(event)}
        defaultValue={selectedCustomer.id}
        name="currentCustomer"
      >
        <OptionList
          optionData={customerOptions}
          attributeID="customerId"
          attributeName="customerName"
        />
      </Select>
    </div>
  );

  return (
    <form
      onSubmit={e => CreateCustomerAssignment(e)}
      className={classesGlobal.flexColumn}
    >
      <AddOrCancelLabel />
      {showCustomerOptions && (
        <>
          <CustomerOptionList />
          <div>
            {' '}
            <Button variant="good" type="submit" disabled={isButtonDisabled}>
              Save Changes
            </Button>
          </div>
        </>
      )}
    </form>
  );
}

export default CreateCustomerAssignment;
