import React from 'react';
import classes from '../../assets/essentials/Input.module.css'
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

//MaskedInput DOCUMENTATION: https://github.com/text-mask/text-mask/tree/master/react#readme

const defaultPercentageMaskOptions = {
  prefix: '',
  suffix: '%',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: false,
  decimalSymbol: '.',
  decimalLimit: 0, // how many digits allowed after the decimal
  integerLimit: 2, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: true,
};

function PercentageInput({...rest }) {
  const percentageMask = createNumberMask({
    ...defaultPercentageMaskOptions,
  });

  return (
    <MaskedInput
      mask={percentageMask}
      {...rest}
      type="text"
      inputMode="numeric" //mobile users - device will pull up number pad instead of alpha keyboard
      className={classes.input}
    />
  );
}

export default PercentageInput;
