import { createRoot } from 'react-dom/client';
import ClosePanel from '../../helpers/panel/Close';

function View(component) {
  ClosePanel();
  const div = document.createElement('div');
  createRoot(document.getElementById('slab-container').appendChild(div)).render(
    component,
  );
}

export default View;
