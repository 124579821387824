import React, { useState, useEffect } from 'react';
import { MdDownload } from 'react-icons/md';
import BBITable from '../../../helpers/bBITable/BBITable';
import classesCustom from '../../../helpers/search/CustomSearch.module.css';
import classesGlobal from '../../../assets/Global.module.css';
import DateOnlyFormatter from '../../../helpers/inputs/DateOnlyFormatter';
import FilterData from '../../../helpers/filter/FilterData';
import Get from '../../../api/internal/Get';
import Header from '../../../layouts/Header';
import View from '../../../helpers/slab/View';
import ViewCustomer from '../../../features/views/customer/Customer';
import ViewLoad from '../../../features/views/load/Load';
import ViewRental from '../../../features/views/rental/Rental';
import ViewReceivable from '../../../features/views/receivable/Receivable';
import PostBody from '../../../api/internal/PostBody';
import Input from '../../../assets/essentials/Input';
import Select from '../../../assets/essentials/Select';
import OptionList from '../../../helpers/options/OptionList';
import Button from '../../../assets/essentials/Button';
import {
  GenerateQueryString,
  ParseUrlForSearchParams,
} from '../../../helpers/search/HelperFunctions';
import { useOutletContext } from 'react-router-dom';

function Receivable({ toggleBoardRefresh }: { toggleBoardRefresh: boolean }) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [receivableSearchFormResults, setReceivableSearchFormResults] =
    useState([]);
  const [paymentStatusOptions, setPaymentStatusOptions] = useState<
    PaymentStatusOption[]
  >([]);
  const emptySearchParams = {
    loadId: null,
    rentalId: null,
    reference: null,
    paymentStatusId: null,
    customerName: null,
    invoiceDate: null,
    dueDate: null,
  };
  const [receivableSearchParams, setReceivableSearchParams] =
    useState(emptySearchParams);
  const filterValue = useOutletContext();

  useEffect(() => {
    setIsLoading(true);
    if (paymentStatusOptions.length === 0) {
      Get(`/Accounting/GetPaymentStatusOptions/`).then(response => {
        if (response) {
          setPaymentStatusOptions(response.data);
        }
      });
    }

    const {
      loadId,
      rentalId,
      reference,
      paymentStatusId,
      customerName,
      invoiceDate,
      dueDate,
    } = ParseUrlForSearchParams(emptySearchParams);

    setReceivableSearchParams({
      loadId: loadId,
      rentalId: rentalId,
      reference: reference,
      paymentStatusId: paymentStatusId,
      customerName: customerName,
      invoiceDate: invoiceDate,
      dueDate: dueDate,
    });
    SearchReceivables({
      loadId: loadId,
      rentalId: rentalId,
      reference: reference,
      paymentStatusId: paymentStatusId,
      customerName: customerName,
      invoiceDate: invoiceDate,
      dueDate: dueDate,
    });
  }, [toggleBoardRefresh]);

  function FormatReceivables(receivables) {
    return receivables.map(receivable => ({
      ...receivable,
      attribute: receivable.loadId
        ? receivable.loadId
        : receivable.rentalId && 'Rental: ' + receivable.rentalId,
      dueDate: DateOnlyFormatter(receivable.dueDate),
      invoiceDate: DateOnlyFormatter(receivable.invoiceDate),
      remainingBalance: receivable.remainingBalance.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      total: receivable.total.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    }));
  }

  function SearchReceivables(SearchParamsFromForm?: Object) {
    const searchParams = SearchParamsFromForm
      ? SearchParamsFromForm
      : receivableSearchParams;
    const queryString = '?' + GenerateQueryString(searchParams);
    const newUrl =
      window.location.origin + window.location.pathname + queryString;
    window.history.replaceState(null, null, newUrl);
    Get(`/Accounting/SearchReceivables${queryString}`).then(response => {
      if (response) {
        setReceivableSearchFormResults(FormatReceivables(response.data));
        setIsLoading(false);
      }
    });
  }

  function ClearSearchParams() {
    setReceivableSearchParams(emptySearchParams);
  }

  function HandleFormSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    const formData = {
      loadId: e.target.loadId?.value,
      rentalId: e.target.rentalId?.value,
      reference: e.target.reference?.value,
      paymentStatusId: e.target.paymentStatusId?.value,
      customerName: e.target.customerName?.value,
      invoiceDate: e.target.invoiceDate?.value,
      dueDate: e.target.dueDate?.value,
    };
    setReceivableSearchParams(formData);
    SearchReceivables(formData);
  }

  function HandleDownloadCSV() {
    if (receivableSearchFormResults.length !== 0) {
      DownloadReceivableReportCSV(
        FilterData(receivableSearchFormResults, filterValue),
      );
    } else {
      alert('No receivables found!');
    }
  }

  function DownloadReceivableReportCSV(receivables) {
    let receivableIdsToDownload = receivables?.map(x => x.customerAssignmentId);
    if (receivableIdsToDownload.length > 0) {
      const payload = {
        CustomerAssignmentIdList: [...receivableIdsToDownload],
      };
      PostBody('Accounting/DownloadReceivableReportCSV', payload).then(
        response => {
          if (response) {
            const blob = new Blob([response.data], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download =
              new Date().toISOString().split('T')[0].replace(/[-]/g, '') +
              '_Receivables Report.csv';
            a.click();
            URL.revokeObjectURL(url);
          }
        },
      );
    }
  }

  const columnConfig = [
    {
      key: '1',
      attribute: 'attribute',
      attributeprimaryhidden: 'loadId',
      attributesecondaryhidden: 'rentalId',
      header: 'Load #',
      onClick: e =>
        View(
          e.target.dataset.attributeprimaryhidden ? (
            <ViewLoad loadId={e.target.dataset.attributeprimaryhidden} />
          ) : (
            e.target.dataset.attributesecondaryhidden && (
              <ViewRental
                rentalId={e.target.dataset.attributesecondaryhidden}
              />
            )
          ),
        ),

      dataType: 'number',
    },
    {
      key: '2',
      attribute: 'reference',
      header: 'Reference',
      attributeprimaryhidden: 'loadId',
      attributesecondaryhidden: 'rentalId',
      onClick: e =>
        View(
          e.target.dataset.attributeprimaryhidden ? (
            <ViewLoad loadId={e.target.dataset.attributeprimaryhidden} />
          ) : (
            e.target.dataset.attributesecondaryhidden && (
              <ViewRental
                rentalId={e.target.dataset.attributesecondaryhidden}
              />
            )
          ),
        ),
    },
    {
      key: '3',
      attribute: 'paymentStatus',
      attributeprimaryhidden: 'customerAssignmentId',
      attributesecondaryhidden: 'loadId',
      attributetertiaryhidden: 'rentalId',
      header: 'Status',
      onClick: e =>
        View(
          <ViewReceivable
            customerAssignmentId={e.target.dataset.attributeprimaryhidden}
            loadId={e.target.dataset.attributesecondaryhidden}
            rentalId={e.target.dataset.attributetertiaryhidden}
          />,
        ),
      dataType: 'string',
    },
    {
      key: '4',
      attribute: 'customerName',
      attributeprimaryhidden: 'customerId',
      header: 'Customer',
      onClick: e =>
        View(
          <ViewCustomer
            customerName={e.target.innerText}
            customerId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '5',
      attribute: 'quickBooksInvoiceId',
      header: 'QuickBooks ID',
      dataType: 'number',
    },
    {
      key: '6',
      attribute: 'invoiceDate',
      header: 'Invoice Date',
      dataType: 'date',
    },
    {
      key: '7',
      attribute: 'dueDate',
      header: 'Due Date',
      dataType: 'date',
    },
    {
      key: '8',
      attribute: 'total',
      header: 'Total',
      dataType: 'currency',
    },
    {
      key: '9',
      attribute: 'remainingBalance',
      header: 'Remaining Balance',
      dataType: 'currency',
    },
  ];

  const ReceivableTable = () => {
    return (
      <BBITable
        data={FilterData(receivableSearchFormResults, filterValue)}
        columns={columnConfig}
      />
    );
  };

  const ReceivableSearchForm = () => {
    return (
      <div className={`${classesCustom.customSearch}`}>
        <form onSubmit={e => HandleFormSubmit(e)}>
          <label>
            Load #
            <Input name="loadId" defaultValue={receivableSearchParams.loadId} />
          </label>
          <label>
            Rental #
            <Input
              name="rentalId"
              defaultValue={receivableSearchParams.rentalId}
            />
          </label>
          <label>
            Reference
            <Input
              name="reference"
              defaultValue={receivableSearchParams.reference}
            />
          </label>
          <label>
            Payment Status
            <Select
              name="paymentStatusId"
              defaultValue={receivableSearchParams.paymentStatusId}
            >
              <option value="">-- Select Status --</option>
              <OptionList
                optionData={paymentStatusOptions}
                attributeID="paymentStatusId"
                attributeName="status"
              />
            </Select>
          </label>
          <label>
            Customer Name
            <Input
              name="customerName"
              defaultValue={receivableSearchParams.customerName}
            />
          </label>
          <label>
            Invoice Date
            <Input
              type="date"
              name="invoiceDate"
              defaultValue={receivableSearchParams.invoiceDate}
            />
          </label>
          <label>
            Due Date
            <Input
              type="date"
              name="dueDate"
              defaultValue={receivableSearchParams.dueDate}
            />
          </label>
          <div className={classesCustom.submitHolder}>
            <Button type="submit" variant="good">
              Search
            </Button>

            <Button
              onClick={() => ClearSearchParams()}
              type="button"
              variant="bad"
            >
              Clear
            </Button>
          </div>
        </form>
      </div>
    );
  };

  const DownloadButton = () => {
    return (
      <button onClick={() => HandleDownloadCSV()}>
        <MdDownload />
        <span>Download</span>
      </button>
    );
  };

  return (
    <>
      <Header>
        <DownloadButton />
      </Header>
      <ReceivableSearchForm />
      {isLoading ? <h3>Searching...</h3> : <ReceivableTable />}
    </>
  );
}
export default Receivable;
