import React from 'react';

import BBITable from '../../../helpers/bBITable/BBITable';
import classes from '../../../assets/Global.module.css';
import DateOnlyFormatter from '../../../helpers/inputs/DateOnlyFormatter';
import View from '../../../helpers/slab/View';
import ViewLoad from '../load/Load';
import ViewDriver from '../users/driver/Driver';

function Summary({ data }: { data: PayrollSummary }) {
  const formattedLineItems = data?.lineItems.map(lineItem => ({
    ...lineItem,
    rate: lineItem.rate.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }),
    total: lineItem.total.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }),
  }));

  const formattedPayments = data?.payments?.map(payment => ({
    ...payment,
    date: DateOnlyFormatter(payment.date),
    amount: payment.amount.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }),
  }));

  const lineItemsColumnConfig = [
    {
      key: '1',
      attribute: 'accountType',
      header: 'Type',
      width: '24ch',
    },
    {
      key: '2',
      attribute: 'note',
      header: 'Note',

      width: '24ch',
    },
    {
      key: '3',
      attribute: 'rate',
      header: 'Rate',
      width: '12ch',
    },
    {
      key: '4',
      attribute: 'quantity',
      header: 'Quantity',
      width: '6ch',
    },
    {
      key: '5',
      attribute: 'total',
      header: 'Total',
      width: 'auto',
    },
  ];

  const paymentsColumnConfig = [
    {
      key: '1',
      attribute: 'cashFlowId',
      header: 'ID #',
      width: '12ch',
    },
    {
      key: '2',
      attribute: 'date',
      header: 'Date',
      width: '12ch',
    },
    {
      key: '3',
      attribute: 'paymentMethod',
      header: 'Payment Method',
      width: '12ch',
    },
    {
      key: '4',
      attribute: 'note',
      header: 'Note',
      width: '12ch',
    },
    {
      key: '5',
      attribute: 'amount',
      header: 'Amount',
      width: '6ch',
    },
  ];

  return (
    <div className={classes.viewGrid}>
      <div className={classes.attribute}>
        <h3>Load</h3>
        {data?.loadDisplayList.map((line, index) => (
          <span key={index}>{line}</span>
        ))}
        <div
          className={classes.cTA}
          onClick={() => View(<ViewLoad loadId={data.loadId} />)}
        >
          View Load Info
        </div>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <h3>Driver</h3>
        <span>{data?.driverName}</span>
        <div
          className={classes.cTA}
          onClick={() =>
            View(
              <ViewDriver
                employeeId={data?.employeeId}
                driverName={data?.driverName}
              />,
            )
          }
        >
          View Driver Info
        </div>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <h3>Payment Status</h3>
        <span>{data?.paymentStatus}</span>
      </div>
      <div className={`${classes.attribute} ${classes.span1}`}>
        <h3>Work Completed Date</h3>
        <span>{DateOnlyFormatter(data?.workAndPaperworkCompletedDate)}</span>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <h3>Load Mileage</h3>
        <span>{data?.loadMileage?.toLocaleString()}</span>
      </div>
      <div className={classes.attribute}>
        <h3>Total</h3>
        <span>
          {data?.total.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </span>
      </div>
      <div className={classes.attribute}>
        <h3>Payment</h3>
        <span>
          {data?.payment.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </span>
      </div>
      <div className={classes.attribute}>
        <h3>Remaining Balance</h3>
        <span>
          {data?.remainingBalance.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </span>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <h3>Line Items</h3>
        <BBITable
          data={formattedLineItems ?? []}
          columns={lineItemsColumnConfig}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <h3>Payments</h3>
        <BBITable
          data={formattedPayments ?? []}
          columns={paymentsColumnConfig}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <h3>Note</h3>
        <span>{data?.note}</span>
      </div>
    </div>
  );
}
export default Summary;
