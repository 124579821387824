import classes from './Panel.module.css';
import Close from './Close';
import ChildCountObserver from '../ChildCountObserver';

function Holder({ onChildCountChange }) {
  return (
    <div className={classes.panelContainer}>
      <div className={classes.overlay} onClick={Close}></div>
      <ChildCountObserver
        targetElementId="panelHolder"
        onChildCountChange={onChildCountChange}
      >
        <div className={classes.panelHolder} id="panel-container"></div>
      </ChildCountObserver>
    </div>
  );
}

export default Holder;
