import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Get from '../../api/internal/Get';
import DateOnlyFormatter from '../../helpers/inputs/DateOnlyFormatter';
import View from '../../helpers/slab/View';
import ViewCustomer from '../../features/views/customer/Customer';
import ViewLoad from '../../features/views/load/Load';
import FilterData from '../../helpers/filter/FilterData';
import BBITable from '../../helpers/bBITable/BBITable';

const CustomerBoard = ({ toggleBoardRefresh }) => {
  const [customerBoard, setCustomerBoard] = useState<Array<CustomerBoard>>([]);
  const filterValue = useOutletContext();
  useEffect(() => {
    Get(`/Customer/GetCustomerBoard/`).then(response => {
      if (response) {
        setCustomerBoard(FormatDates(response.data));
      }
    });
  }, [toggleBoardRefresh]);

  function FormatDates(receivables) {
    return receivables.map(receivable => ({
      ...receivable,
      lastLoadDate: DateOnlyFormatter(receivable.lastLoadDate),
    }));
  }

  let columnConfig = [
    {
      key: '1',
      attribute: 'customerName',
      attributeprimaryhidden: 'customerId',
      header: 'Customer',
      onClick: e =>
        View(
          <ViewCustomer
            customerName={e.target.innerText}
            customerId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '2',
      attribute: 'customerPhone',
      header: 'Phone',
    },
    {
      key: '3',
      attribute: 'customerAddress',
      header: 'Address',
    },
    {
      key: '4',
      attribute: 'customerCity',
      header: 'City',
    },
    {
      key: '5',
      attribute: 'customerState',
      header: 'State',
    },
    {
      key: '6',
      attribute: 'quickBooksCustomerId',
      header: 'QuickBooks ID',
    },
    {
      key: '7',
      attribute: 'lastLoadDate',
      attributeprimaryhidden: 'lastLoadId',
      header: 'Last Load Date',
      onClick: e =>
        View(<ViewLoad loadId={e.target.dataset.attributeprimaryhidden} />),
    },
  ];

  let tableData = FilterData(customerBoard, filterValue);

  return <BBITable data={tableData} columns={columnConfig} />;
};

export default CustomerBoard;
