import React, { useState } from 'react'
import classes from '../../assets/Global.module.css';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from '../../layouts/Header';
import Create from '../../helpers/panel/Create';
import CreateLocation from '../../features/creates/location/Location';
import { IoLocationOutline } from 'react-icons/io5';
import { RiListCheck } from 'react-icons/ri';
import { VscSearch } from 'react-icons/vsc';
import Filter from '../../helpers/filter/Filter';

const Location = () => {
  const [filterValue, setFilterValue] = useState('');

  const navigate = useNavigate();
  const pathName = useLocation().pathname.split('/').slice(-1)[0];
  
  return (
    <>
      <h1>Location</h1>
      <Header>
        <div>
          <button
            onClick={() =>
              Create(<CreateLocation />)
            }
          >
            <IoLocationOutline />
            <span>Create a Location</span>
          </button>
          <button
            onClick={() => navigate('/location')}
            className={pathName === 'location' ? classes.buttonActive : ''}
          >
            <RiListCheck />
            <span>Location Board</span>
          </button>
          <button
            onClick={() => navigate('/location/search')}
            className={pathName === 'search' ? classes.buttonActive : ''}
          >
            <VscSearch />
            <span>Search Location</span>
          </button>
        </div>
        <div>
          <Filter
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            placeholder="Filter locations"
          />
        </div>
      </Header>
      <Outlet context={filterValue} />
    </>
  )
}

export default Location
