import React from 'react';

import classes from './LocationValidationError.module.css';
import Button from '../../assets/essentials/Button';
import Close from './Close';
import Select from '../../assets/essentials/Select';
import OptionList from '../options/OptionList';
import useTimezoneOptions from '../../hooks/useTimezoneOptions';
import PostBody from '../../api/internal/PostBody';
import Location from '../../features/views/location/Location';
import View from '../slab/View';
import Input from '../../assets/essentials/Input';

function LocationValidationError({ location }: { location: LocationInfo }) {
  const timezoneOptions: TimeZoneOption[] = useTimezoneOptions();

  function Submit(e, location: LocationInfo) {
    e.preventDefault();
    const completeLocation = {
      ...location,
      latitude: e.target.latitude.value,
      longitude: e.target.longitude.value,
      ianaTimeZone: e.target.iana.value,
      abbreviatedTimezone: timezoneOptions.find(
        option => option.iana === e.target.iana.value,
      ).abbreviation,
    };

    PostBody(
      `/Location/CreateLocationUserProvidedCoordinates`,
      completeLocation,
    ).then(response => {
      if (response) {
        Close();
        View(
          <Location
            locationId={response.data}
            locationName={location.locationName}
          />,
        );
      }
    });
  }

  return (
    <div className={classes.error}>
      <h2 className={classes.title}>Unable To Validate Location Coordinates</h2>
      <p className={classes.warning}>Manual Override</p>
      <form
        onSubmit={e => {
          Submit(e, location);
        }}
      >
        <label htmlFor="iana">
          <strong>Timezone:</strong>
        </label>
        <Select name="iana" id="iana">
          <OptionList
            optionData={timezoneOptions}
            attributeID="iana"
            attributeName="abbreviation"
          />
        </Select>
        <label htmlFor="latitude">
          <strong>Latitude: </strong>
          <i>Continental US 25.84 to 49.38</i>
        </label>
        <Input
          type="number"
          name="latitude"
          id="latitude"
          title="-90 to 90 degrees."
          min={-90}
          max={90}
          step={0.00000000000001}
          required
        />
        <label htmlFor="longitude">
          <strong>Longitude:</strong> <i>Continental US -66.95 to -124.67</i>
        </label>
        <Input
          type="number"
          name="longitude"
          id="longitude"
          title="-180 to 180 degrees."
          min={-180}
          max={180}
          step={0.00000000000001}
          required
        />
        <p>Accurate coordinates are required for load mileage calculations.</p>
        <div className={classes.buttons}>
          <Button variant="good" type="submit">
            Submit
          </Button>
          <Button variant="bad" onClick={Close}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
}

export default LocationValidationError;
