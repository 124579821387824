import React from 'react';

import classes from '../../../assets/Global.module.css';
import Input from '../../../assets/essentials/Input';
import Select from '../../../assets/essentials/Select';
import TextArea from '../../../assets/essentials/TextArea';
import OptionList from '../../../helpers/options/OptionList';

function AdditionalInfo({
  createCustomerOptions,
  param,
  UpdateParam,
}: {
  createCustomerOptions: CreateCustomerOptions;
  param: CreateCustomerParam;
  UpdateParam: Function;
}) {
  return (
    <>
      <h1>Additional Info</h1>
      <div className={classes.viewGrid}>
        <div className={classes.attribute}>
          <label>General Commodity</label>
          <Select
            defaultValue={param.commodityId}
            onChange={e => UpdateParam(e.target.value, 'commodityId')}
          >
            <option value="" />
            <OptionList
              optionData={createCustomerOptions?.commodityOptions}
              attributeGroup="group"
              attributeID="commodityId"
              attributeName="type"
            />
          </Select>
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>Website</label>
          <Input
            defaultValue={param.website}
            onChange={e => UpdateParam(e.target.value, 'website')}
            type="text"
          />
        </div>
        <div className={classes.attribute}>
          <label>Payment Method</label>
          <Select
            defaultValue={param.paymentMethodId}
            onChange={e => UpdateParam(e.target.value, 'paymentMethodId')}
          >
            <option disabled selected value="">
              {createCustomerOptions?.defaultPaymentMethodId}
            </option>
            <OptionList
              optionData={createCustomerOptions?.paymentMethodOptions}
              attributeID="paymentMethodId"
              attributeName="method"
            />
          </Select>
        </div>
        <div className={classes.attribute}>
          <label>Payment Term</label>
          <Select
            defaultValue={param.paymentTermId}
            onChange={e => UpdateParam(e.target.value, 'paymentTermId')}
          >
            <option disabled selected value="">
              {createCustomerOptions?.defaultPaymentTermId}
            </option>
            <OptionList
              optionData={createCustomerOptions?.paymentTermOptions}
              attributeID="paymentTermId"
              attributeName="term"
            />
          </Select>
        </div>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>Customer Note</label>
          <TextArea
            defaultValue={param.note}
            onChange={e => UpdateParam(e.target.value, 'note')}
            type="text"
          />
        </div>
      </div>
    </>
  );
}

export default AdditionalInfo;
