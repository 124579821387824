import React, { useEffect, useRef, useState } from 'react';
import classesGlobal from '../../assets/Global.module.css';
import classesSearch from '../../helpers/search/CustomSearch.module.css';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import Get from '../../api/internal/Get';
import View from '../../helpers/slab/View';
import ViewVendor from '../../features/views/vendor/Vendor';
import FilterData from '../../helpers/filter/FilterData';
import Input from '../../assets/essentials/Input';
import Select from '../../assets/essentials/Select';
import StatesAndProvinces from '../../helpers/options/StatesAndProvinces';
import Button from '../../assets/essentials/Button';
import BBITable from '../../helpers/bBITable/BBITable';

const SearchVendor = ({ toggleBoardRefresh }) => {
  const [vendorBoard, setVendorBoard] = useState<Array<VendorBoard>>([]);
  const [searchVendorParams, setSearchVendorParams] = useSearchParams();

  const filterValue = useOutletContext();

  useEffect(() => {
    function SearchVendor() {
      Get(`/Vendor/SearchVendor?${searchVendorParams.toString()}`).then(
        response => {
          if (response) {
            setVendorBoard(response.data);
          }
        },
      );
    }

    SearchVendor();
  }, [toggleBoardRefresh, searchVendorParams]);

  const handleFormSubmit = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const newParams = new URLSearchParams();
    formData.forEach((value, key) => {
      if (value !== '') {
        newParams.set(key, value.toString());
      }
    });
    setSearchVendorParams(newParams, { replace: true });
  };

  let columnConfig = [
    {
      key: '1',
      attribute: 'vendorName',
      attributeprimaryhidden: 'vendorId',
      header: 'Vendor',
      onClick: e =>
        View(
          <ViewVendor
            vendorName={e.target.innerText}
            vendorId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '2',
      attribute: 'vendorPhone',
      header: 'Phone',
    },
    {
      key: '4',
      attribute: 'vendorAddress',
      header: 'Address',
    },
    {
      key: '5',
      attribute: 'vendorCity',
      header: 'City',
    },
    {
      key: '6',
      attribute: 'vendorState',
      header: 'State',
    },
  ];

  let tableData = FilterData(vendorBoard, filterValue);

  return (
    <>
      <div className={`${classesSearch.customSearch}`}>
        <form onSubmit={handleFormSubmit}>
          <label>
            Vendor Name
            <Input type="text" name="vendorName" />
          </label>
          <label>
            Address Line One
            <Input type="text" name="addressLineOne" />
          </label>
          <label>
            City
            <Input type="text" name="city" />
          </label>
          <label>
            State
            <Select name="state">
              <option value="" />
              <StatesAndProvinces />
            </Select>
          </label>
          <div className={classesSearch.submitHolder}>
            <Button type="submit" variant="good">
              Search
            </Button>
            <Button
              type="reset"
              variant="bad"
              onClick={() => setSearchVendorParams({})}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
      <BBITable data={tableData} columns={columnConfig} />
    </>
  );
};

export default SearchVendor;
