import React from 'react';
import CreateDriverEscrowEntry from '../../../creates/driver/DriverEscrow';
import BBITable from '../../../../helpers/bBITable/BBITable';
import DateOnlyFormatter from '../../../../helpers/inputs/DateOnlyFormatter';

function DriverEscrow({
  data,
  employeeId,
  RefreshPage,
}: {
  data: Array<DriverEscrowEntry>;
  employeeId: number;
  RefreshPage: Function;
}) {
  let formattedData = [];
  if (data) {
    formattedData = data.map(escrowEntry => ({
      ...escrowEntry,
      date: DateOnlyFormatter(escrowEntry.date),
    }));
  }

  let columnConfig = [
    {
      key: '1',
      attribute: 'reference',
      header: 'Reference',
    },
    {
      key: '2',
      attribute: 'date',
      header: 'Date',
    },
    {
      key: '3',
      attribute: 'type',
      header: 'Type',
    },
    {
      key: '4',
      attribute: 'amount',
      header: 'Amount',
    },
  ];

  return (
    <>
      <CreateDriverEscrowEntry employeeId={employeeId} RefreshPage={RefreshPage} />
      <BBITable data={formattedData} columns={columnConfig} />
    </>
  );
}
export default DriverEscrow;
