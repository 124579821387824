import React, { useState, useEffect, useContext } from 'react';
import { FiTruck } from 'react-icons/fi';
import { MdOutlineRefresh } from 'react-icons/md';

import View from '../helpers/slab/View';
import ViewLoad from '../features/views/load/Load';
import ViewDriver from '../features/views/users/driver/Driver';
import ViewLocation from '../features/views/location/Location';
import CreateLoad from '../features/creates/load/Load';
import BBITable from '../helpers/bBITable/BBITable';
import classes from '../helpers/bBITable/BBITable.module.css';
import Input from '../assets/essentials/Input';
import Get from '../api/internal/Get';
import PutForm from '../api/internal/PutForm';
import Header from '../layouts/Header';
import classesFilter from '../helpers/filter/Filter.module.css';
import Create from '../helpers/panel/Create';

function ActionBoard({ toggleBoardRefresh }) {
  const [dailyActionBoard, setDailyActionBoard] = useState([]);
  const [selectedDate, setSelectedDate] = useState<string>(
    new Date().toISOString().split('T')[0],
  );
  const [loading, setLoading] = useState(true);
  const today = new Date().toISOString().slice(0, 16);

  useEffect(() => {
    GetActionBoard(selectedDate);
  }, [selectedDate, toggleBoardRefresh]);

  function GetActionBoard(date: string) {
    Get(`/Load/GetActionBoard/${date}`).then(response => {
      if (response) {
        setDailyActionBoard(response.data);
      }
      setLoading(false);
    });
  }

  const formattedData = dailyActionBoard?.map(stop => ({
    ...stop,
    completedTime: (
      <div className={classesFilter.filterBar}>
        <Input
          type="datetime-local"
          aria-label="date when stop is completed"
          defaultValue={stop.completedTime}
          onChange={e => UpdateActionBoard(stop.stopId, e.target.value)}
          max={today}
          key={stop.stopId}
        />
      </div>
    ),
  }));

  function UpdateActionBoard(stopId, newStopCompleteTime) {
    setLoading(true);
    const formData = new FormData();
    formData.append('stopId', stopId);
    formData.append('stopCompleteTime', newStopCompleteTime);
    PutForm(`/Load/UpdateActionBoard`, formData).then(() => {
      //forcing refresh on page by reassigning useState
      setDailyActionBoard(prevState => {
        const updatedActionBoard = prevState.map(stop => {
          if (stop.stopId === stopId) {
            return {
              ...stop,
              completedTime: newStopCompleteTime,
            };
          }
          return stop;
        });
        return updatedActionBoard;
      });
      GetActionBoard(selectedDate);
    });
  }

  let columnConfig = [
    {
      key: '1',
      attribute: 'loadId',
      header: 'Load ID',
      onClick: e =>
        View(<ViewLoad startPage="Summary" loadId={e.target.innerText} />),
      width: '8ch',
    },
    {
      key: '2',
      attribute: 'stopType',
      attributeprimaryhidden: 'loadId',
      header: 'Stop Type',
      width: '10ch',
      onClick: e =>
        View(
          <ViewLoad
            startPage="Itinerary"
            loadId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '3',
      attribute: 'locationName',
      attributeprimaryhidden: 'locationId',
      header: 'Location',
      onClick: e =>
        View(
          <ViewLocation
            locationName={e.target.innerText}
            locationId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '4',
      attribute: 'driverNames',
      attributeprimaryhidden: 'employeeId',
      header: 'Drivers',
      onClick: e =>
        View(
          <ViewDriver
            driverName={e.target.innerText.split(', ')[0]}
            employeeId={e.target.dataset.attributeprimaryhidden}
            startPage="Summary"
          />,
        ),
    },
    {
      key: '5',
      attribute: 'appointmentTime',
      header: 'Appointment Time',
      width: '20ch',
    },
    {
      key: '6',
      attribute: 'completedTime',
      header: 'Completed',
      width: '30ch',
    },
  ];

  return (
    <>
      <h1>Daily Action Board</h1>
      <Header>
        <div>
          <button
            onClick={() =>
              Create(
                <CreateLoad
                  RefreshBoard={() => GetActionBoard(selectedDate)}
                />,
              )
            }
          >
            <FiTruck />
            <span>Create a Load</span>
          </button>
          <button onClick={() => GetActionBoard(selectedDate)}>
            <MdOutlineRefresh />
            <span>Refresh Board</span>
          </button>
        </div>
        <div className={classesFilter.filterBar}>
          <input
            type="date"
            value={selectedDate}
            aria-label="date to query"
            onChange={e => {
              setLoading(true);
              setSelectedDate(e.target.value);
            }}
          />
        </div>
      </Header>
      <div className={classes.title}>Appointments</div>
      {loading ? (
        <div />
      ) : formattedData.length > 0 ? (
        <BBITable
          data={formattedData}
          columns={columnConfig}
          compactView={false}
        />
      ) : (
        <div>No stops for this date.</div>
      )}
    </>
  );
}
export default ActionBoard;
