import { useEffect, useState } from 'react';
import Get from '../api/internal/Get';

export default function useTimezoneOptions() {
  const [timezoneOptions, setTimezoneOptions] =
    useState<TimeZoneOption[]>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (isLoading && !timezoneOptions) {
      Get('Location/GetLocationTimezones').then(response => {
        if (response) {
          setTimezoneOptions(response.data);
        }
        setIsLoading(false);
      });
    }
  }, [isLoading, timezoneOptions]);

  return timezoneOptions;
}
