import React, { useState } from 'react';
import { MdAttachMoney } from 'react-icons/md';
import { AiFillBank } from 'react-icons/ai';
import { FiTruck } from 'react-icons/fi';
import Header from '../../../layouts/Header';
import HeaderNavButton from '../../../helpers/buttons/HeaderNavButton';
import Filter from '../../../helpers/filter/Filter';
import { Outlet } from 'react-router-dom';

export default function EmployeeAccounts() {
  const [filterValue, setFilterValue] = useState('');

  const navButtons = [
    { to: 'esa', icon: AiFillBank, label: 'Employee Savings Accounts' },
    { to: 'driverescrow', icon: MdAttachMoney, label: 'Driver Escrow' },
    {
      to: 'drivermaintenance',
      icon: FiTruck,
      label: 'Driver Maintenance',
    },
  ];

  return (
    <>
      <h1>ESA</h1>
      <Header>
        <div>
          {navButtons.map(button => (
            <HeaderNavButton
              key={button.to}
              to={button.to}
              icon={button.icon}
              label={button.label}
            />
          ))}
        </div>
        <div>
          <Filter
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            placeholder="Filter employees"
          />
        </div>
      </Header>
      <Outlet context={filterValue} />
    </>
  );
}
