import React, { useState } from 'react';
import classes from '../../assets/Global.module.css';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from '../../layouts/Header';
import Create from '../../helpers/panel/Create';
import CreateCustomer from '../../features/creates/customer/Customer';
import { RiGroupLine, RiListCheck } from 'react-icons/ri';
import { VscSearch } from 'react-icons/vsc';
import Filter from '../../helpers/filter/Filter';

const Customer = () => {
  const [filterValue, setFilterValue] = useState('');
  const navigate = useNavigate();
  const pathName = useLocation().pathname.split('/').slice(-1)[0];

  return (
    <>
      <h1>Customer</h1>
      <Header>
        <div>
          <button onClick={() => Create(<CreateCustomer />)}>
            <RiGroupLine />
            <span>Create a Customer</span>
          </button>
          <button
            onClick={() => navigate('/customer')}
            className={pathName === 'customer' ? classes.buttonActive : ''}
          >
            <RiListCheck />
            <span>Customer Board</span>
          </button>
          <button
            onClick={() => navigate('/customer/search')}
            className={pathName === 'search' ? classes.buttonActive : ''}
          >
            <VscSearch />
            <span>Search Customer</span>
          </button>
        </div>
        <div>
          <Filter
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            placeholder="Filter customers"
          />
        </div>
      </Header>
      <Outlet context={filterValue} />
    </>
  );
};

export default Customer;
