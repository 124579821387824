import classes from './Header.module.css';

/**
 * Header component that wraps its children in a header element.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The content to be rendered inside the header.
 * @param {string} [props.className=''] - Additional CSS class names to be applied to the header.
 * @returns {React.ReactElement} A header element containing the provided children.
 *
 * @example
 * <Header className={classes.customCSS}>
 *   <h1>Page Title</h1>
 *   <nav>Navigation items</nav>
 * </Header>
 */

function Header({ children, className = '' }) {
  return (
    <header className={`${classes.header} ${className}`}>{children}</header>
  );
}
export default Header;
