import React from 'react';

import classes from '../../../assets/Global.module.css';
import Input from '../../../assets/essentials/Input';
import Select from '../../../assets/essentials/Select';
import OptionList from '../../../helpers/options/OptionList';
import TextArea from '../../../assets/essentials/TextArea';
import StatesAndProvinces from '../../../helpers/options/StatesAndProvinces';

function Details({
  trailer,
  createTrailer,
  setTrailerType,
  UpdateCreateTrailer,
}: {
  trailer: CreateTrailerParam;
  createTrailer: CreateTrailerOptions;
  setTrailerType: Function;
  UpdateCreateTrailer: Function;
}) {
  return (
    <form className={classes.viewGrid}>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label htmlFor='trailerTypeId'>
          Trailer Type <span className={classes.required}>*</span>
        </label>
        <Select
          defaultValue={trailer.trailerTypeId ? trailer.trailerTypeId : ''}
          id="trailerTypeId"
          name="trailerTypeId"
          onChange={e => {
            UpdateCreateTrailer(e.target.value, 'trailerTypeId');
            setTrailerType(e.target.selectedOptions[0].textContent);
          }}
        >
          <option disabled value="">
            -- Required --
          </option>
          <OptionList
            optionData={createTrailer?.trailerTypeOptions}
            attributeID="trailerTypeId"
            attributeName="type"
            attributeGroup="group"
          />
        </Select>
      </div>
      <div className={classes.attribute}>
        <label htmlFor='trailerName'>
          Trailer Name <span className={classes.required}>*</span>
        </label>
        <Input
          defaultValue={trailer.trailerName}
          id="trailerName"
          name="trailerName"
          onChange={e => UpdateCreateTrailer(e.target.value, 'trailerName')}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor='vin'>
          VIN <span className={classes.required}>*</span>
        </label>
        <Input
          defaultValue={trailer.vin}
          id="vin"
          name="vin"
          onChange={e => UpdateCreateTrailer(e.target.value, 'vin')}
        />
      </div>
      <div className={classes.attribute}>
        <label htmlFor='licensePlateNumber'>License Plate Number</label>
        <Input
          defaultValue={trailer.licensePlateNumber}
          id="licensePlateNumber"
          name="licensePlateNumber"
          onChange={e =>
            UpdateCreateTrailer(e.target.value, 'licensePlateNumber')
          }
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor='licensePlateState'>
          License Plate State
          <Select
            id="licensePlateState"
            name="licensePlateState"
            onChange={e =>
              UpdateCreateTrailer(e.target.value, 'licensePlateState')
            }
          >
            <option value="" />
            <StatesAndProvinces />
          </Select>
        </label>
      </div>
      <div className={classes.attribute}>
        <label htmlFor='year'>
          Year <span className={classes.required}>*</span>
        </label>
        <Input
          type="number"
          defaultValue={trailer.year}
          id="year"
          name="year"
          onChange={e => UpdateCreateTrailer(e.target.value, 'year')}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor='make'>
          Make <span className={classes.required}>*</span>
        </label>
        <Input
          defaultValue={trailer.make}
          id="make"
          name="make"
          onChange={e => UpdateCreateTrailer(e.target.value, 'make')}
        />
      </div>
      <div className={classes.attribute}>
        <label htmlFor='mileage'>Mileage</label>
        <Input
          defaultValue={trailer.mileage ? trailer.mileage.toLocaleString() : ''}
          id="mileage"
          name="mileage"
          type="number"
          onChange={e => UpdateCreateTrailer(e.target.value, 'mileage')}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor='mileageAsOfDate'>Mileage As Of Date</label>
        <Input
          defaultValue={trailer.mileageAsOfDate}
          id="mileageAsOfDate"
          name="mileageAsOfDate"
          onChange={e => UpdateCreateTrailer(e.target.value, 'mileageAsOfDate')}
          type="date"
        />
      </div>
      <div className={classes.attribute}>
        <label htmlFor='eldId'>ELD ID</label>
        <Input
          defaultValue={trailer.eldId}
          id="eldId"
          name="eldId"
          onChange={e => UpdateCreateTrailer(e.target.value, 'eldId')}
        />
      </div>
      <div className={classes.attribute}>
        <label htmlFor='eldMake'>ELD Make</label>
        <Input
          defaultValue={trailer.eldMake}
          id="eldMake"
          name="eldMake"
          onChange={e => UpdateCreateTrailer(e.target.value, 'eldMake')}
        />
      </div>
      <div className={classes.attribute}>
        <label htmlFor='eldModel'>ELD Model</label>
        <Input
          defaultValue={trailer.eldModel}
          id="eldModel"
          name="eldModel"
          onChange={e => UpdateCreateTrailer(e.target.value, 'eldModel')}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label htmlFor='isOwned'>Ownership</label>
        <Select
          id="isOwned"
          name="isOwned"
          defaultValue={trailer.isOwned ? 'true' : 'false'}
          onChange={e => UpdateCreateTrailer(e.target.value === 'true', 'isOwned')}
        >
          <option value="true">Owned</option>
          <option value="false">Leased</option>
        </Select>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label htmlFor='note'>Note</label>
        <TextArea
          rows={4}
          defaultValue={trailer.note}
          id="note"
          name="note"
          onChange={e => UpdateCreateTrailer(e.target.value, 'note')}
        />
      </div>
    </form>
  );
}
export default Details;
