import React from 'react';
import classes from '../../assets/essentials/Input.module.css'
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

//MaskedInput DOCUMENTATION: https://github.com/text-mask/text-mask/tree/master/react#readme

const defaultCurrencyMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

function CurrencyInput({...rest }) {
  const currencyMask = createNumberMask({
    ...defaultCurrencyMaskOptions,
  });

  // const HandleChange = (e) => {
  //   //setValue(e.target.value.replace(/[\$,]/g, ''));
  // }

  return (
    <MaskedInput
      mask={currencyMask}
      {...rest}
      type="text"
      inputMode="numeric" //mobile users - device will pull up number pad instead of alpha keyboard
      className={classes.input}
    />
  );
}

export default CurrencyInput;
