import React, { useState } from 'react';
import { BsTrash } from 'react-icons/bs';

import classes from '../../../assets/Global.module.css';
import classesRental from '../../creates/rental/Rental.module.css';
import Form from '../../../assets/essentials/Form';
import OptionList from '../../../helpers/options/OptionList';
import Button from '../../../assets/essentials/Button';
import PutForm from '../../../api/internal/PutForm';
import Details from './Details';
import Select from '../../../assets/essentials/Select';
import PostBody from '../../../api/internal/PostBody';
import DuplicateLoad from '../../views/load/DuplicateLoad';

function Summary({
  data,
  rentalId,
  RefreshPage,
}: {
  data: RentalSummary;
  rentalId: number;
  RefreshPage: Function;
}) {
  const [equipmentLineItems, setEquipmentLineItems] = useState<
    Array<RentalAsset>
  >(data.rentalAssetsList);
  const [disableSaveChanges, setDisableSaveChanges] = useState(true);
  const [displayDuplicateRental, setDisplayDuplicateRental] =
    useState<boolean>(false);

  function UpdateRentalDetails(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const appendix = {
      rentalId: rentalId,
    };
    PutForm(`/Rental/UpdateRentalDetails`, formData, appendix).then(() => {
      RefreshPage('Summary', ['Audit']);
    });
  }

  function UpdateRentalEquipment(e) {
    e.preventDefault();
    setDisableSaveChanges(false);
    // backend will only receive lineItems with assetId
    const updatedRentalAssets = equipmentLineItems
      .filter(item => item.assetId !== null)
      .map(item => ({
        rentalAssetId: item.rentalAssetId,
        assetId: item.assetId,
      }));

    const postBodyData = {
      rentalId: rentalId,
      updatedRentalAssets: updatedRentalAssets,
    };

    PostBody(`/Rental/UpdateRentalEquipment`, postBodyData).then(() => {
      RefreshPage('Summary', ['Audit']);
    });
  }

  const AddLineItemToEquipment = () => {
    setEquipmentLineItems(prevLineItems => [
      ...prevLineItems,
      {
        uniqueId: crypto.randomUUID(),
        rentalAssetId: null,
        assetId: null,
        assetName: '',
        assetType: '',
        assetVin: '',
      },
    ]);
  };

  const DeleteRow = rowIndex => {
    const updatedAssets = equipmentLineItems.filter(
      (item, index) => index !== rowIndex,
    );
    setEquipmentLineItems(updatedAssets);
    setDisableSaveChanges(false);
  };

  function RentalEquipmentTableRows() {
    const HandleEquipmentChange = (e, rowIndex) => {
      const selectedAssetId = e.target.value;
      const updatedEquipmentLineItems = equipmentLineItems.map(
        (item, index) => {
          if (index === rowIndex) {
            return {
              ...item,
              assetId: selectedAssetId,
            };
          }
          return item;
        },
      );
      setEquipmentLineItems(updatedEquipmentLineItems);
      setDisableSaveChanges(false);
    };
    return (
      <>
        {equipmentLineItems.map((item, rowIndex) => {
          return (
            <tr key={item.uniqueId}>
              <td>
                <Select
                  onChange={e => HandleEquipmentChange(e, rowIndex)}
                  name={rowIndex.toString()}
                  defaultValue={item.assetId}
                >
                  <option value="" hidden>
                    Select equipment
                  </option>
                  <OptionList
                    optionData={data.rentalSummaryOptions.assetOptions}
                    attributeID={'assetId'}
                    attributeName={'name'}
                    attributeGroup={'group'}
                  />
                </Select>
              </td>
              <td>{item.assetType}</td>
              <td>{item.assetVin}</td>
              <td>
                <BsTrash
                  className={classes.clickable}
                  onClick={() => DeleteRow(rowIndex)}
                />
              </td>
            </tr>
          );
        })}
      </>
    );
  }

  return (
    <>
      <h3>Details</h3>
      <Form onSubmit={e => UpdateRentalDetails(e)}>
        <Details
          rentalDetails={data.rentalDetails}
          rentalSummaryOptions={data.rentalSummaryOptions}
          rentalId={rentalId}
          RefreshPage={RefreshPage}
        />
        <div>
          <Button type="submit" variant="good" disabled={true}>
            Save Changes
          </Button>
        </div>
      </Form>

      <h3>Equipment</h3>
      <Form onSubmit={e => UpdateRentalEquipment(e)}>
        <div
          className={` ${classesRental.rentalEquipmentTable} ${classes.span3}`}
        >
          <table>
            <thead>
              <tr>
                <th>Asset</th>
                <th>Type</th>
                <th>VIN</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <RentalEquipmentTableRows />
            </tbody>
          </table>
        </div>
        <div>
          <Button type="button" name="toggler" onClick={AddLineItemToEquipment}>
            Add New Asset
          </Button>
          <Button type="submit" variant="good" disabled={disableSaveChanges}>
            Save Changes
          </Button>
        </div>
      </Form>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <div
          className={classes.cTA}
          onClick={() => setDisplayDuplicateRental(!displayDuplicateRental)}
        >
          Duplicate Rental
        </div>
        {displayDuplicateRental && (
          <DuplicateLoad type="rental" id={rentalId} />
        )}
      </div>
    </>
  );
}

export default Summary;
