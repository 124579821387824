import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { MdOutlineRefresh } from 'react-icons/md';
import { VscSearch } from 'react-icons/vsc';

import Get from '../../api/internal/Get';
import OptionList from '../../helpers/options/OptionList';
import Select from '../../assets/essentials/Select';
import BBITable from '../../helpers/bBITable/BBITable';
import View from '../../helpers/slab/View';
import ViewLoad from '../../features/views/load/Load';
import FilterData from '../../helpers/filter/FilterData';
import Header from '../../layouts/Header';
import Filter from '../../helpers/filter/Filter';
import classesCustom from '../../helpers/search/CustomSearch.module.css';
import PostForm from '../../api/internal/PostForm';
import Input from '../../assets/essentials/Input';
import StatesAndProvinces from '../../helpers/options/StatesAndProvinces';
import Button from '../../assets/essentials/Button';
import classesGlobal from '../../assets/Global.module.css';

export default function CustomerLoadReport() {
  const [loadReportData, setLoadReportData] = useState<LoadReportData>();
  const [filterValue, setFilterValue] = useState<string>('');
  const searchOptionsList = useRef(null);
  const { customerId } = useParams();
  const navigate = useNavigate();
  const currentCustomer = searchOptionsList?.current?.customerOptions?.find(
    x => x.customerId === Number(customerId),
  );
  const [isLoadReportSearchOpen, setIsLoadReportSearchOpen] = useState(false);
  const [loadReportList, setLoadReportList] =
    useState<Array<LoadReportLoadData>>();

  useEffect(() => {
    if (!searchOptionsList.current?.customerOptions) {
      Get(`/Customer/GetSearchOptions`).then(response => {
        if (response) {
          searchOptionsList.current = response.data.customerOptions;
        }
      });
    }
    if (customerId) {
      GetCustomerLoadReport(customerId);
    }
  }, [customerId, searchOptionsList]);

  function GetCustomerLoadReport(customerId) {
    setIsLoadReportSearchOpen(false);
    Get(`/Customer/GetCustomerLoadReport/${customerId}`).then(response => {
      if (response) {
        setLoadReportData(response.data);
        setLoadReportList(response.data.loadList);
      }
    });
  }

  const HandleCustomerChange = e => {
    navigate(`../customerloadreport/${e.target.value}`);
  };

  const CustomerStats = ({ loadReportData }) => {
    const formattedAverageMiles = loadReportData.averageMiles.toFixed();
    const formattedAveragePayPerMile =
      loadReportData.averagePayPerMile.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    return (
      <div className={classesCustom.customerStats}>
        <h3>Load Count: {loadReportData.loadCount} </h3>
        <h3>Average Miles: {formattedAverageMiles}</h3>
        <h3>Average Pay Per Mile: {formattedAveragePayPerMile}</h3>
      </div>
    );
  };

  const LoadReportTable = ({ loadList }) => {
    const formattedLoadList = loadList.map(load => ({
      ...load,
      mileage:
        load.mileage.toLocaleString('en-US', {
          maximumFractionDigits: 0,
        }) ?? 0,
      totalReceivable:
        load.totalReceivable.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }) ?? 0,
    }));
    const filteredData = FilterData(formattedLoadList, filterValue);

    let columnConfig = [
      {
        key: '1',
        attribute: 'loadId',
        header: 'Load ID',
        width: '8ch',
        onClick: e => View(<ViewLoad loadId={e.target.innerText} />),
        dataType: 'number',
      },
      {
        key: '2',
        attribute: 'reference',
        header: 'Reference',
        width: '15ch',
      },
      {
        key: '3',
        attribute: 'shipper',
        header: 'Shipper',
        width: '25ch',
      },
      {
        key: '4',
        attribute: 'receiver',
        header: 'Receiver',
        width: '25ch',
      },
      {
        key: '5',
        attribute: 'shipDate',
        header: 'Ship Date',
        width: '14ch',
        dataType: 'date',
      },
      {
        key: '6',
        attribute: 'receiveDate',
        header: 'Receive Date',
        width: '14ch',
        dataType: 'date',
      },
      {
        key: '7',
        attribute: 'lane',
        header: 'Lane',
        width: '6ch',
      },
      {
        key: '4',
        attribute: 'stops',
        header: 'Stops',
        width: '10ch',
      },
      {
        key: '5',
        attribute: 'totalReceivable',
        header: 'Total Receivable',
        width: '10ch',
        dataType: 'currency',
      },
      {
        key: '6',
        attribute: 'equipment',
        header: 'Equipment',
        width: '15ch',
      },
      {
        key: '7',
        attribute: 'mileage',
        header: 'Mileage',
        width: '10ch',
        dataType: 'formattedNumber',
      },
    ];
    return <BBITable data={filteredData ?? []} columns={columnConfig} />;
  };

  function SearchLoadReport(e) {
    e.preventDefault();
    const appendix = {
      customerId: customerId,
    };
    const searchTermsObject = new FormData(e.target);
    PostForm(
      `/Customer/SearchCustomerLoadReport`,
      searchTermsObject,
      appendix,
    ).then(response => {
      if (response) {
        setLoadReportList(response.data);
      }
    });
  }

  const SearchLoadReportForm = () => {
    return (
      <div className={classesCustom.customSearch}>
        <form onSubmit={e => SearchLoadReport(e)}>
          <label>
            Reference
            <Input name="reference" />
          </label>
          <label>
            Equipment
            <Select name="equipmentTypeId">
              <option value="" />
              <OptionList
                optionData={searchOptionsList?.current.equipmentOptions}
                attributeID="equipmentTypeId"
                attributeName="type"
              />
            </Select>
          </label>
          <label>
            Commodity
            <Select name="commodityId">
              <option value="" />
              <OptionList
                optionData={searchOptionsList?.current.commodityOptions}
                attributeID="commodityId"
                attributeName="type"
                attributeGroup="group"
              />
            </Select>
          </label>
          <label>
            Picks
            <Input type="number" step="1" name="picksCount" />
          </label>
          <label>
            Drops
            <Input type="number" step="1" name="dropsCount" />
          </label>
          <label>
            Location
            <Input name="location" />
          </label>
          <label>
            Origin City
            <Input name="originCity" />
          </label>
          <label>
            Origin State
            <Select name="originState">
              <option value="" />
              <StatesAndProvinces />
            </Select>
          </label>
          <label>
            Destination City
            <Input name="destinationCity" />
          </label>
          <label>
            Destination State
            <Select name="destinationState">
              <option value="" />
              <StatesAndProvinces />
            </Select>
          </label>
          <label>
            Ship Date
            <Input type="date" name="pickupDate" />
          </label>
          <label>
            Receive Date
            <Input type="date" name="deliveryDate" />
          </label>
          <div className={classesCustom.submitHolder}>
            <Button type="submit" variant="good">
              Search
            </Button>
            <Button
              variant="bad"
              onClick={() => GetCustomerLoadReport(customerId)}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    );
  };

  return (
    <>
      <h1>Load Report: {currentCustomer?.customerName}</h1>
      {loadReportData && <CustomerStats loadReportData={loadReportData} />}
      <Header>
        <div>
          <Select onChange={HandleCustomerChange} value={customerId}>
            <OptionList
              optionData={searchOptionsList.current}
              attributeID="customerId"
              attributeName="customerName"
              attributeGroup=""
            />
          </Select>
          <button onClick={() => GetCustomerLoadReport(customerId)}>
            <MdOutlineRefresh />
            <span>Refresh Board</span>
          </button>

          <button
            onClick={() => setIsLoadReportSearchOpen(!isLoadReportSearchOpen)}
          >
            <VscSearch />
            <span>Search Load Report</span>
          </button>
        </div>
        <div>
          <Filter
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            placeholder="Filter"
          />
        </div>
      </Header>
      {isLoadReportSearchOpen && <SearchLoadReportForm />}
      {loadReportList && (
        <>
          <LoadReportTable loadList={loadReportList} />
        </>
      )}
    </>
  );
}
