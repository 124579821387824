import React, { useState } from 'react';
import { AiOutlineShop } from 'react-icons/ai';

import OptionList from '../../helpers/options/OptionList';
import PostForm from '../../api/internal/PostForm';
import Form from '../../assets/essentials/Form';
import classes from './Assign.module.css';
import classesGlobal from '../../assets/Global.module.css';
import Button from '../../assets/essentials/Button';
import Select from '../../assets/essentials/Select';
import Input from '../../assets/essentials/Input';
import TextArea from '../../assets/essentials/TextArea';
import SendLoadToBBIButton from '../../helpers/buttons/SendLoadToBBIButton';

function Vendor({
  loadId,
  vendorList,
  isBBIAssignedLoad,
  RefreshPage,
}: {
  loadId: number;
  vendorList: Array<VendorAssignment>;
  isBBIAssignedLoad: boolean;
  RefreshPage: Function;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [vendorAssignment, setVendorAssignment] = useState<VendorAssignment>({
    vendorId: null,
    vendorName: null,
    vendorContactId: null,
    vendorContactNamePosition: null,
    phoneNumber: null,
    addressLineOne: null,
    addressLineTwo: null,
    cityState: null,
    zip: null,
    country: null,
    note: null,
  });
  const HandleVendorChange = e => {
    const currentSelectedVendor = vendorList.find(
      vendor => vendor.vendorId === parseInt(e.target.value),
    );
    setVendorAssignment(prevAssignment => ({
      ...prevAssignment,
      vendorId: currentSelectedVendor.vendorId,
      vendorName: currentSelectedVendor.vendorName,
      phoneNumber: currentSelectedVendor.phoneNumber,
      vendorContactId: currentSelectedVendor.vendorContactId,
      vendorContactNamePosition:
        currentSelectedVendor.vendorContactNamePosition,
      addressLineOne: currentSelectedVendor.addressLineOne,
      addressLineTwo: currentSelectedVendor.addressLineTwo,
      cityState: currentSelectedVendor.cityState,
      zip: currentSelectedVendor.zip,
      country: currentSelectedVendor.country,
      note: currentSelectedVendor.note,
    }));
  };

  const HandleInputChange = e => {
    const { name, value } = e.target;
    setVendorAssignment(prevAssignment => ({
      ...prevAssignment,
      [name]: value,
    }));
  };

  function AssignVendor(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const appendix = {
      loadId,
      ...(vendorAssignment.vendorContactId !== null && {
        vendorContactId: vendorAssignment.vendorContactId,
      }),
    };
    PostForm(`/Load/CreateVendorAssignment`, formData, appendix).then(() =>
      RefreshPage('Summary', ['Audit', 'Financials']),
    );
    setIsOpen(false);
  }

  return (
    <>
      <div className={classes.btnHolder}>
        <button
          type="button"
          onClick={() => {
            if (!isOpen) {
              setVendorAssignment({} as VendorAssignment);
            }
            setIsOpen(!isOpen);
          }}
        >
          <AiOutlineShop />
          <span>Assign Vendor</span>
        </button>
        <SendLoadToBBIButton
          loadId={loadId}
          isDisabled={isBBIAssignedLoad}
          RefreshPage={RefreshPage}
          pageToRefresh={'Summary'}
          pageArrayToRefresh={['Audit', 'Financials']}
        />
      </div>
      <div className={`${classes.expandable} ${classes.vendor}`}>
        {isOpen && (
          <Form onSubmit={e => AssignVendor(e)}>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span3}`}
            >
              <label>
                Vendor Name <span className={classesGlobal.required}>*</span>
              </label>
              <Select
                required
                defaultValue=""
                onChange={HandleVendorChange}
                name="vendorId"
              >
                <option value="" key="disabled" disabled>
                  -- Required --
                </option>
                <OptionList
                  optionData={vendorList}
                  attributeID="vendorId"
                  attributeName="vendorName"
                />
              </Select>
            </div>
            <div className={classesGlobal.attribute}>
              <label>Contact</label>
              <Input
                disabled
                type="text"
                value={vendorAssignment.vendorContactNamePosition}
                readOnly={true}
              />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>Phone</label>
              <Input
                disabled
                type="text"
                value={vendorAssignment.phoneNumber}
                readOnly={true}
              />
            </div>
            <div className={classesGlobal.attribute}>
              <label>Address</label>
              <Input
                disabled
                type="text"
                value={
                  vendorAssignment.addressLineOne
                    ? vendorAssignment.addressLineOne +
                      ' ' +
                      vendorAssignment.addressLineTwo
                    : ''
                }
                readOnly={true}
              />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>City, State</label>
              <Input
                disabled
                placeholder="City, State"
                value={vendorAssignment.cityState}
                readOnly={true}
              />
            </div>
            <div className={classesGlobal.attribute}>
              <label>Zip</label>
              <Input disabled value={vendorAssignment.zip} readOnly={true} />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>Country</label>
              <Input
                disabled
                value={vendorAssignment.country}
                readOnly={true}
              />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span3}`}
            >
              <label>Notes</label>
              <TextArea
                defaultValue={vendorAssignment.note ?? ''}
                name="note"
                onChange={HandleInputChange}
              />
            </div>
            <div>
              <Button type="submit" variant="good" disabled={true}>
                Submit
              </Button>
            </div>
            <div>
              <Button
                type="button"
                onClick={() => {
                  setVendorAssignment({} as VendorAssignment);
                  setIsOpen(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </div>
    </>
  );
}

export default Vendor;
