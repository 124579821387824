import React from 'react';
import { BsTrash } from 'react-icons/bs';

import classes from '../../../assets/Global.module.css';
import BBITable from '../../../helpers/bBITable/BBITable';
import classesTable from '../../../helpers/bBITable/BBITable.module.css';
import Delete from '../../../api/internal/Delete';
import Lane from './Lane';

function Lanes({
  data,
  RefreshPage,
  customerId,
}: {
  data: Array<CustomerLane>;
  RefreshPage: Function;
  customerId: number;
}) {
  const formattedCustomerLaneData = data.map(customerLane => ({
    ...customerLane,
    rate: '$' + customerLane.rate,
    delete: (
      <button
        onClick={() => {
          DeleteCustomerLane(customerLane.customerLaneId);
        }}
        className={`${classesTable.deleteIcon} ${classes.clickable}`}
        title="Delete Lane"
      >
        <BsTrash />
      </button>
    ),
  }));

  let columnConfig = [
    {
      key: '1',
      attribute: 'startZip',
      header: 'Start Zip',
    },
    {
      key: '2',
      attribute: 'endZip',
      header: 'End Zip',
    },
    {
      key: '3',
      attribute: 'rate',
      header: 'Rate',
    },
    {
      key: '4',
      attribute: 'delete',
      header: '',
      width: '5px',
    },
  ];

  function DeleteCustomerLane(customerLaneId) {
    Delete(`/customer/DeleteCustomerLane/${customerLaneId}`).then(() =>
      RefreshPage('Lanes', ['Audit']),
    );
  }

  return (
    <>
      <Lane customerId={customerId} RefreshPage={RefreshPage} />
      <BBITable data={formattedCustomerLaneData} columns={columnConfig} />
    </>
  );
}

export default Lanes;
