import React, { useState } from 'react';
import { BsTelephonePlus } from 'react-icons/bs';

import Form from '../../../assets/essentials/Form';
import classes from './Contacts.module.css';
import classesGlobal from '../../../assets/Global.module.css';
import PostForm from '../../../api/internal/PostForm';
import Button from '../../../assets/essentials/Button';
import Input from '../../../assets/essentials/Input';
import TextArea from '../../../assets/essentials/TextArea';
import Phone from '../../../helpers/inputs/Phone';

function Contacts({
  customerId,
  RefreshPage,
}: {
  customerId: Number;
  RefreshPage: Function;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  function CreateContact(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.set(
      'isPrimaryCustomerContact',
      (formData.get('isPrimaryCustomerContact') === 'on').toString(),
    );
    formData.set(
      'isBillingContact',
      (formData.get('isBillingContact') === 'on').toString(),
    );

    PostForm(`/Customer/CreateCustomerContact`, formData, {
      customerId: customerId,
    }).then(response => {
      if (response) RefreshPage('Contacts', ['Audit', 'Billing']);
    });
    setIsOpen(false);
  }

  return (
    <>
      <div className={classes.btnHolder}>
        <button type="button" onClick={() => setIsOpen(!isOpen)}>
          <BsTelephonePlus />
          <span>Create a Contact</span>
        </button>
      </div>
      <div className={classes.createContactHolder}>
        {isOpen && (
          <Form onSubmit={e => CreateContact(e)}>
            <div className={classesGlobal.attribute}>
              <label>
                First Name <span className={classesGlobal.required}>*</span>
              </label>
              <Input required type="text" name="firstName" />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>
                Last Name <span className={classesGlobal.required}>*</span>
              </label>
              <Input required type="text" name="lastName" />
            </div>
            <div className={classesGlobal.attribute}>
              <label>
                Phone{' '}
                {email ? '' : <span className={classesGlobal.required}>*</span>}
              </label>
              <Phone
                name="phone"
                defaultValue={null}
                required={!email}
                onChange={e => setPhone(e.target.value)}
                expectsEventObject={true}
              />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>Ext</label>
              <Input type="text" name="phoneExt" />
            </div>
            <div className={classesGlobal.attribute}>
              <label>Position</label>
              <Input type="text" name="position" />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>
                Email{' '}
                {phone ? '' : <span className={classesGlobal.required}>*</span>}
              </label>
              <Input
                type="email"
                name="email"
                required={!phone}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span3}`}
            >
              <label>Notes</label>
              <TextArea name="note" />
            </div>
            <div className={classesGlobal.attribute}>
              <label>
                <Input
                  defaultChecked={false}
                  type="checkbox"
                  name="isPrimaryCustomerContact"
                />
                Primary Contact
              </label>
            </div>
            <div className={classesGlobal.attribute}>
              <label>
                <Input
                  defaultChecked={false}
                  type="checkbox"
                  name="isBillingContact"
                />
                Primary Billing
              </label>
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span3}`}
            ></div>
            <div>
              <Button type="submit" variant="good" disabled={true}>
                Submit
              </Button>
            </div>
            <div>
              <Button onClick={() => setIsOpen(!isOpen)}>
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </div>
    </>
  );
}

export default Contacts;
