import React from 'react';

import classes from '../../../assets/Global.module.css';
import Input from '../../../assets/essentials/Input';
import Select from '../../../assets/essentials/Select';
import TextArea from '../../../assets/essentials/TextArea';
import GoogleSearchBoxEstablishment from '../../../helpers/inputs/GoogleSearchBoxEstablishment';
import Phone from '../../../helpers/inputs/Phone';
import { useRoleAuthorization } from '../../../hooks/useRoleAuthorization';
import { ADMIN_AUTHORIZED_ROLES } from '../../../auth/AuthRoles';
import StatesAndProvinces from '../../../helpers/options/StatesAndProvinces';

function Details({
  location,
  UpdateLocationForAutoComplete,
  UpdateCreateLocation,
}: {
  location: LocationInfo;
  UpdateLocationForAutoComplete: Function;
  UpdateCreateLocation: Function;
}) {
  const isUserAdmin = useRoleAuthorization(ADMIN_AUTHORIZED_ROLES);
  function CallBackPlace(place) {
    let streetNumber: string;
    let streetName: string;
    let city: string;
    let state: string;
    let zip: string;
    let country: string;
    place.address_components.forEach(component => {
      component.types.forEach(type => {
        if (type === 'street_number') {
          streetNumber = component.short_name;
        } else if (type === 'route') {
          streetName = component.short_name;
        }
        if (type === 'locality') {
          city = component.short_name;
        } else if (type === 'administrative_area_level_1') {
          state = component.short_name;
        } else if (type === 'postal_code') {
          zip = component.short_name;
        } else if (type === 'country') {
          country = component.short_name;
        }
      });
    });
    let addressLineOne = null;
    if (streetNumber && streetName) {
      addressLineOne = streetNumber + ' ' + streetName;
    } else {
      addressLineOne = null;
    }

    UpdateLocationForAutoComplete(
      place.name,
      addressLineOne,
      city,
      state,
      zip,
      country,
      place.formatted_phone_number,
      place.website,
    );
  }

  return (
    <>
      <h1>Details</h1>
      <div className={classes.viewGrid}>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>Auto-Complete</label>
          <GoogleSearchBoxEstablishment
            assignPlace={CallBackPlace}
            id="location"
          />
        </div>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>
            Name <span className={classes.required}>*</span>
          </label>
          <Input
            key={location.locationName}
            defaultValue={location.locationName}
            onChange={e => UpdateCreateLocation(e.target.value, 'locationName')}
            type="text"
          />
        </div>
        <div className={classes.attribute}>
          <label>
            Address Line One <span className={classes.required}>*</span>
          </label>
          <Input
            key={location.addressLineOne}
            defaultValue={location.addressLineOne}
            onChange={e =>
              UpdateCreateLocation(e.target.value, 'addressLineOne')
            }
            type="text"
            disabled={!isUserAdmin}
          />
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>Address Line Two</label>
          <Input
            key={location.addressLineTwo}
            defaultValue={location.addressLineTwo}
            onChange={e =>
              UpdateCreateLocation(e.target.value, 'addressLineTwo')
            }
            type="text"
            disabled={!isUserAdmin}
          />
        </div>
        <div className={classes.attribute}>
          <label>
            City <span className={classes.required}>*</span>
          </label>
          <Input
            key={location.city}
            defaultValue={location.city}
            onChange={e => UpdateCreateLocation(e.target.value, 'city')}
            type="text"
            disabled={!isUserAdmin}
          />
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>
            State/Province <span className={classes.required}>*</span>
          </label>
          <Select
            key={location.state}
            defaultValue={location.state || ''}
            onChange={e => UpdateCreateLocation(e.target.value, 'state')}
            disabled={!isUserAdmin}
          >
            <option disabled value="">
              -- Required --
            </option>
            <StatesAndProvinces />
          </Select>
        </div>
        <div className={classes.attribute}>
          <label>
            Zip <span className={classes.required}>*</span>
          </label>
          <Input
            key={location.zip}
            defaultValue={location.zip}
            onChange={e => UpdateCreateLocation(e.target.value, 'zip')}
            type="text"
            disabled={!isUserAdmin}
          />
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>
            Country <span className={classes.required}>*</span>
          </label>
          <Select
            key={location.country}
            defaultValue={location.country || ''}
            onChange={e => UpdateCreateLocation(e.target.value, 'country')}
            disabled={!isUserAdmin}
          >
            <option disabled value="">
              -- Required --
            </option>
            <option value="US">US</option>
            <option value="CA">Canada</option>
          </Select>
        </div>
        <div className={classes.attribute}>
          <label>
            Phone
            <span className={classes.required}> *</span>
          </label>
          <Phone
            key={location.phone}
            defaultValue={location.phone}
            onChange={e => UpdateCreateLocation(e.target.value, 'phone')}
            expectsEventObject={true}
          />
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>Ext</label>
          <Input
            key={location.phoneExt}
            defaultValue={location.phoneExt}
            onChange={e => UpdateCreateLocation(e.target.value, 'phoneExt')}
            type="text"
          />
        </div>
        <div className={classes.attribute}>
          <label>Contact Email</label>
          <Input
            key={location.email}
            defaultValue={location.email}
            onChange={e => UpdateCreateLocation(e.target.value, 'email')}
            type="text"
          />
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>Website</label>
          <Input
            key={location.website}
            defaultValue={location.website}
            onChange={e => UpdateCreateLocation(e.target.value, 'website')}
            type="text"
          />
        </div>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>Special Instructions</label>
          <TextArea
            defaultValue={location.specialInstructions}
            onChange={e =>
              UpdateCreateLocation(e.target.value, 'specialInstructions')
            }
            type="text"
          />
        </div>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>Hours</label>
          <TextArea
            defaultValue={location?.hours}
            onChange={e => {
              UpdateCreateLocation(e.target.value, 'hours');
            }}
          />
        </div>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>Directions</label>
          <TextArea
            defaultValue={location.directions}
            onChange={e => UpdateCreateLocation(e.target.value, 'directions')}
            type="text"
          />
        </div>
      </div>
    </>
  );
}

export default Details;
