import React, { useState, useEffect } from 'react';
import Get from '../../../api/internal/Get';
import BBITable from '../../../helpers/bBITable/BBITable';
import classesGlobal from '../../../assets/Global.module.css';
import classesCustom from '../../../helpers/search/CustomSearch.module.css';
import OptionList from '../../../helpers/options/OptionList';
import Select from '../../../assets/essentials/Select';
import View from '../../../helpers/slab/View';
import ARAppliedPayments from '../../../features/views/receivable/AppliedPayments/ARAppliedPayments';
import DateOnlyFormatter from '../../../helpers/inputs/DateOnlyFormatter';
import ViewCustomer from '../../../features/views/customer/Customer';
import Input from '../../../assets/essentials/Input';
import Button from '../../../assets/essentials/Button';
import {
  GetThirtyDaysAgoIsoString,
  GenerateQueryString,
} from '../../../helpers/search/HelperFunctions';
import FilterData from '../../../helpers/filter/FilterData';
import { useOutletContext } from 'react-router-dom';

function ReceivablePayments({
  toggleBoardRefresh,
}: {
  toggleBoardRefresh: boolean;
}) {
  const [paymentBoard, setPaymentBoard] = useState<Array<APCashFlow>>([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [paymentOptionsList, setPaymentOptionsList] = useState([]);
  const [receivablePaymentsSearchParams, setReceivablePaymentsSearchParams] =
    useState(ParseUrlForSearchParams());
  const filterValue = useOutletContext();

  useEffect(() => {
    GetCustomerOptions();
    GetPaymentMethodList();
    const {
      customerId,
      reference,
      loadId,
      paymentMethodId,
      paymentDateMin,
      paymentDateMax,
    } = ParseUrlForSearchParams();
    if (
      customerId != null ||
      reference != null ||
      loadId != null ||
      paymentMethodId != null ||
      paymentDateMin != null ||
      paymentDateMax != null
    ) {
      setReceivablePaymentsSearchParams({
        customerId: customerId,
        reference: reference,
        loadId: loadId,
        paymentMethodId: paymentMethodId,
        paymentDateMin: paymentDateMin,
        paymentDateMax: paymentDateMax,
      });
      SearchReceivablePayments({
        customerId: customerId,
        reference: reference,
        paymentMethodId: paymentMethodId,
        paymentDateMin: paymentDateMin,
        paymentDateMax: paymentDateMax,
      });
    } else {
      SearchReceivablePayments(receivablePaymentsSearchParams);
    }
  }, []);

  function GetCustomerOptions() {
    Get(`/Customer/GetCustomerLoadReportOptions/`).then(response => {
      if (response) {
        setCustomerOptions(response?.data);
      }
    });
  }

  function GetPaymentMethodList() {
    Get(`/Accounting/GetPaymentMethodList`).then(response => {
      if (response) {
        setPaymentOptionsList(response.data);
      }
    });
  }

  function FormatPaymentBoard(paymentBoard) {
    return paymentBoard.map(payment => ({
      ...payment,
      avgDaysToPay: Math.trunc(payment.avgDaysToPay) || 0,
      paymentDate: DateOnlyFormatter(payment.paymentDate),
    }));
  }

  function ParseUrlForSearchParams() {
    const defaultSearchDate = GetThirtyDaysAgoIsoString();
    const url = window.location.href.toString();
    const queryString = url.split('?')[1];
    // Split the query string into an array of key-value pairs
    const queryParamsArray = queryString?.split('&');
    // Create an object to store the decoded parameters
    const decodedParams = {
      customerId: null,
      reference: null,
      loadId: null,
      paymentMethodId: null,
      paymentDateMin: defaultSearchDate,
      paymentDateMax: null,
    };
    // Loop through the key-value pairs and decode each one
    queryParamsArray?.forEach(function (param) {
      const [key, value] = param.split('=');
      decodedParams[key] = decodeURIComponent(value);
    });
    return decodedParams;
  }

  function HandleFormSubmit(e) {
    e.preventDefault();
    const formData = {
      customerId: e.target.customerId?.value,
      reference: e.target.reference?.value,
      loadId: e.target.loadId?.value,
      paymentMethodId: e.target.paymentMethodId?.value,
      paymentDateMin: e.target.paymentDateMin?.value,
      paymentDateMax: e.target.paymentDateMax?.value,
    };
    setReceivablePaymentsSearchParams(formData);
    SearchReceivablePayments(formData);
  }

  function SearchReceivablePayments(SearchParamsFromForm?) {
    //if there is no params passed in, search with default params derived from URL
    const searchParams = SearchParamsFromForm
      ? SearchParamsFromForm
      : receivablePaymentsSearchParams;
    const queryString = '?' + GenerateQueryString(searchParams);
    const newUrl =
      window.location.origin + window.location.pathname + queryString;
    window.history.replaceState(null, null, newUrl);
    Get(`/Accounting/SearchReceivablePayments${queryString}`).then(response => {
      if (response) {
        setPaymentBoard(FormatPaymentBoard(response.data));
      }
    });
  }

  function ClearSearchParams() {
    setReceivablePaymentsSearchParams({
      customerId: null,
      reference: null,
      loadId: null,
      paymentMethodId: null,
      paymentDateMin: null,
      paymentDateMax: null,
    });
  }

  const SearchForm = () => {
    return (
      <div className={classesCustom.customSearch}>
        <form onSubmit={e => HandleFormSubmit(e)}>
          <label>
            Customer
            <Select
              defaultValue={receivablePaymentsSearchParams.customerId || ''}
              onChange={e => {
                receivablePaymentsSearchParams.customerId = e.target.value;
              }}
              name="customerId"
            >
              <option value="">All Customers</option>
              <OptionList
                optionData={customerOptions}
                attributeID="customerId"
                attributeName="customerName"
              />
            </Select>
          </label>
          <label>
            Payment Method
            <Select
              defaultValue={
                receivablePaymentsSearchParams.paymentMethodId || ''
              }
              onChange={e => {
                receivablePaymentsSearchParams.paymentMethodId = e.target.value;
              }}
              name="paymentMethodId"
            >
              <option value="">All Methods</option>
              <OptionList
                optionData={paymentOptionsList}
                attributeID="paymentMethodId"
                attributeName="method"
              />
            </Select>
          </label>

          <label>
            Load #
            <Input
              defaultValue={receivablePaymentsSearchParams.loadId}
              onChange={e => {
                receivablePaymentsSearchParams.loadId = e.target.value;
              }}
              name="loadId"
            />
          </label>
          <label>
            Check #/Reference
            <Input
              defaultValue={receivablePaymentsSearchParams.reference}
              onChange={e => {
                receivablePaymentsSearchParams.reference = e.target.value;
              }}
              name="reference"
            />
          </label>

          <label>
            Payment Date Start
            <Input
              type="date"
              name="paymentDateMin"
              defaultValue={receivablePaymentsSearchParams.paymentDateMin}
            />
          </label>
          <label>
            Payment Date End
            <Input
              type="date"
              name="paymentDateMax"
              defaultValue={receivablePaymentsSearchParams.paymentDateMax}
            />
          </label>

          <div className={classesCustom.submitHolder}>
            <Button type="submit" variant="good">
              Search
            </Button>

            <Button
              type="button"
              onClick={() => ClearSearchParams()}
              variant="bad"
            >
              Clear
            </Button>
          </div>
        </form>
      </div>
    );
  };

  let columnConfig = [
    {
      key: '1',
      attribute: 'paymentDate',
      header: 'Date',
    },
    {
      key: '2',
      attribute: 'reference',
      header: 'Check #/Reference',
      attributeprimaryhidden: 'cashFlowId',
      onClick: e =>
        View(
          <ARAppliedPayments
            cashFlowId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '3',
      attribute: 'customerName',
      attributeprimaryhidden: 'customerId',
      header: 'Customer',
      onClick: e =>
        View(
          <ViewCustomer
            customerName={e.target.innerText}
            customerId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '4',
      attribute: 'paymentMethod',
      header: 'Payment Method',
    },
    {
      key: '5',
      attribute: 'quickBooksPaymentId',
      header: 'QuickBooks ID',
    },
    {
      key: '6',
      attribute: 'avgDaysToPay',
      header: 'Average Days To Pay',
    },
    {
      key: '7',
      attribute: 'totalPayment',
      header: 'Amount',
      dataType: 'currency',
    },
  ];

  return (
    <>
      <div className={` ${classesGlobal.marginBottom05Rem}`}>
        <SearchForm />
      </div>
      <div className={classesGlobal.marginBottom05Rem} />
      {paymentBoard != null ? (
        <BBITable
          data={FormatPaymentBoard(FilterData(paymentBoard, filterValue))}
          columns={columnConfig}
        />
      ) : (
        <>No customer selected.</>
      )}
    </>
  );
}

export default ReceivablePayments;
