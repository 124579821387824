import React, { useState } from 'react';
import { AiOutlineUser } from 'react-icons/ai';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { VscSearch } from 'react-icons/vsc';

import classes from '../../assets/Global.module.css';
import Header from '../../layouts/Header';
import Filter from '../../helpers/filter/Filter';
import CreateDriver from '../../features/creates/driver/Driver';
import Create from '../../helpers/panel/Create';
import { RiListCheck } from 'react-icons/ri';
import { FaHourglassHalf } from 'react-icons/fa';

function Driver() {
  const [filterValue, setFilterValue] = useState('');

  const navigate = useNavigate();
  const pathName = useLocation().pathname.split('/').slice(-1)[0];

  return (
    <>
      <h1>Driver</h1>
      <Header>
        <div>
          <button onClick={() => Create(<CreateDriver />)}>
            <AiOutlineUser />
            <span>Create a Driver</span>
          </button>
          <button
            onClick={() => navigate('/user/driver')}
            className={pathName === 'driver' ? classes.buttonActive : ''}
          >
            <RiListCheck />
            <span>Driver Board</span>
          </button>
          <button
            onClick={() => navigate('/user/driver/search')}
            className={pathName === 'search' ? classes.buttonActive : ''}
          >
            <VscSearch />
            <span>Search Drivers</span>
          </button>
          {/* <button
            onClick={() => navigate('/user/driver/hours')}
            className={pathName === 'hours' ? classes.buttonActive : ''}
          >
            <FaHourglassHalf />
            <span>Hours</span>
          </button> */}
        </div>
        <div>
          <Filter
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            placeholder="Filter drivers"
          />
        </div>
      </Header>
      <Outlet context={filterValue} />
    </>
  );
}

export default Driver;
