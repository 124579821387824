import React, { useState, useEffect } from 'react';
import { MdDownload } from 'react-icons/md';

import BBITable from '../../../helpers/bBITable/BBITable';
import classesCustom from '../../../helpers/search/CustomSearch.module.css';
import classesGlobal from '../../../assets/Global.module.css';
import DateOnlyFormatter from '../../../helpers/inputs/DateOnlyFormatter';
import Filter from '../../../helpers/filter/Filter';
import FilterData from '../../../helpers/filter/FilterData';
import Get from '../../../api/internal/Get';
import Header from '../../../layouts/Header';
import View from '../../../helpers/slab/View';
import ViewDriver from '../../../features/views/users/driver/Driver';
import ViewLoad from '../../../features/views/load/Load';
import ViewPayroll from '../../../features/views/payroll/Payroll';
import Input from '../../../assets/essentials/Input';
import Select from '../../../assets/essentials/Select';
import OptionList from '../../../helpers/options/OptionList';
import Button from '../../../assets/essentials/Button';
import PostBody from '../../../api/internal/PostBody';
import { useOutletContext, useSearchParams } from 'react-router-dom';

function PayrollSearch({
  toggleBoardRefresh,
}: {
  toggleBoardRefresh: boolean;
}) {
  const filterValue = useOutletContext();
  const [payrollBoard, setPayrollBoard] = useState<Array<PayrollBoard>>([]);
  const [paymentStatusOptions, setPaymentStatusOptions] = useState();
  const [searchPayrollParams, setPayrollSearchParams] = useSearchParams();

  useEffect(() => {
    Get(`/Accounting/SearchPayroll?${searchPayrollParams.toString()}`).then(
      response => {
        if (response) {
          setPayrollBoard(FormatPayrolls(response.data));
        }
      },
    );

    if (!paymentStatusOptions) {
      Get(`/Accounting/GetPaymentStatusOptions/`).then(response => {
        if (response) {
          setPaymentStatusOptions(response.data);
        }
      });
    }
  }, [toggleBoardRefresh, searchPayrollParams, paymentStatusOptions]);

  function DownloadPayrollReportCSV(payrolls) {
    let payrollIdsToDownload = [];
    payrollIdsToDownload = payrolls?.map(x => x.assetAssignmentId);
    if (payrollIdsToDownload.length > 0) {
      let testArray = { AssetAssignmentIdList: [...payrollIdsToDownload] };
      PostBody('Accounting/DownloadPayrollReportCSV', testArray).then(
        response => {
          if (response) {
            const blob = new Blob([response.data], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download =
              new Date().toISOString().split('T')[0].replace(/[-]/g, '') +
              '_Payroll Report.csv';
            a.click();
            URL.revokeObjectURL(url);
          }
        },
      );
    }
  }

  const handleFormSubmit = event => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const newParams = new URLSearchParams();
    formData.forEach((value, key) => {
      if (value !== '') {
        newParams.set(key, value.toString());
      }
    });
    setPayrollSearchParams(newParams, { replace: true });
  };

  function FormatPayrolls(payrolls) {
    return payrolls.map(payroll => ({
      ...payroll,
      workCompletedDate: DateOnlyFormatter(payroll.workCompletedDate),
      loadMileage: payroll.loadMileage.toLocaleString(),
      total: payroll.total.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      remainingBalance: payroll.remainingBalance.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    }));
  }

  const SubHeader = () => {
    return (
      <Header>
        <div>
          <button onClick={() => DownloadPayrollReportCSV(tableData)}>
            <MdDownload />
            <span>Download</span>
          </button>
        </div>
      </Header>
    );
  };

  const SearchForm = () => {
    return (
      <div className={`${classesCustom.customSearch}`}>
        <form onSubmit={handleFormSubmit}>
          <label>
            Load #
            <Input
              name="loadId"
              defaultValue={searchPayrollParams.get('loadId') ?? ''}
            />
          </label>
          <label>
            Payment Status
            <Select
              name="paymentStatusId"
              defaultValue={searchPayrollParams.get('paymentStatusId') ?? ''}
            >
              <option value="" />
              <OptionList
                optionData={paymentStatusOptions}
                attributeID="paymentStatusId"
                attributeName="status"
              />
            </Select>
          </label>
          <label>
            Driver First Name
            <Input
              name="driverFirstName"
              defaultValue={searchPayrollParams.get('driverFirstName') ?? ''}
            />
          </label>
          <label>
            Driver Last Name
            <Input
              name="driverLastName"
              defaultValue={searchPayrollParams.get('driverLastName') ?? ''}
            />
          </label>
          <label>
            Work Completed Date
            <Input
              type="date"
              name="workAndPaperworkCompletedDate"
              defaultValue={
                searchPayrollParams.get('workAndPaperworkCompletedDate') ?? ''
              }
            />
          </label>
          <div className={classesCustom.submitHolder}>
            <Button type="submit" variant="good">
              Search
            </Button>
            <Button
              type="reset"
              variant="bad"
              onClick={() => setPayrollSearchParams({})}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    );
  };

  let columnConfig = [
    {
      key: '1',
      attribute: 'loadId',
      header: 'Load #',
      onClick: e => View(<ViewLoad loadId={e.target.innerText} />),
      dataType: 'number',
    },
    {
      key: '2',
      attribute: 'reference',
      attributeprimaryhidden: 'loadId',
      header: 'Reference',
      onClick: e =>
        View(<ViewLoad loadId={e.target.dataset.attributeprimaryhidden} />),
    },
    {
      key: '3',
      attribute: 'paymentStatus',
      attributeprimaryhidden: 'assetAssignmentId',
      header: 'Status',
      onClick: e =>
        View(
          <ViewPayroll
            assetAssignmentId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
      dataType: 'string',
    },
    {
      key: '4',
      attribute: 'driverName',
      attributeprimaryhidden: 'employeeId',
      header: 'Driver',
      onClick: e =>
        View(
          <ViewDriver
            driverName={e.target.innerText}
            employeeId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '5',
      attribute: 'workCompletedDate',
      header: 'Work Completed Date',
      dataType: 'date',
    },
    {
      key: '6',
      attribute: 'loadMileage',
      header: 'Load Mileage',
      dataType: 'formattedNumber',
    },
    {
      key: '7',
      attribute: 'total',
      header: 'Total',
      dataType: 'currency',
    },
    {
      key: '8',
      attribute: 'remainingBalance',
      header: 'Remaining Balance',
      dataType: 'currency',
    },
  ];

  let tableData = FilterData(payrollBoard || [], filterValue);

  return (
    <>
      <SubHeader />
      <SearchForm />
      <BBITable data={tableData} columns={columnConfig} />
    </>
  );
}
export default PayrollSearch;
