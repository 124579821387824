import React, { useState, useEffect, useRef } from 'react';
import BBITable from '../../../helpers/bBITable/BBITable';
import classesGlobal from '../../../assets/Global.module.css';
import classesFinancialReport from './FinancialReport.module.css';
import Filter from '../../../helpers/filter/Filter';
import FilterData from '../../../helpers/filter/FilterData';
import Get from '../../../api/internal/Get';
import Header from '../../../layouts/Header';
import View from '../../../helpers/slab/View';
import ViewCustomer from '../../../features/views/customer/Customer';
import classesCustom from '../../../helpers/search/CustomSearch.module.css';
import { CSVLink } from 'react-csv';
import { MdDownload } from 'react-icons/md';
import {
  GenerateQueryString,
  ParseUrlForSearchParams,
  GetLastFullWeekMonday,
} from '../../../../src/helpers/search/HelperFunctions';
import Input from '../../../assets/essentials/Input';
import Button from '../../../assets/essentials/Button';
import Select from '../../../assets/essentials/Select';
import OptionList from '../../../helpers/options/OptionList';
import { useOutletContext } from 'react-router-dom';

function CustomerFinancials({ toggleBoardRefresh }) {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [customerFinancialsBoard, setCustomerFinancialsBoard] = useState<
    Array<CustomerFinancialsBoard>
  >([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const emptySearchParams = {
    customerId: null,
    dateMin: null, //must use name "dateMin" if we want to use defaultSearchDate
    dateMax: null,
  };
  const [customerFinancialSearchParams, setCustomerFinancialSearchParams] =
    useState(emptySearchParams);
  const isFirstRender = useRef(true);
  const filterValue = useOutletContext();

  useEffect(() => {
    setIsLoading(true);
    if (customerOptions.length === 0) {
      GetCustomerOptions();
    }

    const { customerId, dateMin, dateMax } = ParseUrlForSearchParams(
      emptySearchParams,
      GetLastFullWeekMonday(),
      isFirstRender.current,
    );

    setCustomerFinancialSearchParams({
      customerId: customerId,
      dateMin: dateMin,
      dateMax: dateMax,
    });
    SearchCustomerFinancialReport({
      customerId: customerId,
      dateMin: dateMin,
      dateMax: dateMax,
    });
    isFirstRender.current = false;
  }, [toggleBoardRefresh]);

  function SearchCustomerFinancialReport(SearchParamsFromForm?: Object) {
    const searchParams = SearchParamsFromForm
      ? SearchParamsFromForm
      : customerFinancialSearchParams;
    const queryString = '?' + GenerateQueryString(searchParams);
    const newUrl =
      window.location.origin + window.location.pathname + queryString;
    window.history.replaceState(null, null, newUrl);
    Get(`/Accounting/SearchCustomerFinancialReport${queryString}`).then(
      response => {
        if (response) {
          setCustomerFinancialsBoard(response.data);
          setIsLoading(false);
        }
      },
    );
  }

  function GetCustomerOptions() {
    Get(`/Customer/GetCustomerLoadReportOptions/`).then(response => {
      if (response) {
        setCustomerOptions(response?.data);
      }
    });
  }

  function ClearSearchParams() {
    setCustomerFinancialSearchParams(emptySearchParams);
  }

  function HandleFormSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    const formData = {
      customerId: e.target.customerId?.value,
      dateMin: e.target.dateMin?.value,
      dateMax: e.target.dateMax?.value,
    };
    setCustomerFinancialSearchParams(formData);
    SearchCustomerFinancialReport(formData);
  }

  const formattedBoard = customerFinancialsBoard.map(customerFinancial => ({
    ...customerFinancial,
    revenue: customerFinancial.revenue.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }),

    estFuel: customerFinancial.estFuel.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }),

    payroll: customerFinancial.payroll.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }),

    payable: customerFinancial.payable.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }),
    margin: customerFinancial.margin.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }),
    marginPercentage: (customerFinancial.marginPercentage * 100).toFixed(1),
    miles: customerFinancial.miles.toLocaleString('en-US', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    }),
  }));

  const columnConfig = [
    {
      key: '1',
      attribute: 'customerName',
      attributeprimaryhidden: 'customerId',
      header: 'Customer',
      onClick: e =>
        View(
          <ViewCustomer
            customerId={e.target.dataset.attributeprimaryhidden}
            customerName={e.target.innerText}
          />,
        ),
      dataType: 'number',
    },
    {
      key: '2',
      attribute: 'loadCount',
      header: 'Load Count',
      includeInTotal: true,
    },
    {
      key: '3',
      attribute: 'miles',
      header: 'Miles',
      dataType: 'formattedNumber',
      includeInTotal: true,
    },
    {
      key: '4',
      attribute: 'revenue',
      header: 'Revenue',
      dataType: 'currency',
      includeInTotal: true,
    },
    {
      key: '5',
      attribute: 'estFuel',
      header: 'Est. Fuel',
      dataType: 'currency',
      includeInTotal: true,
    },
    {
      key: '6',
      attribute: 'payroll',
      header: 'Payroll',
      dataType: 'currency',
      includeInTotal: true,
    },
    {
      key: '7',
      attribute: 'payable',
      header: 'Payable',
      dataType: 'currency',
      includeInTotal: true,
    },
    {
      key: '8',
      attribute: 'margin',
      header: 'Margin',
      dataType: 'currency',
      includeInTotal: true,
    },
    {
      key: '9',
      attribute: 'marginPercentage',
      header: 'Margin %',
      dataType: 'number',
      includeInTotal: true,
    },
  ];

  let tableData = FilterData(formattedBoard, filterValue);

  const SearchForm = () => {
    return (
      <div className={classesCustom.customSearch}>
        <form onSubmit={e => HandleFormSubmit(e)}>
          <label>
            Customer
            <Select
              defaultValue={customerFinancialSearchParams.customerId || ''}
              name="customerId"
            >
              <option value="">All Customers</option>
              <OptionList
                optionData={customerOptions}
                attributeID="customerId"
                attributeName="customerName"
              />
            </Select>
          </label>

          <label>
            Pickup Date Start
            <Input
              type="date"
              name="dateMin"
              defaultValue={customerFinancialSearchParams.dateMin}
            />
          </label>
          <label>
            Pickup Date End
            <Input
              type="date"
              name="dateMax"
              defaultValue={customerFinancialSearchParams.dateMax}
            />
          </label>

          <div className={classesCustom.submitHolder}>
            <Button type="submit" variant="good">
              Search
            </Button>

            <Button
              onClick={() => ClearSearchParams()}
              type="button"
              variant="bad"
            >
              Clear
            </Button>
          </div>
        </form>
      </div>
    );
  };

  const Summary = () => {
    const loadCount =
      tableData?.reduce((sum, row) => sum + row.loadCount, 0) ?? 0;
    const totalRevenue =
      tableData?.reduce(
        (sum, row) => sum + Number(row.revenue.replace(/[^\d.-]/g, '')),
        0,
      ) ?? 0;
    const totalEstFuel =
      tableData?.reduce(
        (sum, row) => sum + Number(row.estFuel.replace(/[^\d.-]/g, '')),
        0,
      ) ?? 0;
    const totalPayroll =
      tableData?.reduce(
        (sum, row) => sum + Number(row.payroll.replace(/[^\d.-]/g, '')),
        0,
      ) ?? 0;
    const totalPayable =
      tableData?.reduce(
        (sum, row) => sum + Number(row.payable.replace(/[^\d.-]/g, '')),
        0,
      ) ?? 0;
    const totalMargin =
      tableData?.reduce(
        (sum, row) => sum + Number(row.margin.replace(/[^\d.-]/g, '')),
        0,
      ) ?? 0;
    const marginPercentage =
      totalRevenue === 0 ? 0 : ((totalMargin / totalRevenue) * 100).toFixed(2);

    return (
      <div className={classesFinancialReport.summaryRow}>
        <div>
          <span className={classesFinancialReport.summaryLabel}>
            Load Count:
          </span>
          {loadCount}
        </div>
        <div>
          <span className={classesFinancialReport.summaryLabel}>
            Total Revenue:
          </span>
          {totalRevenue.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </div>
        <div>
          <span className={classesFinancialReport.summaryLabel}>
            Total Est. Fuel:
          </span>
          {totalEstFuel.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </div>
        <div>
          <span className={classesFinancialReport.summaryLabel}>
            Total Payroll:
          </span>
          {totalPayroll.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </div>
        <div>
          <span className={classesFinancialReport.summaryLabel}>
            Total Payable:
          </span>
          {totalPayable.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </div>
        <div>
          <span className={classesFinancialReport.summaryLabel}>
            Total Margin:
          </span>
          {totalMargin.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </div>
        <div>
          <span className={classesFinancialReport.summaryLabel}>Margin:</span>{' '}
          {marginPercentage}%
        </div>
      </div>
    );
  };

  const csvFileName =
    new Date().toISOString().split('T')[0].replace(/[-]/g, '') +
    '_Customer Financial Report.csv';

  return (
    <>
      <Header>
        <button>
          <CSVLink
            data={tableData}
            headers={[
              { label: 'Customer', key: 'customerName' },
              { label: 'Load Count', key: 'loadCount' },
              { label: 'Miles', key: 'miles' },
              { label: 'Revenue', key: 'revenue' },
              { label: 'Est. Fuel', key: 'estFuel' },
              { label: 'Payroll', key: 'payroll' },
              { label: 'Payable', key: 'payable' },
              { label: 'Margin', key: 'margin' },
              { label: 'Margin Percentage', key: 'marginPercentage' },
            ]}
            filename={csvFileName}
            className={classesFinancialReport.downloadButton}
          >
            <MdDownload />
            <span>Download</span>
          </CSVLink>
        </button>
      </Header>
      <SearchForm />
      <Summary />
      {isLoading ? (
        <h3>Generating Report...</h3>
      ) : (
        <BBITable data={tableData} columns={columnConfig} />
      )}
    </>
  );
}

export default CustomerFinancials;
