import React, { useState } from 'react';
import classesPayment from './Summary.module.css';
import BBITable from '../../../../helpers/bBITable/BBITable';
import View from '../../../../helpers/slab/View';
import ViewLoad from '../../load/Load';
import ViewRental from '../../rental/Rental';
import DateOnlyFormatter from '../../../../helpers/inputs/DateOnlyFormatter';
import classesGlobal from '../../../../assets/Global.module.css';
import PostBody from '../../../../api/internal/PostBody';
import Button from '../../../../assets/essentials/Button';

function ReceivablesAppliedCashFlowTable({
  cashFlowId,
  data,
  RefreshPage,
}: {
  cashFlowId;
  data: ARAppliedPaymentsSummary;
  RefreshPage: Function;
}) {
  function FormatAppliedCashFlows(appliedCashFlows) {
    return appliedCashFlows.map(appliedCashFlow => ({
      ...appliedCashFlow,
      attribute: appliedCashFlow.loadId
        ? appliedCashFlow.loadId
        : appliedCashFlow.rentalId && 'Rental: ' + appliedCashFlow.rentalId,
      paymentAmount: appliedCashFlow.paymentAmount?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      balanceAfterPayment: appliedCashFlow.balanceAfterPayment?.toLocaleString(
        'en-US',
        {
          style: 'currency',
          currency: 'USD',
        },
      ),
    }));
  }
  let columnConfig = [
    {
      key: '1',
      attribute: 'attribute',
      attributeprimaryhidden: 'loadId',
      attributesecondaryhidden: 'rentalId',
      header: 'Load #',
      onClick: e =>
        View(
          e.target.dataset.attributeprimaryhidden ? (
            <ViewLoad loadId={e.target.dataset.attributeprimaryhidden} />
          ) : (
            e.target.dataset.attributesecondaryhidden && (
              <ViewRental
                rentalId={e.target.dataset.attributesecondaryhidden}
              />
            )
          ),
        ),
    },
    {
      key: '2',
      attribute: 'paymentAmount',
      header: 'Amount',
    },

    {
      key: '3',
      attribute: 'balanceAfterPayment',
      header: 'Balance After Payment',
    },
    {
      key: '4',
      attribute: 'daysToPay',
      header: 'Days To Pay',
    },
  ];

  const CreateQuickbooksPaymentIdButton = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    function PostPaymentToQuickbooks() {
      setIsLoading(true);
      PostBody('Accounting/PostCustomerPaymentToQuickBooks', {
        cashFlowId: cashFlowId,
      })
        .then(response => {
          if (response) {
            RefreshPage('Summary');
          }
        })
        .finally(() => setIsLoading(false));
    }
    return (
      <div className={classesGlobal.marginTop05Rem}>
        {isLoading ? (
          <Button disabled>Pending...</Button>
        ) : (
          <Button
            variant="good"
            disabled={data?.quickBooksPaymentId != null}
            onClick={() => PostPaymentToQuickbooks()}
            type="button"
          >
            {data?.quickBooksPaymentId != null
              ? `QuickBooks Payment ID: ${data?.quickBooksPaymentId}`
              : 'Post Payment To QuickBooks'}
          </Button>
        )}
      </div>
    );
  };

  return (
    <div className={classesPayment.paymentSummary}>
      <div className={classesPayment.paymentSummaryHeader}>
        <span className={classesPayment.boldText}>Customer: </span>
        <span className={classesGlobal.span3}>{data.customerName}</span>
        <span className={classesPayment.boldText}>Total: </span>
        <span>
          {data.total?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </span>
        <span className={classesPayment.boldText}>Check #/Reference: </span>
        <span>{data.reference}</span>
        <span className={classesPayment.boldText}>Payment Date: </span>
        <span>{DateOnlyFormatter(data.paymentDate)}</span>
        <span className={classesPayment.boldText}>Payment Method: </span>
        <span>{data.paymentMethod}</span>
        <CreateQuickbooksPaymentIdButton />
      </div>
      <BBITable
        data={FormatAppliedCashFlows(data.appliedCashFlows)}
        columns={columnConfig}
        compactView={false}
      />
    </div>
  );
}

export default ReceivablesAppliedCashFlowTable;
