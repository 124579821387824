import { useEffect, useState } from 'react';

import Get from '../../../api/internal/Get';
import Slab from '../../../helpers/slab/Slab';
import Summary from './Summary';

function CashFlow({ cashFlowId, startPage }) {
  const [currentPage, setCurrentPage] = useState();
  //hardcoding a single employeeId to pass as a prop instead of hardcoding in every component
  const employeeId = 1;

  useEffect(() => {
    startPage ? HandlePageChange(startPage) : HandlePageChange('Summary');
  }, []);

  const HandlePageChange = params => {
    if (!(params in dataObject) && pages[params].callerPath) {
      Get(pages[params].callerPath).then(response => {
        dataObject[params] = response.data;
        AssignPages();
        setCurrentPage(params);
      });
    } else {
      setCurrentPage(params);
    }
  };

  function RefreshPage(currentPage, additionalPages) {
    setCurrentPage();
    delete dataObject[currentPage];
    additionalPages?.forEach(pageName => {
      delete dataObject[pageName];
    });
    HandlePageChange(currentPage);
  }

  const [dataObject] = useState({});
  let pages = {};
  AssignPages();

  function AssignPages() {
    pages = {
      Summary: {
        content: (
          <Summary
            data={dataObject.Summary}
            HandlePageChange={HandlePageChange}
          />
        ),
        callerPath: `/Accounting/GetCashFlowSummary/${cashFlowId}`,
      },
    };
  }

  return (
    <Slab
      title={'Cash Flow #' + cashFlowId}
      pages={pages}
      currentPage={currentPage}
      HandlePageChange={HandlePageChange}
      dataObject={dataObject}
    />
  );
}
export default CashFlow;
