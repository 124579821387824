import React, { useState, useEffect } from 'react';

import Details from './Details';
import Panel from '../../../helpers/panel/Panel';
import PostBody from '../../../api/internal/PostBody';
import Close from '../../../helpers/panel/Close';
import Get from '../../../api/internal/Get';

function Truck({ GetTruckBoard }) {
  const [createTruck, setCreateTruck] = useState<CreateTruckOptions>();
  const [truckType, setTruckType] = useState('');
  const [createTruckParam] = useState<CreateTruckParam>({
    truckTypeId: null,
    truckName: null,
    vin: null,
    licensePlateNumber: null,
    licensePlateState: null,
    make: null,
    model: null,
    year: null,
    mileage: null,
    mileageAsOfDate: null,
    eldId: null,
    eldMake: null,
    eldModel: null,
    note: null,
    color: null,
    grossVehicleWeightRating: null,
    weightPermitExpirationDate: null,
    branchId: null,
    internationalFuelTaxAgreementNumber: null,
    isOwned: true,
  });

  useEffect(() => {
    Get(`/Asset/GetCreateTruck`).then(response => {
      if (response) {
        setCreateTruck(response.data);
      }
    });
  }, []);

  function CreateTruck() {
    PostBody('Asset/CreateTruck', createTruckParam).then(response => {
      if (response) {
        GetTruckBoard();
        Close();
      }
    });
  }

  let pages = [
    {
      title: 'Details',
      displayedIcon: '',
      content: (
        <Details
          truck={createTruckParam}
          createTruck={createTruck}
          setTruckType={setTruckType}
          UpdateCreateTruck={UpdateCreateTruck}
        />
      ),
      require: [
        {
          label: 'Truck Type',
          FunctionBool: () => createTruckParam.truckTypeId,
          assignedValue: () => truckType,
          missingDesc: 'Truck Type has not been assigned.',
        },
        {
          label: 'Truck Unit #',
          FunctionBool: () => createTruckParam.truckName,
          assignedValue: () => createTruckParam.truckName,
          missingDesc: 'Truck Unit # has not been assigned.',
        },
        {
          label: 'VIN',
          FunctionBool: () => createTruckParam.vin,
          assignedValue: () => createTruckParam.vin,
          missingDesc: 'VIN has not been assigned.',
        },
        {
          label: 'Make',
          FunctionBool: () => createTruckParam.make,
          assignedValue: () => createTruckParam.make,
          missingDesc: 'Make has not been assigned.',
        },
        {
          label: 'Model',
          FunctionBool: () => createTruckParam.model,
          assignedValue: () => createTruckParam.model,
          missingDesc: 'Model has not been assigned.',
        },
        {
          label: 'Year',
          FunctionBool: () => createTruckParam.year,
          assignedValue: () => createTruckParam.year,
          missingDesc: 'Year has not been assigned.',
        },
        //logic to allow user to input no mileage or date, but if mileage is given a date is required
        {
          label: 'Mileage as of Date',
          FunctionBool: () =>
            createTruckParam.mileage === null || createTruckParam.mileage === ''
              ? true
              : createTruckParam.mileageAsOfDate,
          assignedValue: () => {
            if (
              createTruckParam.mileageAsOfDate &&
              createTruckParam.mileage !== null &&
              createTruckParam.mileage !== ''
            ) {
              let parts = createTruckParam?.mileageAsOfDate
                .toString()
                .split('-');
              let formattedDate = `${parts[1]}/${parts[2]}/${parts[0]}`;
              return createTruckParam.mileage + ' as of ' + formattedDate;
            }
            return 'No Mileage Entered';
          },
          missingDesc: 'Please provide a date.',
        },
      ],
    },
  ];

  function OnSubmit() {
    let fulfillsFormRequirements = true;
    pages.forEach((page, index) => {
      if (!FulFillsPageRequirements(index)) {
        fulfillsFormRequirements = false;
      }
    });
    if (fulfillsFormRequirements) {
      CreateTruck();
    } else alert('Not all info included');
  }

  function UpdateCreateTruck(newValue, attributeName) {
    if (newValue?.toString().trim().length !== 0) {
      createTruckParam[attributeName] = newValue;
    } else {
      createTruckParam[attributeName] = null;
    }
  }

  function FulFillsPageRequirements(pageIndex) {
    let fulfillsRequirements = true;
    pages[pageIndex].require.forEach(requirement => {
      if (!requirement.FunctionBool()) {
        fulfillsRequirements = false;
      }
    });
    return fulfillsRequirements;
  }

  return <Panel OnSubmit={OnSubmit} title="Create Truck" pages={pages} />;
}

export default Truck;
