import React, { useEffect } from 'react';
import TrimbleMaps, {
  GeoJSONSourceRaw,
  LngLat,
  PopupOptions,
} from '@trimblemaps/trimblemaps-js';
import DateTimeUTCFormatter from './inputs/DateTimeUTCFormatter';

type AssetLocationMapProps = {
  coordinates: AssetCoordinates[];
  toggleRerender: boolean;
};

export default function AllAssetsLocationMap({
  coordinates,
  toggleRerender,
}: AssetLocationMapProps) {
  useEffect(() => {
    // Import necessary dependencies (e.g., RenderMap library)
    // Ensure trimbleAssetLocationCoordinates contains valid coordinate data

    const map = RenderMap(trimbleAssetLocationCoordinates);

    return () => {
      map.remove();
    };
  }, [toggleRerender]);

  TrimbleMaps.APIKey = process.env.REACT_APP_TRIMBLE_MAPS_API_KEY;

  const trimbleAssetLocationCoordinates = coordinates.map(
    location => new TrimbleMaps.LngLat(location.longitude, location.latitude),
  );

  function RenderMap(assetLocations: Array<LngLat>) {
    const map = new TrimbleMaps.Map({
      container: 'allAssetsMap',
      style: TrimbleMaps.Common.Style.TRANSPORTATION,
      // These coordinates roughly center the map around the contintental United States
      center: new TrimbleMaps.LngLat(-95.5, 38.25),
      zoom: 3,
    });

    function CreateCoordinateGeoJSON(coordinatesArray, popupData) {
      const features = coordinatesArray?.map((coordinates, index) => ({
        type: 'Feature',
        properties: {
          Site: index + 1,
          locationDate: popupData[index].locationDate,
          estimatedAddress: popupData[index].estimatedAddress,
          assetName: popupData[index].assetName,
        },
        geometry: {
          type: 'Point',
          coordinates,
        },
      }));

      return {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features,
        },
      };
    }

    //trimble wants an array, not an object, with longitude first then latitude
    const truckCoordinatesArray = coordinates
      ?.filter(coordinate => coordinate.assetType === 'Truck')
      .map(checkCallCoordinatesObject => {
        const [latitude, longitude] = Object.values(checkCallCoordinatesObject);
        return [longitude, latitude];
      });

    const truckPopupData = coordinates
      ?.filter(coordinate => coordinate.assetType === 'Truck')
      .map(coordinate => ({
        assetName: coordinate.assetName,
        locationDate: DateTimeUTCFormatter(coordinate.locationDate),
        estimatedAddress: coordinate.estimatedAddress,
      }));

    const trailerCoordinatesArray = coordinates
      ?.filter(coordinate => coordinate.assetType === 'Trailer')
      .map(checkCallCoordinatesObject => {
        const [latitude, longitude] = Object.values(checkCallCoordinatesObject);
        return [longitude, latitude];
      });

    const trailerPopupData = coordinates
      ?.filter(coordinate => coordinate.assetType === 'Trailer')
      .map(coordinate => ({
        assetName: coordinate.assetName,
        locationDate: DateTimeUTCFormatter(coordinate.locationDate),
        estimatedAddress: coordinate.estimatedAddress,
      }));

    const truckGeoJSON = CreateCoordinateGeoJSON(
      truckCoordinatesArray,
      truckPopupData,
    );
    const trailerGeoJSON = CreateCoordinateGeoJSON(
      trailerCoordinatesArray,
      trailerPopupData,
    );

    map.on('load', function () {
      map.addSource('truck', truckGeoJSON as GeoJSONSourceRaw);
      map.addLayer({
        id: 'truck',
        type: 'symbol',
        source: 'truck',
        layout: {
          'icon-image': 'truck-fill-blue',
          'icon-allow-overlap': true,
        },
      });

      map.addSource('trailer', trailerGeoJSON as GeoJSONSourceRaw);
      map.addLayer({
        id: 'trailer',
        type: 'symbol',
        source: 'trailer',
        layout: {
          'icon-image': 'truck-fill-green',
          'icon-allow-overlap': true,
        },
      });

      map.on('mouseenter', 'truck', function () {
        map.getCanvas().style.cursor = 'pointer';
      });
      map.on('mouseleave', 'truck', function () {
        map.getCanvas().style.cursor = '';
      });

      map.on('mouseenter', 'trailer', function () {
        map.getCanvas().style.cursor = 'pointer';
      });
      map.on('mouseleave', 'trailer', function () {
        map.getCanvas().style.cursor = '';
      });

      const popupOptions: PopupOptions = {
        closeButton: false,
        closeOnMove: true,
      };

      const popup = new TrimbleMaps.Popup(popupOptions);

      const popupStyling = `<style>
                              .trimblemaps-popup {
                                position: absolute;
                                top: 0;
                                left: 0;
                                display: flex;
                                will-change: transform;
                                pointer-events: none;
                              }

                              .trimblemaps-popup-content {
                                position: relative;
                                background-color: white;
                                border-radius: .375rem;
                                box-shadow: 0 .063rem .125rem rgba(0, 0, 0, 0.1);
                                padding: .938rem;
                                pointer-events: auto;
                                max-width: 18.75rem;
                                max-height: auto;
                                display: flex;
                                flex-direction: column;
                                align-items: left;
                              }

                              .trimblemaps-popup-content p {
                                font-size: .75rem;
                                color: black;
                                margin: 0rem;
                                text-align: left;
                              }

                            </style>`;

      map.on('click', 'trailer', function (e) {
        const location = e.lngLat;
        const data = e.features[0].properties;

        popup
          .setLngLat(location)
          .setHTML(
            `${popupStyling}<p><strong>Trailer: </strong>${data.assetName}</p><p><strong>Date: </strong>${data.locationDate}</p><p><strong>Location: </strong>${data.estimatedAddress}</p>`,
          );
        popup.addTo(map);
      });

      map.on('click', 'truck', function (e) {
        const location = e.lngLat;
        const data = e.features[0].properties;

        popup
          .setLngLat(location)
          .setHTML(
            `${popupStyling}<p><strong>Truck: </strong>${data.assetName}</p><p><strong>Date: </strong>${data.locationDate}</p><p><strong>Location: </strong>${data.estimatedAddress}</p>`,
          );
        popup.addTo(map);
      });
    });
    return map;
  }
  return <div id="allAssetsMap" />;
}
