import classes from '../../../assets/Global.module.css';
import PostForm from '../../../api/internal/PostForm';
import Input from '../../../assets/essentials/Input';
import Select from '../../../assets/essentials/Select';
import OptionList from '../../../helpers/options/OptionList';
import TextArea from '../../../assets/essentials/TextArea';
import Button from '../../../assets/essentials/Button';
import Form from '../../../assets/essentials/Form';

function Payments({
  relationId,
  relationType,
  data,
  HandlePageChange,
  RefreshPage,
}) {
  function CreateCashFlow(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const appendix = {
      relationId: relationId,
      relationType: relationType,
    };
    PostForm(`/Accounting/CreateCashFlow`, formData, appendix).then(
      () => RefreshPage('Summary'),
      HandlePageChange('Summary'),
    );
  }

  return (
    <Form onSubmit={e => CreateCashFlow(e)}>
      {relationType != 'Asset' && <div className={classes.attribute}>
        <label>
          Payment Method <span className={classes.required}>*</span>
        </label>
        <Select required defaultValue="" name="paymentMethodId">
          <option disabled value="">
            -- Required --
          </option>
          <OptionList
            optionData={data}
            attributeID="paymentMethodId"
            attributeName="method"
          />
        </Select>
      </div>}
      <div className={relationType != 'Asset' ? `${classes.attribute} ${classes.span2}` : `${classes.attribute} ${classes.span3}` }>
        <label>
          Amount <span className={classes.required}>*</span>
        </label>
        <Input type="number" step={0.01} name="amount" required={true} />
      </div>
      {relationType != 'Asset' && <div className={classes.attribute}>
        <label>
          Reference/Check # <span className={classes.required}>*</span>
        </label>
        <Input type="text" name="referenceOrCheckNumber" required={true} />
      </div>}
      <div className={classes.attribute}>
        <label>
          Date <span className={classes.required}>*</span>
        </label>
        <Input type="date" name="date" required={true} />
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>Note</label>
        <TextArea name="note" />
      </div>
      
      <div>
        <Button type="submit" variant="good" disabled={true}>
          Post Cash Flow
        </Button>
      </div>
    </Form>
  );
}
export default Payments;
