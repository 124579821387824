import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { InteractionType } from '@azure/msal-browser';
import {
  useMsal,
  AuthenticatedTemplate,
  useMsalAuthentication,
} from '@azure/msal-react';
import Cookies from 'js-cookie';

import { lightTheme, darkTheme, GlobalStyles } from './assets/Themes.js';
import { BranchContext } from './helpers/BranchContext.tsx';
import styled, { ThemeProvider } from 'styled-components';
import HeaderBanner from './layouts/HeaderBanner.js';
import PageContent from './layouts/PageContent.js';
import PanelHolder from './helpers/panel/PanelHolder';
import SlabHolder from './helpers/slab/SlabHolder.js';
import ErrorHolder from './helpers/error/ErrorHolder.tsx';
import ModalHolder from './helpers/modal/ModalHolder.tsx';
import ActionBoard from './pages/ActionBoard.tsx';
import Load from './pages/Load';
import Rental from './pages/Rental';
import Customer from './pages/customer/Customer.tsx';
import CustomerBoard from './pages/customer/CustomerBoard';
import SearchCustomer from './pages/customer/SearchCustomer';
import Vendor from './pages/vendor/Vendor.tsx';
import VendorBoard from './pages/vendor/VendorBoard'
import SearchVendor from './pages/vendor/SearchVendor';
import Location from './pages/location/Location.tsx';
import LocationBoard from './pages/location/LocationBoard';
import SearchLocation from './pages/location/SearchLocation';
import Driver from './pages/user/Driver.tsx';
import DriverBoard from './pages/user/DriverBoard.tsx';
import SearchDriver from './pages/user/SearchDriver.tsx';
import DriverHours from './pages/user/DriverHours.tsx';
import Dispatch from './pages/user/Dispatch.tsx';
import Truck from './pages/asset/Truck.tsx';
import Trailer from './pages/asset/Trailer.tsx';
import MaintenanceLogs from './pages/asset/MaintenanceLogs';
import NeedsInvoice from './pages/accounting/NeedsInvoice.tsx';
import Receivable from './pages/accounting/receivables/Receivables';
import OutstandingReceivableBoard from './pages/accounting/receivables/OutstandingReceivableBoard';
import ReceivableSearch from './pages/accounting/receivables/ReceivableSearch';
import ReceivableSummary from './pages/accounting/receivables/ReceivableSummary';
import ApplyReceivablePayments from './pages/accounting/receivables/ApplyPayment/ApplyReceivablePayments';
import ReceivablePaymentBoard from './pages/accounting/receivables/ReceivablePayments.tsx';
import PayablePaymentBoard from './pages/accounting/payables/PayablePayments.tsx';
import Payable from './pages/accounting/payables/Payables';
import OutstandingPayableBoard from './pages/accounting/payables/OutstandingPayableBoard';
import PayableSearch from './pages/accounting/payables/PayableSearch';
import Payroll from './pages/accounting/payrolls/Payrolls';
import OutstandingPayrollBoard from './pages/accounting/payrolls/OutstandingPayrollBoard';
import PayrollSearch from './pages/accounting/payrolls/PayrollSearch'
import EmployeeAccounts from './pages/accounting/employeeAccounts/EmployeeAccounts.tsx';
import ESA from './pages/accounting/employeeAccounts/ESA.tsx';
import DriverEscrow from './pages/accounting/employeeAccounts/DriverEscrow.tsx'
import DriverMaintenance from './pages/accounting/employeeAccounts/DriverMaintenance.tsx'
import LoadFinancials from './pages/accounting/financialReports/LoadFinancials'
import Claims from './pages/Claims';

import CashFlow from './pages/accounting/CashFlow.tsx';
import FixedAsset from './pages/accounting/FixedAsset.tsx';
import JournalEntry from './pages/accounting/Ledger.tsx';
import Gas from './pages/transaction/Gas.js';
import Posting from './pages/Posting.tsx';
import CustomerLoadReport from './pages/reports/CustomerLoadReport';
import AccountReceivableReport from './pages/reports/AccountReceivableReport';
import PayrollPayments from './pages/accounting/payrolls/PayrollPayments';
import PayrollApplyPayments from './pages/accounting/payrolls/PayrollApplyPayments'
import QuickBooks from './pages/accounting/receivables/QuickBooks'
import Reports from './pages/accounting/financialReports/Reports.tsx';
import CustomerFinancials from './pages/accounting/financialReports/CustomerFinancials';
import DriverFinancials from './pages/accounting/financialReports/DriverFinancials';
import RentalFinancialReport from './pages/accounting/financialReports/RentalFinancials';
import ApplyPayablePayments from './pages/accounting/payables/ApplyPayablePayments';
import BrokerFinancials from './pages/accounting/financialReports/BrokerFinancials';
import AssetLocations from './pages/asset/AssetLocations.tsx';
import Paylocity from './pages/accounting/payrolls/Paylocity.tsx';
import Documents from './pages/Documents';
import QuickBooksAuth from './pages/accounting/QuickBooksAuth.tsx';

const StyledApp = styled.div``;

function App() {
  const [theme, setTheme] = useState(Cookies.get('theme') || 'light');
  //if cookies aren't set, defaulting to branches 1 and 2
  const [selectedBranches, setSelectedBranches] = useState(Cookies.get('branches')?.split(",") || ['1', '2']);
  const { login, error } = useMsalAuthentication(InteractionType.Redirect);

  useEffect(() => {
    if (error) {
      login(InteractionType.Redirect);
    }
  }, [error]);

  const { accounts } = useMsal();

  function ThemeToggler() {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    Cookies.set('theme', newTheme);
  }

  const [toggleBoardRefresh, setToggleRefreshLoadBoard] = useState(false);
  const handleChildCountChange = newChildCount => {
    if (newChildCount == 0) {
      setToggleRefreshLoadBoard(!toggleBoardRefresh);
    }
  };

  return (
    <React.Fragment>
      <AuthenticatedTemplate>
        <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
          <GlobalStyles />
          <StyledApp>
            <BrowserRouter>
              <HeaderBanner />
              <PageContent theme={theme} ThemeToggler={ThemeToggler}>
              <BranchContext.Provider value={[selectedBranches, setSelectedBranches]}>
                <Routes>
                  <Route index element={<ActionBoard toggleBoardRefresh={toggleBoardRefresh}/>} />
                  <Route
                    path="load/"
                    element={<Load toggleBoardRefresh={toggleBoardRefresh} />}
                  />
                  <Route path="customer" element={<Customer />} >
                    <Route index element={<CustomerBoard toggleBoardRefresh={toggleBoardRefresh}/>} />
                    <Route path="search" element={<SearchCustomer toggleBoardRefresh={toggleBoardRefresh}/>} />
                  </Route>
                  <Route path="vendor" element={<Vendor />} >
                      <Route index element={<VendorBoard toggleBoardRefresh={toggleBoardRefresh}/>} />
                      <Route path="search" element={<SearchVendor toggleBoardRefresh={toggleBoardRefresh}/>} />
                    </Route>
                  <Route path="location" element={<Location toggleBoardRefresh={toggleBoardRefresh}/>} >
                      <Route index element={<LocationBoard toggleBoardRefresh={toggleBoardRefresh}/>} />
                      <Route path="search" element={<SearchLocation toggleBoardRefresh={toggleBoardRefresh}/>} />
                    </Route>
                  <Route path="posting/" element={<Posting toggleBoardRefresh={toggleBoardRefresh}/>} />
                  <Route path="user/">
                    <Route path="dispatch" element={<Dispatch toggleBoardRefresh={toggleBoardRefresh}/>} />
                    <Route path="driver" element={<Driver />} >
                      <Route index element={<DriverBoard toggleBoardRefresh={toggleBoardRefresh}/>} />
                      <Route path="search" element={<SearchDriver toggleBoardRefresh={toggleBoardRefresh}/>} />
                      <Route path="hours" element={<DriverHours toggleBoardRefresh={toggleBoardRefresh}/>} />
                    </Route>
                  </Route>
                  <Route path="asset/">
                    <Route path="truck" element={<Truck toggleBoardRefresh={toggleBoardRefresh}/>} />
                    <Route path="trailer" element={<Trailer toggleBoardRefresh={toggleBoardRefresh}/>} />
                    <Route path="assetlocations" element={<AssetLocations />} />
                    <Route path='maintenance-logs' element={<MaintenanceLogs toggleBoardRefresh={toggleBoardRefresh}/>}/>
                  </Route>
                  <Route path="accounting/">
                    <Route path="needsinvoice" element={<NeedsInvoice toggleBoardRefresh={toggleBoardRefresh}/>} />
                    <Route path="cashflow" element={<CashFlow toggleBoardRefresh={toggleBoardRefresh}/>} />
                    <Route path="journalentry" element={<JournalEntry toggleBoardRefresh={toggleBoardRefresh}/>} />
                    <Route path="fixedasset" element={<FixedAsset toggleBoardRefresh={toggleBoardRefresh}/>} />
                    <Route path="loadfinancials" element={<LoadFinancials toggleBoardRefresh={toggleBoardRefresh}/>} />
                    <Route path="receivable/" element={<Receivable /> } >
                      <Route path="outstanding" element={<OutstandingReceivableBoard toggleBoardRefresh={toggleBoardRefresh} />} />
                      <Route path="search" element={<ReceivableSearch toggleBoardRefresh={toggleBoardRefresh} />} />
                      <Route path="summary" element={<ReceivableSummary toggleBoardRefresh={toggleBoardRefresh} />} />
                      <Route path="apply-payments" element={<ApplyReceivablePayments toggleBoardRefresh={toggleBoardRefresh} />}/>
                      <Route path="payments" element={<ReceivablePaymentBoard toggleBoardRefresh={toggleBoardRefresh} />} />
                      <Route path="quickbooks" element={<QuickBooks/>} />
                    </Route>
                    <Route path="payable/" element={<Payable />} >
                      <Route path="outstanding" element={<OutstandingPayableBoard toggleBoardRefresh={toggleBoardRefresh} />} />
                      <Route path="search" element={<PayableSearch toggleBoardRefresh={toggleBoardRefresh} />} />
                      <Route path="apply-payments" element={<ApplyPayablePayments toggleBoardRefresh={toggleBoardRefresh} />}/>
                      <Route path="payments" element={<PayablePaymentBoard toggleBoardRefresh={toggleBoardRefresh} />} />
                    </Route>
                    <Route path="payroll/" element={<Payroll />} >
                      <Route path="outstanding" element={<OutstandingPayrollBoard toggleBoardRefresh={toggleBoardRefresh} /> } />
                      <Route path="search" element={<PayrollSearch toggleBoardRefresh={toggleBoardRefresh} /> } />
                      <Route path="apply-payments" element={<PayrollApplyPayments toggleBoardRefresh={toggleBoardRefresh} />}/>
                      <Route path="payments" element={<PayrollPayments toggleBoardRefresh={toggleBoardRefresh} />}/>
                      <Route path="paylocity" element={<Paylocity toggleBoardRefresh={toggleBoardRefresh} />}/>
                    </Route>
                    <Route path="reports/financials" element={<Reports />}>
                      <Route path="load" element={<LoadFinancials toggleBoardRefresh={toggleBoardRefresh} /> } />
                      <Route path="customer" element={<CustomerFinancials toggleBoardRefresh={toggleBoardRefresh} /> } />
                      <Route path="driver" element={<DriverFinancials toggleBoardRefresh={toggleBoardRefresh} /> } />
                      <Route path="rental" element={<RentalFinancialReport toggleBoardRefresh={toggleBoardRefresh} /> } />
                      <Route path="broker" element={<BrokerFinancials toggleBoardRefresh={toggleBoardRefresh} /> } />
                    </Route>
                    <Route path="employeeaccounts/" element={<EmployeeAccounts />} >
                      <Route path="esa" element={<ESA toggleBoardRefresh={toggleBoardRefresh} /> } />
                      <Route path="driverescrow" element={<DriverEscrow toggleBoardRefresh={toggleBoardRefresh} /> } />
                      <Route path="drivermaintenance" element={<DriverMaintenance toggleBoardRefresh={toggleBoardRefresh} /> } />
                    </Route>
                  <Route path="quickbooks" element={<QuickBooksAuth/>}/>
                  </Route>
                  <Route path="transaction/">
                    <Route path="gas" element={<Gas toggleBoardRefresh={toggleBoardRefresh} />} />
                  </Route>
                  <Route path="claims" element={<Claims />} />
                  <Route path="rental" element={<Rental toggleBoardRefresh={toggleBoardRefresh} />} />
                  <Route
                    path="claims"
                    element={<Claims toggleBoardRefresh={toggleBoardRefresh} />}
                  />
                  <Route
                    path="rental"
                    element={<Rental toggleBoardRefresh={toggleBoardRefresh} />}
                  />
                  <Route path="reports/" >
                    <Route path="customerloadreport/:customerId" element={<CustomerLoadReport />} />
                    <Route path="accountreceivablereport/:customerId" element={<AccountReceivableReport />} />
                  </Route>
                  <Route path="documents/" element={<Documents toggleBoardRefresh={toggleBoardRefresh} />} />
                </Routes>
                </BranchContext.Provider>
              </PageContent>
            </BrowserRouter>
            <SlabHolder onChildCountChange={handleChildCountChange} />
            <PanelHolder onChildCountChange={handleChildCountChange} />
            <ModalHolder />
            <ErrorHolder />
          </StyledApp>
        </ThemeProvider>
      </AuthenticatedTemplate>
    </React.Fragment>
  );
}

export default App;
