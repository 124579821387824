import Header from '../../../layouts/Header';
import View from '../../../helpers/slab/View';
import ViewDriver from '../../../features/views/users/driver/Driver';
import React, { useState, useEffect } from 'react';
import FilterData from '../../../helpers/filter/FilterData';
import BBITable from '../../../helpers/bBITable/BBITable';
import Get from '../../../api/internal/Get';
import { CSVLink } from 'react-csv';
import { MdDownload } from 'react-icons/md';
import classesGlobal from '../../../assets/Global.module.css';
import { useOutletContext } from 'react-router-dom';

function ESA({ toggleBoardRefresh }) {
  const [employeeSavingsAccountBoard, setEmployeeSavingsAccountBoard] =
    useState<Array<ESABoard>>([]);
  const filterValue = useOutletContext();

  useEffect(() => {
    Get(`/Accounting/GetEmployeeSavingsAccountBoard`).then(response => {
      if (response) {
        setEmployeeSavingsAccountBoard(response.data);
      }
    });
  }, [toggleBoardRefresh]);

  const filteredData: ESABoard[] = FilterData(
    employeeSavingsAccountBoard,
    filterValue,
  );
  const tableData = filteredData.map(employeeSavingsAccount => ({
    ...employeeSavingsAccount,
    balance: employeeSavingsAccount.balance.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }),
  }));

  const columnConfig = [
    {
      key: '1',
      attribute: 'employeeName',
      attributeprimaryhidden: 'employeeId',
      header: 'Employee',
      onClick: e =>
        View(
          <ViewDriver
            driverName={e.target.innerText}
            employeeId={e.target.dataset.attributeprimaryhidden}
            startPage={'ESA'}
          />,
        ),
    },
    {
      key: '2',
      attribute: 'balance',
      header: 'Balance',
      dataType: 'currency',
    },
  ];

  const csvFileName =
    new Date().toISOString().split('T')[0].replace(/[-]/g, '') +
    '_ESA Report.csv';

  return (
    <>
      <Header>
        <div>
          <button>
            <CSVLink
              data={tableData}
              headers={[
                { label: 'Employee Number', key: 'employeeId' },
                { label: 'Employee', key: 'employeeName' },
                { label: 'Balance', key: 'balance' },
              ]}
              filename={csvFileName}
            >
              <MdDownload />
              <span>Download</span>
            </CSVLink>
          </button>
        </div>
      </Header>
      <BBITable data={tableData} columns={columnConfig} />
    </>
  );
}

export default ESA;
