import React from 'react';
import { useEffect, useState } from 'react';
import Slab from '../../../../helpers/slab/Slab';
import Details from './Details';
import Summary from './Summary';
import Audit from '../../generic/Audit';
import Files from '../../../creates/file/Files';
import Get from '../../../../api/internal/Get';
import Maintenance from './DriverMaintenance';
import Escrow from './DriverEscrow';
import ESA from '../ESA';
import Rate from './Rate';
import Admin from '../Admin';
import { useRoleAuthorization } from '../../../../hooks/useRoleAuthorization';
import { ADMIN_AUTHORIZED_ROLES } from '../../../../auth/AuthRoles';

function Driver({
  employeeId,
  driverName,
  startPage,
}: {
  employeeId: number;
  driverName: string;
  startPage?: string;
}) {
  const [currentPage, setCurrentPage] = useState();
  const [driverDataObject] = useState<DriverDataObject>({
    Audit: null,
    Admin: null,
    Details: null,
    Maintenance: null,
    Escrow: null,
    ESA: null,
    Files: null,
    Rate: null,
    Summary: null,
  });

  useEffect(() => {
    startPage ? HandlePageChange(startPage) : HandlePageChange('Summary');
  }, []);

  function HandlePageChange(params) {
    if (!driverDataObject[params] && pages[params].callerPath) {
      Get(pages[params].callerPath).then(response => {
        if (response) {
          driverDataObject[params] = response.data;
        }
        AssignPages();
        setCurrentPage(params);
      });
    } else {
      setCurrentPage(params);
    }
  }

  function RefreshPage(currentPage: string, additionalPages: string[]) {
    if (driverDataObject) {
      setCurrentPage(null);
      driverDataObject[currentPage] = null;
      additionalPages?.forEach(pageName => {
        driverDataObject[pageName] = null;
      });
      HandlePageChange(currentPage);
    }
  }

  let pages = {};
  AssignPages();
  function AssignPages() {
    pages = {
      Summary: {
        content: <Summary data={driverDataObject.Summary} />,
        callerPath: `/Employee/GetDriverSummary/${employeeId}`,
      },
      Details: {
        content: (
          <Details
            data={driverDataObject.Details}
            employeeId={employeeId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Employee/GetDriverDetails/${employeeId}`,
      },
      Rate: {
        content: (
          <Rate
            data={driverDataObject.Rate}
            RefreshPage={RefreshPage}
            employeeId={employeeId}
          />
        ),
        callerPath: `/Employee/GetDriverRate/${employeeId}`,
      },
      Files: {
        content: (
          <Files
            data={driverDataObject.Files}
            categoryId={employeeId}
            fileCategory={'driver'}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/File/GetAllFiles/driver-${employeeId}`,
      },
      Maintenance: {
        content: (
          <Maintenance
            data={driverDataObject.Maintenance}
            employeeId={employeeId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Accounting/GetDriverMaintenanceEntries/${employeeId}`,
      },
      Escrow: {
        content: (
          <Escrow
            data={driverDataObject.Escrow}
            employeeId={employeeId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Accounting/GetDriverEscrowEntries/${employeeId}`,
      },
      ESA: {
        content: (
          <ESA
            data={driverDataObject.ESA}
            employeeId={employeeId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Accounting/GetEmployeeEsaEntries/${employeeId}`,
      },
      Audit: {
        content: <Audit data={driverDataObject.Audit} />,
        callerPath: `/Employee/GetEmployeeAudit/${employeeId}`,
      },
    };
    if (useRoleAuthorization(ADMIN_AUTHORIZED_ROLES))
      pages['Admin'] = {
        content: (
          <Admin
            data={driverDataObject.Admin}
            employeeId={employeeId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Employee/GetEmployeeAdmin/${employeeId}`,
      };
  }

  return (
    <Slab
      title={'Driver ' + driverName}
      pages={pages}
      currentPage={currentPage}
      HandlePageChange={HandlePageChange}
      dataObject={driverDataObject}
    />
  );
}
export default Driver;
