import React, { useState } from 'react';
import { MdOutlineCropFree, MdOutlineCrop169 } from 'react-icons/md';
import { BiHide } from 'react-icons/bi';
import Cookies from 'js-cookie';

import classes from './ResizableTable.module.css';
import BBITable, { BBITableInterface } from '../bBITable/BBITable';

interface ResizableTableInterface extends BBITableInterface {
  title: string;
  noDataMessage: string;
}

/**
 * A resizable table component extending BBITable.
 *
 * @param {*} title - `string` - The title to be displayed above the table.
 * @param {*} noDataMessage - `string` - The message to be shown in place of the data if the data array is empty.
 * @param {*} data - `Object[]` - An array of objects containing the table data.
 * @param {*} columns - `Array<Column>` - An array of column configurations.
 * @param {*} compactView - `boolean?` - A flag indicating whether to display the table in a compact view.
 *
 * @returns {*} `JSX.Element` - The JSX element representing resizable table.
 *
 * @example
 * <ResizableTable
 * title='Future'
 * noDataMessage={`No ${title} loads.`}
 * data={data}
 * columns={columnConfig}
 * compactView={compactView}
 * />
 *
 * Column configuration for the BBITable component.
 *
 * @param {*} key - `string` - A unique key for the column.
 * @param {*} attribute - `string` - The attribute to be displayed from the data object.
 * @param {*} class - `string?` - Additional CSS class for styling the column.
 * @param {*} width - `string?` - The width of the column.
 * @param {*} header - `string?` - The header text for the column.
 * @param {*} onClick - `Function?` - A function to handle click events on the column.
 * @param {*} dataType - `string?` - The data type of the column (e.g., 'date', 'number', 'currency').
 * @param {*} notSortable - `string?` - A flag to disable column sorting.
 * @param {*} attributeprimaryhidden - `string?` - An optional attribute for hidden data.
 * @param {*} attributesecondaryhidden - `string?` - An optional attribute for secondary hidden data.
 * @param {*} attributetertiaryhidden - `string?` - From the makers of attributeprimaryhidden and attributesecondaryhidden.
 *
 * @example
 *   let columnConfig = [
    {
      key: '1',
      attribute: 'customerName',
      attributeprimaryhidden: 'customerId',
      header: 'Customer',
      onClick: e =>
        e.target.dataset.attributeprimaryhidden &&
        View(
          <ViewCustomer
            customerName={e.target.innerText}
            customerId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '2',
      attribute: 'customerPhone',
      header: 'Phone',
    },
    {
      key: '3',
      attribute: 'customerAddress',
      header: 'Address',
    },
  ];
 */

function ResizableTable({
  title,
  noDataMessage,
  data,
  columns,
  compactView,
}: ResizableTableInterface) {
  const [size, setSize] = useState(Cookies.get(title + 'Size') || 'partial');
  //prevSize holds which size the table should revert to if the user has the table hidden and clicks the hide icon again
  const [prevSize, setPrevSize] = useState(
    Cookies.get(title + 'PrevSize') || 'partial',
  );

  return (
    <>
      <div className={classes.title}>
        {title}
        <div className={classes.iconContainer}>
          <MdOutlineCropFree
            title="Expand"
            className={`${size === 'max' ? classes.tableActive : ''}`}
            onClick={() => {
              if (size === 'max') {
                setPrevSize('max');
                Cookies.set(title + 'PrevSize', 'max');
                setSize('min');
                Cookies.set(title + 'Size', 'min');
              } else {
                setSize('max');
                Cookies.set(title + 'Size', 'max');
              }
            }}
          />
          <MdOutlineCrop169
            title="Lock height"
            className={`${size === 'partial' ? classes.tableActive : ''}`}
            onClick={() => {
              if (size === 'partial') {
                setPrevSize('partial');
                Cookies.set(title + 'PrevSize', 'partial');
                setSize('min');
                Cookies.set(title + 'Size', 'min');
              } else {
                setSize('partial');
                Cookies.set(title + 'Size', 'partial');
              }
            }}
          />
          <BiHide
            title="Hide"
            className={`${size === 'min' ? classes.tableActive : ''}`}
            onClick={() => {
              if (size === 'min') {
                setSize(prevSize);
                Cookies.set(title + 'Size', prevSize);
              } else {
                setPrevSize(size);
                setSize('min');
                Cookies.set(title + 'Size', 'min');
              }
            }}
          />
        </div>
      </div>
      {data.length > 0 ? (
        <div
          className={`${classes.boardContainer} ${
            size === 'partial'
              ? classes.tablePartial
              : size === 'min'
              ? classes.tableMin
              : ''
          }`}
        >
          <BBITable
            data={data}
            columns={columns}
            compactView={compactView}
            stickyTableHead={false}
          />
        </div>
      ) : (
        <div
          className={`${classes.boardContainer} ${
            size === 'partial'
              ? classes.tablePartial
              : size === 'min'
              ? classes.tableMin
              : ''
          }`}
        >
          {noDataMessage}
        </div>
      )}
    </>
  );
}

export default ResizableTable;
