function DateTimeUTCFormatter(dateTimeObject) {
  // add "Z" for UTC time
  if (!dateTimeObject) return null;
  if (!dateTimeObject.endsWith('Z')) {
    dateTimeObject += 'Z';
  }
  const dateTime = new Date(dateTimeObject);
  if (!dateTime) {
    return null;
  }
  const dateString = dateTime.toLocaleDateString();
  //empty array being passed in to defer to users default locale
  const timeString = dateTime.toLocaleTimeString([], {
    hour: 'numeric',
    minute: '2-digit',
  });
  return `${dateString} ${timeString}`;
}

export default DateTimeUTCFormatter;
