import React, { useState } from 'react';
import type { ComponentPropsWithoutRef } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';

import classes from './SelectMultiple.module.css';
import Select from '../../assets/essentials/Select';
import OptionList from '../options/OptionList';

interface SelectMultipleProps extends ComponentPropsWithoutRef<'input'> {
  items: Array<GenericItem>;
  setItems: Function;
  optionData: any[];
  attributeID: string;
  attributeName: string;
  attributeGroup?: string;
  submitButtonId?: string;
}

export default function SelectMultiple({
  items,
  setItems,
  submitButtonId,
  optionData,
  attributeID,
  attributeName,
  attributeGroup,
}: SelectMultipleProps) {
  //Props items/setItems are holding the state in the parent component, where the backend call is made
  //The submit button in the form needs a uniqueId to be passed down to enable save changes button
  const [value, setValue] = useState<string>('');
  const [error, setError] = useState<string>(null);

  const HandleChange = e => {
    const item = {
      id: e.target.value,
      name: e.target.selectedOptions[0].textContent,
    };
    if (IsValid(item)) {
      items.push(item);
      setItems(items);
    }
    setValue('');
  };

  const HandleDelete = (idToRemove: string | number) => {
    setError(null);
    const filteredItems = items.filter(item => item.id !== idToRemove);
    setItems(filteredItems);
    EnableSubmitButton(submitButtonId);
  };

  //via prop from parent holding Form component, removes disabled when inputs aren't changed ex: delete, pasting
  const EnableSubmitButton = (submitButtonId: string) => {
    if (submitButtonId) {
      const submitButton = document.getElementById(submitButtonId);
      submitButton.removeAttribute('disabled');
    }
  };

  const IsValid = (newItem: GenericItem) => {
    if (items.some(item => item.id === newItem.id)) {
      setError(`${newItem.name} has already been added.`);
      return false;
    } else if (newItem.id === null) {
      setError(null);
      return false;
    }
    setError(null);
    return true;
  };

  return (
    <>
      <Select onChange={HandleChange} value={value} key={value}>
        <option value="" />
        <OptionList
          optionData={optionData}
          attributeID={attributeID}
          attributeName={attributeName}
          attributeGroup={attributeGroup}
        />
      </Select>
      {error && <p className={classes.error}>{error}</p>}
      <div key={items.length} className={classes.assetListHolder}>
        {items?.map(item => (
          <div key={item.id} className={classes.tagItem}>
            {item.name}
            <AiOutlineCloseCircle
              className={classes.deleteIcon}
              onClick={() => HandleDelete(item.id)}
              key={item.id}
            />
          </div>
        ))}
      </div>
    </>
  );
}
