import React, { useState } from 'react';
import { TrailerTireDiagram } from '../../../assets/TrailerTireDiagram';
import { TruckTireDiagram } from '../../../assets/TruckTireDiagram';
import { MaintenanceLog } from './MaintenanceLog';
import classes from './Tires.module.css';
import Input from '../../../assets/essentials/Input';

interface TiresProps {
  log: MaintenanceLog;
  assetType: 'truck' | 'trailer';
  UpdateLog: Function;
}

function Tires({ log, UpdateLog, assetType }: TiresProps) {
  // Calculate the total cost of tires based on the asset type
  function sumTireCosts(
    log: MaintenanceLog,
    assetType: 'truck' | 'trailer',
  ): number {
    const fields = assetType === 'truck' ? truckTireFields : trailerTireFields;

    const total = fields.reduce((sum, field) => {
      const cost = log[field.id];
      return sum + (typeof cost === 'number' ? cost : 0);
    }, 0);

    return Number(total.toFixed(2));
  }

  // Define tire fields for trucks
  const truckTireFields = [
    {
      id: 'truckDriverSideSteerTireCost',
      label: 'Driver Side Steer',
      class: 'dss',
    },
    {
      id: 'truckPassengerSideSteerTireCost',
      label: 'Passenger Side Steer',
      class: 'pss',
    },
    {
      id: 'truckFrontAxleDriverSideOuterTireCost',
      label: 'Front Axle Driver Side Outer',
      class: 'fadso',
    },
    {
      id: 'truckFrontAxleDriverSideInnerTireCost',
      label: 'Front Axle Driver Side Inner',
      class: 'fadsi',
    },
    {
      id: 'truckFrontAxlePassengerSideInnerTireCost',
      label: 'Front Axle Passenger Side Inner',
      class: 'fapsi',
    },
    {
      id: 'truckFrontAxlePassengerSideOuterTireCost',
      label: 'Front Axle Passenger Side Outer',
      class: 'fapso',
    },
    {
      id: 'truckRearAxleDriverSideOuterTireCost',
      label: 'Rear Axle Driver Side Outer',
      class: 'radso',
    },
    {
      id: 'truckRearAxleDriverSideInnerTireCost',
      label: 'Rear Axle Driver Side Inner',
      class: 'radsi',
    },
    {
      id: 'truckRearAxlePassengerSideInnerTireCost',
      label: 'Rear Axle Passenger Side Inner',
      class: 'rapsi',
    },
    {
      id: 'truckRearAxlePassengerSideOuterTireCost',
      label: 'Rear Axle Passenger Side Outer',
      class: 'rapso',
    },
  ];

  // Component for rendering truck tire costs
  const TruckTireCosts = () => {
    const [totalCost, setTotalCost] = useState(sumTireCosts(log, assetType));

    const handleInputChange = (
      e: React.ChangeEvent<HTMLInputElement>,
      field: string,
    ) => {
      UpdateLog(Number(e.target.value), field);
      setTotalCost(sumTireCosts(log, assetType));
    };

    return (
      <>
        <h3>Truck Tire Costs</h3>
        <div className={classes.truckCostsContainer}>
          <div className={classes.truckInputContainer}>
            {truckTireFields.map(field => (
              <div key={field.id} className={classes[field.class]}>
                <label htmlFor={field.id}>{field.label}</label>
                <Input
                  id={field.id}
                  type="number"
                  defaultValue={log[field.id] ?? ''}
                  onChange={e => handleInputChange(e, field.id)}
                  min={0}
                  step={0.01}
                />
              </div>
            ))}
          </div>
          <div className={classes.truckDiagramContainer}>
            <TruckTireDiagram />
          </div>
          <strong>
            Tires Total:{' '}
            {totalCost.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </strong>
        </div>
      </>
    );
  };

  // Define tire fields for trailers
  const trailerTireFields = [
    {
      id: 'trailerFrontAxleDriverSideOuterTireCost',
      label: 'Front Axle Driver Side Outer',
      class: 'fadso',
    },
    {
      id: 'trailerFrontAxleDriverSideInnerTireCost',
      label: 'Front Axle Driver Side Inner',
      class: 'fadsi',
    },
    {
      id: 'trailerFrontAxlePassengerSideInnerTireCost',
      label: 'Front Axle Passenger Side Inner',
      class: 'fapsi',
    },
    {
      id: 'trailerFrontAxlePassengerSideOuterTireCost',
      label: 'Front Axle Passenger Side Outer',
      class: 'fapso',
    },
    {
      id: 'trailerRearAxleDriverSideOuterTireCost',
      label: 'Rear Axle Driver Side Outer',
      class: 'radso',
    },
    {
      id: 'trailerRearAxleDriverSideInnerTireCost',
      label: 'Rear Axle Driver Side Inner',
      class: 'radsi',
    },

    {
      id: 'trailerRearAxlePassengerSideInnerTireCost',
      label: 'Rear Axle Passenger Side Inner',
      class: 'rapsi',
    },
    {
      id: 'trailerRearAxlePassengerSideOuterTireCost',
      label: 'Rear Axle Passenger Side Outer',
      class: 'rapso',
    },
  ];

  // Component for rendering trailer tire costs
  const TrailerTireCosts = () => {
    const [totalCost, setTotalCost] = useState(sumTireCosts(log, assetType));

    const handleInputChange = (
      e: React.ChangeEvent<HTMLInputElement>,
      field: string,
    ) => {
      UpdateLog(Number(e.target.value), field);
      setTotalCost(sumTireCosts(log, assetType));
    };

    return (
      <>
        <h3>Trailer Tire Costs</h3>
        <div className={classes.trailerCostsContainer}>
          <div className={classes.trailerInputContainer}>
            {trailerTireFields.map(field => (
              <div key={field.id} className={classes[field.class]}>
                <label htmlFor={field.id}>{field.label}</label>
                <Input
                  id={field.id}
                  type="number"
                  defaultValue={log[field.id] ?? ''}
                  onChange={e => handleInputChange(e, field.id)}
                  min={0}
                  step={0.01}
                />
              </div>
            ))}
          </div>
          <div className={classes.trailerDiagramContainer}>
            <TrailerTireDiagram />
          </div>
          <br />
          <strong>
            Tires Total:{' '}
            {totalCost.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
          </strong>
        </div>
      </>
    );
  };

  return (
    <>
      {assetType === 'truck' && <TruckTireCosts />}
      {assetType === 'trailer' && <TrailerTireCosts />}
    </>
  );
}

export default Tires;
