import React, { useState } from 'react';
import { AiOutlineShop } from 'react-icons/ai';

import OptionList from '../../helpers/options/OptionList';
import PostForm from '../../api/internal/PostForm';
import Form from '../../assets/essentials/Form';
import classes from './Assign.module.css';
import classesGlobal from '../../assets/Global.module.css';
import Button from '../../assets/essentials/Button';
import Select from '../../assets/essentials/Select';
import Input from '../../assets/essentials/Input';
import TextArea from '../../assets/essentials/TextArea';

function Driver({
  loadId,
  driverList,
  truckList,
  trailerList,
  RefreshPage,
  GetLoad,
}: {
  loadId: number;
  driverList: Array<DriverOption>;
  truckList: Array<TruckOption>;
  trailerList: Array<TrailerOption>;
  RefreshPage: Function;
  GetLoad: Function;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [assetAssignment, setAssetAssignment] = useState<DriverAssignment>({
    driverId: null,
    driverName: null,
    truckId: null,
    trailerId: null,
    truckNumber: null,
    trailerNumber: null,
    phone: null,
    note: null,
  });
  function AssignDriver(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    PostForm(`/Load/CreateAssetAssignment`, formData, {
      loadId: loadId,
    }).then(() => {
      GetLoad();
      RefreshPage('Summary', ['Audit', 'Itinerary']);
    });
    setIsOpen(false);
  }

  const HandleDriverChange = e => {
    const currentSelectedDriver = driverList.find(
      driver => driver.driverId === parseInt(e.target.value),
    );
    const currentSelectedTruck = truckList.find(
      truck => truck.truckId === currentSelectedDriver?.preferredTruckId,
    );
    setAssetAssignment(prevAssignment => ({
      ...prevAssignment,
      driverId: currentSelectedDriver.driverId,
      driverName: currentSelectedDriver.name,
      phone: currentSelectedDriver.phone,
      truckId: currentSelectedDriver?.preferredTruckId,
      truckNumber: currentSelectedTruck?.truckNumber,
    }));
  };

  const HandleTruckChange = e => {
    const currentSelectedTruck = truckList.find(
      truck => truck.truckId === parseInt(e.target.value),
    );
    setAssetAssignment(prevAssignment => ({
      ...prevAssignment,
      truckId: currentSelectedTruck.truckId,
      truckNumber: currentSelectedTruck.truckNumber,
    }));
  };

  const HandleTrailerChange = e => {
    const currentSelectedTrailer = trailerList.find(
      trailer => trailer.trailerId === parseInt(e.target.value),
    );
    setAssetAssignment(prevAssignment => ({
      ...prevAssignment,
      trailerId: currentSelectedTrailer?.trailerId,
      trailerNumber: currentSelectedTrailer?.trailerNumber ?? '',
    }));
  };

  const HandleInputChange = e => {
    const { name, value } = e.target;
    setAssetAssignment(prevAssignment => ({
      ...prevAssignment,
      [name]: value,
    }));
  };
  return (
    <>
      <div className={classes.btnHolder}>
        <button
          type="button"
          onClick={() => {
            if (!isOpen) {
              setAssetAssignment({} as DriverAssignment);
            }
            setIsOpen(!isOpen);
          }}
        >
          <AiOutlineShop />
          <span>Assign Driver</span>
        </button>
      </div>
      <div className={`${classes.expandable} ${classes.driver}`}>
        {isOpen && (
          <Form onSubmit={e => AssignDriver(e)}>
            <div className={classesGlobal.attribute}>
              <label>
                Driver <span className={classesGlobal.required}>*</span>
              </label>
              <Select
                required
                defaultValue=""
                onChange={HandleDriverChange}
                name="driverId"
              >
                <option value="" disabled>
                  -- Required --
                </option>
                <OptionList
                  optionData={driverList}
                  attributeID="driverId"
                  attributeName="driverName"
                />
              </Select>
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>Phone</label>
              <Input
                type="text"
                value={assetAssignment.phone}
                name="phone"
                disabled
              />
            </div>
            <div className={classesGlobal.attribute}>
              <label>
                Truck <span className={classesGlobal.required}>*</span>
              </label>
              <Select
                required
                key={assetAssignment.truckId}
                defaultValue={assetAssignment.truckId}
                onChange={HandleTruckChange}
                name="truckId"
              >
                <option value="" hidden>
                  -- Required --
                </option>
                <OptionList
                  optionData={truckList}
                  attributeID="truckId"
                  attributeName="description"
                />
              </Select>
            </div>
            {true && (
              <div
                className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
              >
                <label>Truck License Plate #</label>
                <Input
                  value={assetAssignment.truckNumber ?? ''}
                  name="truckNumber"
                  disabled
                />
              </div>
            )}
            <div className={classesGlobal.attribute}>
              <label>Trailer</label>
              <Select
                defaultValue=""
                onChange={HandleTrailerChange}
                name="trailerId"
              >
                <option value="" />
                <OptionList
                  optionData={trailerList}
                  attributeID="trailerId"
                  attributeName="description"
                />
              </Select>
            </div>
            {true && (
              <div
                className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
              >
                <label>Trailer License Plate #</label>
                <Input
                  value={assetAssignment.trailerNumber}
                  name="trailerNumber"
                  disabled
                />
              </div>
            )}
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span3}`}
            >
              <label>Notes</label>
              <TextArea name="note" onChange={HandleInputChange} />
            </div>
            <div>
              <Button type="submit" variant="good" disabled={true}>
                Submit
              </Button>
            </div>
            <div>
              <Button
                type="button"
                onClick={() => {
                  setAssetAssignment({} as DriverAssignment);
                  setIsOpen(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </Form>
        )}
      </div>
    </>
  );
}

export default Driver;
