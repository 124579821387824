import React from 'react';
import type { ComponentPropsWithoutRef } from 'react';
import classes from './Button.module.css';
import { LoadingSpinner } from '../../helpers/loadingSpinner/LoadingSpinner';
import { IoMdCheckmark } from 'react-icons/io';

/**
 * Button component with built-in status indicators.
 *
 * Extends basic button functionality to include various states
 * such as loading, success, and failure. It maintains its size while changing states.
 *
 * Note: To automatically reset the button status to 'idle' after a success or failure state,
 * use the `useTimeout` custom hook in the parent component.
 *
 * @param {string} [variant] - Button color variant ('good' or 'bad').
 * @param {React.ReactNode} children - Button text content.
 * @param {boolean} [isLoading=false] - Deprecated. Use 'status' prop instead.
 * @param {ButtonStatus} [status='idle'] - Current button state ('idle', 'pending', 'success', or 'failure').
 * @returns {JSX.Element} Styled button with appropriate status indicator.
 *
 * @example
 * import useTimeout from './useTimeout';
 *
 * function ParentComponent() {
 *   const [buttonStatus, setButtonStatus] = useState('idle');
 *
 *   useTimeout(() => {
 *     if (buttonStatus === 'success' || buttonStatus === 'failure') {
 *       setButtonStatus('idle');
 *     }
 *   }, 5000);
 *
 *   const handleSubmit = () => {
 *     setButtonStatus('pending');
 *     // ... perform action ...
 *     // Then set status to 'success' or 'failure'
 *   };
 *
 *   return (
 *     <Button
 *       variant='good'
 *       status={buttonStatus}
 *       onClick={handleSubmit}
 *       disabled={buttonStatus !== 'idle'}
 *     >
 *       Save Changes
 *     </Button>
 *   );
 * }
 */

export type ButtonStatus = 'pending' | 'success' | 'failure' | 'idle';

interface ButtonProps extends ComponentPropsWithoutRef<'button'> {
  variant?: string;
  isLoading?: boolean;
  status?: ButtonStatus;
  children: React.ReactNode;
}

function Button({
  variant,
  children,
  isLoading = false,
  status = 'idle',
  ...rest
}: ButtonProps) {
  return (
    <button className={`${classes.button} ${classes[variant]}`} {...rest}>
      <span
        className={
          isLoading || status === 'pending' || status === 'success'
            ? classes.hiddenText
            : ''
        }
      >
        {children}
      </span>
      {isLoading || status === 'pending' ? <LoadingSpinner /> : null}
      {status === 'success' ? (
        <IoMdCheckmark className={classes.success} />
      ) : null}
    </button>
  );
}

export default Button;
