import React from 'react';
import classes from '../../../assets/Global.module.css';

function Summary({ HandlePageChange, data } : {
  data: LocationSummary;
  HandlePageChange: Function;
}) {
  return (
    <div className={classes.viewGrid}>
      <div className={classes.attribute}>
        <h3>Address</h3>
        {data?.addressDisplayList.map((line, index) => (
          <span key={index}>{line}</span>
        ))}
        <div
          className={classes.cTA}
          onClick={() => HandlePageChange('Details')}
        >
          View Details
        </div>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <h3>Contact</h3>
        {data?.contactDisplayList.map((line, index) => (
          <span key={index}>{line}</span>
        ))}
      </div>
      <div className={classes.attribute}>
        <h3>Hours</h3>
        {data?.hoursDisplayList.map((line, index) => (
          <span key={index}>{line}</span>
        ))}
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <h3>Website</h3>
        {data?.website !== 'None' ? (
          <a href={data?.website} target="_blank" rel="noopener noreferrer">
            <u>{data?.website}</u>
          </a>
        ) : (
          <span>{data?.website}</span>
        )}
      </div>
      <div className={classes.attribute}>
        <h3>Services</h3>
        {data?.servicesDisplayList.map((line, index) => (
          <span key={index}>{line}</span>
        ))}
        <div
          className={classes.cTA}
          onClick={() => HandlePageChange('Services')}
        >
          View Services
        </div>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <h3>Coordinates</h3>
        {data?.coordinatesDisplayList.map((line, index) => (
          <span key={index}>{line}</span>
        ))}
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <h3>Directions</h3>
        <span>{data?.directions}</span>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <h3>Special Instructions</h3>
        <span>{data?.specialInstructions}</span>
      </div>
    </div>
  );
}
export default Summary;
