import React from 'react';
import classes from './Details.module.css';
import classesGlobal from '../../../../assets/Global.module.css';
import Input from '../../../../assets/essentials/Input';
import Select from '../../../../assets/essentials/Select';
import OptionList from '../../../../helpers/options/OptionList';
import Button from '../../../../assets/essentials/Button';
import PutForm from '../../../../api/internal/PutForm';
import Form from '../../../../assets/essentials/Form';
import TextArea from '../../../../assets/essentials/TextArea';

interface MaintenanceLogDetailsProps {
  maintenanceLogId: number;
  data: MaintenanceLogDetails;
  RefreshPage: Function;
}

function Details({
  maintenanceLogId,
  data,
  RefreshPage,
}: MaintenanceLogDetailsProps) {
  function UpdateMaintenanceLogDetails(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const appendix = {
      maintenanceLogId: maintenanceLogId,
    };

    PutForm(`/Asset/UpdateMaintenanceLogDetails`, formData, appendix).then(() =>
      RefreshPage('Details', ['Summary', 'Audit']),
    );
  }

  function getAssetType(assetId: number | string) {
    return data.assetOptions
      .find(option => option.assetId === Number(assetId))
      ?.group.toLowerCase() === 'truck'
      ? 'truck'
      : 'trailer';
  }

  const assetType = getAssetType(data.assetId);

  const truckOptions = data.assetOptions.filter(
    option => option.group === 'Truck',
  );
  const trailerOptions = data.assetOptions.filter(
    option => option.group === 'Trailer',
  );

  const truckTireFields = [
    {
      id: 'truckDriverSideSteerTireCost',
      label: 'Driver Side Steer',
      className: classesGlobal.span2,
    },
    {
      id: 'truckPassengerSideSteerTireCost',
      label: 'Passenger Side Steer',
      className: classesGlobal.span2,
    },
    {
      id: 'truckFrontAxleDriverSideOuterTireCost',
      label: 'Front Axle Driver Side Outer',
    },
    {
      id: 'truckFrontAxleDriverSideInnerTireCost',
      label: 'Front Axle Driver Side Inner',
    },
    {
      id: 'truckFrontAxlePassengerSideInnerTireCost',
      label: 'Front Axle Passenger Side Inner',
    },
    {
      id: 'truckFrontAxlePassengerSideOuterTireCost',
      label: 'Front Axle Passenger Side Outer',
    },
    {
      id: 'truckRearAxleDriverSideOuterTireCost',
      label: 'Rear Axle Driver Side Outer',
    },
    {
      id: 'truckRearAxleDriverSideInnerTireCost',
      label: 'Rear Axle Driver Side Inner',
    },
    {
      id: 'truckRearAxlePassengerSideInnerTireCost',
      label: 'Rear Axle Passenger Side Inner',
    },
    {
      id: 'truckRearAxlePassengerSideOuterTireCost',
      label: 'Rear Axle Passenger Side Outer',
    },
  ];

  const TruckInputs = () => {
    return (
      <>
        {truckTireFields.map(field => (
          <div key={field.id} className={`${field.className || ''} `}>
            <label htmlFor={field.id}>{field.label}</label>
            <Input
              id={field.id}
              name={field.id}
              type="number"
              defaultValue={data[field.id] ?? ''}
              min={0}
              step={0.01}
            />
          </div>
        ))}
      </>
    );
  };

  const trailerTireFields = [
    {
      id: 'trailerFrontAxleDriverSideOuterTireCost',
      label: 'Front Axle Driver Side Outer',
    },
    {
      id: 'trailerFrontAxleDriverSideInnerTireCost',
      label: 'Front Axle Driver Side Inner',
    },
    {
      id: 'trailerFrontAxlePassengerSideInnerTireCost',
      label: 'Front Axle Passenger Side Inner',
    },
    {
      id: 'trailerFrontAxlePassengerSideOuterTireCost',
      label: 'Front Axle Passenger Side Outer',
    },
    {
      id: 'trailerRearAxleDriverSideOuterTireCost',
      label: 'Rear Axle Driver Side Outer',
    },
    {
      id: 'trailerRearAxleDriverSideInnerTireCost',
      label: 'Rear Axle Driver Side Inner',
    },

    {
      id: 'trailerRearAxlePassengerSideInnerTireCost',
      label: 'Rear Axle Passenger Side Inner',
    },
    {
      id: 'trailerRearAxlePassengerSideOuterTireCost',
      label: 'Rear Axle Passenger Side Outer',
    },
  ];

  const TrailerInputs = () => {
    return (
      <>
        {trailerTireFields.map(field => (
          <div key={field.id}>
            <label htmlFor={field.id}>{field.label}</label>
            <Input
              id={field.id}
              name={field.id}
              type="number"
              defaultValue={data[field.id] ?? ''}
              min={0}
              step={0.01}
            />
          </div>
        ))}
      </>
    );
  };

  return (
    <Form onSubmit={e => UpdateMaintenanceLogDetails(e)}>
      <div className={`${classesGlobal.attribute}`}>
        <label htmlFor="invoiceNumber">
          Invoice Number <span className={classesGlobal.required}>*</span>
        </label>
        <Input
          defaultValue={data.invoiceNumber}
          id="invoiceNumber"
          name="invoiceNumber"
          required
        />
      </div>
      <div className={`${classesGlobal.attribute} ${classesGlobal.span2}`}>
        <h2>
          Total:{' '}
          {data.totalCost.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </h2>
      </div>
      <div className={`${classesGlobal.attribute}`}>
        <label htmlFor="assetId">
          {assetType === 'truck' ? 'Truck' : 'Trailer'}
        </label>
        <Select defaultValue={data.assetId} id="assetId" name="assetId">
          <OptionList
            optionData={assetType === 'truck' ? truckOptions : trailerOptions}
            attributeID="assetId"
            attributeName="name"
          />
        </Select>
      </div>
      <div className={`${classesGlobal.attribute}`}>
        <label htmlFor="vendorId">Vendor</label>
        <Select defaultValue={data.vendorId} id="vendorId" name="vendorId">
          <option value=''>-- No Vendor --</option>
          <OptionList
            optionData={data.vendorOptions}
            attributeID="vendorId"
            attributeName="name"
          />
        </Select>
      </div>
      <div className={`${classesGlobal.attribute} ${classesGlobal.span3}`}>
        <label htmlFor="description">Description</label>
        <TextArea
          defaultValue={data.description}
          id="description"
          name="description"
        />
      </div>
      <div className={`${classesGlobal.attribute} ${classesGlobal.span3}`}>
        <label htmlFor="billDate">Bill Date</label>
        <Input
          defaultValue={
            data.billDate
              ? new Date(data.billDate).toISOString().split('T')[0]
              : ''
          }
          id="billDate"
          name="billDate"
          type="date"
        />
      </div>
      <div className={`${classesGlobal.attribute} ${classesGlobal.span2}`}>
        <label htmlFor="isBillingBack">Billing Back</label>
        <Select
          defaultValue={data.isBillingBack ? 'true' : 'false'}
          id="isBillingBack"
          name="isBillingBack"
        >
          <option value="true">Yes</option>
          <option value="false">No</option>
        </Select>
      </div>
      <div className={`${classesGlobal.attribute} ${classesGlobal.span3}`}>
        <label htmlFor="laborCost">Labor Cost</label>
        <Input
          defaultValue={data.laborCost}
          id="laborCost"
          name="laborCost"
          type="number"
          min={0}
          step={0.01}
        />
      </div>
      <div className={`${classesGlobal.attribute} ${classesGlobal.span3}`}>
        <label htmlFor="partsCost">Parts Cost</label>
        <Input
          defaultValue={data.partsCost}
          id="partsCost"
          name="partsCost"
          type="number"
          min={0}
          step={0.01}
        />
      </div>
      <div className={`${classesGlobal.attribute} ${classesGlobal.span3}`}>
        <label htmlFor="roadsideCost">Roadside Cost</label>
        <Input
          defaultValue={data.roadsideCost}
          id="roadsideCost"
          name="roadsideCost"
          type="number"
          min={0}
          step={0.01}
        />
      </div>
      <div className={`${classesGlobal.attribute} ${classesGlobal.span3}`}>
        <label htmlFor="miscellaneousCost">Misc Cost</label>
        <Input
          defaultValue={data.miscellaneousCost}
          id="miscellaneousCost"
          name="miscellaneousCost"
          type="number"
          min={0}
          step={0.01}
        />
      </div>
      <div className={`${classesGlobal.attribute} ${classesGlobal.span3}`}>
        <label htmlFor="taxesCost">Taxes Cost</label>
        <Input
          defaultValue={data.taxesCost}
          id="taxesCost"
          name="taxesCost"
          type="number"
          min={0}
          step={0.01}
        />
      </div>
      <div className={`${classesGlobal.attribute} ${classesGlobal.span3}`}>
        <h3>Tires Cost</h3>
      </div>
      <div className={classes.subGrid}>
        {assetType === 'truck' ? <TruckInputs /> : <TrailerInputs />}
      </div>
      <div>
        <Button type="submit" variant="good" disabled={true}>
          Save Changes
        </Button>
      </div>
    </Form>
  );
}
export default Details;
