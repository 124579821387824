import React, { useState } from 'react';
import classes from '../../assets/Global.module.css';
import Header from '../../layouts/Header';
import Create from '../../helpers/panel/Create';
import CreateVendor from '../../features/templates/vendor/Vendor';
import { AiOutlineShop } from 'react-icons/ai';
import CreatePayable from '../../features/creates/payable/Payable';
import { GiPayMoney } from 'react-icons/gi';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { RiListCheck } from 'react-icons/ri';
import { VscSearch } from 'react-icons/vsc';
import Filter from '../../helpers/filter/Filter';

const Vendor = () => {
  const [filterValue, setFilterValue] = useState('');

  const navigate = useNavigate();
  const pathName = useLocation().pathname.split('/').slice(-1)[0];

  return (
    <>
      <h1>Vendor</h1>
      <Header>
        <div>
          <button onClick={() => Create(<CreateVendor />)}>
            <AiOutlineShop />
            <span>Create a Vendor</span>
          </button>
          <button onClick={() => Create(<CreatePayable GetPayables={null} />)}>
            <GiPayMoney />
            <span>Create Payables</span>
          </button>

          <button
            onClick={() => navigate('/vendor')}
            className={pathName === 'vendor' ? classes.buttonActive : ''}
          >
            <RiListCheck />
            <span>Vendor Board</span>
          </button>
          <button
            onClick={() => navigate('/vendor/search')}
            className={pathName === 'search' ? classes.buttonActive : ''}
          >
            <VscSearch />
            <span>Search Vendors</span>
          </button>
        </div>
        <div>
          <Filter
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            placeholder="Filter vendors"
          />
        </div>
      </Header>
      <Outlet context={filterValue} />
    </>
  );
};

export default Vendor;
