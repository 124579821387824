import React, { useState, useEffect, useRef } from 'react';
import { CgProfile } from 'react-icons/cg';
import { pca } from '..';
import classes from './ProfileMenu.module.css';
import Button from '../assets/essentials/Button';

function ProfileMenu() {
  const [menuVisible, setMenuVisible] = useState(false);
  const menuRef = useRef();

  const account = pca.getAllAccounts()[0];

  const handleLogout = () => {
    pca.logoutRedirect({
      account: account,
      authority: process.env.REACT_APP_AAD_AUTHORITY_URL,
      mainWindowRedirectUri: process.env.REACT_APP_URL
    });
    setMenuVisible(false);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenuVisible(false);
    }
  };

  const toggleMenu = (e) => {
    e.stopPropagation();
    setMenuVisible(!menuVisible);
  };

  useEffect(() => {
    if (menuVisible) {
      window.addEventListener('click', handleClickOutside);
    }

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [menuVisible]);

  return (
    <div className={classes.iconHolder} ref={menuRef}>
      <CgProfile
        className={classes.profileIcon}
        onClick={toggleMenu}
      />
      {menuVisible && (
        <div className={classes.menu}>
          <h2>{account.name}</h2>
          <h4>{account.username}</h4>
          <Button onClick={handleLogout} variant="good">
            Log out
          </Button>
        </div>
      )}
    </div>
  );
}

export default ProfileMenu;
