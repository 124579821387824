import React from 'react';
import classes from '../../../../assets/Global.module.css';

function Summary({
  data,
  HandlePageChange,
}: {
  data: TrailerSummary,
  HandlePageChange: Function,
}) {
  return (
    <div className={classes.viewGrid}>
      <div className={classes.attribute}>
        <h3>Make</h3>
        {data?.make}
        <div
          className={classes.cTA}
          onClick={() => HandlePageChange('Details')}
        >
          Manage Trailer Info
        </div>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <h3>License Plate</h3>
        {data?.licensePlateDisplayList.map((line, index) => (
          <span key={index}>{line}</span>
        ))}
      </div>
      <div className={classes.attribute}>
        <h3>Type</h3>
        <span>{data?.trailerType}</span>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <h3>VIN</h3>
        <span>{data?.vin}</span>
      </div>
      <div className={classes.attribute}>
        <h3>ELD</h3>
        {data?.eldDisplayList.map((line, index) => (
          <span key={index}>{line}</span>
        ))}
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <h3>Mileage</h3>
        {data?.mileageDisplayList.map((line, index) => (
          <span key={index}>{line}</span>
        ))}
      </div>
      <div className={`${classes.span3}`}>
        <h3>Ownership</h3>
        <span>{data.isOwned ? "Owned" : "Leased"}</span>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <h3>Note</h3>
        <span>{data?.note}</span>
      </div>
    </div>
  );
}
export default Summary;
