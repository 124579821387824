import React, { useState, useEffect } from 'react';

import Details from './Details';
import IndividualPayable from './IndividualPayable';
import Panel from '../../../helpers/panel/Panel';
import Get from '../../../api/internal/Get';
import PostBody from '../../../api/internal/PostBody';
import Close from '../../../helpers/panel/Close';
import View from '../../../helpers/slab/View';
import ViewPayable from '../../../features/views/payable/Payable';

function Payable({ GetPayables }: { GetPayables?: Function }) {
  const [refreshPages, setRefreshPages] = useState(false);
  const [createPayablesOptions, setCreatePayablesOptions] =
    useState<CreatePayableOptions>();
  const [createPayablesParam] = useState<CreatePayablesParam>({
    vendorId: null,
    payableList: [],
    note: null,
  });

  function CreateIndividualPayable(
    index: number,
  ): CreateIndividualPayableParam {
    return {
      index: index,
      accountTypeId: null,
      rate: 0,
      quantity: 1,
      note: null,
    };
  }

  useEffect(() => {
    GetOptionsForCreatePayables();
  }, []);

  function GetOptionsForCreatePayables() {
    Get(`Accounting/GetCreatePayablesWithoutLoadOptions/`).then(response => {
      if (response) {
        setCreatePayablesOptions(response.data);
      }
    });
  }

  function CreatePayables() {
    PostBody('Accounting/CreatePayablesWithoutLoad', createPayablesParam).then(
      response => {
        if (response) {
          GetPayables && GetPayables();
          Close();
          View(<ViewPayable vendorAssignmentId={response.data} />);
        }
      },
    );
  }

  let prefixPages = [
    {
      title: 'Details',
      displayedIcon: '',
      content: (
        <Details
          createPayablesParam={createPayablesParam}
          createPayableOptions={createPayablesOptions}
          UpdatePayableListLength={UpdatePayableListLength}
          UpdatePayablesParam={UpdatePayablesParam}
        />
      ),
      require: [
        {
          label: 'Vendor',
          FunctionBool: () => createPayablesParam.vendorId,
          assignedValue: () =>
            createPayablesOptions.vendorOptions.find(
              x => x.vendorId === Number(createPayablesParam.vendorId),
            )?.vendorName,
          missingDesc: 'Vendor has not been assigned.',
        },
        {
          label: 'Number of Payables',
          FunctionBool: () => createPayablesParam.payableList.length !== 0,
          assignedValue: () => createPayablesParam.payableList.length,
          missingDesc: 'No Payables have been added.',
        },
        {
          label: 'Grand Total',
          FunctionBool: () =>
            createPayablesParam.payableList.length !== 0 &&
            createPayablesParam.payableList.some(
              x => x.rate * x.quantity >= 0.01,
            ),
          assignedValue: () => {
            let grandTotal = 0;
            createPayablesParam.payableList.forEach(payable => {
              const total = payable.rate * payable.quantity;
              //Applying toFixed(2) to the total of each Payable before adding them to the grandTotal
              //If the grandTotal is only formatted at the end it will round up/down from however many decimal places
              //deep it is and can differ from what the displayed individual totals add up to.
              grandTotal += parseFloat(total.toFixed(2));
            });
            return new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
            }).format(grandTotal);
          },
          missingDesc: 'Grand Total cannot be $0.00.',
        },
      ],
    },
  ];

  let payablePages = [];
  createPayablesParam.payableList.map((payable, index) =>
    payablePages.push({
      title: 'Payable #' + (index + 1),
      displayedIcon: '',
      content: (
        <IndividualPayable
          createIndividualPayableParam={payable}
          createPayableOptions={createPayablesOptions}
          index={index}
          UpdateParam={UpdateIndividualPayableParam}
        />
      ),
      require: [
        {
          label: 'Type',
          FunctionBool: () =>
            createPayablesParam.payableList[index].accountTypeId,
          assignedValue: () =>
            createPayablesOptions.accountTypeOptions.find(
              x =>
                x.accountTypeId ===
                Number(createPayablesParam.payableList[index].accountTypeId),
            ).type,
          missingDesc: 'Type has not been assigned.',
        },
        {
          label: 'Total',
          FunctionBool: () =>
            createPayablesParam.payableList[index].quantity *
              createPayablesParam.payableList[index].rate >=
            0.01,
          assignedValue: () =>
            new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(
              createPayablesParam.payableList[index].quantity *
                createPayablesParam.payableList[index].rate,
            ),
          missingDesc: 'Total cannot be $0.00.',
        },
      ],
    }),
  );

  let pages = prefixPages.concat(payablePages);
  function OnSubmit() {
    let fulfillsFormRequirements = true;
    pages.forEach((page, index) => {
      if (!FulFillsPageRequirements(index)) {
        fulfillsFormRequirements = false;
      }
    });
    if (fulfillsFormRequirements) {
      CreatePayables();
      Close();
    } else alert('Not all info included');
  }

  function UpdatePayableListLength(newValue) {
    let updateValue = 50;
    if (newValue < 50) {
      updateValue = newValue;
    }
    while (createPayablesParam.payableList.length > updateValue) {
      createPayablesParam.payableList.splice(-1);
    }
    for (let i = 0; i < updateValue; i++) {
      if (updateValue > createPayablesParam.payableList.length) {
        createPayablesParam.payableList.push(CreateIndividualPayable(i));
      }
    }
    setRefreshPages(!refreshPages);
  }

  function UpdatePayablesParam(newValue, attributeName) {
    if (newValue?.toString().trim().length !== 0) {
      createPayablesParam[attributeName] = newValue;
    } else {
      createPayablesParam[attributeName] = null;
    }
  }

  function UpdateIndividualPayableParam(newValue, attributeName, index) {
    if (newValue?.toString().trim().length !== 0) {
      createPayablesParam.payableList[index][attributeName] = newValue;
    } else {
      createPayablesParam.payableList[index][attributeName] = null;
    }
  }

  function FulFillsPageRequirements(pageIndex) {
    let fulfillsRequirements = true;
    pages[pageIndex].require.forEach(requirement => {
      if (!requirement.FunctionBool()) {
        fulfillsRequirements = false;
      }
    });
    return fulfillsRequirements;
  }
  return <Panel OnSubmit={OnSubmit} title="Create Payables" pages={pages} />;
}

export default Payable;
