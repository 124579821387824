import React from 'react';
import { AiFillRightSquare } from 'react-icons/ai';
import classes from './SendLoadToBBIButton.module.css';
import TransportationCaller from '../../api/internal/TransportationCaller';
import Create from '../error/Create';
import Error from '../../helpers/error/Error';

/**
SendLoadToBBIButton component for sending a load to BBI.
This component renders a button that, when clicked, sends a load to BBI. It also handles error states and provides a callback function to refresh the page after sending the load.
@param {string} loadId - The ID of the load to send to BBI.
@param {boolean} isDisabled - Determines if the button is disabled.
@param {Function} RefreshPage - A callback function to refresh the page after sending the load.
@param {string} pageToRefresh - The page to refresh after sending the load.
@param {string[]} pageArrayToRefresh - An array of pages to refresh after sending the load.
@returns {JSX.Element} - The SendLoadToBBIButton component.
@example
// Example usage of SendLoadToBBIButton component:
<SendLoadToBBIButton
loadId="123"
isDisabled={false}
RefreshPage={RefreshPage}
pageToRefresh={'Summary'}
pageArrayToRefresh={['Financials', 'Audit']}
/> */

export default function SendLoadToBBIButton({
  loadId,
  isDisabled,
  RefreshPage,
  pageToRefresh,
  pageArrayToRefresh,
}: {
  loadId: number;
  isDisabled: boolean;
  RefreshPage: Function;
  pageToRefresh: string;
  pageArrayToRefresh: string[];
}) {
  const HandleSendLoadToBBI = () => {
    TransportationCaller.post(`/Load/SendLoadToBBI/${loadId}`)
      .then(response => {
        if (response) {
          RefreshPage(pageToRefresh, pageArrayToRefresh);
        }
      })
      .catch(error => {
        Create(<Error error={error} />);
      });
  };
  return (
    <>
      <button
        onClick={HandleSendLoadToBBI}
        disabled={isDisabled}
        className={classes.button}
      >
        <AiFillRightSquare />
        Send Load to BBI
      </button>
    </>
  );
}
