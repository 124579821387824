import React from 'react';

import classes from '../../../assets/Global.module.css';
import Input from '../../../assets/essentials/Input';
import Select from '../../../assets/essentials/Select';
import TextArea from '../../../assets/essentials/TextArea';
import OptionList from '../../../helpers/options/OptionList';

function AdditionalInfo({
  param,
  requirementDisplay,
  UpdateParam,
  vendorOptions,
}: {
  param: CreateVendorParam;
  requirementDisplay: VendorRequirementDisplay;
  UpdateParam: Function;
  vendorOptions: CreateVendorOptions;
}) {
  return (
    <>
      <h1>Additional Info</h1>
      <div className={classes.viewGrid}>
        <div className={classes.attribute}>
          <label>
            Vendor Type <span className={classes.required}>*</span>
          </label>
          <Select
            defaultValue={param?.vendorTypeId || ''}
            onChange={e => {
              UpdateParam(e.target.value, 'vendorTypeId');
              requirementDisplay.vendorType =
                e.target.selectedOptions[0].textContent;
            }}
          >
            <option disabled value="">
              -- Required --
            </option>
            <OptionList
              optionData={vendorOptions?.vendorTypeOptions}
              attributeID="vendorTypeId"
              attributeName="type"
              attributeGroup="group"
            />
          </Select>
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>Website</label>
          <Input
            defaultValue={param.website}
            onChange={e => UpdateParam(e.target.value, 'website')}
            type="text"
          />
        </div>
        <div className={classes.attribute}>
          <label>Payment Method</label>
          <Select
            defaultValue={param.paymentMethodId}
            onChange={e => {
              UpdateParam(e.target.value, 'paymentMethodId');
              requirementDisplay.paymentMethod =
                e.target.selectedOptions[0].textContent;
            }}
          >
            <OptionList
              optionData={vendorOptions?.paymentMethodOptions}
              attributeID="paymentMethodId"
              attributeName="method"
            />
          </Select>
        </div>
        <div className={classes.attribute}>
          <label>Payment Term</label>
          <Select
            defaultValue={param.paymentTermId}
            onChange={e => {
              UpdateParam(e.target.value, 'paymentTermId');
              requirementDisplay.paymentTerm =
                e.target.selectedOptions[0].textContent;
            }}
          >
            <OptionList
              optionData={vendorOptions?.paymentTermOptions}
              attributeID="paymentTermId"
              attributeName="term"
            />
          </Select>
        </div>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>Vendor Note</label>
          <TextArea
            defaultValue={param.note}
            onChange={e => UpdateParam(e.target.value, 'note')}
            type="text"
          />
        </div>
      </div>
    </>
  );
}

export default AdditionalInfo;
