import React, { useState } from 'react';
import type { ComponentPropsWithoutRef } from 'react';
import {
  AiFillCaretUp,
  AiFillCaretDown,
  AiOutlineUp,
  AiOutlineDown,
} from 'react-icons/ai';

import Resizable from './Resizable';
import classes from './BBITable.module.css';

export interface BBITableInterface {
  data: Object[];
  columns: Array<Column>;
  compactView?: boolean;
  stickyTableHead?: boolean;
}

interface Column extends ComponentPropsWithoutRef<'td'> {
  key: string;
  attribute: string;
  class?: string;
  width?: string;
  header?: string;
  dataType?: string;
  notSortable?: boolean;
  includeInTotal?: boolean;
  attributeprimaryhidden?: string;
  attributesecondaryhidden?: string;
  attributetertiaryhidden?: string;
}
/**
 * A sortable table component with resizable columns.
 *
 * @param {*} data - `Object[]` - An array of objects containing the table data.
 * @param {*} columns - `Array<Column>` - An array of column configurations.
 * @param {*} compactView - `boolean?` - A flag indicating whether to display the table in a compact view. Defaults to false.
 * @param {*} stickyTableHead - `boolean?` - Sets the height of the headers sticky position to the height of the header component. Default to true. Keeps header + tableheader at top of page when scrolling.
 *
 * @returns {*} `JSX.Element` - The JSX element representing the table.
 *
 * @example
 * <BBITable
 * data={customerData}
 * columns={columnConfig}
 * compactView={compactView}
 * />
 *
 * Column configuration for the BBITable component.
 *
 * @param {*} key - `string` - A unique key for the column.
 * @param {*} attribute - `string` - The attribute to be displayed from the data object.
 * @param {*} class - `string?` - Additional CSS class for styling the column.
 * @param {*} width - `string?` - The width of the column.
 * @param {*} header - `string?` - The header text for the column.
 * @param {*} onClick - `Function?` - A function to handle click events on the column.
 * @param {*} dataType - `string?` - The data type of the column (e.g., 'date', 'number', 'currency', 'formattedNumber') - used for sorting.
 * @param {*} notSortable - `boolean?` - A flag to disable sorting rows by the data within the given column.
 * @param {*} attributeprimaryhidden - `string?` - An optional attribute for hidden data.
 * @param {*} attributesecondaryhidden - `string?` - An optional attribute for secondary hidden data.
 * @param {*} attributetertiaryhidden - `string?` - From the makers of attributeprimaryhidden and attributesecondaryhidden.
 *
 * @example
 *   let columnConfig = [
    {
      key: '1',
      attribute: 'customerName',
      attributeprimaryhidden: 'customerId',
      header: 'Customer',
      onClick: e =>
        e.target.dataset.attributeprimaryhidden &&
        View(
          <ViewCustomer
            customerName={e.target.innerText}
            customerId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '2',
      attribute: 'customerPhone',
      header: 'Phone',
    },
    {
      key: '3',
      attribute: 'customerAddress',
      header: 'Address',
    },
  ];
 */

function BBITable({
  data,
  columns,
  compactView,
  stickyTableHead = true,
}: BBITableInterface) {
  const [sortKeyPrimary, setSortKeyPrimary] = useState<string>('');
  const [sortDirectionPrimary, setSortDirectionPrimary] = useState<string>('');
  const [sortKeySecondary, setSortKeySecondary] = useState<string>('');
  const [sortDirectionSecondary, setSortDirectionSecondary] =
    useState<string>('');
  const [columnWidths, setColumnWidths] = useState<object>({});
  function HandleResizeStop({ size, index }) {
    setColumnWidths(prevWidths => ({ ...prevWidths, [index]: size?.width }));
  }

  function HandleSort(column: Column, event) {
    if (
      event.shiftKey &&
      sortKeyPrimary &&
      column.attribute !== sortKeyPrimary
    ) {
      if (sortKeySecondary === column.attribute) {
        setSortDirectionSecondary(
          sortDirectionSecondary === 'asc' ? 'desc' : 'asc',
        );
      } else {
        setSortKeySecondary(column.attribute);
        setSortDirectionSecondary('desc');
      }
    } else {
      setSortKeySecondary('');
      setSortDirectionSecondary('');
      if (sortKeyPrimary === column.attribute) {
        setSortDirectionPrimary(
          sortDirectionPrimary === 'asc' ? 'desc' : 'asc',
        );
      } else {
        setSortKeyPrimary(column.attribute);
        setSortDirectionPrimary('desc');
      }
    }
  }

  const sortedData = React.useMemo(() => {
    if (!sortKeyPrimary && !sortDirectionSecondary) {
      return data;
    } else if (sortKeySecondary) {
      return SingleSort(
        sortKeyPrimary,
        sortDirectionPrimary,
        SingleSort(sortKeySecondary, sortDirectionSecondary, data),
      );
    } else {
      return SingleSort(sortKeyPrimary, sortDirectionPrimary, data);
    }
  }, [
    data,
    sortKeyPrimary,
    sortDirectionPrimary,
    sortKeySecondary,
    sortDirectionSecondary,
  ]);

  function SingleSort(sortKeyValue, sortDirectionValue, sortableData) {
    return sortableData.slice().sort((a, b) => {
      const aValue = a[sortKeyValue];
      const bValue = b[sortKeyValue];
      let sortColumnDataType = 'string';

      columns.forEach(function (column) {
        if (column.attribute === sortKeyValue) {
          sortColumnDataType = column.dataType;
        }
      });

      if (aValue === null || aValue === undefined) return 1;
      if (bValue === null || bValue === undefined) return -1;

      if (sortColumnDataType === 'date') {
        if (sortDirectionValue === 'desc') {
          let aDate = new Date(
            aValue.split('-')[0].split(' ').length > 1
              ? aValue.split('-')[0].split(' ')[0] +
                ' ' +
                aValue.split('-')[0].split(' ')[1] +
                ' ' +
                aValue.split('-')[0].split(' ')[2]
              : aValue.split('-')[0].split(' ')[0],
          );
          let bDate = new Date(
            bValue.split('-')[0].split(' ').length > 1
              ? bValue.split('-')[0].split(' ')[0] +
                ' ' +
                bValue.split('-')[0].split(' ')[1] +
                ' ' +
                bValue.split('-')[0].split(' ')[2]
              : bValue.split('-')[0].split(' ')[0],
          );
          if (aDate < bDate) return -1;
          if (aDate > bDate) return 1;
        } else {
          if (aValue === null) return -1;
          if (bValue === null) return 1;
          let aDate = new Date(
            aValue.split('-')[0].split(' ').length > 1
              ? aValue.split('-')[0].split(' ')[0] +
                ' ' +
                aValue.split('-')[0].split(' ')[1] +
                ' ' +
                aValue.split('-')[0].split(' ')[2]
              : aValue.split('-')[0].split(' ')[0],
          );
          let bDate = new Date(
            bValue.split('-')[0].split(' ').length > 1
              ? bValue.split('-')[0].split(' ')[0] +
                ' ' +
                bValue.split('-')[0].split(' ')[1] +
                ' ' +
                bValue.split('-')[0].split(' ')[2]
              : bValue.split('-')[0].split(' ')[0],
          );
          if (aDate > bDate) return -1;
          if (aDate < bDate) return 1;
        }
      } else if (sortColumnDataType === 'number') {
        if (sortDirectionValue === 'desc') {
          if (Number(aValue) < Number(bValue)) return -1;
          if (Number(aValue) > Number(bValue)) return 1;
        } else {
          if (Number(aValue) > Number(bValue)) return -1;
          if (Number(aValue) < Number(bValue)) return 1;
        }
      } else if (
        (sortColumnDataType === 'currency' ||
          sortColumnDataType === 'formattedNumber') &&
        (typeof aValue === 'string' || aValue === null) &&
        (typeof bValue === 'string' || bValue === null)
      ) {
        if (sortDirectionValue === 'desc') {
          if (aValue === null) return 1;
          if (bValue === null) return -1;
          if (
            parseFloat(aValue.replace(/[$,]/g, '')) <
            parseFloat(bValue.replace(/[$,]/g, ''))
          )
            return -1;
          if (
            parseFloat(aValue.replace(/[$,]/g, '')) >
            parseFloat(bValue.replace(/[$,]/g, ''))
          )
            return 1;
        } else {
          if (aValue === null) return -1;
          if (bValue === null) return 1;
          if (
            parseFloat(aValue.replace(/[$,]/g, '')) >
            parseFloat(bValue.replace(/[$,]/g, ''))
          )
            return -1;
          if (
            parseFloat(aValue.replace(/[$,]/g, '')) <
            parseFloat(bValue.replace(/[$,]/g, ''))
          )
            return 1;
        }
      } else {
        if (sortDirectionValue === 'desc') {
          if (aValue < bValue) return -1;
          if (aValue > bValue) return 1;
        } else {
          if (aValue > bValue) return -1;
          if (aValue < bValue) return 1;
        }
      }
      return 0;
    });
  }

  let totals = {};
  let maxDecimalPlaces = 0;

  sortedData?.forEach(row => {
    columns.forEach(column => {
      if (column.includeInTotal) {
        // Parse currency string to number
        const value = parseFloat(
          row[column.attribute].toString().replace(/\$|,/g, ''),
        );
        if (column.attribute === 'marginPercentage') {
          totals[column.attribute] =
            (parseFloat(totals['margin'].toString().replace(/\$|,/g, '')) /
              parseFloat(totals['revenue'].toString().replace(/\$|,/g, ''))) *
              100 || 0;
        } else {
          totals[column.attribute] = (totals[column.attribute] || 0) + value;
        }

        // Update maxDecimalPlaces if necessary
        let decimalPlaces = (value.toString().split('.')[1] || '').length;
        maxDecimalPlaces = Math.max(maxDecimalPlaces, decimalPlaces);
      }
    });
  });

  // Convert totals to fixed decimal places and remove trailing zeroes
  for (let key in totals) {
    if (
      columns.find(column => column.attribute === key)?.dataType === 'currency'
    ) {
      // For currency columns, format with fixed decimal places and currency symbol
      let roundedValue = totals[key].toFixed(maxDecimalPlaces);
      roundedValue = roundedValue.replace(/(\.\d*?[1-9])0*$/, '$1'); // Remove trailing zeroes
      totals[key] = roundedValue.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    } else {
      // For non-currency columns, format without trailing zeroes
      totals[key] = parseFloat(totals[key]).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
    }
  }

  return (
    <div className={classes.tableHolder}>
      <table>
        <thead className={stickyTableHead ? classes.stickyTableHead : ''}>
          <tr>
            {columns?.map((column, index) => (
              <React.Fragment key={index}>
                <Resizable
                  key={column.key}
                  column={column}
                  columnWidth={columnWidths[column.key]}
                  HandleResizeStop={HandleResizeStop}
                >
                  <span
                    onClick={e => !column.notSortable && HandleSort(column, e)}
                  >
                    {column.header}
                  </span>
                  {sortKeyPrimary === column.attribute &&
                    ((sortDirectionPrimary === 'asc' && <AiFillCaretUp />) ||
                      (sortDirectionPrimary === 'desc' && <AiFillCaretDown />))}
                  {sortKeySecondary === column.attribute &&
                    ((sortDirectionSecondary === 'asc' && <AiOutlineUp />) ||
                      (sortDirectionSecondary === 'desc' && <AiOutlineDown />))}
                </Resizable>
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData?.map((row, rowIndex: number) => (
            <>
              <tr
                key={rowIndex}
                className={
                  compactView ? classes.compactView : classes.normalView
                }
              >
                {columns.map((column, columnIndex) => (
                  <td
                    key={`${rowIndex}-${columnIndex}`}
                    onClick={e => {
                      if (
                        typeof column.onClick === 'function' &&
                        row[column.attribute]
                      ) {
                        column.onClick(e);
                      }
                    }}
                    className={`${
                      // IF the column has an onClick function AND
                      column.onClick &&
                      // IF the cell has a displayed value AND,
                      ((row[column.attribute] &&
                        // attributeprimaryhidden of any type (most clickable cells will meet these requirements) OR
                        (row[column.attributeprimaryhidden] ||
                          // attributesecondaryhidden that is a number (such as Rental # on Needs Invoice page) OR
                          (row[column.attributesecondaryhidden] &&
                            typeof row[column.attributesecondaryhidden] ===
                              'number'))) ||
                        // IF the cell does NOT have attributeprimaryhidden AND
                        (!row[column.attributeprimaryhidden] &&
                          // has attribute that is a number (such as ID on load board) AND
                          typeof row[column.attribute] === 'number'))
                        ? // pass through the classes from columnConfig and add the clickable class.
                          `${column.class} ${classes.clickable}`
                        : // Otherwise, only passthrough the classes from columnConfig.
                          column.class
                      // This ensures that cells with content that have an onClick work and appear clickable as expected and
                      // cells without content or have content only to indicate missing info won't appear clickable.
                    } ${
                      (column.dataType === 'currency' ||
                        column.dataType === 'formattedNumber') &&
                      classes.textRightAlign
                    }`}
                    data-attributeprimaryhidden={
                      row[column.attributeprimaryhidden]
                    }
                    data-attributesecondaryhidden={
                      row[column.attributesecondaryhidden]
                    }
                    data-attributetertiaryhidden={
                      row[column.attributetertiaryhidden]
                    }
                  >
                    {column.dataType === 'date' && row[column.attribute]
                      ? row[column.attribute].toLocaleString('en-US', {
                          timeZoneName: 'short',
                        })
                      : row[column.attribute]}
                  </td>
                ))}
              </tr>
            </>
          ))}
          {columns.some(column => column.includeInTotal === true) && (
            <tr className={classes.totalRow}>
              {columns.map((column, columnIndex) => (
                <td
                  className={`${
                    (column.dataType === 'currency' ||
                      column.dataType === 'formattedNumber') &&
                    classes.textRightAlign
                  }`}
                  key={columnIndex}
                >
                  {columnIndex === 0
                    ? 'Totals'
                    : column.includeInTotal && totals[column.attribute]
                    ? column?.dataType === 'currency'
                      ? parseFloat(totals[column.attribute]).toLocaleString(
                          'en-US',
                          {
                            style: 'currency',
                            currency: 'USD',
                          },
                        )
                      : totals[column.attribute]
                    : null}
                </td>
              ))}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default BBITable;
