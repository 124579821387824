import React from 'react';

import classes from '../../assets/Global.module.css';
import TextArea from '../../assets/essentials/TextArea';
import Input from '../../assets/essentials/Input';

function StopInfo({
  stop,
  UpdateParam,
  stopIndex,
}: {
  stop: CreateStopParam;
  UpdateParam: Function;
  stopIndex: number;
}) {
  return (
    <>
      <h4>Stop Information</h4>
      <div className={`${classes.splitRow} ${classes.span3}`}>
        <div className={`${classes.attribute} `}>
          <label>Stop Info</label>
          <TextArea
            type="text"
            defaultValue={stop?.deliveryInfo}
            name="deliveryInfo"
            onChange={e =>
              UpdateParam(e.target.value, 'deliveryInfo', stopIndex)
            }
          />
        </div>
        <div className={classes.attribute}>
          <label>PO#</label>
          <TextArea
            type="text"
            defaultValue={stop?.poNumbers}
            name="poNumbers"
            onChange={e => UpdateParam(e.target.value, 'poNumbers', stopIndex)}
          />
        </div>
      </div>
      <div className={classes.attribute}>
        <label>Weight</label>
        <Input
          min={0}
          type="number"
          name="weight"
          defaultValue={stop?.weight}
          onChange={e => UpdateParam(e.target.value, 'weight', stopIndex)}
        />
      </div>
      <div className={classes.attribute}>
        <label>Pallets</label>
        <Input
          min={0}
          type="number"
          name="pallets"
          defaultValue={stop?.pallets}
          onChange={e => UpdateParam(e.target.value, 'pallets', stopIndex)}
        />
      </div>
      <div className={classes.attribute}>
        <label>Cases</label>
        <Input
          min={0}
          type="number"
          name="cases"
          defaultValue={stop?.cases}
          onChange={e => UpdateParam(e.target.value, 'cases', stopIndex)}
        />
      </div>

      <div className={classes.attribute}>
        <label>Product</label>
        <Input
          type="text"
          name="product"
          defaultValue={stop?.product}
          onChange={e => UpdateParam(e.target.value, 'product', stopIndex)}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>SKU</label>
        <Input
          type="text"
          name="sku"
          defaultValue={stop?.sku}
          onChange={e => UpdateParam(e.target.value, 'sku', stopIndex)}
        />
      </div>
    </>
  );
}

export default StopInfo;
