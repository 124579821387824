import React from 'react';
import PutForm from '../../../api/internal/PutForm';
import Form from '../../../assets/essentials/Form';
import classes from '../../../assets/Global.module.css';
import Select from '../../../assets/essentials/Select';
import OptionList from '../../../helpers/options/OptionList';
import Button from '../../../assets/essentials/Button';

const Admin = ({
  data,
  assetAssignmentId,
  RefreshPage,
}: {
  data: PayrollAdmin;
  assetAssignmentId: Number;
  RefreshPage: Function;
}) => {
  const { paymentStatusId, paymentStatusOptions } = data;

  function UpdatePayrollAdmin(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const appendix = {
      assetAssignmentId: assetAssignmentId,
    };
    PutForm(`/Accounting/UpdatePayrollAdmin`, formData, appendix).then(
      response => {
        if (response) {
          RefreshPage('Admin', ['Audit', 'Summary']);
        }
      },
    );
  }

  return (
    <>
      <Form
        onSubmit={e => {
          UpdatePayrollAdmin(e);
        }}
      >
        <div className={`${classes.span3} ${classes.attribute}`}>
          <label>Payment Status</label>
          <Select defaultValue={paymentStatusId} name="paymentStatusId">
            <OptionList
              optionData={paymentStatusOptions}
              attributeID="paymentStatusId"
              attributeName="status"
            />
          </Select>
        </div>
        <div>
          <Button disabled variant="good" type="submit">
            Save Changes
          </Button>
        </div>
      </Form>
    </>
  );
};

export default Admin;
