import React, { useEffect, useState } from 'react';
import { MdDownload } from 'react-icons/md';
import { Link } from 'react-router-dom';

import Header from '../../../layouts/Header';
import ViewCustomer from '../../../features/views/customer/Customer';
import View from '../../../helpers/slab/View';
import BBITable from '../../../helpers/bBITable/BBITable';
import FilterData from '../../../helpers/filter/FilterData';
import Get from '../../../api/internal/Get';
import PostBody from '../../../api/internal/PostBody';
import { useOutletContext } from 'react-router-dom';
import { HiOutlineMagnifyingGlass } from 'react-icons/hi2';

function Customer({ toggleBoardRefresh }: { toggleBoardRefresh: boolean }) {
  const [receivableSummaryBoard, setReceivableSummaryBoard] = useState<
    Array<ReceivableSummaryBoardItem>
  >([]);
  const filterValue = useOutletContext();

  useEffect(() => {
    GetReceivableSummaryBoard();
  }, [toggleBoardRefresh]);

  function GetReceivableSummaryBoard() {
    Get(`/Accounting/GetReceivableSummaryBoard/`).then(response => {
      if (response) {
        setReceivableSummaryBoard(
          response.data?.map(receivableSummary => ({
            ...receivableSummary,
            arReport: (
              <Link
                to={`/reports/accountreceivablereport/${receivableSummary.customerId}`}
              >
                <HiOutlineMagnifyingGlass /> View
              </Link>
            ),
          })),
        );
      }
    });
  }

  let tableData = FilterData(receivableSummaryBoard, filterValue);

  function DownloadReceivableSummaryCSV(tableData) {
    let customerIdsToDownload = [];
    customerIdsToDownload = tableData?.map(x => x.customerId);
    if (customerIdsToDownload.length > 0) {
      let testArray = {
        CustomerIdList: [...customerIdsToDownload],
      };
      PostBody('Accounting/DownloadReceivableSummaryCSV', testArray).then(
        response => {
          if (response) {
            const blob = new Blob([response.data], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download =
              new Date().toISOString().split('T')[0].replace(/[-]/g, '') +
              '_AR Summary.csv';
            a.click();
            URL.revokeObjectURL(url);
          }
        },
      );
    }
  }

  let columnConfig = [
    {
      key: '1',
      attribute: 'customerName',
      attributeprimaryhidden: 'customerId',
      header: 'Customer',
      onClick: e =>
        View(
          <ViewCustomer
            customerName={e.target.innerText}
            customerId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '2',
      attribute: 'arReport',
      attributeprimaryhidden: 'customerId',
      header: 'A/R Report',
    },
    {
      key: '3',
      attribute: 'currentTotal',
      header: 'Current',
      dataType: 'currency',
    },
    {
      key: '4',
      attribute: 'thirtyDayTotal',
      header: '30 Days',
      dataType: 'currency',
    },
    {
      key: '5',
      attribute: 'sixtyDayTotal',
      header: '60 Days',
      dataType: 'currency',
    },
    {
      key: '6',
      attribute: 'ninetyDayTotal',
      header: '90 Days',
      dataType: 'currency',
    },
    {
      key: '7',
      attribute: 'extendedTotal',
      header: '120+ Days',
      dataType: 'currency',
    },
    {
      key: '8',
      attribute: 'grandTotal',
      header: 'Total',
      dataType: 'currency',
    },
  ];

  return (
    <>
      <Header>
        <div>
          <button onClick={() => DownloadReceivableSummaryCSV(tableData)}>
            <MdDownload />
            Download
          </button>
        </div>
      </Header>
      <BBITable data={tableData} columns={columnConfig} />
    </>
  );
}

export default Customer;
