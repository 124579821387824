import React from 'react';

import classes from '../../../assets/Global.module.css';
import OptionList from '../../../helpers/options/OptionList';
import Select from '../../../assets/essentials/Select';
import Input from '../../../assets/essentials/Input';
import TextArea from '../../../assets/essentials/TextArea';
import SelectMultiple from '../../../helpers/inputs/SelectMultiple';

function Details({
  createRentalParam,
  createRentalOptions,
  UpdateParam,
  rentalDisplayItems,
}: {
  createRentalParam: CreateRentalParam;
  createRentalOptions: CreateRentalOptions;
  UpdateParam: Function;
  rentalDisplayItems: RentalDisplayItems;
}) {
  function UpdateAssets(assetList: Array<GenericItem>) {
    UpdateParam(assetList, 'assets');
  }

  const AssetSection = () => {
    return (
      <div className={`${classes.attribute}`}>
        <label>Asset</label>
        <SelectMultiple
          items={createRentalParam.assets}
          setItems={UpdateAssets}
          optionData={createRentalOptions?.assetOptions}
          attributeID="assetId"
          attributeName="name"
          attributeGroup="group"
        />
      </div>
    );
  };

  return (
    <>
      <h1>Details</h1>
      <div className={classes.viewGrid}>
        <div className={`${classes.attribute}`}>
          <label>
            Customer <span className={classes.required}>*</span>
          </label>
          <Select
            defaultValue={
              createRentalParam?.customerId ? createRentalParam.customerId : ''
            }
            onChange={e => {
              UpdateParam(e.target.value, 'customerId');
              rentalDisplayItems.customer =
                e.target.selectedOptions[0].textContent;
            }}
          >
            <option disabled value="">
              -- Required --
            </option>
            <OptionList
              optionData={createRentalOptions?.customerOptions}
              attributeID="customerId"
              attributeName="customerName"
            />
          </Select>
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>Reference</label>
          <Input
            defaultValue={createRentalParam?.reference}
            onChange={e => UpdateParam(e.target.value, 'reference')}
            type="text"
          />
        </div>
        <div className={classes.attribute}>
          <label>
            Start Date <span className={classes.required}>*</span>
          </label>
          <Input
            defaultValue={createRentalParam?.startDate}
            onChange={e => {
              UpdateParam(e.target.value, 'startDate');
              rentalDisplayItems.startDate = e.target.value.toLocaleString();
            }}
            type="date"
          />
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>
            End Date <span className={classes.required}>*</span>
          </label>
          <Input
            defaultValue={createRentalParam?.endDate}
            onChange={e =>
              UpdateParam(
                e.target.value !== '' ? e.target.value : null,
                'endDate',
              ) && (rentalDisplayItems.endDate = e.target.value)
            }
            type="date"
          />
        </div>
        <AssetSection />
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>Instructions</label>
          <TextArea
            defaultValue={createRentalParam?.instructions}
            name="instructions"
            onChange={e => UpdateParam(e.target.value, 'instructions')}
          />
        </div>
      </div>
    </>
  );
}

export default Details;
