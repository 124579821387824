import React from 'react';

import classes from '../../../assets/Global.module.css';
import OptionList from '../../../helpers/options/OptionList';
import Select from '../../../assets/essentials/Select';
import Input from '../../../assets/essentials/Input';
import TextArea from '../../../assets/essentials/TextArea';

function Details({
  createPayablesParam,
  createPayableOptions,
  UpdatePayableListLength,
  UpdatePayablesParam,
}: {
  createPayablesParam: CreatePayablesParam;
  createPayableOptions: CreatePayableOptions;
  UpdatePayableListLength: Function;
  UpdatePayablesParam: Function;
}) {
  return (
    <>
      <h1>Details</h1>
      <div className={classes.viewGrid}>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>
            Vendor <span className={classes.required}>*</span>
          </label>
          <Select
            required
            onChange={e => UpdatePayablesParam(e.target.value, 'vendorId')}
            defaultValue={
              createPayablesParam.vendorId ? createPayablesParam.vendorId : ''
            }
          >
            <option disabled value="">
              -- Required --
            </option>
            <OptionList
              optionData={createPayableOptions?.vendorOptions}
              attributeID="vendorId"
              attributeName="vendorName"
            />
          </Select>
        </div>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>
            Number of Payables <span className={classes.required}>*</span>
          </label>
          <Input
            defaultValue={createPayablesParam?.payableList.length}
            onChange={e => UpdatePayableListLength(e.target.value, 'payables')}
            type="number"
            min="0"
            max="50"
            step="1"
          />
        </div>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>Note</label>
          <TextArea
            onChange={e => UpdatePayablesParam(e.target.value, 'note')}
            defaultValue={createPayablesParam.note}
          ></TextArea>
        </div>
      </div>
    </>
  );
}

export default Details;
