import React, { useState, useEffect } from 'react';
import { MdOutlineRefresh } from 'react-icons/md';
import { AiOutlineUser } from 'react-icons/ai';
import Header from '../../layouts/Header';
import Filter from '../../helpers/filter/Filter';
import View from '../../helpers/slab/View';
import ViewDispatch from '../../features/views/users/dispatch/Dispatch';
import CreateDispatch from '../../features/creates/dispatch/Dispatch';
import Create from '../../helpers/panel/Create';
import FilterData from '../../helpers/filter/FilterData';
import BBITable from '../../helpers/bBITable/BBITable';
import Get from '../../api/internal/Get';

function Dispatch({ toggleBoardRefresh }) {
  const [filterValue, setFilterValue] = useState('');
  const [dispatchBoard, setDispatchBoard] = useState<Array<DispatchBoard>>([]);

  useEffect(() => {
    GetDispatchBoard();
  }, [toggleBoardRefresh]);

  function GetDispatchBoard() {
    Get(`/Employee/GetDispatchBoard`).then(response => {
      if (response) {
        setDispatchBoard(response.data);
      }
    });
  }

  let columnConfig = [
    {
      key: '1',
      attribute: 'dispatchName',
      attributeprimaryhidden: 'employeeId',
      header: 'Dispatch',
      onClick: e =>
        View(
          <ViewDispatch
            dispatchName={e.target.innerText}
            employeeId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '2',
      attribute: 'dispatchPhone',
      header: 'Phone',
    },
    {
      key: '3',
      attribute: 'dispatchBranch',
      header: 'Branch',
    },
  ];

  let tableData = FilterData(dispatchBoard, filterValue);

  return (
    <>
      <h1>Dispatch</h1>
      <Header>
        <div>
          <button
            onClick={() =>
              Create(<CreateDispatch GetDispatchBoard={GetDispatchBoard} />)
            }
          >
            <AiOutlineUser />
            <span>Create a Dispatcher</span>
          </button>
          <button onClick={GetDispatchBoard}>
            <MdOutlineRefresh />
            <span>Refresh Board</span>
          </button>
        </div>
        <div>
          <Filter
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            placeholder="Filter dispatchers"
          />
        </div>
      </Header>
      <BBITable data={tableData} columns={columnConfig} />
    </>
  );
}

export default Dispatch;
