import React, { useEffect, useState } from 'react';

import Details from './Details';
import Get from '../../../api/internal/Get';
import Slab from '../../../helpers/slab/Slab';
import Summary from './Summary';
import Payments from '../accounting/Payments';
import Audit from '../generic/Audit';
import Admin from './Admin';
import { useRoleAuthorization } from '../../../hooks/useRoleAuthorization';
import { ADMIN_AUTHORIZED_ROLES } from '../../../auth/AuthRoles';

function Payable({
  vendorAssignmentId,
  startPage,
}: {
  vendorAssignmentId: Number;
  startPage?: String;
}) {
  const [currentPage, setCurrentPage] = useState<string>('');
  const [payableDataObject] = useState<PayableDataObject>({
    Summary: null,
    Details: null,
    Payments: null,
    Audit: null,
    Admin: null,
  });

  useEffect(() => {
    startPage ? HandlePageChange(startPage) : HandlePageChange('Summary');
  }, []);

  const HandlePageChange = params => {
    if (!payableDataObject[params] && pages[params].callerPath) {
      Get(pages[params].callerPath).then(response => {
        if (response) {
          payableDataObject[params] = response.data;
          AssignPages();
          setCurrentPage(params);
        }
      });
    } else {
      setCurrentPage(params);
    }
  };

  function RefreshPage(currentPage: string, additionalPages: string[]) {
    setCurrentPage(null);
    delete payableDataObject[currentPage];
    additionalPages?.forEach(pageName => {
      delete payableDataObject[pageName];
    });
    HandlePageChange(currentPage);
  }

  let pages = {};
  AssignPages();

  function AssignPages() {
    pages = {
      Summary: {
        content: <Summary data={payableDataObject.Summary} />,
        callerPath: `/Accounting/GetPayableSummary/${vendorAssignmentId}`,
      },
      Details: {
        content: (
          <Details
            data={payableDataObject.Details}
            vendorAssignmentId={vendorAssignmentId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Accounting/GetPayableDetails/${vendorAssignmentId}`,
      },
      Payments: {
        content: (
          <Payments
            relationId={vendorAssignmentId}
            relationType={'Vendor'}
            data={payableDataObject.Payments}
            HandlePageChange={HandlePageChange}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Accounting/GetPaymentMethodList`,
      },
      Audit: {
        content: <Audit data={payableDataObject.Audit} />,
        callerPath: `/Accounting/GetVendorAssignmentAudit/${vendorAssignmentId}`,
      },
    };
    if (useRoleAuthorization(ADMIN_AUTHORIZED_ROLES))
      pages['Admin'] = {
        content: (
          <Admin
            data={payableDataObject.Admin}
            vendorAssignmentId={vendorAssignmentId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Accounting/GetPayableAdmin/${vendorAssignmentId}`,
      };
  }

  return (
    <Slab
      title={'Payable Assignment: AP' + vendorAssignmentId}
      pages={pages}
      currentPage={currentPage}
      HandlePageChange={HandlePageChange}
      dataObject={payableDataObject}
    />
  );
}
export default Payable;
