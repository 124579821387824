import React from 'react';

import classes from '../../../assets/Global.module.css';
import Input from '../../../assets/essentials/Input';
import Select from '../../../assets/essentials/Select';
import OptionList from '../../../helpers/options/OptionList';
import TextArea from '../../../assets/essentials/TextArea';
import StatesAndProvinces from '../../../helpers/options/StatesAndProvinces';

function Details({
  truck,
  createTruck,
  UpdateCreateTruck,
  setTruckType,
}: {
  truck: CreateTruckParam;
  createTruck: CreateTruckOptions;
  UpdateCreateTruck: Function;
  setTruckType: Function;
}) {
  return (
    <form className={classes.viewGrid}>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label htmlFor="truckTypeId">
          Truck Type <span className={classes.required}>*</span>
        </label>
        <Select
          defaultValue={truck.truckTypeId ? truck.truckTypeId : ''}
          id="truckTypeId"
          name="truckTypeId"
          onChange={e => {
            UpdateCreateTruck(e.target.value, 'truckTypeId');
            setTruckType(e.target.selectedOptions[0].textContent);
          }}
        >
          <option disabled value="">
            -- Required --
          </option>
          <OptionList
            optionData={createTruck?.truckTypeOptions}
            attributeID="truckTypeId"
            attributeName="type"
            attributeGroup="group"
          />
        </Select>
      </div>
      <div className={classes.attribute}>
        <label>
          Truck Unit # <span className={classes.required}>*</span>
        </label>
        <Input
          defaultValue={truck.truckName}
          id="truckName"
          name="truckName"
          onChange={e => UpdateCreateTruck(e.target.value, 'truckName')}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor="vin">
          VIN <span className={classes.required}>*</span>
        </label>
        <Input
          defaultValue={truck.vin}
          id="vin"
          name="vin"
          onChange={e => UpdateCreateTruck(e.target.value, 'vin')}
        />
      </div>
      <div className={classes.attribute}>
        <label htmlFor="licensePlateNumber">License Plate Number</label>
        <Input
          defaultValue={truck.licensePlateNumber}
          id="licensePlateNumber"
          name="licensePlateNumber"
          onChange={e =>
            UpdateCreateTruck(e.target.value, 'licensePlateNumber')
          }
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor="licensePlateState">License Plate State</label>
        <Select
          id="licensePlateState"
          name="licensePlateState"
          onChange={e => UpdateCreateTruck(e.target.value, 'licensePlateState')}
        >
          <option value="" />
          <StatesAndProvinces />
        </Select>
      </div>
      <div className={classes.attribute}>
        <label htmlFor="year">
          Year <span className={classes.required}>*</span>
        </label>
        <Input
          type="number"
          defaultValue={truck.year}
          id="year"
          name="year"
          onChange={e => UpdateCreateTruck(e.target.value, 'year')}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor="make">
          Make <span className={classes.required}>*</span>
        </label>
        <Input
          defaultValue={truck.make}
          id="make"
          name="make"
          onChange={e => UpdateCreateTruck(e.target.value, 'make')}
        />
      </div>
      <div className={classes.attribute}>
        <label htmlFor="model">
          Model <span className={classes.required}>*</span>
        </label>
        <Input
          defaultValue={truck.model}
          id="model"
          name="model"
          onChange={e => UpdateCreateTruck(e.target.value, 'model')}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor="color">Color</label>
        <Input
          defaultValue={truck.color}
          id="color"
          name="color"
          onChange={e => UpdateCreateTruck(e.target.value, 'color')}
        />
      </div>
      <div className={classes.attribute}>
        <label htmlFor="mileage">Mileage</label>
        <Input
          defaultValue={truck.mileage ? truck.mileage.toLocaleString() : ''}
          id="mileage"
          name="mileage"
          onChange={e => UpdateCreateTruck(e.target.value, 'mileage')}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label htmlFor="mileageAsOfDate">Mileage As Of Date</label>
        <Input
          defaultValue={truck.mileageAsOfDate}
          id="mileageAsOfDate"
          name="mileageAsOfDate"
          onChange={e => UpdateCreateTruck(e.target.value, 'mileageAsOfDate')}
          type="date"
        />
      </div>
      <div className={classes.attribute}>
        <label htmlFor="eldId">ELD ID</label>
        <Input
          defaultValue={truck.eldId}
          id="eldId"
          name="eldId"
          onChange={e => UpdateCreateTruck(e.target.value, 'eldId')}
        />
      </div>
      <div className={classes.attribute}>
        <label htmlFor="eldMake">ELD Make</label>
        <Input
          defaultValue={truck.eldMake}
          id="eldMake"
          name="eldMake"
          onChange={e => UpdateCreateTruck(e.target.value, 'eldMake')}
        />
      </div>
      <div className={classes.attribute}>
        <label htmlFor="eldModel">ELD Model</label>
        <Input
          defaultValue={truck.eldModel}
          id="eldModel"
          name="eldModel"
          onChange={e => UpdateCreateTruck(e.target.value, 'eldModel')}
        />
      </div>
      <div className={classes.attribute}>
        <label htmlFor="grossVehicleWeightRating">
          Gross Vehicle Weight Rating
        </label>
        <Input
          defaultValue={truck.grossVehicleWeightRating}
          id="grossVehicleWeightRating"
          name="grossVehicleWeightRating"
          onChange={e =>
            UpdateCreateTruck(e.target.value, 'grossVehicleWeightRating')
          }
          type="number"
        />
      </div>
      <div className={`${classes.attribute}`}>
        <label htmlFor="weightPermitExpirationDate">
          Weight Permit Expiration Date
        </label>
        <Input
          defaultValue={truck.weightPermitExpirationDate}
          id="weightPermitExpirationDate"
          name="weightPermitExpirationDate"
          onChange={e => UpdateCreateTruck(e.target.value, 'weightPermitExpirationDate')}
          type="date"
        />
      </div>
      <div className={`${classes.attribute}`}>
        <label htmlFor="internationalFuelTaxAgreementNumber">IFTA #</label>
        <Input
          defaultValue={truck.internationalFuelTaxAgreementNumber}
          onChange={e => UpdateCreateTruck(e.target.value, 'internationalFuelTaxAgreementNumber')}
          id="internationalFuelTaxAgreementNumber"
          name="internationalFuelTaxAgreementNumber"
        />
      </div>
      <div className={`${classes.attribute}`}>
        <label htmlFor="branchId">Branch</label>
        <Select 
          defaultValue={truck.branchId} 
          onChange={e => UpdateCreateTruck(e.target.value, 'branchId')}
          id="branchId" 
          name="branchId"
          >
            <option value="">-- Select Branch --</option>
            <OptionList
              optionData={createTruck?.branchOptions}
              attributeID="branchId"
              attributeName="branchName"
              attributeGroup="group"
            />
        </Select>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label htmlFor="isOwned">Ownership</label>
        <Select
          id="isOwned"
          name="isOwned"
          defaultValue={truck.isOwned ? 'true' : 'false'}
          onChange={e =>
            UpdateCreateTruck(e.target.value === 'true', 'isOwned')
          }
        >
          <option value="true">Owned</option>
          <option value="false">Leased</option>
        </Select>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label htmlFor="note">Note</label>
        <TextArea
          rows={4}
          defaultValue={truck.note}
          id="note"
          name="note"
          onChange={e => UpdateCreateTruck(e.target.value, 'note')}
        />
      </div>
    </form>
  );
}
export default Details;
