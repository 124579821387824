import React from 'react';
import classes from './Modal.module.css';
import CloseModal from './CloseModal';

function Holder() {
  return (
    <div className={classes.modalContainer}>
      <div className={classes.overlay} onClick={CloseModal} />
      <div className={classes.modalHolder} id="modal-container" />
    </div>
  );
}

export default Holder;
