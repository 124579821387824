import React, { useState, useEffect, useId } from 'react';
import classes from '../Global.module.css';

function Form({ children, onSubmit, ...rest }) {
  const [redefineFormInputs, setRedefineFormInputs] = useState(false);
  const formId = useId();
  let formContent;
  useEffect(() => {
    // all form fields that can be changed
    formContent = document.getElementById(formId);
    const inputList = formContent.querySelectorAll(
      'input[name], select[name], textarea[name]',
    );
    // add an onChange listener to each field
    for (const input of inputList) {
      input.addEventListener('input', HandleFormModification);
    }

    // checkboxes that conditionally render sections of the form require "refresh" of inputs
    // Load > Itinerary > Stop > Lumper Fee for example
    const inputToggleList = formContent.querySelectorAll(
      'input[type="checkbox"]',
    );
    for (const input of inputToggleList) {
      input.addEventListener('input', ToggleRedefineFormInputs);
    }
    return () => {
      inputToggleList.forEach(checkbox => {
        checkbox.removeEventListener('input', ToggleRedefineFormInputs);
      });
      inputList.forEach(input => {
        input.removeEventListener('input', HandleFormModification);
      });
    };
  }, [redefineFormInputs]);

  function ToggleRedefineFormInputs() {
    setRedefineFormInputs(!redefineFormInputs);
  }

  function HandleFormModification(e) {
    const disabledSubmitButton = formContent.querySelector(
      'button[type="submit"][disabled]',
    );
    if (e.target.value !== e.target.defaultValue && disabledSubmitButton) {
      disabledSubmitButton.removeAttribute('disabled');
    }
  }

  function SubmitForm(e) {
    let submittedForm = document.getElementById(formId);
    //When the submit is hit we need to reevaluate

    const submitButtonList = submittedForm.querySelectorAll(
      'button[type="submit"]',
    );

    for (const submitButton of submitButtonList) {
      submitButton.setAttribute('disabled', 'true');
    }

    onSubmit(e);
  }

  return (
    <form
      id={formId}
      onSubmit={e => SubmitForm(e)}
      {...rest}
      className={classes.viewGrid}
    >
      {children}
    </form>
  );
}

export default Form;
