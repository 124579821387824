import React from 'react';

import CreateContacts from '../../creates/contacts/Vendor';
import Contact from './Contact';

function Contacts({
  data,
  vendorId,
  RefreshPage,
}: {
  data: Array<VendorContact>;
  vendorId: Number;
  RefreshPage: Function;
}) {
  return (
    <>
      <CreateContacts vendorId={vendorId} RefreshPage={RefreshPage} />
      {data?.map((individual, index) => (
        <React.Fragment key={index}>
          <Contact individual={individual} RefreshPage={RefreshPage} />
        </React.Fragment>
      ))}
    </>
  );
}
export default Contacts;
