import React, { useEffect, useState } from 'react';
import Slab from '../../../../helpers/slab/Slab';
import Details from './Details';
import Get from '../../../../api/internal/Get';
import Audit from '../../generic/Audit';
import Files from '../../../creates/file/Files';

interface MaintenanceLogProps {
  maintenanceLogId: number;
  invoiceNumber: string;
  startPage?: string;
}

function MaintenanceLog({
  maintenanceLogId,
  invoiceNumber,
  startPage,
}: MaintenanceLogProps) {
  const [currentPage, setCurrentPage] = useState();
  const [maintenanceLogObject] = useState<MaintenanceLogDataObject>({
    Audit: null,
    Details: null,
    Files: null,
  });

  useEffect(() => {
    startPage ? HandlePageChange(startPage) : HandlePageChange('Details');
  }, []);

  function HandlePageChange(params) {
    if (!maintenanceLogObject[params] && pages[params].callerPath) {
      Get(pages[params].callerPath).then(response => {
        if (response) {
          maintenanceLogObject[params] = response.data;
        }
        AssignPages();
        setCurrentPage(params);
      });
    } else {
      setCurrentPage(params);
    }
  }

  function RefreshPage(currentPage: string, additionalPages: string[]) {
    setCurrentPage(null);
    maintenanceLogObject[currentPage] = null;
    additionalPages?.forEach(pageName => {
      maintenanceLogObject[pageName] = null;
    });
    HandlePageChange(currentPage);
  }

  let pages = {};
  AssignPages();
  function AssignPages() {
    pages = {
      Details: {
        content: (
          <Details
            data={maintenanceLogObject.Details}
            maintenanceLogId={maintenanceLogId}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Asset/GetMaintenanceLogDetails/${maintenanceLogId}`,
      },
        Files: {
          content: (
            <Files
              data={maintenanceLogObject.Files}
              fileCategory={'maintenance-log'}
              categoryId={maintenanceLogId}
              RefreshPage={RefreshPage}
            />
          ),
          callerPath: `/File/GetAllFiles/maintenance-log-${maintenanceLogId}`,
        },
        Audit: {
          content: <Audit data={maintenanceLogObject.Audit} />,
          callerPath: `/Asset/GetMaintenanceLogAudit/${maintenanceLogId}`,
        },
    };
  }

  return (
    <Slab
      title={'Maintenance Log: ' + invoiceNumber}
      pages={pages}
      currentPage={currentPage}
      HandlePageChange={HandlePageChange}
      dataObject={maintenanceLogObject}
    />
  );
}
export default MaintenanceLog;
