import React from 'react';

import classes from '../../../assets/Global.module.css';
import Button from '../../../assets/essentials/Button';
import Input from '../../../assets/essentials/Input';
import Select from '../../../assets/essentials/Select';
import TextArea from '../../../assets/essentials/TextArea';
import PutForm from '../../../api/internal/PutForm';
import OptionList from '../../../helpers/options/OptionList';
import Form from '../../../assets/essentials/Form';
import Phone from '../../../helpers/inputs/Phone';
import StatesAndProvinces from '../../../helpers/options/StatesAndProvinces';

function Details({
  locationId,
  data,
  RefreshPage,
}: {
  data: LocationDetails;
  locationId: number;
  RefreshPage: Function;
}) {
  function UpdateLocationDetails(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const appendix = {
      locationId: locationId,
    };
    PutForm(`/Location/UpdateLocationDetails`, formData, appendix).then(() =>
      RefreshPage('Details', ['Audit', 'Summary']),
    );
  }

  return (
    <Form className={classes.viewGrid} onSubmit={e => UpdateLocationDetails(e)}>
      <div className={classes.attribute}>
        <label>
          Name <span className={classes.required}>*</span>
        </label>
        <Input required defaultValue={data.locationName} name="locationName" />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Timezone</label>
        <Select defaultValue={data?.ianaTimeZone} name="ianaTimeZone">
          <OptionList
            optionData={data.timeZoneOptions}
            attributeID="iana"
            attributeName="abbreviation"
          />
        </Select>
      </div>
      <div className={classes.attribute}>
        <label>Address Line One</label>
        <Input readOnly defaultValue={data.addressLineOne} />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Address Line Two</label>
        <Input readOnly defaultValue={data.addressLineTwo} />
      </div>
      <div className={classes.attribute}>
        <label>City</label>
        <Input readOnly defaultValue={data.city} />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>State</label>
        <Select disabled defaultValue={data.state}>
          <StatesAndProvinces />
        </Select>
      </div>
      <div className={classes.attribute}>
        <label>Zip</label>
        <Input readOnly defaultValue={data.zip} />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Country</label>
        <Select disabled defaultValue={data.country}>
          <option value="US">US</option>
          <option value="CA">Canada</option>
        </Select>
      </div>
      <div className={classes.attribute}>
        <label>Phone</label>
        <Phone
          defaultValue={data?.phone}
          name="phone"
          onChange={null}
          expectsEventObject={false}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Ext</label>
        <Input defaultValue={data.phoneExt} name="phoneExt" />
      </div>
      <div className={classes.attribute}>
        <label>Contact Email</label>
        <Input defaultValue={data.email} name="email" />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Website</label>
        <Input defaultValue={data.website} name="website" />
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>Special Instructions</label>
        <TextArea
          defaultValue={data.specialInstructions}
          name="specialInstructions"
        />
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>Hours</label>
        <TextArea defaultValue={data?.hours} name="hours" />
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>Directions</label>
        <TextArea defaultValue={data.directions} name="directions" />
      </div>
      <div>
        <Button type="submit" variant="good" disabled={true}>
          Save Changes
        </Button>
      </div>
    </Form>
  );
}
export default Details;
