import { createGlobalStyle } from 'styled-components';

export const lightTheme = {
  colorFont: '#000',
  colorPrimary: '#fff',
  colorSecondary: '#e9e9e9',
  colorSecondaryHover: 'rgba(210,210,210)',
  colorSecondarySubtle: 'rgba(210,210,210,0.1)',
  colorCTA: 'rgba(63,179,241)',
  colorCTAAccent: 'rgb(235, 247, 254)',
  colorHeaderBanner: '#484644',
  colorOverlay: 'rgba(0, 0, 0, 0.4)',
  colorDisabled: '#d4d4d4',
  colorGood: 'rgba(63,179,241)',
  colorBad: '#e74c3c',
  colorProfileBorder: 'rgba(0, 0, 0, 0)',
  calendarIconColor: 'unset',
  loadStatusColor: {
    noDriver: '#FFFF00',
    needLoadInfo: '#00FFFF',
    needsAttention: '#FF00FF',
    waitingOnPaperwork: 'rgba(248, 211, 5, 0.5)',
    sentToClaims: '#FF00FF',
    readyToLoad: '#1F79fB',
    onTheRoad: 'rgba(179, 241, 63, 0.5)',
    delivered: 'rgba(0, 0, 0, 0.8)',
  },
  dailyStatusColor: {
    needsCall: '#fa7560',
    dispatched: '#f5f5dc',
    checkCall: '#47ff22',
    noContact: '#fa7560',
    issue: '#ff00ff',
    nightWatch: '#f6c6ad',
  },
};
export const darkTheme = {
  colorFont: '#fff',
  colorPrimary: '#141414',
  colorSecondary: '#212121',
  colorSecondaryHover: '#484848',
  colorSecondarySubtle: 'rgba(72,72,72,0.1)',
  colorCTA: 'rgba(63,179,241)',
  colorCTAAccent: 'rgb(28, 52, 64)',
  colorHeaderBanner: '#141414',
  colorOverlay: 'rgba(0, 0, 0, 0.4)',
  colorDisabled: '#444',
  colorGood: 'rgba(63,179,241)',
  colorBad: '#e74c3c',
  colorProfileBorder: '#fff',
  calendarIconColor: 'invert(1)',
  loadStatusColor: {
    noDriver: '#FFFF00',
    needLoadInfo: '#00FFFF',
    needsAttention: '#FF00FF',
    waitingOnPaperwork: 'rgba(248, 211, 5, 0.5)',
    sentToClaims: '#FF00FF',
    readyToLoad: '#1F79fB',
    onTheRoad: 'rgba(179, 241, 63, 0.5)',
    delivered: 'rgba(0, 0, 0, 0.8)',
  },
  dailyStatusColor: {
    needsCall: '#fa7560',
    dispatched: '#f5f5dc',
    checkCall: '#47ff22',
    noContact: '#fa7560',
    issue: '#ff00ff',
    nightWatch: '#f6c6ad',
  },
};

export const GlobalStyles = createGlobalStyle`
  html {
    --fontStandard: .7rem;
    --fontMedium: .9rem;
    --fontLarge: 1.3rem;
    --fontWeightBold: 600;
    --headerHeight: 2rem;
    --colorFont: ${props => props.theme.colorFont};
    --colorPrimary: ${props => props.theme.colorPrimary};
    --colorSecondary: ${props => props.theme.colorSecondary};
    --colorSecondaryHover: ${props => props.theme.colorSecondaryHover};
    --colorSecondarySubtle: ${props => props.theme.colorSecondarySubtle};
    --colorCTA: ${props => props.theme.colorCTA};
    --colorCTAAccent: ${props => props.theme.colorCTAAccent};
    --colorHeaderBanner: ${props => props.theme.colorHeaderBanner};
    --colorOverlay: ${props => props.theme.colorOverlay};
    --colorDisabled: ${props => props.theme.colorDisabled};
    --colorGood: ${props => props.theme.colorGood};
    --colorBad: ${props => props.theme.colorBad};
    --colorProfileBorder: ${props => props.theme.colorProfileBorder};
    --calendarIconColor: ${props => props.theme.calendarIconColor};
    --noDriver: ${props => props.theme.loadStatusColor.noDriver};
    --needLoadInfo: ${props => props.theme.loadStatusColor.needLoadInfo};
    --needsAttention: ${props => props.theme.loadStatusColor.needsAttention};
    --waitingOnPaperwork: ${props =>
      props.theme.loadStatusColor.waitingOnPaperwork};
    --sendToClaims: ${props => props.theme.loadStatusColor.sendToClaims};
    --readyToLoad: ${props => props.theme.loadStatusColor.readyToLoad};
    --onTheRoad: ${props => props.theme.loadStatusColor.onTheRoad};
    --delivered: ${props => props.theme.loadStatusColor.delivered};
    --needsCall: ${props => props.theme.dailyStatusColor.needsCall};
    --dispatched: ${props => props.theme.dailyStatusColor.dispatched};
    --checkCall: ${props => props.theme.dailyStatusColor.checkCall};
    --noContact: ${props => props.theme.dailyStatusColor.noContact};
    --issue: ${props => props.theme.dailyStatusColor.issue};
    --nightWatch: ${props => props.theme.dailyStatusColor.nightWatch};
  }  

  div, button {
    color: ${props => props.theme.colorFont};
    transition: color 100ms ease, background-color 200ms ease;

  }

  a {
    color: unset;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
  
  svg {
    color: ${props => props.theme.colorCTA};
  }

  h1 {
    font-size: var(--fontLarge);
    margin: .375rem 0 .9rem;
  }

  h3 {
    font-size: var(--fontMedium);
  }

  h4 {
    margin-top: .2rem;
  }

  p {
    margin-bottom: 2.5rem;
  }

  input::-webkit-calendar-picker-indicator {
    filter: var(--calendarIconColor);
  }

  `;
