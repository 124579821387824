import React, { useState } from 'react';
import { IoMdRemoveCircleOutline, IoIosAddCircleOutline } from 'react-icons/io';
import classes from '../../assets/Global.module.css';
import OptionList from '../../helpers/options/OptionList';
import Select from '../../assets/essentials/Select';
import Input from '../../assets/essentials/Input';

function Appointment({
  stop,
  UpdateParam,
  stopIndex,
  timeZoneOptions,
}: {
  stop: CreateStopParam;
  UpdateParam: Function;
  stopIndex: Number;
  timeZoneOptions: Array<TimeZoneOption>;
}) {
  const [isAppointmentRange, setIsAppointmentRange] = useState<boolean>(false);

  return (
    <>
      <h4>Appointment Information</h4>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>
          Date <span className={classes.required}>*</span>
        </label>
        <Input
          type="date"
          defaultValue={stop?.appointmentDate}
          name="appointmentDate"
          onChange={e =>
            UpdateParam(e.target.value, 'appointmentDate', stopIndex)
          }
        />
      </div>
      <div className={classes.attribute}>
        <label>
          Time{' '}
          {!isAppointmentRange ? (
            <IoIosAddCircleOutline
              className={classes.iconCTA}
              onClick={() => setIsAppointmentRange(true)}
              title="Add Appointment Window"
            />
          ) : (
            <>
              Lower{' '}
              <IoMdRemoveCircleOutline
                className={classes.iconCTA}
                onClick={() => setIsAppointmentRange(false)}
                title="Remove Appointment Window"
              />
            </>
          )}
        </label>
        <Input
          type="time"
          defaultValue={stop?.appointmentTime}
          name="appointmentTime"
          onChange={e => {
            UpdateParam(e.target.value + ':00', 'appointmentTime', stopIndex);
          }}
          required={isAppointmentRange}
        />
      </div>
      {isAppointmentRange && (
        <div className={classes.attribute}>
          <label>Time Upper</label>
          <Input
            type="time"
            defaultValue={stop?.appointmentTimeUpperRange}
            name="appointmentTimeUpperRange"
            required={isAppointmentRange}
            onChange={e =>
              UpdateParam(
                e.target.value + ':00',
                'appointmentTimeUpperRange',
                stopIndex,
              )
            }
          />
        </div>
      )}
      <div className={classes.attribute}>
        <label>Time Zone</label>
        <Select
          disabled
          value={stop.locationObj?.ianaTimeZone}
          name="ianaTimeZone"
          onChange={e =>
            UpdateParam && UpdateParam(e.target.value, 'timeZone', stopIndex)
          }
        >
          <option value="" />
          <OptionList
            optionData={timeZoneOptions}
            attributeID="iana"
            attributeName="abbreviation"
          />
        </Select>
      </div>
    </>
  );
}
export default Appointment;
