import React, { useEffect, useRef, useState } from 'react';
import classes from '../../assets/Global.module.css';
import classesSearch from '../../helpers/search/CustomSearch.module.css';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import Get from '../../api/internal/Get';
import DateOnlyFormatter from '../../helpers/inputs/DateOnlyFormatter';
import View from '../../helpers/slab/View';
import ViewCustomer from '../../features/views/customer/Customer';
import ViewLoad from '../../features/views/load/Load';
import FilterData from '../../helpers/filter/FilterData';
import Input from '../../assets/essentials/Input';
import Select from '../../assets/essentials/Select';
import StatesAndProvinces from '../../helpers/options/StatesAndProvinces';
import Button from '../../assets/essentials/Button';
import BBITable from '../../helpers/bBITable/BBITable';

const SearchCustomer = ({ toggleBoardRefresh }) => {
  const [customerBoard, setCustomerBoard] = useState<Array<CustomerBoard>>([]);
  const [searchCustomerParams, setSearchCustomerParams] = useSearchParams();

  const filterValue = useOutletContext();

  useEffect(() => {
    Get(`/Customer/SearchCustomer?${searchCustomerParams.toString()}`).then(
      response => {
        if (response) {
          setCustomerBoard(FormatDates(response.data));
        }
      },
    );
  }, [toggleBoardRefresh, searchCustomerParams]);

  function FormatDates(receivables) {
    return receivables.map(receivable => ({
      ...receivable,
      lastLoadDate: DateOnlyFormatter(receivable.lastLoadDate),
    }));
  }

  const handleFormSubmit = event => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const newParams = new URLSearchParams();
    formData.forEach((value, key) => {
      if (value !== '') {
        newParams.set(key, value.toString());
      }
    });
    setSearchCustomerParams(newParams, { replace: true });
  };

  let columnConfig = [
    {
      key: '1',
      attribute: 'customerName',
      attributeprimaryhidden: 'customerId',
      header: 'Customer',
      onClick: e =>
        View(
          <ViewCustomer
            customerName={e.target.innerText}
            customerId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '2',
      attribute: 'customerPhone',
      header: 'Phone',
    },
    {
      key: '3',
      attribute: 'customerAddress',
      header: 'Address',
    },
    {
      key: '4',
      attribute: 'customerCity',
      header: 'City',
    },
    {
      key: '5',
      attribute: 'customerState',
      header: 'State',
    },
    {
      key: '6',
      attribute: 'quickBooksCustomerId',
      header: 'QuickBooks ID',
    },
    {
      key: '7',
      attribute: 'lastLoadDate',
      attributeprimaryhidden: 'lastLoadId',
      header: 'Last Load Date',
      onClick: e =>
        View(<ViewLoad loadId={e.target.dataset.attributeprimaryhidden} />),
    },
  ];

  let tableData = FilterData(customerBoard, filterValue);

  return (
    <>
      <div className={`${classesSearch.customSearch}`}>
        <form onSubmit={handleFormSubmit}>
          <label htmlFor="customerName">
            Customer Name
            <Input
              id="customerName"
              type="text"
              name="customerName"
              defaultValue={searchCustomerParams.get('customerName') ?? ''}
            />
          </label>
          <label htmlFor="addressLineOne">
            Address Line One
            <Input
              id="addressLineOne"
              type="text"
              name="addressLineOne"
              defaultValue={searchCustomerParams.get('addressLineOne') ?? ''}
            />
          </label>
          <label htmlFor="city">
            City
            <Input
              id="city"
              type="text"
              name="city"
              defaultValue={searchCustomerParams.get('city') ?? ''}
            />
          </label>
          <label htmlFor="state">
            State
            <Select
              id="state"
              name="state"
              defaultValue={searchCustomerParams.get('state') ?? ''}
            >
              <option value="" />
              <StatesAndProvinces />
            </Select>
          </label>
          <div className={classesSearch.submitHolder}>
            <Button type="submit" variant="good">
              Search
            </Button>
            <Button
              type="reset"
              variant="bad"
              onClick={() => setSearchCustomerParams({})}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
      <BBITable data={tableData} columns={columnConfig} />
    </>
  );
};

export default SearchCustomer;
