import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import Get from '../../api/internal/Get';
import View from '../../helpers/slab/View';
import ViewVendor from '../../features/views/vendor/Vendor';
import FilterData from '../../helpers/filter/FilterData';
import BBITable from '../../helpers/bBITable/BBITable';

const VendorBoard = ({ toggleBoardRefresh }) => {
  const [vendorBoard, setVendorBoard] = useState<Array<VendorBoard>>([]);

  const filterValue = useOutletContext();

  useEffect(() => {
    GetVendorBoard();
  }, [toggleBoardRefresh]);

  function GetVendorBoard() {
    Get(`/Vendor/GetVendorBoard`).then(response => {
      if (response) {
        setVendorBoard(response.data);
      }
    });
  }

  let columnConfig = [
    {
      key: '1',
      attribute: 'vendorName',
      attributeprimaryhidden: 'vendorId',
      header: 'Vendor',
      onClick: e =>
        View(
          <ViewVendor
            vendorName={e.target.innerText}
            vendorId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '2',
      attribute: 'vendorPhone',
      header: 'Phone',
    },
    {
      key: '4',
      attribute: 'vendorAddress',
      header: 'Address',
    },
    {
      key: '5',
      attribute: 'vendorCity',
      header: 'City',
    },
    {
      key: '6',
      attribute: 'vendorState',
      header: 'State',
    },
  ];

  let tableData = FilterData(vendorBoard, filterValue);

  return <BBITable data={tableData} columns={columnConfig} />;
};

export default VendorBoard;
