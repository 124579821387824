import React, { useState, useEffect } from 'react';

import Details from './Details';
import Panel from '../../../helpers/panel/Panel';
import PostBody from '../../../api/internal/PostBody';
import Close from '../../../helpers/panel/Close';
import Get from '../../../api/internal/Get';
import ViewDriver from '../../views/users/driver/Driver';
import View from '../../../helpers/slab/View';

function Driver() {
  const [createDriverOptions, setCreateDriverOptions] =
    useState<CreateDriverOptions>();
  const [createDriverParam, setCreateDriverParam] =
    useState<CreateDriverParam>();

  useEffect(() => {
    Get(`/Employee/GetCreateDriver`).then(response => {
      if (response) {
        setCreateDriverOptions(response.data);
      }
    });
  }, []);

  function CreateDriver() {
    PostBody('Employee/CreateDriver', createDriverParam).then(response => {
      if (response) {
        Close();
        View(
          <ViewDriver
            employeeId={response.data.employeeId}
            driverName={response.data.driverName}
          />,
        );
      }
    });
  }

  const FormatHireDate = () => {
    const parts = createDriverParam.hireDate.toString().split('-');
    return `${parts[1]}/${parts[2]}/${parts[0]}`;
  };

  let pages = [
    {
      title: 'Details',
      displayedIcon: '',
      content: createDriverOptions && (
        <Details
          createDriverOptions={createDriverOptions}
          setCreateDriverParam={setCreateDriverParam}
          createDriverParam={createDriverParam}
        />
      ),
      require: [
        {
          label: 'Name',
          FunctionBool: () =>
            createDriverParam.firstName !== '' &&
            createDriverParam.firstName &&
            createDriverParam.lastName !== '' &&
            createDriverParam.lastName,
          assignedValue: () =>
            createDriverParam.firstName + ' ' + createDriverParam.lastName,
          missingDesc: 'Name has not been properly assigned.',
        },
        {
          label: 'Mobile',
          FunctionBool: () =>
            createDriverParam.mobile !== '' &&
            createDriverParam.mobile &&
            // Accounting for the phone number being formatted with the country code
            createDriverParam?.mobile?.startsWith('+')
              ? createDriverParam?.mobile?.length >= 17
              : createDriverParam?.mobile?.length >= 14,
          assignedValue: () => createDriverParam.mobile,
          missingDesc: 'Mobile Phone Number has not been properly assigned.',
        },
        {
          label: 'Hire Date',
          FunctionBool: () => createDriverParam.hireDate,
          assignedValue: () => FormatHireDate(),
          missingDesc: 'Hire Date has not been assigned.',
        },
        {
          label: 'Branch',
          FunctionBool: () => createDriverParam.branchId,
          assignedValue: () =>
            createDriverOptions?.branchOptions?.find(
              branch => branch.branchId === Number(createDriverParam.branchId),
            )?.branchName,
          missingDesc: 'Branch has not been assigned.',
        },
      ],
    },
  ];

  function OnSubmit() {
    let fulfillsFormRequirements = true;
    pages.forEach((page, index) => {
      if (!FulFillsPageRequirements(index)) {
        fulfillsFormRequirements = false;
      }
    });
    if (fulfillsFormRequirements) {
      CreateDriver();
      Close();
    } else alert('Not all info included');
  }

  function FulFillsPageRequirements(pageIndex) {
    let fulfillsRequirements = true;
    pages[pageIndex].require.forEach(requirement => {
      if (!requirement.FunctionBool()) {
        fulfillsRequirements = false;
      }
    });
    return fulfillsRequirements;
  }

  return <Panel OnSubmit={OnSubmit} title="Create Driver" pages={pages} />;
}

export default Driver;
