import React, { useState } from 'react';
import PutForm from '../../../api/internal/PutForm';
import Form from '../../../assets/essentials/Form';
import Input from '../../../assets/essentials/Input';
import Button from '../../../assets/essentials/Button';
import Select from '../../../assets/essentials/Select';
import OptionList from '../../../helpers/options/OptionList';
import classes from '../../../assets/Global.module.css';

const Admin = ({
  data,
  customerAssignmentId,
  RefreshPage,
}: {
  data: ReceivableAdmin;
  customerAssignmentId: Number;
  RefreshPage: Function;
}) => {
  const { quickBooksInvoiceId, paymentStatusId, paymentStatusOptions } = data;

  function UpdateReceivableAdmin(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const appendix = {
      customerAssignmentId: customerAssignmentId,
    };
    PutForm(`/Accounting/UpdateReceivableAdmin`, formData, appendix).then(
      response => {
        if (response) {
          RefreshPage('Admin', ['Audit', 'Summary', 'Invoice']);
        }
      },
    );
  }

  return (
    <>
      <Form
        onSubmit={e => {
          UpdateReceivableAdmin(e);
        }}
      >
        <div className={classes.attribute}>
          <label>Payment Status</label>
          <Select defaultValue={paymentStatusId} name="paymentStatusId">
            <OptionList
              optionData={paymentStatusOptions}
              attributeID="paymentStatusId"
              attributeName="status"
            />
          </Select>
        </div>
        <div className={`${classes.span2} ${classes.attribute}`}>
          <label>QuickBooks Invoice ID</label>
          <Input
            defaultValue={quickBooksInvoiceId}
            name="quickBooksInvoiceId"
          />
        </div>
        <div>
          <Button disabled variant="good" type="submit">
            Save Changes
          </Button>
        </div>
      </Form>
    </>
  );
};

export default Admin;
