import React from 'react';
import FinancialTable from '../../../helpers/financialsEditableTable/FinancialsTable';
import AssembleUpdatedFinancial from '../../../helpers/financialsEditableTable/AssembleFinancialObject';
import PostBody from '../../../api/internal/PostBody';

type EditLineItemsProps = {
  lineItems: FinancialTableRow[];
  assignmentId: number;
  assignmentType: string;
  accountTypeOptions: AccountTypeOption[];
  RefreshPage: Function;
};

function EditLineItems({
  lineItems,
  assignmentId,
  assignmentType,
  accountTypeOptions,
  RefreshPage,
}: EditLineItemsProps) {
  function SaveDraftLineItems(e, assignmentId: number) {
    e.preventDefault();
    PostBody(
      'Accounting/SaveDraftFinancial',
      AssembleUpdatedFinancial(e, assignmentId, assignmentType),
    ).then(response => {
      if (response) {
        RefreshPage();
      }
    });
  }
  return (
    <>
      <h3>Line Items</h3> 
      <FinancialTable
        rowData={lineItems}
        assignmentId={assignmentId}
        assignmentType={assignmentType}
        accountTypeOptions={accountTypeOptions}
        onSubmit={SaveDraftLineItems}
      />
    </>
  );
}

export default EditLineItems;
