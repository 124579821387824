import React from 'react';
import type { ComponentPropsWithoutRef } from 'react';
import classes from './Input.module.css';

interface SelectProps extends ComponentPropsWithoutRef<'select'> {
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  children: React.ReactNode;
}

function Select({ children, ...rest }: SelectProps) {
  return (
    <select className={classes.select} {...rest}>
      {children}
    </select>
  );
}

export default Select;
