import React, { useEffect, useRef, useState } from 'react';
import Get from '../../../../api/internal/Get';
import Select from '../../../../assets/essentials/Select';
import OptionList from '../../../../helpers/options/OptionList';
import DateOnlyFormatter from '../../../../helpers/inputs/DateOnlyFormatter';
import classesGlobal from '../../../../assets/Global.module.css';
import classesApplyPayment from './ApplyReceivablePayments.module.css';
import Header from '../../../../layouts/Header';
import FilterData from '../../../../helpers/filter/FilterData';
import Input from '../../../../assets/essentials/Input';
import TextArea from '../../../../assets/essentials/TextArea';
import Button from '../../../../assets/essentials/Button';
import PostBody from '../../../../api/internal/PostBody';
import View from '../../../../helpers/slab/View';
import ViewLoad from '../../../../features/views/load/Load';
import ViewRental from '../../../../features/views/rental/Rental';
import { GetCurrentDate, GetSixMonthsAgoDate } from '../../helperFunctions';
import GetApplyPaymentsSearchParams from './GetApplyPaymentsSearchParamForm';
import ARAppliedPayments from '../../../../features/views/receivable/AppliedPayments/ARAppliedPayments';
import { useOutletContext } from 'react-router-dom';

type ApplyPaymentStatus = 'success' | 'pending' | 'idle';

function ApplyReceivablePayments({
  toggleBoardRefresh,
}: {
  toggleBoardRefresh: boolean;
}) {
  const [searchOptionsList, setSearchOptionsList] = useState([]);
  const [paymentOptionsList, setPaymentOptionsList] = useState<
    PaymentMethodOption[]
  >([]);
  const [customerAssignments, setCustomerAssignments] = useState<
    ApplyPaymentCustomerAssignment[]
  >([]);
  const [appliedPaymentStatus, setAppliedPaymentStatus] =
    useState<ApplyPaymentStatus>('idle');
  const [paymentAmount, setPaymentAmount] = useState<number>(0);
  const inputValues = useRef({
    customerId: null,
    paymentMethodId: '',
    reference: '',
    note: '',
    paymentDate: GetCurrentDate(),
  });
  const filterValue = useOutletContext();

  useEffect(() => {
    Get(`/Accounting/GetCustomerAssignmentApplyPaymentOptions`).then(
      response => {
        if (response) {
          setSearchOptionsList(response.data.customerOptionList);
          setPaymentOptionsList(response.data.paymentMethodOptionList);
        }
      },
    );
  }, [toggleBoardRefresh]);

  function GetCustomerAssignments(params) {
    const queryParams = new URLSearchParams(params);
    const url = `/Accounting/GetCustomerAssignmentsForApplyPayment?${queryParams.toString()}`;

    Get(url).then(response => {
      if (response) {
        setCustomerAssignments(response.data);
        inputValues.current.customerId = response.data[0].customerId;
        setPaymentAmount(0.0);
      }
    });
  }

  const appliedAmount = customerAssignments.reduce(
    (sum, row) => sum + Number(row.applyToBalance ?? 0),
    0,
  );

  //unary operator because i need a number, not a string
  const balance = +(paymentAmount - appliedAmount).toFixed(2);

  function FormatCustomerAssignments(
    customerAssignments: ApplyPaymentCustomerAssignment[],
  ) {
    return customerAssignments?.map(assignment => ({
      ...assignment,
      attribute: assignment.loadId
        ? assignment.loadId
        : assignment.rentalId && 'Rental: ' + assignment.rentalId,
      invoiceDate: DateOnlyFormatter(assignment.invoiceDate),
      loadId: assignment.loadId,
      rentalId: assignment.rentalId,
      dueDate: assignment.dueDate
        ? DateOnlyFormatter(assignment.dueDate)
        : 'N/A',
      totalReceivable: assignment.totalReceivable.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      remainingReceivableBalance:
        assignment.remainingReceivableBalance.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
      applyToBalance:
        typeof assignment?.applyToBalance === 'number'
          ? assignment.applyToBalance.toFixed(2)
          : null,
    }));
  }

  function ApplyPaymentsToSelectedCustomerAssignments(paymentAmount: number) {
    const updatedCustomerAssignments = [...customerAssignments];
    let remainingPayment = paymentAmount;

    for (let i = 0; i < updatedCustomerAssignments.length; i++) {
      const customerAssignment = updatedCustomerAssignments[i];
      customerAssignment.applyToBalance = null;
      if (customerAssignment.isSelected) {
        if (remainingPayment >= customerAssignment.remainingReceivableBalance) {
          remainingPayment -= customerAssignment.remainingReceivableBalance;
          customerAssignment.applyToBalance =
            customerAssignment.remainingReceivableBalance;
        } else {
          customerAssignment.applyToBalance = remainingPayment;
          remainingPayment = 0;
        }
      }
    }
    setCustomerAssignments(updatedCustomerAssignments);
  }

  const CustomerStats = () => {
    const totalBalance = customerAssignments
      .reduce((sum, row) => sum + row.remainingReceivableBalance, 0)
      .toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    return (
      <div>
        <h3>Invoice Count: {customerAssignments.length}</h3>
        <h3>Total Balance: {totalBalance}</h3>
      </div>
    );
  };

  const CustomerAssignmentTable = () => {
    const isBalanceApplied = applyToBalance =>
      !isNaN(applyToBalance) && applyToBalance;

    const HandleInputChange = (index: number, value: string) => {
      setCustomerAssignments(prevCustomerAssignments => {
        return prevCustomerAssignments.map((assignment, i) => {
          if (i === index) {
            return {
              ...assignment,
              isSelected: isBalanceApplied(value),
              applyToBalance: Number(value),
            };
          }
          return assignment;
        });
      });
    };

    function HandleRowSelect(index: number, checked: boolean) {
      if (checked === true) {
        setCustomerAssignments(prevAssignments => {
          return prevAssignments.map((assignment, i) => {
            if (i === index) {
              return {
                ...assignment,
                isSelected: true,
                applyToBalance: assignment.remainingReceivableBalance,
              };
            }
            return assignment;
          });
        });
      } else {
        setCustomerAssignments(prevAssignments => {
          return prevAssignments.map((assignment, i) => {
            if (i === index) {
              return {
                ...assignment,
                isSelected: false,
                applyToBalance: null,
              };
            }
            return assignment;
          });
        });
      }
    }

    const HandleAttributeOnClick = row => {
      if (row.loadId) {
        return View(<ViewLoad loadId={row.loadId} />);
      } else if (row.rentalId) {
        return View(<ViewRental rentalId={row.rentalId} />);
      }
    };

    const formattedCustomerAssignments =
      FormatCustomerAssignments(customerAssignments);
    const filteredCustomerAssignments = FilterData(
      formattedCustomerAssignments,
      filterValue,
    );

    const isEveryAssignmentSelected =
      filteredCustomerAssignments.every(assignment => assignment.isSelected) &&
      filteredCustomerAssignments.length > 0;

    const ToggleSelectAll = () => {
      const updatedCustomerAssignments = customerAssignments.map(item => {
        const customerAssignment = {
          ...item,
          isSelected: !isEveryAssignmentSelected,
        };
        if (isEveryAssignmentSelected) {
          customerAssignment.applyToBalance = null;
        } else if (!isEveryAssignmentSelected) {
          customerAssignment.applyToBalance =
            customerAssignment.remainingReceivableBalance;
        }
        return customerAssignment;
      });
      setCustomerAssignments(updatedCustomerAssignments);
    };

    return (
      <div className={classesApplyPayment.tableContainer}>
        <table>
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  onChange={ToggleSelectAll}
                  checked={isEveryAssignmentSelected}
                />
              </th>
              <th>Load #</th>
              <th>Reference</th>
              <th>QuickBooks ID</th>
              <th>Invoice Date</th>
              <th>Due Date</th>
              <th>Invoice Total</th>
              <th>Balance</th>
              <th>Apply To Balance</th>
            </tr>
          </thead>
          <tbody>
            {filteredCustomerAssignments?.map((row, index) => (
              <tr key={row.customerAssignmentId}>
                <td>
                  <input
                    type="checkbox"
                    name="selected"
                    checked={row.isSelected}
                    onChange={e => HandleRowSelect(index, e.target.checked)}
                  />
                </td>
                <td
                  onClick={() => HandleAttributeOnClick(row)}
                  className={classesGlobal.clickable}
                >
                  {row.attribute}
                </td>
                <td
                  onClick={() => HandleAttributeOnClick(row)}
                  className={row.reference ? classesGlobal.clickable : ''}
                >
                  {row.reference}
                </td>
                <td>{row.quickBooksInvoiceId}</td>
                <td>{row.invoiceDate}</td>
                <td>{row.dueDate}</td>
                <td>{row.totalReceivable}</td>
                <td>{row.remainingReceivableBalance}</td>
                <td>
                  <input
                    placeholder="0.00"
                    min={0}
                    max={row.remainingReceivableBalance}
                    type="number"
                    defaultValue={row.applyToBalance}
                    onBlur={e => {
                      HandleInputChange(index, e.target.value);
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const HandleSubmitApplyPayments = e => {
    e.preventDefault();
    setAppliedPaymentStatus('pending');
    const selectedCustomerAssignments = customerAssignments
      .filter(ca => ca.applyToBalance)
      .map(ca => ({
        customerAssignmentId: ca.customerAssignmentId,
        applyToBalanceAmount: ca.applyToBalance.toFixed(2),
      }));
    const applyPaymentParam = {
      customerId: inputValues.current.customerId,
      paymentMethodId: inputValues.current.paymentMethodId,
      reference: inputValues.current.reference,
      note: inputValues.current.note,
      paymentDate: inputValues.current.paymentDate,
      paymentAmount: paymentAmount,
      customerAssignments: selectedCustomerAssignments,
    };
    PostBody(
      'Accounting/ApplyPaymentCustomerAssignment',
      applyPaymentParam,
    ).then(response => {
      if (response) {
        const newCashFlowId = response.data;
        const queryParams = GenerateQueryString({
          loadId: '',
          rentalId: '',
          customerId: applyPaymentParam.customerId,
        });
        GetCustomerAssignments(queryParams);
        HandleAppliedPaymentSuccessfully(newCashFlowId);
      }
    });
  };

  // Function to prepare the query string for the API call
  function GenerateQueryString(params) {
    return Object.keys(params)
      .map(
        key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
      )
      .join('&');
  }

  const HandleAppliedPaymentSuccessfully = (newCashFlowId: number) => {
    inputValues.current.paymentMethodId = '';
    inputValues.current.reference = '';
    inputValues.current.note = '';
    inputValues.current.paymentDate = GetCurrentDate();
    setAppliedPaymentStatus('success');
    View(<ARAppliedPayments cashFlowId={newCashFlowId} />);
  };

  const SubmitPaymentsButton = () => {
    const isDisabled = appliedPaymentStatus === 'pending' || balance !== 0;
    return (
      <Button disabled={isDisabled} type="submit" variant="good">
        {balance !== 0 ? 'Balance must be zero.' : 'Submit Payments'}
      </Button>
    );
  };

  //using form for input validation
  const ApplyPaymentForm = () => {
    return (
      <form
        className={classesApplyPayment.applyPaymentSection}
        onSubmit={e => HandleSubmitApplyPayments(e)}
      >
        <div className={classesGlobal.attribute}>
          <label htmlFor="paymentMethodId">
            Payment Method <span className={classesGlobal.required}>*</span>
          </label>
          <Select
            required
            defaultValue={inputValues.current.paymentMethodId}
            name="paymentMethodId"
            id="paymentMethodId"
            onChange={e => {
              inputValues.current.paymentMethodId = e.target.value;
            }}
          >
            <option disabled value="">
              -- Required --
            </option>
            <OptionList
              optionData={paymentOptionsList}
              attributeID="paymentMethodId"
              attributeName="method"
              attributeGroup=""
            />
          </Select>
          <div className={`${classesGlobal.attribute} ${classesGlobal.span2}`}>
            <label htmlFor="reference">
              Reference/Check Number{' '}
              <span className={classesGlobal.required}>*</span>
            </label>
            <Input
              type="text"
              id="reference"
              defaultValue={inputValues.current.reference}
              onChange={e => {
                inputValues.current.reference = e.target.value;
              }}
              required
            />
          </div>
          <div className={`${classesGlobal.attribute} ${classesGlobal.span2}`}>
            <label htmlFor="note">Note</label>
            <TextArea
              id="note"
              name="note"
              defaultValue={inputValues.current.note}
              onChange={e => {
                inputValues.current.note = e.target.value;
              }}
            />
          </div>
          <div>
            <SubmitPaymentsButton />
          </div>
        </div>
        <div className={`${classesGlobal.attribute}`}>
          <label htmlFor="paymentDate">
            Payment Date <span className={classesGlobal.required}>*</span>
          </label>
          <Input
            type="date"
            id="paymentDate"
            min={GetSixMonthsAgoDate()}
            max={GetCurrentDate()}
            defaultValue={inputValues.current.paymentDate}
            onChange={e => {
              inputValues.current.paymentDate = e.target.value;
            }}
            required
          />
          <label htmlFor="paymentAmount">
            Payment Amount <span className={classesGlobal.required}>*</span>
          </label>
          <Input
            type="number"
            id="paymentAmount"
            step={0.01}
            min={0.01}
            defaultValue={paymentAmount}
            onBlur={e => {
              setPaymentAmount(Number(e.target.value));
            }}
            required
          />
          <div>
            <button
              className={classesApplyPayment.applyPaymentButton}
              onClick={() =>
                ApplyPaymentsToSelectedCustomerAssignments(paymentAmount)
              }
            >
              Apply Payment to Selected
            </button>
          </div>
          <label htmlFor="balance">Balance</label>
          <Input
            type="string"
            id="balance"
            value={balance?.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
            min={0}
            readOnly
          />
          <label htmlFor="appliedAmount">Applied Amount</label>
          <Input
            type="string"
            id="appliedAmount"
            value={appliedAmount?.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}
            readOnly
          />
        </div>
      </form>
    );
  };

  return (
    <>
      <GetApplyPaymentsSearchParams
        setAppliedPaymentStatus={setAppliedPaymentStatus}
        searchOptionsList={searchOptionsList}
        selectCustomerId={inputValues.current.customerId}
        GetCustomerAssignments={GetCustomerAssignments}
        GenerateQueryString={GenerateQueryString}
      />
      <ApplyPaymentForm />
      <Header>
        <h3>Payment Details</h3>
        <CustomerStats />
        <br />
      </Header>
      <CustomerAssignmentTable />
    </>
  );
}

export default ApplyReceivablePayments;
