function FilterData(originalData, filterValue) {
  if (filterValue === '') {
    return originalData;
  } else
    return originalData.reduce(function (filtered, obj) {
      var matchFound = false;
      for (var key in obj) {
        if (obj.hasOwnProperty(key) && obj[key] != null) {
          var value = obj[key].toString().toLowerCase();
          if (value.includes(filterValue.toLowerCase())) {
            matchFound = true;
            break;
          }
        }
      }
      if (matchFound) {
        filtered.push(obj);
      }
      return filtered;
    }, []);
}

export default FilterData;
