import React, { useState, useEffect } from 'react';
import { MdDownload } from 'react-icons/md';

import Get from '../../../api/internal/Get';
import BBITable from '../../../helpers/bBITable/BBITable';
import classesCustom from '../../../helpers/search/CustomSearch.module.css';
import classesGlobal from '../../../assets/Global.module.css';
import OptionList from '../../../helpers/options/OptionList';
import Select from '../../../assets/essentials/Select';
import Header from '../../../layouts/Header';
import View from '../../../helpers/slab/View';
import ViewDriver from '../../../features/views/users/driver/Driver';
import ViewLoad from '../../../features/views/load/Load';
import DateOnlyFormatter from '../../../helpers/inputs/DateOnlyFormatter';
import PayrollPaymentDetail from '../../../features/views/payroll/AppliedPayments/PayrollAppliedPayments';
import Input from '../../../assets/essentials/Input';
import Button from '../../../assets/essentials/Button';
import PostBody from '../../../api/internal/PostBody';
import TransportationCaller from '../../../api/internal/TransportationCaller';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import FilterData from '../../../helpers/filter/FilterData';
import { MdOutlineMailOutline } from 'react-icons/md';
import Create from '../../../helpers/panel/Create';
import BulkEmailPDFs from '../../../features/creates/payroll/BulkEmailPDFs';

function PayrollPayments({
  toggleBoardRefresh,
}: {
  toggleBoardRefresh: boolean;
}) {
  const filterValue = useOutletContext();
  const [payrollPaymentsBoard, setPayrollPaymentsBoard] = useState<
    Array<APCashFlow>
  >([]);
  const [driverOptions, setDriverOptions] = useState<Array<DriverOption>>();
  const [payrollPaymentSearchParams, setPayrollPaymentSearchParams] =
    useSearchParams();

  useEffect(() => {
    Get(
      `/Accounting/SearchPayrollPayments?${payrollPaymentSearchParams.toString()}`,
    ).then(response => {
      if (response) {
        setPayrollPaymentsBoard(response.data);
      }
    });

    if (!driverOptions) {
      Get(`/Employee/GetDriverOptions/`).then(response => {
        if (response) {
          setDriverOptions(response?.data);
        }
      });
    }
  }, [toggleBoardRefresh, payrollPaymentSearchParams, driverOptions]);

  const filteredData = FilterData(payrollPaymentsBoard, filterValue);

  const tableData = filteredData.map(payment => ({
    ...payment,
    paymentDate: DateOnlyFormatter(payment.paymentDate),
    totalPayment: Number(payment.totalPayment).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }),
    loadIds: payment.loadIds.map((loadId, index) => {
      return (
        <span
          className={classesGlobal.clickable}
          onClick={() => View(<ViewLoad loadId={loadId} />)}
        >
          {index !== payment.loadIds.length - 1 ? `${loadId}, ` : loadId}
        </span>
      );
    }),
    loadMileage: payment.loadMileage.toLocaleString(),
  }));

  function DownloadPayrollPaymentReportCSV(payrollPayments) {
    let cashFlowIdsToDownload = [];
    cashFlowIdsToDownload = payrollPayments?.map(x => x.cashFlowId);
    if (cashFlowIdsToDownload.length > 0) {
      let testArray = { CashFlowIdList: [...cashFlowIdsToDownload] };
      PostBody('Accounting/DownloadPayrollPaymentReportCSV', testArray).then(
        response => {
          if (response) {
            const blob = new Blob([response.data], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download =
              new Date().toISOString().split('T')[0].replace(/[-]/g, '') +
              '_Payroll Payments Report.csv';
            a.click();
            URL.revokeObjectURL(url);
          }
        },
      );
    }
  }

  function BulkDownloadPayrollDetailPDFs(payrolls) {
    const payrollIdsToDownload = payrolls.map(x => x.cashFlowId);
    if (payrollIdsToDownload.length > 0) {
      const payrollIdsObject = { cashFlowIdList: [...payrollIdsToDownload] };
      TransportationCaller.post(
        `/Accounting/DownloadZippedPayrollDetailPDFs`,
        payrollIdsObject,
        {
          headers: {
            Accept: 'application/zip',
          },
          responseType: 'blob', // Set responseType to 'blob' for binary data
        },
      ).then(response => {
        if (response) {
          const blob = new Blob([response.data], { type: 'application/zip' });
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download =
            'Payroll Detail_Bulk Download_' +
            new Date().toISOString().split('T')[0].replace(/[-]/g, '') +
            '.zip';
          a.click();
          URL.revokeObjectURL(url);
        }
      });
    }
  }

  const handleFormSubmit = event => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const newParams = new URLSearchParams();
    formData.forEach((value, key) => {
      if (value !== '') {
        newParams.set(key, value.toString());
      }
    });
    setPayrollPaymentSearchParams(newParams, { replace: true });
  };

  const SubHeader = () => {
    return (
      <Header>
        <div>
          <button onClick={() => DownloadPayrollPaymentReportCSV(tableData)}>
            <MdDownload />
            <span>Download</span>
          </button>
          <button onClick={() => BulkDownloadPayrollDetailPDFs(tableData)}>
            <MdDownload />
            <span>Bulk Download</span>
          </button>
          <button onClick={() => Create(<BulkEmailPDFs />)}>
            <MdOutlineMailOutline />
            <span>Bulk Email</span>
          </button>
        </div>
      </Header>
    );
  };

  const SearchForm = () => {
    return (
      <div className={`${classesCustom.customSearch}`}>
        <form onSubmit={handleFormSubmit}>
          <label>
            Payment ID #
            <Input
              name="cashFlowId"
              type="number"
              defaultValue={payrollPaymentSearchParams.get('cashFlowId') ?? ''}
            />
          </label>
          <label>
            Driver
            <Select
              defaultValue={payrollPaymentSearchParams.get('driverId') ?? ''}
              name="driverId"
            >
              <option value="">Select Driver</option>
              <OptionList
                optionData={driverOptions}
                attributeID="driverId"
                attributeName="name"
              />
            </Select>
          </label>
          <label>
            Load #
            <Input
              defaultValue={payrollPaymentSearchParams.get('loadId') ?? ''}
              name="loadId"
              type="number"
            />
          </label>
          <label>
            Date
            <Input
              defaultValue={payrollPaymentSearchParams.get('date') ?? ''}
              type="date"
              name="date"
            />
          </label>
          <label>
            Week of Year
            <Input
              defaultValue={payrollPaymentSearchParams.get('weekOfYear') ?? ''}
              name="weekOfYear"
              type="number"
            />
          </label>
          <div className={classesCustom.submitHolder}>
            <Button type="submit" variant="good">
              Search
            </Button>
            <Button
              type="reset"
              variant="bad"
              onClick={() => setPayrollPaymentSearchParams({})}
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    );
  };

  let columnConfig = [
    {
      key: '1',
      attribute: 'cashFlowId',
      header: 'Payment ID #',
      onClick: e =>
        View(<PayrollPaymentDetail cashFlowId={e.target.innerText} />),
      dataType: 'number',
    },
    {
      key: '2',
      attribute: 'driverName',
      header: 'Driver',
      attributeprimaryhidden: 'employeeId',
      onClick: e =>
        View(
          <ViewDriver
            driverName={e.target.innerText}
            employeeId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '3',
      attribute: 'loadIds',
      header: 'Load #',
    },
    {
      key: '4',
      attribute: 'loadMileage',
      header: 'Load Mileage',
      dataType: 'formattedNumber',
    },
    {
      key: '5',
      attribute: 'paymentDate',
      header: 'Date',
      dataType: 'date',
    },
    {
      key: '6',
      attribute: 'weekOfYear',
      header: 'Week of Year',
      dataType: 'number',
    },
    {
      key: '7',
      attribute: 'totalPayment',
      header: 'Amount',
      dataType: 'currency',
    },
  ];

  return (
    <>
      <SubHeader />
      <SearchForm />
      <BBITable data={tableData} columns={columnConfig} />
    </>
  );
}

export default PayrollPayments;
