import React, { useEffect, useState } from 'react';
import { MdOutlineRefresh, MdOutlineVpnKey } from 'react-icons/md';
import { AiOutlineCheck } from 'react-icons/ai';
import { FiAlignLeft } from 'react-icons/fi';
import { TbAlignLeft } from 'react-icons/tb';
import { VscSearch } from 'react-icons/vsc';

import Get from '../api/internal/Get';
import Create from '../helpers/panel/Create';
import Cookies from 'js-cookie';
import {
  GenerateQueryString,
  ParseUrlForSearchParams,
} from '../helpers/search/HelperFunctions';
import FilterData from '../helpers/filter/FilterData';
import DateOnlyFormatter from '../helpers/inputs/DateOnlyFormatter';
import Header from '../layouts/Header';
import Filter from '../helpers/filter/Filter';
import BBITable from '../helpers/bBITable/BBITable';
import Input from '../assets/essentials/Input';
import Button from '../assets/essentials/Button';
import View from '../helpers/slab/View';
import ViewRental from '../features/views/rental/Rental';
import ViewCustomer from '../features/views/customer/Customer';
import CreateRental from '../features/creates/rental/Rental';
import classesGlobal from '../assets/Global.module.css';
import classesTable from '../helpers/bBITable/BBITable.module.css';
import classesCustom from '../helpers/search/CustomSearch.module.css';
import Select from '../assets/essentials/Select';
import OptionList from '../helpers/options/OptionList';

function Rental({ toggleBoardRefresh }) {
  const [filterValue, setFilterValue] = useState('');
  const [rentalBoard, setRentalBoard] = useState([]);
  const [isCompact, setIsCompact] = useState(
    Cookies.get('rentalIsCompact') === 'true',
  );
  const [showViewOption, setShowViewOption] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const defaultSearchParams = {
    rentalId: null,
    reference: null,
    customerName: null,
    rentalStatusId: 0,
  };
  const [rentalSearchParams, setRentalSearchParams] =
    useState(defaultSearchParams);
  const [rentalStatusOptions, setRentalStatusOptions] = useState([]);

  useEffect(() => {
    const { rentalId, reference, customerName, rentalStatusId } =
      ParseUrlForSearchParams(defaultSearchParams);
    if (rentalStatusOptions.length === 0) {
      GetRentalStatusOptions();
    }
    if (rentalId != null || reference != null || customerName != null) {
      setIsSearchOpen(true); //open search form if using search params from URL
      setRentalSearchParams({
        rentalId: rentalId,
        reference: reference,
        customerName: customerName,
        rentalStatusId: rentalStatusId,
      });
      GetRentalBoard({
        rentalId: rentalId,
        reference: reference,
        customerName: customerName,
        rentalStatusId: rentalStatusId,
      });
    } else {
      GetRentalBoard(defaultSearchParams);
    }
  }, [toggleBoardRefresh]);

  function GetRentalStatusOptions() {
    Get(`/Rental/GetRentalStatusOptions/`).then(response => {
      if (response) {
        setRentalStatusOptions(response?.data);
      }
    });
  }

  function GetRentalBoard(searchParamsFromForm?: Object) {
    const searchParams = searchParamsFromForm
      ? searchParamsFromForm
      : rentalSearchParams;
    const queryString = '?' + GenerateQueryString(searchParams);
    const newUrl =
      window.location.origin + window.location.pathname + queryString;
    window.history.replaceState(null, null, newUrl);
    Get(`/Rental/GetRentalBoard${queryString}`).then(response => {
      if (response) {
        setRentalBoard(FormatRentals(response.data));
      }
    });
  }

  function SearchRentals(e) {
    e.preventDefault();
    setFilterValue('');
    const formData = {
      rentalId: e.target.rentalId?.value,
      reference: e.target.reference?.value,
      customerName: e.target.customerName?.value,
      rentalStatusId: e.target.rentalStatusId?.value,
    };
    setRentalSearchParams(formData);
    GetRentalBoard(formData);
  }

  function ClearSearchParams() {
    setRentalSearchParams(defaultSearchParams);
  }

  function RefreshRentalBoard() {
    setIsSearchOpen(false);
    GetRentalBoard(defaultSearchParams);
  }

  function ToggleOpenSearch() {
    if (!isSearchOpen) {
      setRentalBoard([]);
    } else {
      ClearSearchParams();
      GetRentalBoard(defaultSearchParams);
    }
    setIsSearchOpen(!isSearchOpen);
    setFilterValue('');
  }

  function FormatRentals(rentals) {
    return rentals.map(rental => ({
      ...rental,
      startDate: DateOnlyFormatter(rental.startDate),
      endDate: DateOnlyFormatter(rental.endDate),
      totalInvoice: rental.totalInvoice.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    }));
  };

  // passed into BBI Table to build table headers and rows
  let columnConfig = [
    {
      key: '1',
      attribute: 'rentalId',
      header: 'ID',
      onClick: e => View(<ViewRental rentalId={e.target.innerText} />),
      dataType: 'number',
    },
    {
      key: '2',
      attribute: 'rentalStatus',
      header: 'Status',
      attributeprimaryhidden: 'rentalId',
      onClick: e =>
        View(<ViewRental rentalId={e.target.dataset.attributeprimaryhidden} />),
      width: '7.5rem',
    },
    {
      key: '3',
      attribute: 'customerName',
      attributeprimaryhidden: 'customerId',
      header: 'Customer',
      onClick: e =>
        View(
          <ViewCustomer
            customerName={e.target.innerText}
            customerId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '4',
      attribute: 'startDate',
      header: 'Start Date',
      dataType: 'date',
    },
    {
      key: '5',
      attribute: 'endDate',
      header: 'End Date',
      dataType: 'date',
    },
    {
      key: '6',
      attribute: 'reference',
      header: 'Reference',
      // Data contains loadId, rentalId resulted in a Not Found error.
      // Will likely need updated in the future.
      attributeprimaryhidden: 'rentalId',
      onClick: e =>
        View(<ViewRental rentalId={e.target.dataset.attributeprimaryhidden} />),
    },
    {
      key: '7',
      attribute: 'totalInvoice',
      header: 'Total Invoice',
      dataType: 'currency',
    },
  ];

  const RentalSearchDisplay = ({ rentals, filterValue, columnConfig }) => (
    <>
      <div className={classesTable.title}>Results</div>
      {rentals.length > 0 ? (
        <div className={classesTable.normalView}>
          <BBITable
            data={FilterData(rentals, filterValue)}
            columns={columnConfig}
            compactView={false}
          />
        </div>
      ) : (
        <p>No Results.</p>
      )}
    </>
  );

  const RentalSearchForm = () => {
    return (
      <>
        <div className={classesCustom.customSearch}>
          <form onSubmit={e => SearchRentals(e)}>
            <label>
              Rental #
              <Input
                type="text"
                name="rentalId"
                defaultValue={rentalSearchParams.rentalId}
              />
            </label>
            <label>
              Reference
              <Input
                type="text"
                name="reference"
                defaultValue={rentalSearchParams.reference}
              />
            </label>
            <label>
              Customer Name
              <Input
                type="text"
                name="customerName"
                defaultValue={rentalSearchParams.customerName}
              />
            </label>
            <label>
              Rental Status
              <Select
                name="rentalStatusId"
                defaultValue={rentalSearchParams.rentalStatusId}
              >
                <option value="0">Active Rentals</option>
                <OptionList
                  optionData={rentalStatusOptions}
                  attributeID="rentalStatusId"
                  attributeName="status"
                />
                <option value="-1">Any Status</option>
              </Select>
            </label>
            <div className={classesCustom.submitHolder}>
              <Button type="submit" variant="good">
                Search
              </Button>
              <Button variant="bad" onClick={ClearSearchParams}>
                Cancel
              </Button>
            </div>
          </form>
        </div>
        <div>
          <RentalSearchDisplay
            rentals={rentalBoard}
            filterValue={filterValue}
            columnConfig={columnConfig}
          />
        </div>
      </>
    );
  };

  const HeaderNavButtons = () => {
    return (
      <div>
        <button
          onClick={() => Create(<CreateRental RefreshBoard={GetRentalBoard} />)}
        >
          <MdOutlineVpnKey />
          <span>Create a Rental</span>
        </button>
        <button onClick={RefreshRentalBoard}>
          <MdOutlineRefresh />
          <span>Refresh Board</span>
        </button>
        <button onClick={() => ToggleOpenSearch()}>
          <VscSearch />
          <span>Search Rental</span>
        </button>
      </div>
    );
  };

  const CompactViewButton = () => {
    return (
      <div className={classesGlobal.dropdown}>
        <button
          aria-label="Open Toggle Compact Menu"
          onClick={() => setShowViewOption(!showViewOption)}
        >
          {isCompact ? <FiAlignLeft /> : <TbAlignLeft />}
        </button>
        {showViewOption && (
          <div className={classesGlobal.dropdownOptions}>
            <div
              onClick={() => {
                setIsCompact(false);
                Cookies.set('rentalIsCompact', false);
                setShowViewOption(false);
              }}
            >
              {!isCompact ? <AiOutlineCheck /> : <div />}
              <TbAlignLeft />
              <span>Normal View</span>
            </div>
            <div
              onClick={() => {
                setIsCompact(true);
                Cookies.set('rentalIsCompact', true);
                setShowViewOption(false);
              }}
            >
              {isCompact ? <AiOutlineCheck /> : <div />}
              <FiAlignLeft />
              <span>Compact View</span>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <h1>Rental</h1>
      <Header>
        <HeaderNavButtons />
        <div>
          <CompactViewButton />
          <Filter
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            placeholder="Filter postings"
          />
        </div>
      </Header>
      {isSearchOpen ? (
        <RentalSearchForm />
      ) : rentalBoard.length > 0 ? (
        <BBITable
          data={FilterData(rentalBoard, filterValue)}
          columns={columnConfig}
          compactView={isCompact}
        />
      ) : (
        <p>No rentals.</p>
      )}
    </>
  );
}

export default Rental;
