import React from 'react';
import PostForm from '../../../api/internal/PostForm';
import View from '../../../helpers/slab/View';
import ViewLoad from '../../views/load/Load';
import ViewRental from '../../views/rental/Rental';
import classes from './DuplicateLoad.module.css';

type DuplicateType = "rental" | "load";

export default function DuplicateLoad({ type, id } : {type: DuplicateType, id: number}) {
  const HandleDuplicateLoad = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const appendix = { loadId: id };
    PostForm(`/Load/CreateDuplicateLoad`, formData, appendix).then(response => {
      if (response) {
        View(<ViewLoad loadId={response.data} />);
      }
    });
  };


  const HandleDuplicateRental = e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const appendix = { rentalId: id };
    PostForm(`/Rental/CreateDuplicateRental`, formData, appendix).then(
      response => {
        if (response) {
          View(<ViewRental rentalId={response.data} />);
        }
      },
    );
  };

  return (
    <form
      onSubmit={e =>
        type === 'load' ? HandleDuplicateLoad(e) : HandleDuplicateRental(e)
      }
      className={classes.container}
    >
      <div>
        <label>Quantity:</label>
      </div>
      <input
        type="number"
        name="quantity"
        min="1"
        max="5"
        step="1"
        defaultValue="1"
        id={`duplicate${type === 'load' ? 'Load' : 'Rental'}Input`}
      />
      <button type="submit">{`Duplicate ${
        type === 'load' ? 'Load' : 'Rental'
      }`}</button>
    </form>
  );
}
