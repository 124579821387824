import classes from './Filter.module.css';
import { FiFilter } from 'react-icons/fi';
import { RxCross1 } from 'react-icons/rx';

function Filter(props) {
  return (
    <div className={classes.filterBar}>
      <FiFilter />
      <input
        type={props.type}
        value={props.filterValue}
        placeholder={props.placeholder}
        onChange={e => {
          props.setFilterValue(e.target.value);
        }}
      />
      <RxCross1
        className={props.filterValue ? classes.displayX : classes.hiddenX}
        onClick={() => props.setFilterValue('')}
      />
    </div>
  );
}

export default Filter;
