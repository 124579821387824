import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import View from '../../helpers/slab/View';
import ViewDriver from '../../features/views/users/driver/Driver';
import FilterData from '../../helpers/filter/FilterData';
import BBITable from '../../helpers/bBITable/BBITable';
import Get from '../../api/internal/Get';

function DriverHours({ toggleBoardRefresh }) {
  const [driverHoursBoard, setDriverHoursBoard] = useState<Array<DriverBoard>>([]);
  const filterValue = useOutletContext();

  useEffect(() => {
    GetDriverHoursBoard();
  }, [toggleBoardRefresh]);

  function GetDriverHoursBoard() {
    Get(`/Employee/GetDriverHours`).then(response => {
      if (response) {
        setDriverHoursBoard(response.data);
        console.table(response.data)
      }
    });
  }

  const columnConfig = [
    {
      key: '1',
      attribute: 'name',
    //   attributeprimaryhidden: 'employeeId', TODO
      header: 'Driver',
    //   onClick: e =>
    //     View(
    //       <ViewDriver
    //         driverName={e.target.innerText}
    //         employeeId={e.target.dataset.attributeprimaryhidden}
    //       />,
    //     ),
    },
    {
      key: '2',
      attribute: 'currentVehicleName',
      header: 'Truck',
    },
    {
      key: '3',
      attribute: 'currentDutyStatus',
      header: 'Status',
    },
    {
        key: '4',
        attribute: 'shiftDrivingViolation',
        header: 'Shift Violation',
      },
      {
        key: '5',
        attribute: 'cycleViolation',
        header: 'Cycle Violation',
      },
      {
        key: '6',
        attribute: 'timeUntilBreak',
        header: 'Time Until Break',
      },
      {
        key: '7',
        attribute: 'driveRemaining',
        header: 'Drive Time Remaining',
      },
      {
        key: '8',
        attribute: 'shiftRemaining',
        header: 'Shift Time Remaining',
      },
      {
        key: '9',
        attribute: 'cycleStartedAt',
        header: 'Cycle Started',
      },
      {
        key: '10',
        attribute: 'cycleRemaining',
        header: 'Cycle Remaining',
      },
      {
        key: '11',
        attribute: 'cycleTomorrow',
        header: 'Cycle Tomorrow',
      },
  ];

  let tableData = FilterData(driverHoursBoard, filterValue);

  return <BBITable data={tableData} columns={columnConfig} />;
}

export default DriverHours;
