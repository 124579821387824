import React, { useState, useRef } from 'react';
import Select from '../../../../assets/essentials/Select';
import OptionList from '../../../../helpers/options/OptionList';
import classesGlobal from '../../../../assets/Global.module.css';
import Input from '../../../../assets/essentials/Input';
import classesSubHeader from './ApplyReceivablePaymentSearchParamForm.module.css';
import Button from '../../../../assets/essentials/Button';

function GetApplyPaymentsSearchParams({
  setAppliedPaymentStatus,
  searchOptionsList,
  selectCustomerId,
  GetCustomerAssignments,
  GenerateQueryString,
}) {
  // Used to switch between making rentalId and loadId fields readOnly
  const [activeInput, setActiveInput] = useState('loadId');
  const [inputValues, setInputValues] = useState({
    loadId: '',
    rentalId: '',
  });

  // Handles updates to LoadId and RentalId fields
  const HandleInputChange = e => {
    const { name, value } = e.target;
    setInputValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
  };

  // Function to clear the values in the input fields when switching from LoadId to RentalId and vice-versa
  const HandleReadOnlySwitch = inputType => {
    if (inputType === 'loadId') {
      setInputValues(prevValues => ({
        ...prevValues,
        loadId: '',
        rentalId: null,
        customerId: null,
      }));
      setActiveInput('loadId');
    } else if (inputType === 'rentalId') {
      setInputValues(prevValues => ({
        ...prevValues,
        rentalId: '',
        loadId: null,
        customerId: null,
      }));
      setActiveInput('rentalId');
    }
  };

  function HandleSubmit(e, values) {
    e.preventDefault();
    const queryParams = GenerateQueryString({
      loadId: values.loadId || '',
      rentalId: values.rentalId || '',
      customerId: values.customerId || '',
    });
    GetCustomerAssignments(queryParams);
  }

  // Calling the API onChange for the customer select, so update the array and call HandleSubmit
  const HandleCustomerChange = e => {
    selectCustomerId = e.target.value;
    setAppliedPaymentStatus('idle');
    setInputValues(prevValues => {
      const newValues = {
        ...prevValues,
        customerId: selectCustomerId,
        rentalId: null,
        loadId: null,
      };
      HandleSubmit(e, newValues);
      return newValues;
    });
  };

  return (
    <form
      onSubmit={e => {
        HandleSubmit(e, inputValues);
      }}
      className={classesSubHeader.arApplyPaymentForm}
    >
      <h4>Find Outstanding Receivables</h4>
      <span>
        <section className={classesSubHeader.customerSection}>
          {' '}
          <div className={classesGlobal.attribute}>
            <label htmlFor="customerId">Customer</label>
            <Select
              value={selectCustomerId != null ? selectCustomerId : ''}
              onChange={e => {
                HandleCustomerChange(e);
              }}
              id="customerId"
            >
              <option disabled value="">
                Select Customer
              </option>
              <OptionList
                optionData={searchOptionsList}
                attributeID="customerId"
                attributeName="customerName"
              />
            </Select>
          </div>
        </section>
        <div className={classesSubHeader.divider}></div>
        <section className={classesSubHeader.idSection}>
          <div className={classesGlobal.attribute}>
            <label htmlFor="loadId">Load ID</label>
            <Input
              type="number"
              name="loadId"
              id="loadId"
              onChange={HandleInputChange}
              onClick={() => HandleReadOnlySwitch('loadId')}
              readOnly={activeInput !== 'loadId'}
              value={inputValues.loadId || ''}
            />
          </div>
          <div className={classesGlobal.attribute}>
            <label htmlFor="rentalId">Rental ID</label>
            <Input
              type="number"
              name="rentalId"
              id="rentalId"
              onChange={HandleInputChange}
              onClick={() => HandleReadOnlySwitch('rentalId')}
              readOnly={activeInput !== 'rentalId'}
              value={inputValues.rentalId || ''}
            />
          </div>
        </section>
        <Button variant="good" type="submit">
          Search by ID
        </Button>
      </span>
    </form>
  );
}
export default GetApplyPaymentsSearchParams;
