import React from 'react';
import classesPayment from './Summary.module.css';
import BBITable from '../../../../helpers/bBITable/BBITable';
import View from '../../../../helpers/slab/View';
import ViewLoad from '../../load/Load';
import Button from '../../../../assets/essentials/Button';
import TransportationCaller from '../../../../api/internal/TransportationCaller';
import DateOnlyFormatter from '../../../../helpers/inputs/DateOnlyFormatter';
import classesGlobal from '../../../../assets/Global.module.css';

function PayrollPaymentDetails({
  data,
  cashFlowId,
}: {
  data: APPaymentDetail;
  cashFlowId: number;
}) {
  const formattedAppliedCashFlows = data?.appliedCashFlows.map(
    appliedCashFlow => ({
      ...appliedCashFlow,
      amount: appliedCashFlow.amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      loadMileage: appliedCashFlow.loadMileage.toLocaleString(),
    }),
  );

  let columnConfig = [
    {
      key: '1',
      attribute: 'loadId',
      header: 'Load #',
      attributeprimaryhidden: 'loadId',
      onClick: e =>
        View(
          <ViewLoad
            loadId={parseInt(e.target.dataset.attributeprimaryhidden)}
          />,
        ),
    },
    {
      key: '2',
      attribute: 'loadMileage',
      header: 'Load Mileage',
    },
    {
      key: '3',
      attribute: 'amount',
      header: 'Amount',
    },
  ];

  function DownloadPayrollDetailPDF() {
    const path = `/Accounting/DownloadPayrollDetailPDF/${cashFlowId}`;
    TransportationCaller.get(path, {
      responseType: 'blob',
    }).then(response => {
      if (response) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        const fileName = `BBI Payroll Week ${data.weekOfYear}_${data.driverName}.pdf`;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(url);
      }
    });
  }

  return (
    <div className={classesPayment.paymentSummary}>
      <div className={classesPayment.paymentSummaryHeader}>
        <span className={classesPayment.boldText}>Driver: </span>
        <span>{data.driverName}</span>
        <span className={classesPayment.boldText}>Payment ID: </span>
        <span>{cashFlowId}</span>
        <span className={classesPayment.boldText}>Total: </span>
        <span>
          {data.total.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
          })}
        </span>
        <span className={classesPayment.boldText}>Total Mileage: </span>
        <span>{data.totalMileage.toLocaleString()}</span>
        <span className={classesPayment.boldText}>Payment Date: </span>
        <span>{DateOnlyFormatter(data.paymentDate)}</span>
        <span className={classesPayment.boldText}>Week of Year: </span>
        <span>{data.weekOfYear}</span>
      </div>
      <BBITable
        data={formattedAppliedCashFlows}
        columns={columnConfig}
        compactView={false}
      />
      <span>
        <div>
          <Button variant="good" onClick={() => DownloadPayrollDetailPDF()}>
            <span>Download Report</span>
          </Button>
        </div>
      </span>
    </div>
  );
}

export default PayrollPaymentDetails;
