import React from 'react';
import classes from './Holder.module.css';
import Close from './Close';
import ChildCountObserver from '../ChildCountObserver';

function SlabHolder({ onChildCountChange }) {
  
  return (
    <div className={classes.slabContainer}>
      <div className={classes.overlay} onClick={() => Close()}></div>
      <ChildCountObserver
        targetElementId="slabHolder"
        onChildCountChange={onChildCountChange}
      >
        <div id="slab-container" className={classes.slabHolder}></div>
      </ChildCountObserver>
    </div>
  );
}

export default SlabHolder;
