import React, { useEffect, useState } from 'react';

import Details from './Details';
import Panel from '../../../helpers/panel/Panel';
import Stop from './Stop';
import Get from '../../../api/internal/Get';
import PostBody from '../../../api/internal/PostBody';
import Close from '../../../helpers/panel/Close';
import View from '../../../helpers/slab/View';
import ViewLoad from '../../views/load/Load';

function Load({ RefreshBoard }) {
  const [refreshPages, setRefreshPages] = useState(false);
  const [minCargoAreaLengths, setMinCargoAreaLengths] = useState([]);
  const [createLoadOptions, setCreateLoadOptions] =
    useState<CreateLoadOptions>();
  const [createLoadParam, setCreateLoadParam] = useState<CreateLoadParam>({
    availableCredit: null,
    branchId: null,
    customerId: null,
    reference: null,
    commodityId: null,
    equipmentTypeId: null,
    minCargoAreaLengthId: null,
    temperature: null,
    weight: null,
    pallets: null,
    cases: null,
    instructions: null,
    stopList: [],
  });

  const [requirementDisplay] = useState<LoadRequirementDisplay>({
    availableCredit: null,
    customer: '',
    commodity: '',
    equipment: '',
    stopType: '',
    length: '',
  });

  const REEFER_EQUIPMENT_ID = 2;
  const POWER_ONLY_IDS = [7, 8];

  function CreateStop(index: number): CreateStopParam {
    return {
      index: index,
      locationObj: {
        locationId: null,
        locationName: '',
        ianaTimeZone: '',
        addressLineOne: '',
        addressLineTwo: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        phone: '',
        phoneExt: '',
        email: '',
        hours: '',
        directions: '',
        website: '',
        specialInstructions: '',
        searchResult: '',
      },
      ianaTimeZone: null,
      locationId: null,
      stopTypeId: null,
      appointmentDate: null,
      appointmentTime: null,
      appointmentTimeUpperRange: null,
      deliveryInfo: null,
      poNumbers: null,
      weight: null,
      pallets: null,
      cases: null,
      product: null,
      sku: null,
    };
  }
  useEffect(() => {
    GetOptionsForCreateLoad();
  }, []);

  function HandleCustomerSelection(e) {
    UpdateCreateLoadParam(e.target.value, 'customerId');
    const selectedCustomerId = parseInt(e.target.value);
    const selectedCustomer = createLoadOptions.customerOptions.find(
      customer => customer.customerId === selectedCustomerId,
    );
    UpdateCreateLoadParam(selectedCustomer.availableCredit, 'availableCredit');
    requirementDisplay.availableCredit = selectedCustomer.availableCredit;
    requirementDisplay.customer = selectedCustomer.customerName;
  }

  function GetOptionsForCreateLoad() {
    Get(`Load/GetOptionsForCreateLoad/`).then(response => {
      if (response) {
        setCreateLoadOptions(response.data);
        setCreateLoadParam(prevState => ({
          ...prevState,
          branchId: response.data.userBranchId,
        }));
      }
    });
  }

  function CreateLoad() {
    PostBody('Load/CreateLoad', createLoadParam).then(response => {
      if (response) {
        RefreshBoard();
        Close();
        View(<ViewLoad loadId={response.data} />);
      }
    });
  }

  let prefixPages = [
    {
      title: 'Details',
      displayedIcon: '',
      content: createLoadOptions && (
        <Details
          loadDetails={createLoadParam}
          createLoadOptionLists={createLoadOptions}
          minCargoAreaLengths={minCargoAreaLengths}
          setMinCargoAreaLengths={setMinCargoAreaLengths}
          UpdateParam={UpdateCreateLoadParam}
          UpdateStopListLength={UpdateStopListLength}
          requirementDisplay={requirementDisplay}
          HandleCustomerSelection={HandleCustomerSelection}
          REEFER_EQUIPMENT_ID={REEFER_EQUIPMENT_ID}
          POWER_ONLY_IDS={POWER_ONLY_IDS}
        />
      ),
      require: [
        {
          label: 'Customer',
          FunctionBool: () =>
            createLoadParam.customerId && createLoadParam.availableCredit > 0,
          assignedValue: () => requirementDisplay.customer,
          missingDesc:
            createLoadParam.customerId == null
              ? 'Customer has not been assigned.'
              : requirementDisplay.customer + ' does not have enough credit.',
        },
        {
          label: 'Commodity',
          FunctionBool: () =>
            createLoadParam.commodityId ||
            POWER_ONLY_IDS.includes(Number(createLoadParam.equipmentTypeId)),
          assignedValue: () => {
            if (
              POWER_ONLY_IDS.includes(
                Number(createLoadParam.equipmentTypeId),
              ) &&
              !requirementDisplay.commodity
            ) {
              return 'Commodity not required for power only';
            } else {
              return requirementDisplay.commodity;
            }
          },
          missingDesc: 'Commodity has not been assigned.',
        },
        {
          label: 'Equipment',
          FunctionBool: () => createLoadParam.equipmentTypeId,
          assignedValue: () => requirementDisplay.equipment,
          missingDesc: 'Equipment has not been assigned.',
        },
        {
          label: 'Length',
          FunctionBool: () =>
            createLoadParam.minCargoAreaLengthId ||
            POWER_ONLY_IDS.includes(Number(createLoadParam.equipmentTypeId)),
          assignedValue: () => {
            if (
              POWER_ONLY_IDS.includes(Number(createLoadParam.equipmentTypeId))
            ) {
              return 'Length not required for power only';
            } else {
              return requirementDisplay.length;
            }
          },
          missingDesc: 'Length has not been assigned.',
        },
        {
          label: 'Branch',
          FunctionBool: () => createLoadParam.branchId,
          assignedValue: () =>
            createLoadOptions?.branchOptions.find(
              x => Number(x.branchId) === Number(createLoadParam.branchId),
            )?.branchName,
          missingDesc: 'Branch has not been assigned.',
        },
      ],
    },
  ];

  if (createLoadParam.equipmentTypeId == REEFER_EQUIPMENT_ID) {
    prefixPages[0].require.push({
      label: 'Temperature',
      FunctionBool: () => createLoadParam.temperature,
      assignedValue: () => createLoadParam.temperature.toString() + '°F',
      missingDesc: 'Temperature has not been assigned.',
    });
  }

  let stopPages = [];
  createLoadParam.stopList.map((stop, index) =>
    stopPages.push({
      title: 'Stop #' + (index + 1),
      displayedIcon: '',
      content: (
        <Stop
          stop={stop}
          createLoad={createLoadOptions}
          UpdateParam={UpdateCreateLoadParamStop}
          stopIndex={index}
          requirementDisplay={requirementDisplay}
        />
      ),
      require: [
        {
          label: 'Stop Type',
          FunctionBool: () => createLoadParam.stopList[index].stopTypeId,
          assignedValue: () =>
            createLoadOptions.stopTypeOptions.find(
              x =>
                x.stopTypeId ===
                Number(createLoadParam.stopList[index].stopTypeId),
            ).type,
          missingDesc: 'Stop Type has not been assigned.',
        },
        {
          label: 'Location',
          FunctionBool: () =>
            (createLoadParam.stopList[index].locationObj as LocationObj)
              ?.locationName,
          assignedValue: () => {
            const locationObj = createLoadParam.stopList[index]
              .locationObj as LocationObj;
            // Add the location name - address line one
            let addressString =
              locationObj.locationName + ' - ' + locationObj.addressLineOne;

            // Add address line two if applicable
            if (locationObj.addressLineTwo) {
              addressString += ' ' + locationObj.addressLineTwo;
            }

            // Add on the remainder of the address - City, State ZIP
            addressString +=
              ' ' +
              locationObj.city +
              ', ' +
              locationObj.state +
              ' ' +
              locationObj.zip;

            // Return final location name with full address.
            // Helps ensure the user selected the correct location when there are multiple with the same name.
            return addressString;
          },
          missingDesc: 'Location has not been assigned.',
        },
        {
          label: 'Date',
          FunctionBool: () => createLoadParam.stopList[index].appointmentDate,
          assignedValue: () => {
            let parts = createLoadParam.stopList[index].appointmentDate
              .toString()
              .split('-');
            let formattedDate = `${parts[1]}/${parts[2]}/${parts[0]}`;
            return formattedDate;
          },
          missingDesc: 'Date has not been assigned.',
        },
      ],
    }),
  );

  let pages = prefixPages.concat(stopPages);
  function OnSubmit() {
    let fulfillsFormRequirements = true;
    pages.forEach((page, index) => {
      if (!FulFillsPageRequirements(index)) {
        fulfillsFormRequirements = false;
      }
    });
    if (fulfillsFormRequirements) {
      CreateLoad();
    } else alert('Not all info included');
  }

  function UpdateStopListLength(newValue) {
    let updateValue = 50;
    if (newValue < 50) {
      updateValue = newValue;
    }
    while (createLoadParam.stopList.length > updateValue) {
      createLoadParam.stopList.splice(-1);
    }
    for (let i = 0; i < updateValue; i++) {
      if (updateValue > createLoadParam.stopList.length) {
        createLoadParam.stopList.push(CreateStop(i));
      }
    }
    setRefreshPages(!refreshPages);
  }

  function UpdateCreateLoadParam(newValue, attributeName) {
    setCreateLoadParam(prevCreateLoadParam => {
      if (newValue?.toString().trim().length !== 0) {
        return {
          ...prevCreateLoadParam,
          [attributeName]: newValue,
        };
      }
      return { ...prevCreateLoadParam, [attributeName]: null };
    });
  }

  function UpdateCreateLoadParamStop(newValue, attributeName, stopIndex) {
    if (newValue?.toString().trim().length !== 0) {
      createLoadParam.stopList[stopIndex][attributeName] = newValue;
    } else {
      createLoadParam.stopList[stopIndex][attributeName] = null;
    }
  }

  function FulFillsPageRequirements(pageIndex) {
    let fulfillsRequirements = true;
    pages[pageIndex].require.forEach(requirement => {
      if (!requirement.FunctionBool()) {
        fulfillsRequirements = false;
      }
    });
    return fulfillsRequirements;
  }
  return <Panel OnSubmit={OnSubmit} title="Create Load" pages={pages} />;
}

export default Load;
