import React, { useEffect } from 'react';

import classes from './Error.module.css';
import Button from '../../assets/essentials/Button';
import Close from './Close';
import { AxiosError } from 'axios';

type ErrorProps = {
  error?: AxiosError;
  customMessage?: string;
  messageCharacterLimit?: number;
};

function Error({
  error,
  customMessage,
  messageCharacterLimit = 200,
}: ErrorProps) {
  useEffect(() => {
    const handleEsc = event => {
      let escapeKeyCode = 27;
      if (event.keyCode === escapeKeyCode) {
        Close();
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const status = error?.response?.status || 500;
  let message: string;

  switch (status) {
    case 403:
      message =
        'Verify that you are signed in with a Transportation account and try again.';
      break;
    case 401:
      message =
        'Unauthorized to perform this action. Please contact your administrator.';
      break;
    default:
      const genericErrorMessage = 'An error has occurred. Please try again.';
      const errorMessage = error?.response?.data || error?.message;
      message =
        typeof errorMessage === 'string' &&
        errorMessage.length < messageCharacterLimit
          ? errorMessage
          : genericErrorMessage;
      break;
  }

  return (
    <div className={classes.error}>
      <h2>Error - {status}</h2>
      <h3>{customMessage ? customMessage : message}</h3>
      <Button variant="bad" onClick={Close}>
        Cancel
      </Button>
    </div>
  );
}

export default Error;
