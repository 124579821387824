import React, { useState, useEffect } from 'react';

import Details from './GasDetails';
import Panel from '../../../helpers/panel/Panel';
import PostBody from '../../../api/internal/PostBody';
import Close from '../../../helpers/panel/Close';
import Get from '../../../api/internal/Get';

function Gas({ GetGasBoard }) {
  //all below is demo and not final code
  const [truckTrailerList, setTruckTrailerList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [createDriverParam] = useState({
    branchId: null,
    firstName: null,
    lastName: null,
    phone: null,
    phoneExt: null,
    mobile: null,
    email: null,
    hireDate: null,
    isIndependentContractor: false,
    note: null,
    preferredTruckId: null,
    preferredTrailerId: null,
    license: null,
    licenseExpirationDate: null,
  });

  useEffect(() => {
    GetBranchList();
    GetTruckTrailerList();
  }, []);

  function GetTruckTrailerList() {
    Get(`/Employee/GetCreateDriverTruckTrailerList`).then(response => {
      if (response) {
        setTruckTrailerList(response.data);
      }
    });
  }

  function GetBranchList() {
    Get(`/Employee/GetBranchList`).then(response => {
      if (response) {
        setBranchList(response.data);
      }
    });
  }

  function CreateDriver() {
    PostBody('Employee/CreateDriver', createDriverParam).then(() => {
      GetGasBoard();
      Close();
    });
  }

  let pages = [
    {
      title: 'Details',
      displayedIcon: '',
      content: <Details />,
      require: [
        {
          label: 'First Name',
          FunctionBool: () =>
            createDriverParam.firstName !== '' && createDriverParam.firstName,
          assignedValue: () => createDriverParam.firstName,
          missingDesc: 'First Name has not been assigned.',
        },
        {
          label: 'Hire Date',
          FunctionBool: () => createDriverParam.hireDate,
          assignedValue: () => createDriverParam.hireDate,
          missingDesc: 'Hire Date has not been assigned.',
        },
        {
          label: 'License',
          FunctionBool: () => createDriverParam.license,
          assignedValue: () => createDriverParam.license,
          missingDesc: 'License Expiration Date has not been assigned.',
        },
        {
          label: 'License Expiration',
          FunctionBool: () => createDriverParam.licenseExpirationDate,
          assignedValue: () => createDriverParam.licenseExpirationDate,
          missingDesc: 'License Expiration Date has not been assigned.',
        },
      ],
    },
  ];

  function OnSubmit() {
    let fulfillsFormRequirements = true;
    pages.forEach((page, index) => {
      if (!FulFillsPageRequirements(index)) {
        fulfillsFormRequirements = false;
      }
    });
    if (fulfillsFormRequirements) {
      CreateDriver();
      Close();
    } else alert('Not all info included');
  }

  function UpdateCreateDriver(newValue, attributeName) {
    createDriverParam[attributeName] = newValue;
  }

  function FulFillsPageRequirements(pageIndex) {
    let fulfillsRequirements = true;
    pages[pageIndex].require.forEach(requirement => {
      if (!requirement.FunctionBool()) {
        fulfillsRequirements = false;
      }
    });
    return fulfillsRequirements;
  }

  return <Panel OnSubmit={OnSubmit} title="Create Gas Report" pages={pages} />;
}

export default Gas;
