import React, { useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import classes from './MultipleEmailInput.module.css';
import type { ComponentPropsWithoutRef } from 'react';

interface MultipleEmailInputProps extends ComponentPropsWithoutRef<'input'> {
  emails: string[];
  setEmails: Function;
  submitButtonId: string;
}

/**
 * MultipleEmailInput component for handling multiple emails.
 *
 * This component allows users to input multiple email addresses, validate them, and display them as tags.
 *
 * @param {string[]} emails - An array of email addresses, a useState held in the parent Form component.
 * @param {Function} setEmails - A function to update the email addresses in the parent component.
 * @param {string} submitButtonId - The ID of the submit button in the parent form.
 *
 * @returns {JSX.Element} - The MultipleEmailInput component.
 *
 * @example
 * // Example usage of MultipleEmailInput component:
 * <MultipleEmailInput
 *   emails={useStateEmailsArray}
 *   setEmails={setUseStateEmailsArray}
 *   submitButtonId="submit-button"
 * />
 */

export default function MultipleEmailInput({
  emails,
  setEmails,
  submitButtonId,
  ...rest
}: MultipleEmailInputProps) {
  //props emails/setEmails are holding the state in the parent component, where the backend call is made
  //the submit button in the form needs a uniqueId to be passed down to enable save changes button
  const [value, setValue] = useState<string>('');
  const [error, setError] = useState<string>(null);

  const HandleChange = e => {
    setValue(e.target.value);
    setError(null);
  };

  //after specific key presses, trims, validates, adds email to emails array
  const HandleKeyDown = e => {
    if (['Enter', 'Tab', ',', ';'].includes(e.key)) {
      e.preventDefault();
      const email = value.trim();
      if (email && IsValid(email)) {
        setEmails(prevEmails => [...prevEmails, email]);
        setValue('');
        EnableSubmitButton(submitButtonId);
      }
    }
  };

  const HandleDelete = (toBeRemoved: string) => {
    setEmails(emails.filter(email => email !== toBeRemoved));
    EnableSubmitButton(submitButtonId);
  };

  const HandleBlur = () => {
    const email = value.trim();
    if (email && IsValid(email)) {
      setEmails(prevEmails => [...prevEmails, email]);
      setValue('');
      EnableSubmitButton(submitButtonId);
    }
  };

  //grabs clipboard text from event, applies regex test, checks if existing emails are in current array, then adds
  const HandlePaste = e => {
    e.preventDefault();
    const paste = e.clipboardData.getData('text');
    const extractedEmails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
    if (extractedEmails && extractedEmails.length > 0) {
      setEmails(prevEmails => {
        const toBeAdded = extractedEmails.filter(email => !isInList(email));
        return [...prevEmails, ...toBeAdded];
      });
      EnableSubmitButton(submitButtonId);
    }
  };

  //via prop from parent holding Form component, removes disabled when inputs aren't changed ex: delete, pasting
  const EnableSubmitButton = submitButtonId => {
    const submitButton = document.getElementById(submitButtonId);
    submitButton.removeAttribute('disabled');
  };

  let isEmail = (email: string) => {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  };

  let isInList = (email: string) => {
    return emails.includes(email);
  };

  const IsValid = (email: string) => {
    if (!isEmail(email)) {
      setError(`${email} is not a valid email address.`);
      return false;
    }
    if (isInList(email)) {
      setError(`${email} has already been added.`);
      return false;
    }
    setError(null);
    return true;
  };

  return (
    <>
      <input
        className={error ? classes.hasError : classes.MultipleEmailInput}
        placeholder="Type or paste emails, then press Enter"
        value={value}
        onChange={HandleChange}
        onKeyDown={HandleKeyDown}
        onPaste={HandlePaste}
        onBlur={HandleBlur}
        type='email'
        {...rest}
      />
      {error && <p className={classes.error}>{error}</p>}
      <div className={classes.emailHolder}>
        {emails?.map(email => (
          <div key={email} className={classes.tagItem}>
            {email}
            <AiOutlineCloseCircle
              className={classes.deleteIcon}
              onClick={() => HandleDelete(email)}
              key={email}
            />
          </div>
        ))}
      </div>
    </>
  );
}
