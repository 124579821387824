import React from 'react';

function OptionList({
  optionData,
  attributeID,
  attributeName,
  attributeGroup,
}: {
  optionData: Array<any>;
  attributeID: string;
  attributeName: string;
  attributeGroup?: string;
}) {
  let groupsList = [];
  let optionList = [];
  if (attributeGroup) {
    optionData?.map(option => {
      if (!groupsList.includes(option[attributeGroup])) {
        groupsList.push(option[attributeGroup]);
      }
    });
    groupsList?.map((optionGroup, index) => {
      let optionSubGroup = [];
      optionData?.map(option => {
        if (option[attributeGroup] === optionGroup) {
          optionSubGroup.push(
            <option key={option[attributeID]} value={option[attributeID]}>
              {option[attributeName]}
            </option>,
          );
        }
      });
      optionList.push(
        <optgroup key={index} label={optionGroup}>
          {optionSubGroup}
        </optgroup>,
      );
    });
  } else {
    optionList = optionData?.map((option, index) => (
      <option key={index} value={option[attributeID]}>
        {option[attributeName]}
      </option>
    ));
  }
  return <>{optionList}</>;
}
export default OptionList;
