import React, { useState } from 'react';

import Form from '../../../assets/essentials/Form';
import classes from '../../../assets/Global.module.css';
import PutForm from '../../../api/internal/PutForm';
import Button from '../../../assets/essentials/Button';
import Input from '../../../assets/essentials/Input';
import PostBody from '../../../api/internal/PostBody';

export default function Admin({
  data,
  vendorId,
  RefreshPage,
}: {
  data: VendorAdmin;
  vendorId: number;
  RefreshPage: Function;
}) {
  function UpdateVendorAdmin(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const appendix = {
      vendorId: vendorId,
    };
    PutForm(`/Vendor/UpdateVendorAdmin`, formData, appendix).then(response => {
      if (response) {
        RefreshPage('Admin', ['Audit']);
      }
    });
  }

  const vendorHasQuickbooksId =
    data?.quickBooksVendorId !== null && data?.quickBooksVendorId !== '';

  const CreateQuickbooksIdButton = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    function PostVendorToQuickbooks() {
      setIsLoading(true);
      if (vendorId)
        PostBody('Accounting/PostVendorToQuickBooks', {
          vendorId: Number(vendorId),
        })
          .then(response => {
            if (response) {
              RefreshPage('Admin', ['Audit']);
            }
          })
          .finally(() => setIsLoading(false));
    }
    return (
      <div>
        {isLoading ? (
          <Button disabled>Pending...</Button>
        ) : (
          <Button
            variant="good"
            disabled={vendorHasQuickbooksId}
            onClick={() => PostVendorToQuickbooks()}
            type="button"
          >
            {vendorHasQuickbooksId ? 'ID Already Exists' : 'Post To QuickBooks'}
          </Button>
        )}
      </div>
    );
  };

  return (
    <form
      onSubmit={e => UpdateVendorAdmin(e)}
      className={`${classes.viewGrid}`}
    >
      <section>
        <div className={`${classes.attribute}`}>
          <label htmlFor="quickBooksVendorId">QuickBooks Vendor ID</label>
          <Input
            defaultValue={data?.quickBooksVendorId}
            name="quickBooksVendorId"
          />
        </div>
        <div>
          <Button
            disabled={!vendorHasQuickbooksId}
            variant="good"
            type="submit"
          >
            Save Changes
          </Button>
        </div>
      </section>
      <div className={classes.marginTop1Rem}>
        <CreateQuickbooksIdButton />
      </div>
    </form>
  );
}
