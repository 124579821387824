import React, { useState } from 'react';

import classes from '../../../assets/Global.module.css';
import OptionList from '../../../helpers/options/OptionList';
import View from '../../../helpers/slab/View';
import ViewCustomer from '../customer/Customer';
import ViewLocation from '../location/Location';
import PutForm from '../../../api/internal/PutForm';
import Button from '../../../assets/essentials/Button';
import Select from '../../../assets/essentials/Select';
import Input from '../../../assets/essentials/Input';
import TextArea from '../../../assets/essentials/TextArea';
import Form from '../../../assets/essentials/Form';
import CreateNote from '../../creates/notes/Load';
import BBITable from '../../../helpers/bBITable/BBITable';
import DuplicateLoad from './DuplicateLoad';
import Driver from './Driver';
import Vendor from './Vendor';
import AssignVendor from '../../assign/Vendor';
import AssignDriver from '../../assign/Driver';
import DateTimeUTCFormatter from '../../../helpers/inputs/DateTimeUTCFormatter';

export default function Summary({
  data,
  loadId,
  RefreshPage,
  HandlePageChange,
  GetLoad,
}: {
  data: LoadSummary;
  loadId: number;
  RefreshPage: Function;
  HandlePageChange: Function;
  GetLoad: Function;
}) {
  const [lengthOptions, setLengthOptions] = useState(FindDefaultOptionList);
  const [reefer, setReefer] = useState(data?.equipmentTypeId == 2);
  const [displayDuplicateLoad, setDisplayDuplicateLoad] =
    useState<boolean>(false);

  function FindDefaultOptionList() {
    let equipmentTypeIndex = data?.equipmentTypeOptions.findIndex(
      x => x.equipmentTypeId == data.equipmentTypeId,
    );
    if (
      (equipmentTypeIndex || equipmentTypeIndex === 0) &&
      equipmentTypeIndex !== -1
    ) {
      return (
        <OptionList
          optionData={
            data.equipmentTypeOptions[equipmentTypeIndex]
              .minCargoAreaLengthOptions
          }
          attributeID="minCargoAreaLengthId"
          attributeName="displayLength"
        />
      );
    }
  }

  function UpdateEquipment(equipmentTypeId) {
    // if (!data.equipmentTypeId) {
    //   return;
    // }
    const REEFER_EQUIPMENTID = 2;
    // Set reefer to true so that it can be used in conditional rendering for the temperature field
    setReefer(equipmentTypeId == REEFER_EQUIPMENTID);
    let equipmentTypeIndex = data.equipmentTypeOptions.findIndex(
      x => x.equipmentTypeId == equipmentTypeId,
    );
    if (
      (equipmentTypeIndex || equipmentTypeIndex === 0) &&
      equipmentTypeIndex !== -1
    ) {
      setLengthOptions(
        <OptionList
          optionData={
            data.equipmentTypeOptions[equipmentTypeIndex]
              .minCargoAreaLengthOptions
          }
          attributeID="minCargoAreaLengthId"
          attributeName="displayLength"
        />,
      );
    }
  }

  function UpdateLoadDetails(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const appendix = {
      loadId: loadId,
    };
    PutForm(`/Load/UpdateLoadDetails`, formData, appendix).then(() => {
      GetLoad();
      RefreshPage('Summary', ['Audit', 'Financials', 'Itinerary']);
    });
  }

  let stopTableColumnConfig = [
    {
      key: '1',
      attribute: 'stopType',
      header: 'Type',
      width: '18ch',
    },
    {
      key: '2',
      attribute: 'appointment',
      header: 'Appointment',
    },
    {
      key: '3',
      attribute: 'locationName',
      attributeprimaryhidden: 'locationId',
      header: 'Location',
      onClick: e =>
        e.target.dataset.attributeprimaryhidden &&
        View(
          <ViewLocation
            locationId={e.target.dataset.attributeprimaryhidden}
            locationName={e.target.innerText}
          />,
        ),
    },
    {
      key: '4',
      attribute: 'cityState',
      header: 'City/State',
    },
    {
      key: '5',
      attribute: 'note',
      header: 'Note',
    },
  ];

  const LoadStatus = () => {
    //hidden input required to pass loadStatusId via form submission
    if (!data) return null;
    const canLoadStatusBeUpdated = data.loadStatusOptions.length !== 1;
    const selectId = 'loadStatusSelect';
    const hiddenInputId = 'loadStatusHidden';

    return (
      <>
        <label htmlFor={canLoadStatusBeUpdated ? selectId : hiddenInputId}>
          Status
        </label>
        {!canLoadStatusBeUpdated ? (
          <>
            <Input
              type="hidden"
              name="loadStatusId"
              id={hiddenInputId}
              defaultValue={data.loadStatusId}
            />
            <Select defaultValue={data.loadStatusId} disabled>
              <OptionList
                optionData={data.loadStatusOptions}
                attributeID="loadStatusId"
                attributeName="status"
                attributeGroup="group"
              />
            </Select>
          </>
        ) : (
          <Select
            name="loadStatusId"
            id={selectId}
            defaultValue={data?.loadStatusId}
          >
            <OptionList
              optionData={data.loadStatusOptions}
              attributeID="loadStatusId"
              attributeName="status"
              attributeGroup="group"
            />
          </Select>
        )}
      </>
    );
  };

  return (
    <>
      <CreateNote
        data={data}
        loadId={loadId}
        page={'Summary'}
        RefreshPage={RefreshPage}
      />
      <h3>Details</h3>
      <Form onSubmit={e => UpdateLoadDetails(e)}>
        <div className={classes.attribute}>
          <label>Customer</label>
          <Input readOnly type="text" defaultValue={data.customerName} />
          <span
            className={`${classes.cTA}`}
            onClick={() =>
              View(
                <ViewCustomer
                  customerId={data.customerId}
                  customerName={data.customerName}
                />,
              )
            }
          >
            Manage Customer Info
          </span>
        </div>
        <div className={`${classes.attribute}`}>
          <label>Reference</label>
          <Input defaultValue={data?.reference} name="reference" />
        </div>
        <div className={`${classes.attribute}`}>
          <LoadStatus />
        </div>
        <div className={classes.attribute}>
          <label>Equipment</label>
          <Select
            defaultValue={data?.equipmentTypeId}
            name="equipmentTypeId"
            onChange={e => UpdateEquipment(e.target.value)}
          >
            <OptionList
              optionData={data.equipmentTypeOptions}
              attributeID="equipmentTypeId"
              attributeName="type"
            />
          </Select>
        </div>
        <div className={`${classes.attribute}`}>
          <label>Length</label>
          <Select
            defaultValue={data?.minCargoAreaLengthId}
            name="minCargoAreaLengthId"
            disabled={
              lengthOptions && lengthOptions.props.optionData.length === 0
            }
          >
            {lengthOptions}
          </Select>
        </div>

        <div className={classes.attribute}>
          <label>
            Temperature (°F){' '}
            {reefer && <span className={classes.required}>*</span>}
          </label>
          <Input
            required
            type="number"
            disabled={!reefer}
            defaultValue={data?.temperature}
            name="temperature"
          />
        </div>
        <div className={`${classes.attribute}`}>
          <label>Commodity</label>
          <Select defaultValue={data?.commodityId} name="commodityId">
            <OptionList
              optionData={data.commodityTypeOptions}
              attributeID="commodityId"
              attributeName="type"
              attributeGroup="group"
            />
          </Select>
        </div>
        <div className={classes.attribute}>
          <label>Weight</label>
          <Input type="number" defaultValue={data?.weight} name="weight" />
        </div>
        <div className={`${classes.attribute}`}>
          <label>Pallets</label>
          <Input type="number" defaultValue={data?.pallets} name="pallets" />
        </div>
        <div className={classes.attribute}>
          <label>Branch</label>
          <Input readOnly type="text" defaultValue={data.branchName} />
        </div>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>Load Instructions</label>
          <TextArea
            rows={3}
            defaultValue={data?.loadInstructions}
            name="instructions"
          ></TextArea>
        </div>
        <div>
          <Button type="submit" variant="good" disabled={true}>
            Save Changes
          </Button>
        </div>
      </Form>

      <div className={classes.assignmentsContainer}>
        <div className={classes.assignment}>
          <h3>Driver </h3>{' '}
          <AssignDriver
            loadId={loadId}
            driverList={data.driverAssignmentOptions}
            truckList={data.truckOptions}
            trailerList={data.trailerOptions}
            RefreshPage={RefreshPage}
            GetLoad={GetLoad}
          />
          {data.assetAssignments?.map((driver, index) => (
            <React.Fragment key={index}>
              <Driver
                loadId={loadId}
                driver={driver}
                driverList={data.driverAssignmentOptions}
                truckList={data.truckOptions}
                trailerList={data.trailerOptions}
                RefreshPage={RefreshPage}
                GetLoad={GetLoad}
              />
              <h4>
                Last Contacted:{' '}
                {data?.lastContactDate
                  ? DateTimeUTCFormatter(data.lastContactDate)
                  : 'Not yet contacted'}
              </h4>
            </React.Fragment>
          ))}
        </div>
        <div className={classes.assignment}>
          <h3>Vendor</h3>
          <AssignVendor
            loadId={loadId}
            vendorList={data.vendorAssignmentOptions}
            RefreshPage={RefreshPage}
            isBBIAssignedLoad={data.isBBIAssignedLoad}
          />
          {data.vendorAssignments?.map((vendor, index) => (
            <React.Fragment key={index}>
              <Vendor
                vendor={vendor}
                loadId={loadId}
                RefreshPage={RefreshPage}
              />
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className={classes.viewGrid}>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <h3>Stops</h3>
          <h4>Total Distance: {data.totalDistance?.toLocaleString()} miles</h4>
          <BBITable
            data={data.stopList ?? []}
            columns={stopTableColumnConfig}
          />
          <div
            className={classes.cTA}
            onClick={() => HandlePageChange('Itinerary')}
          >
            View Itinerary
          </div>
        </div>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <div
            className={classes.cTA}
            onClick={() => setDisplayDuplicateLoad(!displayDuplicateLoad)}
          >
            Duplicate Load
          </div>
          {displayDuplicateLoad && <DuplicateLoad type="load" id={loadId} />}
        </div>
      </div>
    </>
  );
}
