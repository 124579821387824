import React, { useEffect, useState } from 'react';

import Slab from '../../../helpers/slab/Slab';
import Details from './Details';
import Summary from './Summary';
import Audit from '../generic/Audit';
import Admin from './Admin';
import Services from './Services';
import Get from '../../../api/internal/Get';
import { useRoleAuthorization } from '../../../hooks/useRoleAuthorization';
import { ADMIN_AUTHORIZED_ROLES } from '../../../auth/AuthRoles';

function Location({
  locationId,
  locationName,
  startPage,
}: {
  locationId: number;
  locationName: string;
  startPage?: string;
}) {
  const [currentPage, setCurrentPage] = useState();
  const [locationDataObject] = useState<LocationDataObject>({
    Admin: null,
    Audit: null,
    Details: null,
    Services: null,
    Summary: null,
  });

  useEffect(() => {
    startPage ? HandlePageChange(startPage) : HandlePageChange('Summary');
  }, []);

  function HandlePageChange(params) {
    if (!locationDataObject[params] && pages[params].callerPath) {
      Get(pages[params].callerPath).then(response => {
        if (response) {
          locationDataObject[params] = response.data;
        }
        AssignPages();
        setCurrentPage(params);
      });
    } else {
      setCurrentPage(params);
    }
  }

  function RefreshPage(currentPage: string, additionalPages: string[]) {
    if (locationDataObject) {
      setCurrentPage(null);
      locationDataObject[currentPage] = null;
      additionalPages?.forEach(pageName => {
        locationDataObject[pageName] = null;
      });
      HandlePageChange(currentPage);
    }
  }

  let pages = {};
  AssignPages();
  function AssignPages() {
    pages = {
      Summary: {
        content: (
          <Summary
            HandlePageChange={HandlePageChange}
            data={locationDataObject.Summary}
          />
        ),
        callerPath: `/Location/GetLocationSummary/${locationId}`,
      },
      Details: {
        content: (
          <Details
            locationId={locationId}
            data={locationDataObject.Details}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Location/GetLocationDetails/${locationId}`,
      },
      Services: {
        content: (
          <Services
            locationId={locationId}
            data={locationDataObject.Services}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Location/GetLocationServices/${locationId}`,
      },
      Audit: {
        content: <Audit data={locationDataObject.Audit} />,
        callerPath: `/Location/GetLocationAudit/${locationId}`,
      },
    };
    if (useRoleAuthorization(ADMIN_AUTHORIZED_ROLES))
      pages['Admin'] = {
        content: (
          <Admin
            locationId={locationId}
            data={locationDataObject.Admin}
            RefreshPage={RefreshPage}
          />
        ),
        callerPath: `/Location/GetLocationAddress/${locationId}`,
      };
  }

  return (
    <Slab
      title={'Location ' + locationName}
      pages={pages}
      currentPage={currentPage}
      HandlePageChange={HandlePageChange}
      dataObject={locationDataObject}
    />
  );
}

export default Location;
