import React, { useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import classes from '../../../assets/Global.module.css';
import FinancialTable from '../../../helpers/financialsEditableTable/FinancialsTable';
import PostBody from '../../../api/internal/PostBody';
import View from '../../../helpers/slab/View';
import ViewCustomer from '../customer/Customer';
import AssembleUpdatedFinancial from '../../../helpers/financialsEditableTable/AssembleFinancialObject';
import ViewReceivable from '../receivable/Receivable';
import Button from '../../../assets/essentials/Button';

function RentalFinancials({
  data,
  rentalId,
  RefreshPage,
}: {
  data: RentalFinancials;
  rentalId: number;
  RefreshPage: Function;
}) {
  const [receivablesVisible, setReceivablesVisible] = useState(true);

  const ReceivableSection = ({
    customer,
  }: {
    customer: RentalCustomerAssignmentFinancialObject;
  }) => {
    function SaveDraftReceivable(e, assignmentId) {
      e.preventDefault();
      PostBody(
        'Accounting/SaveDraftFinancial',
        AssembleUpdatedFinancial(e, assignmentId, 'Customer'),
      ).then(response => {
        if (response) {
          RefreshPage('Financials', ['Audit', 'Summary']);
        }
      });
    }

    const SubheaderLinksViewCustomerAndReceivable = () => {
      return (
        <div className={classes.flexSpaceBetween}>
          <div className={classes.sideBySideButtons}>
            <div
              className={`${classes.cTA}`}
              onClick={() =>
                View(
                  <ViewCustomer
                    customerId={customer.customerId}
                    customerName={customer.customerName}
                  />,
                )
              }
            >
              View Customer
            </div>
            <div
              className={`${classes.cTA}`}
              onClick={() =>
                View(
                  <ViewReceivable
                    customerAssignmentId={customer.customerAssignmentId}
                    loadId={null}
                    rentalId={rentalId}
                  />,
                )
              }
            >
              View Receivable
            </div>
          </div>
          <div>Status: {customer.paymentStatus}</div>
        </div>
      );
    };

    return (
      <div key={customer.customerAssignmentId}>
        {receivablesVisible && (
          <>
            <div className={classes.flexColumn}>
              <h3 className={classes.headerWithoutMargin}>
                {customer.customerName}
              </h3>
              <SubheaderLinksViewCustomerAndReceivable />
            </div>
            <FinancialTable
              rowData={customer.receivables}
              assignmentId={customer.customerAssignmentId}
              assignmentType="Customer"
              accountTypeOptions={customer.receivableAccountTypeOptions}
              onSubmit={SaveDraftReceivable}
            />
          </>
        )}
      </div>
    );
  };

  function CreateAdditionalInvoice() {
    PostBody(`/rental/CreateRentalInvoice/${rentalId}`, null).then(response => {
      if (response) {
        RefreshPage('Financials', ['Audit', 'Summary']);
      }
    });
  }

  return (
    <>
      {/*Receivables per Customer */}
      <div className={classes.grayBottomDivider}>
        <span className={classes.flexSpaceBetween}>
          <h2 onClick={() => setReceivablesVisible(!receivablesVisible)}>
            Invoices {receivablesVisible ? <BsChevronDown /> : <BsChevronUp />}
          </h2>
          <h3>
            Total Receivable: $
            {data.totalReceivable?.toLocaleString('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </h3>
        </span>
        {data.customerFinancialsList?.map(customer => (
          <ReceivableSection
            key={customer.customerAssignmentId}
            customer={customer}
          />
        ))}
        <span className={classes.flexAlignCenterJustifyRight}>
          <Button onClick={CreateAdditionalInvoice} variant="good">
            {data.customerFinancialsList.length > 0
              ? 'Create Additional Invoice'
              : 'Create Invoice'}
          </Button>
        </span>
      </div>
    </>
  );
}

export default RentalFinancials;
