import React, { useState, useEffect } from 'react';

import Details from './Details';
import Panel from '../../../helpers/panel/Panel';
import PostBody from '../../../api/internal/PostBody';
import Close from '../../../helpers/panel/Close';
import Get from '../../../api/internal/Get';

function Dispatch({ GetDispatchBoard }) {
  const [createDispatchOptions, setCreateDispatchOptions] =
    useState<CreateDispatchOptions>();
  const [createDispatchParam, setCreateDispatchParam] =
    useState<CreateDispatchParam>(
      {
        accessType: "Default Access",
        branchId: null,
        firstName: null,
        lastName: null,
        phone: null,
        phoneExt: null,
        mobile: null,
        email: null,
        hireDate: null,
        note: null,
        payrollId: null
      }
    );

  useEffect(() => {
    Get(`/Employee/GetCreateDispatch`).then(response => {
      if (response) {
        setCreateDispatchOptions(response.data);
      }
    });
  }, []);

  function CreateDispatch() {
    PostBody('Employee/CreateDispatch', createDispatchParam).then(response => {
      if (response) {
        GetDispatchBoard();
        Close();
      }
    });
  }

  const FormatHireDate = () => {
    const parts = createDispatchParam.hireDate.toString().split('-');
    return `${parts[1]}/${parts[2]}/${parts[0]}`;
  };

  let pages = [
    {
      title: 'Details',
      displayedIcon: '',
      content: (
        <Details
          createDispatchOptions={createDispatchOptions}
          createDispatchParam={createDispatchParam}
          setCreateDispatchParam={setCreateDispatchParam}
        />
      ),
      require: [
        {
          label: 'Name',
          FunctionBool: () =>
            createDispatchParam.firstName !== '' &&
            createDispatchParam.firstName &&
            createDispatchParam.lastName !== '' &&
            createDispatchParam.lastName,
          assignedValue: () =>
            createDispatchParam.firstName + ' ' + createDispatchParam.lastName,
          missingDesc: 'Name has not been properly assigned.',
        },
        {
          label: 'Phone',
          FunctionBool: () =>
            createDispatchParam.phone !== '' &&
            createDispatchParam.phone &&
            // Accounting for the phone number being formatted with the country code
            createDispatchParam?.phone?.startsWith('+')
              ? createDispatchParam?.phone?.length >= 17
              : createDispatchParam?.phone?.length >= 14,
          assignedValue: () =>
            createDispatchParam.phone +
            (createDispatchParam.phoneExt
              ? ' Ext: ' + createDispatchParam.phoneExt
              : ''),
          missingDesc: 'Phone Number has not been properly assigned',
        },
        {
          label: 'Hire Date',
          FunctionBool: () => createDispatchParam.hireDate,
          assignedValue: () => FormatHireDate(),
          missingDesc: 'Hire Date has not been assigned.',
        },
        {
          label: 'Branch',
          FunctionBool: () => createDispatchParam.branchId,
          assignedValue: () =>
            createDispatchOptions?.branchOptions?.find(
              branch =>
                branch.branchId === Number(createDispatchParam.branchId),
            )?.branchName,
          missingDesc: 'Branch has not been assigned.',
        },
        {
          label: 'Access Type',
          FunctionBool: () => createDispatchParam.accessType,
          assignedValue: () => createDispatchParam.accessType,
        },
      ],
    },
  ];

  function OnSubmit() {
    let fulfillsFormRequirements = true;
    pages.forEach((page, index) => {
      if (!FulFillsPageRequirements(index)) {
        fulfillsFormRequirements = false;
      }
    });
    if (fulfillsFormRequirements) {
      CreateDispatch();
      Close();
    } else alert('Not all info included');
  }

  function FulFillsPageRequirements(pageIndex) {
    let fulfillsRequirements = true;
    pages[pageIndex].require.forEach(requirement => {
      if (!requirement.FunctionBool()) {
        fulfillsRequirements = false;
      }
    });
    return fulfillsRequirements;
  }

  return <Panel OnSubmit={OnSubmit} title="Create Dispatch" pages={pages} />;
}

export default Dispatch;
