import React from 'react';
import classes from '../../../../assets/Global.module.css';

function Summary({
  data,
  HandlePageChange,
}: {
  data: TruckSummary;
  HandlePageChange: Function;
}) {
  return (
    <div className={classes.viewGrid}>
      <div className={classes.attribute}>
        <h3>Make & Model</h3>
        <span>{data?.makeModel}</span>
        <span>Color: {data?.color}</span>
        <div
          className={classes.cTA}
          onClick={() => HandlePageChange('Details')}
        >
          Manage Truck Info
        </div>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <h3>License Plate</h3>
        {data?.licensePlateDisplayList.map((line, index) => (
          <span key={index}>{line}</span>
        ))}
      </div>
      <div className={classes.attribute}>
        <h3>Type</h3>
        <span>{data?.truckType}</span>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <h3>VIN</h3>
        <span>{data?.vin}</span>
      </div>
      <div className={classes.attribute}>
        <h3>ELD</h3>
        {data?.eldDisplayList.map((line, index) => (
          <span key={index}>{line}</span>
        ))}
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <h3>Mileage</h3>
        {data?.mileageDisplayList.map((line, index) => (
          <span key={index}>{line}</span>
        ))}
      </div>
      <div>
        <h3>Gross Vehicle Weight Rating</h3>
        <span>
          {data?.grossVehicleWeightRating
            ? `${data?.grossVehicleWeightRating.toLocaleString()} lb.`
            : 'None'}
        </span>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <h3>Weight Permit Expiration Date</h3>
        <span>
          {data?.weightPermitExpirationDate
            ? new Date(data.weightPermitExpirationDate).toLocaleDateString()
            : ''}
        </span>
      </div>
      <div className={`${classes.attribute}`}>
        <h3>Branch</h3>
        <span>{data?.branchName}</span>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <h3>IFTA #</h3>
        <span>{data?.internationalFuelTaxAgreementNumber}</span>
      </div>
      <div>
        <h3>Ownership</h3>
        <span>{data.isOwned ? 'Owned' : 'Leased'}</span>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <h3>Note</h3>
        <span>{data?.note}</span>
      </div>
    </div>
  );
}
export default Summary;
