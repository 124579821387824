import React from 'react';

import classes from '../../../assets/Global.module.css';
import Input from '../../../assets/essentials/Input';
import Select from '../../../assets/essentials/Select';
import OptionList from '../../../helpers/options/OptionList';
import TextArea from '../../../assets/essentials/TextArea';
import Phone from '../../../helpers/inputs/Phone';

function Details({
  createDispatchOptions,
  createDispatchParam,
  setCreateDispatchParam,
}: {
  createDispatchOptions: CreateDispatchOptions;
  createDispatchParam: CreateDispatchParam;
  setCreateDispatchParam: Function;
}) {
  function UpdateCreateDispatchParam(newValue, attributeName) {
    if (newValue?.toString().trim().length !== 0) {
      setCreateDispatchParam(prevState => ({
        ...prevState,
        [attributeName]: newValue,
      }));
    }
  }

  return (
    <form className={classes.viewGrid}>
      <div className={classes.attribute}>
        <label>
          First Name <span className={classes.required}>*</span>
        </label>
        <Input
          defaultValue={createDispatchParam?.firstName}
          name="firstName"
          onChange={e => UpdateCreateDispatchParam(e.target.value, 'firstName')}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>
          Last Name <span className={classes.required}>*</span>
        </label>
        <Input
          defaultValue={createDispatchParam?.lastName}
          name="lastName"
          onChange={e => UpdateCreateDispatchParam(e.target.value, 'lastName')}
        />
      </div>
      <div className={classes.attribute}>
        <label>
          Phone <span className={classes.required}>*</span>
        </label>
        <Phone
          defaultValue={createDispatchParam?.phone}
          name="phone"
          onChange={e => UpdateCreateDispatchParam(e.target.value, 'phone')}
          expectsEventObject={true}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Ext</label>
        <Input
          defaultValue={createDispatchParam?.phoneExt}
          name="phoneExt"
          onChange={e => UpdateCreateDispatchParam(e.target.value, 'phoneExt')}
        />
      </div>
      <div className={classes.attribute}>
        <label>Mobile</label>
        <Phone
          defaultValue={createDispatchParam?.mobile}
          name="mobile"
          onChange={e => UpdateCreateDispatchParam(e.target.value, 'mobile')}
          expectsEventObject={true}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Email</label>
        <Input
          defaultValue={createDispatchParam?.email}
          name="email"
          onChange={e => UpdateCreateDispatchParam(e.target.value, 'email')}
        />
        <p className={classes.inputNote}>
          A bbitransportation email is required for app access.
        </p>
      </div>
      <div className={classes.attribute}>
        <label>
          Hire Date <span className={classes.required}>*</span>
        </label>
        <Input
          defaultValue={createDispatchParam?.hireDate}
          name="hireDate"
          onChange={e => UpdateCreateDispatchParam(e.target.value, 'hireDate')}
          type="date"
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>
          Branch <span className={classes.required}>*</span>
        </label>
        <Select
          defaultValue={createDispatchParam?.branchId ?? ''}
          name="branchId"
          onChange={e => {
            UpdateCreateDispatchParam(e.target.value, 'branchId');
          }}
        >
          <option disabled value="">
            -- Required --
          </option>
          <OptionList
            optionData={createDispatchOptions?.branchOptions}
            attributeID="branchId"
            attributeName="branchName"
          />
        </Select>
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Access Type</label>
        <Select
          defaultValue={createDispatchParam.accessType}
          name="accessType"
          onChange={e => {
            UpdateCreateDispatchParam(e.target.value, 'accessType');
          }}
        >
          <OptionList
            optionData={createDispatchOptions?.accessOptions}
            attributeID="accessTypeName"
            attributeName="accessTypeName"
          />
        </Select>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>Note</label>
        <TextArea
          defaultValue={createDispatchParam?.note}
          rows={4}
          name="note"
          onChange={e => UpdateCreateDispatchParam(e.target.value, 'note')}
        />
      </div>
    </form>
  );
}

export default Details;
