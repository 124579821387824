import React, { useState, useRef } from 'react';
import classes from '../../../assets/Global.module.css';
import accordionClasses from '../../../assets/Accordion.module.css';
import GoogleSearchBoxEstablishment from '../../../helpers/inputs/GoogleSearchBoxEstablishment';
import TextArea from '../../../assets/essentials/TextArea';
import Input from '../../../assets/essentials/Input';
import Create from '../../../helpers/error/Create';
import Error from '../../../helpers/error/Error';
import classesAssign from '../../assign/Assign.module.css';
import Select from '../../../assets/essentials/Select';
import StatesAndProvinces from '../../../helpers/options/StatesAndProvinces';
import TransportationCaller from '../../../api/internal/TransportationCaller';

function LocationForm({ locationInfo, setLocationInfo }) {
  const MINIMUM_SEARCH_CHARS = 1;
  const [searchResults, setSearchResults] = useState([]);
  const [createLocation, setCreateLocation] = useState(false);
  const createLocationButtonRef = useRef(null);

  /*
     - Resetting the location object has to be handled differently for each case
     - The searchTerm in HandleDBSearch must be maintained between responses from the backend
     - Setting locationInfo to null will not rerender, so the user can keep typing
     - Creating a new location needs the page to rerender to clear the fields and prevent the user from sumbitting
    */
  function ResetLocation(renderLocationObj) {
    // checking DB
    if (!renderLocationObj) {
      setLocationInfo(null);
    } else {
      // creating new location
      setLocationInfo({
        // React will treat this like a new object and rerender to clear out the fields
        ianaTimeZone: '',
        locationName: '',
        addressLineOne: '',
        addressLineTwo: '',
        city: '',
        state: '',
        zip: '',
        country: '',
        phone: '',
        phoneExt: '',
        email: '',
        hours: '',
        directions: '',
        specialInstructions: '',
        website: '',
        searchResult: '',
      });
    }
  }

  // Search current location directory
  function HandleDBSearch(searchTerm) {
    if (searchResults.length) {
      let searchLocationMatch = searchResults.find(
        result => result.searchResult === searchTerm,
      );
      if (searchLocationMatch) {
        UpdateLocation(searchLocationMatch.locationId, 'locationId');
        UpdateLocation(searchLocationMatch.ianaTimeZone, 'ianaTimeZone');
        setLocationInfo(searchLocationMatch);
      } else {
        ResetLocation(false);
      }
    } else ResetLocation(false);
    if (searchTerm.length >= MINIMUM_SEARCH_CHARS) {
      const encodedSearchString = encodeURIComponent(searchTerm);
      TransportationCaller.get(
        `/Location/SearchLocationByLocationName?searchTerm=${encodedSearchString}`,
      ).then(response => {
        if (!response || response.status === 204) {
          return;
        }
        setSearchResults(response?.data);
      });
    }
  }

  function UpdateLocation(newValue, attributeName) {
    if (newValue?.toString().trim().length !== 0) {
      setLocationInfo(prevParams => ({
        ...prevParams,
        [attributeName]: newValue,
      }));
    }
  }

  // Extract address components from Google Place
  function HandleNewLocationCallBack(place) {
    const renderLocationObj = true;
    const {
      name,
      formatted_phone_number: phone,
      website,
      address_components,
    } = place;

    let addressLineOne = '';
    let streetNumber = '';
    let streetName = '';
    let city = '';
    let state = '';
    let zip = '';
    let country = '';
    let requiredFields = [];

    address_components.forEach(googleResult => {
      googleResult.types.forEach(type => {
        if (type === 'street_number') {
          streetNumber = googleResult.short_name;
        } else if (type === 'route') {
          streetName = googleResult.short_name;
        } else if (type === 'locality') {
          city = googleResult.short_name;
        } else if (type === 'administrative_area_level_1') {
          state = googleResult.short_name;
        } else if (type === 'postal_code') {
          zip = googleResult.short_name;
        } else if (type === 'country') {
          country = googleResult.short_name;
        }
      });
    });

    if (streetNumber && streetName) {
      addressLineOne = streetNumber + ' ' + streetName;
    }

    requiredFields.push(addressLineOne, city, state, zip, country);
    for (const field of requiredFields) {
      if (field.trim() === '') {
        ResetLocation(renderLocationObj);
        Create(
          <Error customMessage="Location does not contain all required fields. Please try a different location." />,
        );
        return;
      }
    }
    UpdateLocation(name, 'locationName');
    UpdateLocation(addressLineOne, 'addressLineOne');
    UpdateLocation(city, 'city');
    UpdateLocation(state, 'state');
    UpdateLocation(zip, 'zip');
    UpdateLocation(country, 'country');
    UpdateLocation(phone, 'phone');
    UpdateLocation(website, 'website');

    //Removes locationId when creating a new location
    UpdateLocation(null, 'locationId');
  }

  const AutoCompleteBehavior = createLocation ? (
    // If we are creating a location, we want the search box to search Google for location info
    <>
      <GoogleSearchBoxEstablishment
        assignPlace={HandleNewLocationCallBack}
        id="location"
      />
      <div className={classes.cTA}>
        <label className={classes.cTA}>
          Add existing location
          <div className={classes.hiddenToggler}>
            <Input
              type="checkbox"
              defaultChecked={createLocation}
              onChange={() => setCreateLocation(false)}
              name="toggleNewLocation"
              className={classes.hiddenToggler}
            />
          </div>
        </label>
      </div>
    </>
  ) : (
    // If we are not creating a location, we will display the datalist that calls the backend
    <>
      <Input
        type="text"
        value={locationInfo?.searchResult}
        onChange={e => HandleDBSearch(e.target.value)}
        placeholder="Search for saved locations..."
        list="optionsList"
        name="location"
        id="location"
        autoComplete="off"
      />
      <div
        className={`${classes.cTA}`}
        tabIndex={0}
        ref={createLocationButtonRef}
        onClick={e => {
          e.preventDefault();
          setCreateLocation(!createLocation);
        }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            setCreateLocation(true);
          }
        }}
      >
        <label className={classes.cTA}>Add new location</label>
      </div>
      <datalist id="optionsList">
        {searchResults?.map(location => (
          <option key={location.locationId} value={location.searchResult} />
        ))}
      </datalist>
    </>
  );

  // Editable fields that are only rendered when the createLocation variable is true
  const CreateLocationAdditionalFields = () => {
    return (
      <>
        <div
          className={`${classes.attribute} ${classes.span3} ${classesAssign.createLocation} ${accordionClasses.createLocation}`}
        >
          <label>Hours</label>
          <TextArea
            defaultValue={locationInfo?.hours}
            onBlur={e => {
              UpdateLocation(e.target.value, 'hours');
            }}
          />
        </div>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>Directions</label>
          <TextArea
            defaultValue={locationInfo?.directions}
            onBlur={e => UpdateLocation(e.target.value, 'directions')}
            type="text"
          />
        </div>
      </>
    );
  };

  return (
    <>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <strong>Location Information</strong>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>Auto-Complete</label>
        {AutoCompleteBehavior}
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>
          Name <span className={classes.required}>*</span>
        </label>
        <Input
          disabled
          value={locationInfo ? locationInfo?.locationName : ''}
        />
      </div>
      <div className={classes.attribute}>
        <label>
          Address Line One <span className={classes.required}>*</span>
        </label>
        <Input
          disabled
          value={locationInfo?.addressLineOne}
          onChange={e => UpdateLocation(e.target.value, 'addressLineOne')}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>Address Line Two</label>
        <Input
          disabled
          value={locationInfo?.addressLineTwo}
          onChange={e => UpdateLocation(e.target.value, 'addressLineTwo')}
        />
      </div>
      <div className={classes.attribute}>
        <label>
          City <span className={classes.required}>*</span>
        </label>
        <Input
          disabled
          value={locationInfo?.city}
          onChange={e => UpdateLocation(e.target.value, 'city')}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>
          State/Province <span className={classes.required}>*</span>
        </label>
        <Select
          disabled
          value={locationInfo?.state}
          onChange={e => UpdateLocation(e.target.value, 'state')}
        >
          <option value="" />
          <StatesAndProvinces />
        </Select>
      </div>
      <div className={classes.attribute}>
        <label>
          Zip <span className={classes.required}>*</span>
        </label>
        <Input
          disabled
          value={locationInfo?.zip}
          onChange={e => UpdateLocation(e.target.value, 'zip')}
        />
      </div>
      <div className={`${classes.attribute} ${classes.span2}`}>
        <label>
          Country <span className={classes.required}>*</span>
        </label>
        <Select
          disabled
          value={locationInfo?.country || ''}
          onChange={e => UpdateLocation(e.target.value, 'country')}
        >
          <option value="" />
          <option value="US">US</option>
          <option value="CA">Canada</option>
        </Select>
      </div>
      <div className={`${classes.attribute} ${classes.span3}`}>
        <label>Special Instructions</label>
        <TextArea
          value={locationInfo?.specialInstructions}
          disabled={createLocation ? false : true}
          onChange={e => UpdateLocation(e.target.value, 'specialInstructions')}
        />
      </div>
      {createLocation && <CreateLocationAdditionalFields />}
    </>
  );
}

export default LocationForm;
