import React, { useState } from 'react';
import { BsTelephonePlus } from 'react-icons/bs';

import classes from './Contacts.module.css';
import classesGlobal from '../../../assets/Global.module.css';
import PostForm from '../../../api/internal/PostForm';
import Button from '../../../assets/essentials/Button';
import Input from '../../../assets/essentials/Input';
import TextArea from '../../../assets/essentials/TextArea';
import Form from '../../../assets/essentials/Form';
import Phone from '../../../helpers/inputs/Phone';

function Contacts({
  vendorId,
  RefreshPage,
}: {
  vendorId: Number;
  RefreshPage: Function;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [phone, setPhone] = useState<string>('');
  const [email, setEmail] = useState<string>('');

  const [contactData, setContactData] = useState({
    email: '',
    phone: '',
  });

  function CreateContact(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    PostForm(`/Vendor/CreateVendorContact`, formData, {
      vendorId: vendorId,
    }).then(response => {
      if (response) RefreshPage('Contacts', ['Audit']);
    });
    setIsOpen(false);
  }

  return (
    <>
      <div className={classes.btnHolder}>
        <button type="button" onClick={() => setIsOpen(!isOpen)}>
          <BsTelephonePlus />
          <span>Create a Contact</span>
        </button>
      </div>
      <div className={classes.createContactHolder}>
        {isOpen && (
          <Form onSubmit={e => CreateContact(e)}>
            <div className={classesGlobal.attribute}>
              <label>
                First Name <span className={classesGlobal.required}>*</span>
              </label>
              <Input type="text" name="firstName" required={true} />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>
                Last Name <span className={classesGlobal.required}>*</span>
              </label>
              <Input type="text" name="lastName" required={true} />
            </div>
            <div className={classesGlobal.attribute}>
              <label>
                Phone{' '}
                {email ? (
                  '*'
                ) : (
                  <span className={classesGlobal.required}>*</span>
                )}
              </label>
              <Phone
                name="phone"
                defaultValue={contactData.phone ? contactData.phone : null}
                required={!email}
                onChange={e => setPhone(e.target.value)}
                expectsEventObject={true}
              />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>Ext</label>
              <Input type="text" name="phoneExt" />
            </div>
            <div className={classesGlobal.attribute}>
              <label>Position</label>
              <Input type="text" name="position" />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span2}`}
            >
              <label>
                Email{' '}
                {phone ? (
                  '*'
                ) : (
                  <span className={classesGlobal.required}>*</span>
                )}
              </label>
              <Input
                type="email"
                name="email"
                defaultValue={contactData.email}
                required={!phone}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span3}`}
            >
              <label>Notes</label>
              <TextArea name="note" />
            </div>
            <div
              className={`${classesGlobal.attribute} ${classesGlobal.span3}`}
            ></div>
            <div>
              <Button type="submit" variant="good" disabled={true}>
                Submit
              </Button>
            </div>
            <div>
              <Button onClick={() => setIsOpen(!isOpen)}>Cancel</Button>
            </div>
          </Form>
        )}
      </div>
    </>
  );
}

export default Contacts;
